/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import './MultiCodeReader.css';
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType  } from '@zxing/library';

interface IMultiCodeReader {
  readCode: (...arg: any[]) => void
}

const MultiCodeReader: React.FC<IMultiCodeReader> = (props) => {
  const { readCode } = props;

  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [selectedVideoDevice, selectVideoDevice] = useState("");

  const reader = useRef(new BrowserMultiFormatReader());

  useEffect(() => {
    const hints = new Map();
    const formats = [BarcodeFormat.CODABAR];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, true);
    reader.current = new BrowserMultiFormatReader();

    (async () => {
      const videoInputDeviceList = await reader.current.listVideoInputDevices();
      setVideoInputDevices(videoInputDeviceList.map(d => d.deviceId));
      if (videoInputDeviceList.length > 0 && selectedVideoDevice==null) {
          selectVideoDevice(videoInputDeviceList[0].deviceId);
      }
    })();

    reader.current.decodeFromVideoDevice(selectedVideoDevice, "videoElement", (res, err) => {
      if (res) if(readCode) props.readCode(res?.getText());
      if (err) console.log(err);
    });

    return () => {
      reader.current?.reset();
    }
  }, [selectedVideoDevice]);



  return (

    <div className="multicode-reader-content">
      <video id="videoElement"/>
      <div className="switch-camera" onClick={() => {
        if(videoInputDevices.length > 0) {
          console.log(videoInputDevices)
          const index = videoInputDevices.indexOf(selectedVideoDevice);
          const next = index + 1 < videoInputDevices.length? index + 1 : 0;
          selectVideoDevice(videoInputDevices[next]);
        }
      }}>
        <span className="material-icons">sync</span>
      </div>
    </div>
  );
};

export default MultiCodeReader;
