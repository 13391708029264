/* eslint-isDisable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './CartItem.css';
import { CartItemType, ProductType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import { number2jpmoney } from 'src/utils/numberUtils';

interface ICartItem {
  cartItem: CartItemType;
  product: ProductType;
  isDisable?: boolean;
  onDelete?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
}

const CartItem: React.FC<ICartItem> = (props) => {
  //Value

  const { cartItem, product, onDelete, onChange, isDisable } = props;
  const [productName, setProductName] = useState<string>();
  const [productCode, setProductCode] = useState<string>();
  const [avatar, setAvatar] = useState<string>();
  const [price, setPrice] = useState<number>();
  const addPopup = useAddPopup();


  const onMinusQuantity = () => {
    if (cartItem.quantity > 1) {
      const nCartItem = { ...cartItem };
      nCartItem.quantity--;
      onChange(nCartItem);
    } else {
      onDelete(cartItem);
    }
  };

  const onPlusQuantity = () => { 
    if(product.stockRemainQuantity - cartItem.quantity > 0) {
      const nCartItem = { ...cartItem };
      nCartItem.quantity++;
      onChange(nCartItem);
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Số lượng chọn trong giỏ hàng không được vượt quá số lượng tồn kho!',
        },
      });
    }
  };

  const onChangeQuantity = (value: string) => {
    const quantity = Number(value);
    if (!isNaN(quantity)) {
      if(product.stockRemainQuantity - quantity >= 0) {
        const nCartItem = { ...cartItem };
        nCartItem.quantity == quantity;
        onChange(nCartItem);
      } else {
        addPopup({
          txn: {
            success: false,
            summary: 'Số lượng chọn trong giỏ hàng không được vượt quá số lượng tồn kho!',
          },
        });
      }
    }
  };

  useEffect(() => {
    setProductName(product?.productName);
    setProductCode(product?.productCode);
    setPrice(product?.price);
    setAvatar(product?.thumbAvatar);

  }, [product])

  //Main
  return (
    <div className='cartitem-container'>
      <img className='cartitem-image' src={avatar} />
      <div className='cartitem-content'>
        <div className='cartitem-name'><label className='text-medium' title={`${productName} (${productCode})`}>{productName} ({productCode})</label> <label className='cart-price' title={number2jpmoney(price)}>{number2jpmoney(price)}</label></div>
        <div className='cartitem-action'>
          <div className='cartitem-action-quantity'>
            <span className="material-icons text-small" onClick={isDisable ? undefined : onMinusQuantity}>remove</span>
            <input className='text-small' value={cartItem.quantity} disabled={isDisable} onChange={(e) => onChangeQuantity(e.target.value)} />
            <span className="material-icons text-small" onClick={isDisable ? undefined : onPlusQuantity}>add</span>
          </div>

          {!isDisable ? <span className="material-icons cartitem-remove" onClick={() => onDelete(cartItem)}>delete</span> : null}
        </div>


      </div>


    </div>
  );
};

export default CartItem;
