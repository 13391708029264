import React, { useState, useEffect } from 'react';
import {
  EnumShipOrderProgressStatus, ShipOrderProgressStatusList,
} from 'src/api/models';
import { usePutUpdateShipOrderProgressStatus } from 'src/api/shipOrderApi';
import { useAddPopup } from 'src/state/application/hooks';
import './UpdateShipOrderProgressStatus.css';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IUpdateShipOrderProgressStatus {
  checkListIds: number[];
  postProcess?: (...args: any[]) => void;
}

const UpdateShipOrderProgressStatus: React.FC<IUpdateShipOrderProgressStatus> = (props) => {
  const {checkListIds, postProcess} = props
  //State
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>(EnumShipOrderProgressStatus.JapanStore);
  //Function
  const putUpdateShipOrderProgressStatus = usePutUpdateShipOrderProgressStatus();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve) => {
    putUpdateShipOrderProgressStatus(checkListIds, progressStatus)
      .then((res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Cập nhật trạng thái thành công',
          },
        });
        if(postProcess) postProcess();
        resolve(true);
      }).catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        resolve(false);
      });
  });

  //Main
  return (
    <div className="updateshiporderstatus-container">
      <div className="updateshiporderstatus-row">
        <SelectBoxComponent
          width="360px"
          require={true}
          onChange={setProgressStatus}
          isDisable={false}
          title={'Trạng thái'}
          value={progressStatus}
          data={ShipOrderProgressStatusList}
          valueType={'value'}
          titleType={'title'}
        />
      </div>
      <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
    </div>
  );
};

export default UpdateShipOrderProgressStatus;
