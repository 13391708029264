/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { EnumAction, EnumShipOrderProgressStatus, ImageType, ShipOrderProgressStatusList, ShipPackageType, ShipInvoiceType, EnumExportBillStatus, ExportBillType, OrderRequirementType, EventButton, ExportBillStatus } from 'src/api/models';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import { useAddPopup } from 'src/state/application/hooks';
import "./ExportBillDetail.css";
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useGetShipInvoiceById, usePostShipInvoice, usePutShipInvoice } from 'src/api/shipInvoiceApi';
import DataScanList from 'src/components/DataScanList';
import { useGetShipPackageByCode } from 'src/api/shipPackageApi';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import { useGetExportBill, usePutExportBill } from 'src/api/exportBillApi';
import { useGetOrderRequirements } from 'src/api/orderRequirementApi';
import Cart from 'src/components/Cart/Cart';
import { InspectionRowType } from 'src/views/StockInspection/StockInspectionDetail/StockInspectionDetail';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import ExtraInfoInspection from 'src/components/ExtraInfoInspection/ExtraInfoInspection';
import { BASE_WEB_URL } from 'src/constants';

interface IExportBillDetail {
  exportBillId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ExportBillDetail: React.FC<IExportBillDetail> = (props) => {

  const [exportBillId, setExportBillId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);

  const params = useParams<{type: string; exportBillId: string }>();

  useEffect(() => {
    setExportBillId(props.exportBillId || Number(params.exportBillId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [params.exportBillId, params.type, props.isDisable, props.exportBillId]);

  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();

  const [note, setNote] = useState<string>();
  const [createdAt, setCreatedAt] = useState<string>();
  const [createdBy, setCreatedBy] = useState<string>();
  const [orders, setOrders] =  useState<OrderRequirementType[]>([]);
  const [status, setStatus] = useState<EnumExportBillStatus>(EnumExportBillStatus.Waiting);
  const [products, setProducts] = useState<InspectionRowType[]>([]);
  const [scanCode, setScanCode] = useState<string>();
  const [productCode, setProductCode] = useState<string>();
  const [orderProducts, setOrderProducts] = useState<{[productCode: string]: number[]}>({});
  //End of state

  //Function
  const addPopup = useAddPopup();
  const getExportBill = useGetExportBill();
  const putExportBill =  usePutExportBill();
  const getOrderRequirements = useGetOrderRequirements();

  const qrReaderModal = useModal(QRCodeReader);
  const confirmModal = useModal(ConfirmModal);

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_WEB_URL}/export-bill/${exportBillId}/${EnumViewType.Edit}`);
  };

  //TODO
  const onSave = (status: number) => new Promise((resolve) => {
    const exportBill: ExportBillType = {
      exportBillId: exportBillId,
      product: JSON.stringify(products),
      status: status,
      orderId: JSON.stringify(orders.map(o => o.orderRequirementId)),
      note: note
    };
    putExportBill(exportBill).then(
      (res) => {
        setExportBillId(res.exportBillId);
        setIsDisable(true);
        addPopup({
          txn: {
            success: true,
            summary: 'Thay đổi phiếu xuất thành công',
          }
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        resolve(false);
      });
  });

  useEffect(() => {
    if(exportBillId) {
      getExportBill(exportBillId).then((res) => {
        setExportBillId(res.exportBillId);
        setProducts(res.product? JSON.parse(res.product) : []);
        setStatus(res.status);
        setCreatedAt(res.createdAt);
        setCreatedBy(res.createdBy);
        setNote(res.note);
        const orderIds = JSON.parse(res.orderId);
        getOrderRequirements(orderIds).then((res) => {
          setOrders(res);
          setOrderProducts(res.flatMap(o => o.orderRequirementDetails).map(d => {
            return {
              productId: d.productId,
              productCode: d.product.productCode,
              quantity: d.quantity
            }
          }).reduce((rv, x) => {
            if(Object.prototype.hasOwnProperty.call(rv, x.productCode)) {
              rv[x.productCode][1] += x.quantity;
            } else {
              rv[x.productCode] = [x.productId, x.quantity];
            }
            return rv;
          }, {} as {[productCode: string]: number[]}))
        })
      })
    }
  }, [exportBillId]);

  const onInsert = (v: string) => {
    const code = v?.trim();
    if(code?.length > 0) {
      const product = products.find(p => p.productCode == code);
      const message = product? `Trong danh sách xuất kho đã có ${product.quantity} sản phẩm ${product.productCode}. Bạn muốn tăng số lượng sản phẩm lên không?` : 'Chưa có sản phẩm này trong danh sách xuất kho. Bạn muốn thêm vào danh sách không?';
      const listButton: EventButton[] = [
        {
          name: 'Xác nhận',
          icon: 'check',
          actionType: EnumAction.Confirm,
          action: (number: number) => {
            if(product) {
              if(number > 0) {
                product.quantity = number;
              } else {
                product.quantity++;
              }
              setProducts([...products]);
            } else {
              console.log(number);
              setProducts([...products, {productCode: code, quantity: number > 0 ? number : 1}]);
            }
          },
          buttonClass: 'info',
          align: 'center',
        },
        {
          name: 'Hủy',
          icon: 'clear',
          actionType: EnumAction.Cancel,
          buttonClass: 'info100',
          align: 'center',
        },
      ];
      confirmModal.handlePresent(
        {
          question: message,
          listActionButton: listButton,
          postProcess: () => {
            confirmModal.handleDismiss();
            setScanCode(undefined);
          },
          hasInput: true,
          type: 'number',
          inputTitle: 'Nhập số lượng sản phẩm'
        },
        'XÁC NHẬN SẢN PHẨM',
      );
    }
  };

  useEffect(() => {
    if(scanCode) onInsert(scanCode);
  }, [scanCode])


  const onKeyDown = (event: KeyboardEvent) => {
    if(event.key == 'Enter') {
      onInsert(productCode);
      setProductCode(undefined);
    }
  }


  //Main
  return (
    <>
      {props.isSlide || props.isPopup ? null : (
        <ToolBar
          toolbarName={`XUẤT KHO NGÀY ${createdAt}`}
          listRightButton={isDisable? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`export-bill-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
        <div className='export-bill-info'>
          <div className="export-bill-row">
            <Cart orders={orders} />
          </div>
        </div>
        <div className='export-bill-info'>
          <div className="export-bill-row">
            <Input
              width="100%"
              title="Người xuất"
              require={false}
              disabled={true}
              value={createdBy}
            />
          </div>
          <div className="export-bill-row">
            <Input
              width="100%"
              title="Ghi chú"
              require={false}
              disabled={isDisable}
              value={note}
              onChange={setNote}
            />
          </div>
          <div className="export-bill-row">
            <SelectBoxComponent
              width="100%"
              require={true}
              isDisable={true}
              title={'Trạng thái'}
              value={status}
              data={ExportBillStatus}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          <div className='stock-inspection-tool'>
            <span className="stock-inspection-tool-left material-icons" onClick={() => {
              onInsert(productCode);
              setProductCode(undefined);
            }}>
              add_circle
            </span>
            <Input
              value={productCode}
              disabled={isDisable}
              onChange={setProductCode}
              type='text'
              onKeyDown={onKeyDown}
            />
            <span className="stock-inspection-tool-right material-icons" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: setScanCode
              }, "QUÉT MÃ QR");
            }}>
              qr_code_scanner
            </span>
          </div>
          
          {!isDisable && <div className="export-bill-row inline">
            <div>
              <ButtonComponent icon="save" title={'LƯU'} onClick={() => onSave(EnumExportBillStatus.Waiting)} loader={true} />
            </div>
            <ButtonComponent className='export-bill-done' icon="done" title={'HOÀN THÀNH'} onClick={() => onSave(EnumExportBillStatus.Exported)} loader={true} />
          </div>}
          <fieldset className='stock-inspection-content'>
            <legend className='stock-inspection-content-title'>Danh sách đã lấy</legend>
            {products.map((item, index) => (
              <div className='stock-inspection-row inline' key={`stock-inspection-row${index}`}>
                <Input
                  value={item.productCode}
                  disabled={true}
                  isCopy={true}
                />
                <span className="stock-inspection-multiplication material-icons" onClick={() => {
                  if(item.quantity > 1) {
                    item.quantity--;
                    setProducts([...products]);
                  } else {
                    setProducts(products.filter((o) => o != item));
                  }
                }}>arrow_drop_down</span>
                <input
                  className='stock-inspection-quantity'
                  type='number'
                  value={item.quantity}
                  onChange={(event) => {
                    const value = (event.target as HTMLInputElement).value;
                    item.quantity = value? isNaN(Number(value))? 0 : Number(value) : 0;

                    if(item.quantity > 100000000) {
                      addPopup({
                        error: {
                          message: 'Số lượng không đúng. Vui lòng kiểm tra lại!',
                          title: 'Đã có lỗi xảy ra!',
                        },
                      });
                    } else {
                      setProducts([...products]);
                    }
                  }}
                  onBlur={() => {
                    if(item.quantity < 1) {
                      setProducts(products.filter((o) => o != item));
                    }
                  }}
                />
                <span className="stock-inspection-multiplication material-icons" onClick={() => {
                  item.quantity++;
                  setProducts([...products]);
                }}>arrow_drop_up</span>
                <span
                  className="stock-inspection-clear material-icons"
                  onClick={() => {
                    setProducts(products.filter((o) => o != item));
                  }}
                >
                  clear
                </span>
                <ExtraInfoInspection 
                  data={Object.prototype.hasOwnProperty.call(orderProducts, item.productCode)? orderProducts[item.productCode] : undefined}
                  type={Object.prototype.hasOwnProperty.call(orderProducts, item.productCode)? orderProducts[item.productCode][1] == item.quantity? 'info' : 'warning' : 'error'} 
                  isDisable={true}
                />
              </div>
            ))}

          </fieldset>
          <fieldset className='stock-inspection-content'>
            <legend className='stock-inspection-content-title'>Danh sách còn thiếu</legend>
            {Object.entries(orderProducts).filter(([key, item]) => !products?.some(v => v.productCode == key)).map(([key, item], index) => (
              <div className='stock-inspection-row inline' key={`stock-inspection-missing${index}`}>
                <Input
                  value={key}
                  disabled={true}
                  isCopy={true}
                />
                <span className="stock-inspection-multiplication material-icons" >close</span>
                <input
                  className='stock-inspection-quantity'
                  style={{marginRight: '5px'}}
                  type='number'
                  value={item[1]}
                  disabled={true}
                />
                <ExtraInfoInspection 
                  data={item}
                  type='fmd_bad' 
                  isDisable={true}
                />
              </div>
            ))} 
          </fieldset>

        </div>

      </div>
    </>
  );
};

export default ExportBillDetail;
