/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../Input/Input';
import useModal from 'src/hooks/useModal';
import QRCodeReader from '../QRCodeReader/QRCodeReader';
import { useAddPopup } from 'src/state/application/hooks';
import { useDebounce } from 'use-debounce';

interface DataListProps<T> {
  width?: string;
  title?: string;
  disabled?: boolean;
  require?: boolean;
  onChange?: (value: T[]) => void;
  value?: T[];
  name?: string;
  api?: (code: string) => Promise<T>;
  onCopy?: (value: T[]) => void;
  hasScan?: boolean
}


const DataScanList = <T, > (props: DataListProps<T>) => {
  const { width, title, disabled, require, onChange, value, name, api, onCopy, hasScan } = props;
  const qrReaderModal = useModal(QRCodeReader);
  const [qrCode, setQrCode] = useState<string>();

  const [inputCode, setInputCode] = useState<string>();
  const [qrCodeDebound] =  useDebounce(qrCode, 500);

  const addPopup = useAddPopup();

  const onInsert = (v: string) => {
    const code = v?.trim();
    if(code?.length > 0) {
      if(value.some(s => (name?eval(`s.${name}`):s) == code)) {
        addPopup({
          error: { message: 'Mã vừa quét bị trùng!', title: 'Đã có lỗi xảy ra!' },
        });
      } else {
        if(api) {
          api(code).then((res) => {
            if(res) {
              onChange([...value, res]);
              setQrCode(undefined);
              addPopup({
                txn: {
                  success: true,
                  summary: `Thêm thành công thành công ${code}`,
                }
              });
            } else {
              addPopup({
                error: { message: 'Mã không tồn tại!', title: 'Đã có lỗi xảy ra!' },
              });
              setQrCode(undefined);
            }
          }).catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
            setQrCode(undefined);
          })
        } else {
          onChange([...value, (name? {name: code} : code) as T]);
          setQrCode(undefined);
          addPopup({
            txn: {
              success: true,
              summary: `Thêm thành công thành công ${code}`,
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    onInsert(qrCodeDebound as string);
  }, [qrCodeDebound])

  const onKeyDown = (event: KeyboardEvent) => {
    if(event.key == 'Enter') {
      onInsert(inputCode);
      setInputCode(undefined);
    }
  }

  return (
    <StyledOptionWrapper width={width}>
      {title ? (
        <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle>
      ) : null}
      {onCopy ? <StyledCopyIcon className="material-icons" onClick={() => {
        onCopy(value);
      }}>
        content_copy
      </StyledCopyIcon> : null}
      <StyledOptionContainer width={width}>
        {value.map((item, index) => (
          <StyledOption key={`option${index}`}>
            <Input value={name?eval(`item.${name}`):item} disabled={true} />
            {!disabled && (
              <StyledClearIcon
                className="material-icons"
                onClick={() => {
                  onChange(value.filter((i) => i != item));
                }}
              >
                clear
              </StyledClearIcon>
            )}

          </StyledOption>
        ))}
        {!disabled && (<StyledTool>
          <StyledAddIcon className="material-icons" onClick={() => {
            onInsert(inputCode);
            setInputCode(undefined);
          }}>
            add_circle
          </StyledAddIcon>
          <Input
            value={inputCode}
            disabled={disabled}
            onChange={setInputCode}
            onKeyDown={onKeyDown}
            type='text'
          />
          {hasScan? <StyledScanIcon className="material-icons" onClick={() => {
            qrReaderModal.handlePresent({
              readQR: (code: string) => setQrCode(code)
            }, "QUÉT MÃ QR");
          }}>
            qr_code_scanner
          </StyledScanIcon> : null}
        </StyledTool>)}
      </StyledOptionContainer>
    </StyledOptionWrapper>
  );
};

const StyledTool = styled.div`
  margin: 5px 0 0 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledCopyIcon = styled.span`
  cursor: pointer;
  margin: 5px;
  position: absolute;
  right: 10px;
  top: 0;
  background: white;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  &:active {
    opacity: 0;
    transition: all 0.8s;
  }
  &:hover {
    color: gray;
  }

`

const StyledAddIcon = styled.span`
  cursor: pointer;
  margin: auto 5px auto 0;
`

const StyledScanIcon = styled.span`
  cursor: pointer;
  margin: auto 5px;
`

const StyledClearIcon = styled.span`
  position: absolute;
  top: -2px;
  left: 0px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledOptionWrapper = styled.fieldset<{ width?: string }>`
  border: 1px solid #dddcdc;
  flex: 1;
  height: fit-content;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 0 10px 0px 10px;
  border-radius: 5px;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const StyledOptionContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  min-width: ${({ width }) => (width ? `calc(${width} - 20px)` : '240px')};
  max-width: 80vw;
  padding: 5px 0;
`;

const StyledOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2px;
  margin: 2px 3px;
`;


export default DataScanList;
