import { useEffect, useState } from 'react';
import { SpecialShipType, SpecialSurchargeType } from 'src/api/models';
import { useGetSpecialShip } from 'src/api/specialShipApi';
import { useAddPopup } from 'src/state/application/hooks';
import styled from 'styled-components';
import OptionComponent from '../OptionComponent/OptionComponent';
import { useGetSpecialSurcharge } from 'src/api/specialSurchargeApi';

export type SpecialProductType = {
  specialShipId: number;
  quantity: number;
  price: number;
}

interface SpecialProductProps {
  width?: string;
  title?: string;
  disabled?: boolean;
  require?: boolean;
  onChange?: (specialProduct: SpecialProductType[]) => void;
  specialProduct?: SpecialProductType[];
}

const SpecialProduct = (props: SpecialProductProps) => {
  const { width, title, disabled, require, onChange, specialProduct } = props;
  const [specialShipList, setSpecialShipList] = useState<SpecialShipType[]>([]);
  const [specialSurcharges, setSpecialSurcharges] = useState<SpecialSurchargeType[]>([]);


  const addPopup = useAddPopup();
  const getSpecialShip = useGetSpecialShip();
  const getSpecialSurcharge = useGetSpecialSurcharge();

  useEffect(() => {
    Promise.all([
      getSpecialShip(),
      getSpecialSurcharge()
    ]).then(([specialShipRes, specialSurchargeRes]) => {
      setSpecialShipList(specialShipRes);
      setSpecialSurcharges(specialSurchargeRes);
    })
     .catch((e) => {
      addPopup({ error: { title: 'Đã có lỗi xảy ra', message: e.errorMessage } });
    });
  }, [addPopup, getSpecialShip, getSpecialSurcharge])


  return (
    <StyledOptionWrapper width={width}>
      {title ? (
        <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle>
      ) : null}
      <StyledOptionContainer width={width}>
        {specialProduct.map((option, index) => (
          <StyledOption key={`option${index}`}>
            <OptionComponent
              width='220px'
              value={option.specialShipId}
              data={specialShipList}
              onChange={(value) => {
                if(value) {
                  option.specialShipId = value;
                  const surcharges = specialSurcharges
                    .filter(s => s.specialShipId == value && s.minQuantity <= (option.quantity??0))
                    .sort((a, b) => b.minQuantity - a.minQuantity);
                  option.price = surcharges.length > 0? surcharges[0].specialSurcharge : 0;
                  onChange([...specialProduct]);
                }
              }}
              titleType='specialShipName'
              valueType='specialShipId'
            />
            <StyledQuantity
              type='number'
              value={option.quantity}
              step={1}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value;
                option.quantity = isNaN(Number(value))? 0 : Number(value);
                if(option.specialShipId) {
                  const surcharges = specialSurcharges
                  .filter(s => s.specialShipId == option.specialShipId && s.minQuantity <= (option.quantity??0))
                  .sort((a, b) => b.minQuantity - a.minQuantity);
                  option.price = surcharges.length > 0? surcharges[0].specialSurcharge : 0;
                }
                onChange([...specialProduct]);
              }}
            />
            <StyledPrice
              type='number'
              value={option.price}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value;
                option.price = isNaN(Number(value))? 0 : Number(value);
                onChange([...specialProduct]);

              }}
              step={1000}
            />
            {!disabled && (
              <StyledClearIcon
                className="material-icons"
                onClick={() => {
                  onChange(specialProduct.filter((o) => o != option));
                }}
              >
                clear
              </StyledClearIcon>
            )}


          </StyledOption>
        ))}
        {!disabled && (
          <StyledAddIcon className="material-icons " onClick={() => onChange([...specialProduct, {specialShipId: undefined, quantity: 1, price: undefined}])}>
            add_circle
          </StyledAddIcon>
        )}
      </StyledOptionContainer>
    </StyledOptionWrapper>
  );
};

const StyledAddIcon = styled.span`
  margin-left: 5px;
  width: fit-content;
  cursor: pointer;
`

const StyledQuantity = styled.input`
  margin: 5px 0 5px 0px;
  width: 40px;
  outline: none;
  padding: 0 2px;
  border: 2px solid gray;
  border-radius: 5px;
  text-align: right;
`

const StyledPrice = styled.input`
  margin: 5px 0 5px 5px;
  width: 68px;
  outline: none;
  padding: 0 2px;
  border: 2px solid gray;
  border-radius: 5px;
  text-align: right;
`

const StyledClearIcon = styled.span`
  position: absolute;
  top: -2px;
  left: 0px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledOptionWrapper = styled.fieldset<{ width?: string }>`
  border: 1px solid #dddcdc;
  flex: 1;
  height: fit-content;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 0 10px 0px 10px;
  border-radius: 5px;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const StyledOptionContainer = styled.div<{ width?: string }>`
  font-size: small;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  min-width: ${({ width }) => (width ? `calc(${width} - 20px)` : '240px')};
  max-width: 80vw;
  padding: 5px 0;
`;

const StyledOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2px;
  margin: 2px 3px;
`;


export default SpecialProduct;
