import './CodeInputModal.css';
import React, { useRef, useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import ButtonComponent from '../../ButtonComponent/ButtonComponent';

interface ICodeInputModal {
  value?: string;
  isDisable?: boolean;
  language?: string;
  postProcess: (value: string) => void;
}

const CodeInputModal: React.FC<ICodeInputModal> = (props) => {
  const {value, isDisable, language, postProcess} = props;
  const editorRef = useRef(null);

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const save = () => {
    const data = editorRef.current.getValue();
    postProcess(data);
  }

  return (<div className='code-input-container'>
    <ButtonComponent className="payment-button" icon="save" title={'LƯU'} onClick={save} loader={false}/>
    <MonacoEditor
      height="100%"
      defaultLanguage={language}
      value={value}
      onMount={handleEditorDidMount}
      options={{
        readOnly: isDisable,
      }}
      theme="vs-dark"
    />

  </div>
   
  );
};

export default CodeInputModal;
