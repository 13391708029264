/* eslint-isDisable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './CartOrderItem.css';
import { CartItemType, OrderRequirementType, ProductType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import { number2jpmoney } from 'src/utils/numberUtils';
import CartItem from '../CartItem/CartItem';

interface ICartOrderItem {
  order: OrderRequirementType
}

const CartOrderItem: React.FC<ICartOrderItem> = (props) => {
  //Value

  const { order } = props;
  const [collapse, setCollapse] = useState(false);

  //Main
  return (
    <div className={`cart-info`}>
      <div className="cart-info-header text-medium">
        <div><span className="material-icons cart-info-header-middle">shopping_cart</span><label className="cart-info-header-middle">{order?.orderRequirementDetails?.length} sản phẩm</label> </div>
        <span className="cart-info-header-middle">{order.receiverFullname}</span>
        <span className="material-icons cart-info-header-middle" onClick={() => setCollapse(!collapse)}>{collapse ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
      </div>
      <div className={collapse ? 'collapse' : 'uncollapse'}>
        {order.orderRequirementDetails.map((detail, j) => {
          return (
            <CartItem
              key={`cartitem$-${j}`}
              cartItem={{
                productId: detail.productId,
                quantity: detail.quantity,
              }}
              product={detail.product}
              isDisable={true}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CartOrderItem;
