import { EnumInventoryType, InventoryType, ProductType } from 'src/api/models';
import './InventoryDetail.css';
import React, { useEffect, useState } from 'react';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import { getCurrentTime } from 'src/utils/formatTime';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { useGetInventory, usePostInventory } from 'src/api/inventoryApi';
import { useAddPopup } from 'src/state/application/hooks';
import { error } from 'console';
import SelectSearchComponent from 'src/components/SelectSearchComponent/SelectSearchComponent';
import { useGetProduct, useGetProductById } from 'src/api/productApi';

interface IInventoryDetail {
  inventoryId: number;
  isDisable: boolean;
  postProcess?: (...args: any[]) => void;
}

const InventoryDetail: React.FC<IInventoryDetail> = (props) => {

  const [isDisable, setIsDisable] = useState(true);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [inventoryId, setInventoryId] = useState<number>();
  const [quantity, setQuantity] = useState<number>();
  const [inventoryType, setInventoryType] = useState<number>(EnumInventoryType.Input);
  const [effectiveDate, setEffectiveDate] = useState<string>(getCurrentTime("ja-JP"));
  const [product, setProduct] = useState<ProductType>();

  const getInventory = useGetInventory();
  const postInventory = usePostInventory();
  const getProduct = useGetProductById();
  const getProducts = useGetProduct();
  const addPopup = useAddPopup();

  const lstType = [{
    title: 'Nhập kho',
    value: EnumInventoryType.Input,
    css: {
      color: 'blue',
    },
  },
  {
    title: 'Xuất kho',
    value: EnumInventoryType.Output,
    css: {
      color: 'red',
    },
  }];

  useEffect(() => {
    setInventoryId(props.inventoryId);
    setIsDisable(props.isDisable);
  }, [props.inventoryId, props.isDisable]);

  useEffect(() => {
    if(inventoryId) {
      getInventory(inventoryId).then((inventory) => {
        setQuantity(inventory.quantity);
        getProduct(inventory.productId).then(res => setProduct(res));
        setInventoryType(inventory.inventoryType);
        setEffectiveDate(inventory.effectiveDate);
      }).catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.messenge
          }
        })
      })
    }

  }, [addPopup, getInventory, inventoryId, reloadFlag])


  const onSave = () => new Promise((resolve, reject) => {
    const body = {
      quantity: quantity,
      effectiveDate: effectiveDate,
      inventoryType: inventoryType,
      productId: product.productId
    }
    postInventory(body).then((r) => {
      setInventoryId(r.inventoryId);
      addPopup({
        txn: {
          success: true,
          summary: 'Thêm phiếu thành công',
        }
      })
      if(props.postProcess) props.postProcess(r);
      resolve(true);
    }).catch((e) => {
      addPopup({
        error: {
          title: 'Thêm phiếu thất bại',
          message: e.message
        }
      });
      reject(false);
    })
  });

  //Main
  return (
    <div className='inventory-detail-container'>

      <div className='inventory-detail-row'>
        <CalendarInput width="300px" title='Ngày xuất/nhập' value={effectiveDate} onChange={setEffectiveDate} />
      </div>
      
      <div className='inventory-detail-row'>
      <SelectSearchComponent
        width="300px"
        require={false}
        value={product}
        onChange={setProduct}
        onLoad={getProducts}
        isDisable={isDisable}
        title={'Sản phẩm'}
        convert={(option) => `${option?.productName} (${option?.productCode})`}
        titleField='productName'
      />
      </div>

      <div className='inventory-detail-row'>
        <Input
          width="300px"
          title="Số lượng"
          require={false}
          disabled={isDisable}
          value={quantity}
          type="number"
          onChange={setQuantity}
        />
      </div>
      <div className='inventory-detail-row'>
        <SelectBoxComponent
          width="300px"
          require={true}
          onChange={setInventoryType}
          isDisable={isDisable}
          value={inventoryType}
          data={lstType}
          valueType={'value'}
          titleType={'title'}
          title="Loại phiếu"
        />
      </div>
      {isDisable ? null : (
        <div className='inventory-button'>
          <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
        </div>
      )}
    </div>

  );
};

export default InventoryDetail;
