import React, { useState, useEffect } from 'react';
import { CustomerType, PageData, ProductType, SimPriceType } from 'src/api/models';
import { useGetProduct } from 'src/api/productApi';
import { useGetProductRealm } from 'src/api/productRealmApi';
import { useGetSimPriceById, usePostSimPrice, usePutSimPrice } from 'src/api/simPriceApi';
import { useAddPopup, useReloadTable } from 'src/state/application/hooks';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import './SimPriceDetail.css';
import { useGetCustomerType } from 'src/api/customerTypeApi';

export enum EnumConfigSimPriceDetail {
  add = 1,
  edit = 2,
  view = 3,
  update = 4,
}

interface ISimPriceDetail {
  config: EnumConfigSimPriceDetail;
  simPriceId?: number;
}

const SimPriceDetail: React.FC<ISimPriceDetail> = (props) => {
  //Value
  const config = props.config;
  const simPriceId = props.simPriceId;

  //State
  const [price, setPrice] = useState(null);
  const [priceError, setPriceError] = useState(null);

  const [productId, setProductId] = useState<number>(0);
  const [productIdError, setProductIdError] = useState(null);

  const [customerTypeId, setCustomerTypeId] = useState(0);
  const [customerTypeIdError, setCustomerTypeIdError] = useState(null);

  const [effectiveDate, setEffectiveDate] = useState(null);
  const [monthlySubscriptionFee, setMonthlySubscriptionFee] = useState(null);
  const [deposit, setDeposit] = useState(null);

  const [displayOrder, setDisplayOrder] = useState(null);

  const [customerTypeList, setCustomerTypeList] = useState<CustomerType[]>([]);
  const [simList, setSimList] = useState<PageData<ProductType>>(null);
  const [focusInput, setFocusInput] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  //End of state

  //Function
  const reloadTable = useReloadTable();
  const getRealm = useGetProductRealm();
  const getProduct = useGetProduct();
  const addPopup = useAddPopup();
  const postSimPrice = usePostSimPrice();
  const putSimPrice = usePutSimPrice();
  const getSimPriceById = useGetSimPriceById();
  const getCustomerType = useGetCustomerType();

  const onChangeCalendar = (str: string) => {
    setEffectiveDate(str);
  };

  //Validate
  const validatePrice = () => {
    let isContinue = true;

    if (!price || price == '') {
      isContinue = false;
      setPriceError('Chưa nhập giá');
    } else setPriceError(null);

    return isContinue;
  };

  const validateProductId = () => {
    let isContinue = true;

    if (!productId || productId == 0) {
      isContinue = false;
      setProductIdError('Chưa chọn sim');
    } else setProductIdError(null);

    return isContinue;
  };

  const validateCustomerTypeId = () => {
    let isContinue = true;

    if (!customerTypeId || customerTypeId == 0) {
      isContinue = false;
      setCustomerTypeIdError('Chưa chọn đối tượng');
    } else setCustomerTypeIdError(null);

    return isContinue;
  };

  const onPostSimPrice = () => {
    const isPrice = validatePrice();
    const isProductId = validateProductId();
    const isCustomerTypeId = validateCustomerTypeId();

    if (isPrice && isCustomerTypeId && isProductId) {
      const _temp: SimPriceType = {
        customerTypeId: customerTypeId,
        productId: productId,
        price: price,
        monthlySubscriptionFee: monthlySubscriptionFee,
        effectiveDate: effectiveDate,
        displayOrder: displayOrder,
        deposit: deposit,
      };

      postSimPrice(_temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm bản ghi thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  const onPutSimPrice = () => {
    const isPrice = validatePrice();
    const isProductId = validateProductId();
    const isCustomerTypeId = validateCustomerTypeId();

    if (isPrice && isCustomerTypeId && isProductId) {
      const _temp: SimPriceType = {
        customerTypeId: customerTypeId,
        productId: productId,
        price: price,
        monthlySubscriptionFee: monthlySubscriptionFee,
        displayOrder: displayOrder,
        simPriceId: simPriceId,
        effectiveDate: effectiveDate,
        deposit: deposit,
      };

      putSimPrice(_temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm bản ghi thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  const onUpdateSimPrice = () => {
    const isPrice = validatePrice();
    if (isPrice && customerTypeId != 0 && productId != 0) {
      const dateObj = new Date();
      const dd = dateObj.getDate();
      const mm = dateObj.getMonth() + 1;
      const yy = dateObj.getFullYear();

      const _effectiveDate = `${dd}/${mm}/${yy}`;
      const _temp: SimPriceType = {
        customerTypeId: customerTypeId,
        productId: productId,
        price: price,
        monthlySubscriptionFee: monthlySubscriptionFee,
        effectiveDate: _effectiveDate,
        displayOrder: displayOrder,
      };

      postSimPrice(_temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm bản ghi thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  //Component
  //1
  const priceInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 1 ? 'focus-input' : ''} ${
            price ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Giá bán</div>
          <input
            type="number"
            value={price}
            onChange={(event) => {
              setPrice(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validatePrice();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            disabled={isDisable}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{priceError}</div>
      </>
    );
  };

  //2
  const monthlySubscriptionFeeInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 2 ? 'focus-input' : ''} ${
            monthlySubscriptionFee ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Cước hàng tháng</div>
          <input
            type="number"
            value={monthlySubscriptionFee}
            onChange={(event) => {
              setMonthlySubscriptionFee(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            disabled={isDisable}
            placeholder="Để trống nếu là sim bán đứt"
          />
        </div>
      </>
    );
  };

  //3
  const effectiveDateInput = () => {
    return (
      <div className="sim-price-detail-calendar">
        <div
          className={`sim-price-detail-input calendar  ${
            effectiveDate ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Ngày hiệu lực</div>
          <input type="text" value={effectiveDate} disabled={true} />
        </div>
        <div className="sim-calendar-container">
          <Calendar
            align={EnumCalendarAlign.right}
            pos={EnumCalendarPos.top}
            onChange={onChangeCalendar}
          />
        </div>
      </div>
    );
  };

  //4
  const displayOrderInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 4 ? 'focus-input' : ''} ${
            displayOrder ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Thứ tự</div>
          <input
            type="number"
            value={displayOrder}
            onChange={(event) => {
              setDisplayOrder(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(4);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 4}
            disabled={isDisable || config == EnumConfigSimPriceDetail.update}
            placeholder="Nên thêm để quản lý tốt hơn"
          />
        </div>
      </>
    );
  };

  //5
  const depositInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 5 ? 'focus-input' : ''} ${
            deposit ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Tiền cọc</div>
          <input
            type="number"
            value={deposit}
            onChange={(event) => {
              setDeposit(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(5);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 5}
            disabled={isDisable}
            placeholder="Để trống nếu không cần cọc"
          />
        </div>
      </>
    );
  };

  const btnComponent = () => {
    if (config == EnumConfigSimPriceDetail.add) {
      return (
        <div
          className="sim-price-detail-btn"
          onClick={() => {
            onPostSimPrice();
          }}
        ></div>
      );
    } else if (config == EnumConfigSimPriceDetail.edit) {
      return (
        <div
          className="edit-order-btn"
          onClick={() => {
            onPutSimPrice();
          }}
        ></div>
      );
    } else if (config == EnumConfigSimPriceDetail.update) {
      return (
        <div
          className="edit-order-btn"
          onClick={() => {
            onUpdateSimPrice();
          }}
        ></div>
      );
    }
  };

  const custormerTypeOption = () => {
    return (
      <select
        value={customerTypeId.toString()}
        className="sim-price-detail-option"
        onChange={(event) => {
          if (Number(event.target.value) != 0) {
            setCustomerTypeId(Number(event.target.value));
          }
          event.preventDefault();
        }}
        onBlur={() => {
          validateCustomerTypeId();
        }}
        disabled={isDisable || config == EnumConfigSimPriceDetail.update}
      >
        <option value={0}>Chọn đối tượng</option>
        {customerTypeList.map((value) => {
          return <option value={value.customerTypeId}>{value.customerTypeTitle}</option>;
        })}
      </select>
    );
  };

  const simOption = () => {
    return simList ? (
      <select
        value={productId.toString()}
        className="sim-price-detail-option"
        onChange={(event) => {
          setProductId(Number(event.target.value));
          event.preventDefault();
        }}
        onBlur={() => {
          validateProductId();
        }}
        disabled={isDisable || config == EnumConfigSimPriceDetail.update}
      >
        <option value={0}>Chọn sim</option>
        {simList
          ? simList.items.map((value) => {
              return <option value={value.productId}>{value.productName}</option>;
            })
          : null}
      </select>
    ) : null;
  };
  //End of component

  //useEffect
  useEffect(() => {
    getRealm()
      .then((data) => {
        const _arr: number[] = [];
        data.map((value) => {
          if (value.isHidden) {
            _arr.push(Number(value.productRealmId));
          }
        });

        getProduct(null, 1, 9999, _arr)
          .then((data) => {
            setSimList(data);
          })
          .catch((error) => {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra',
                message: error.errorMessage,
              },
            });
          });
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getProduct, getRealm]);

  useEffect(() => {
    if (config == EnumConfigSimPriceDetail.view) {
      setIsDisable(true);
    }
  }, [config]);

  useEffect(() => {
    if (simPriceId) {
      getSimPriceById(simPriceId)
        .then((data) => {
          setPrice(data.price);
          setMonthlySubscriptionFee(data.monthlySubscriptionFee);
          setDisplayOrder(data.displayOrder);
          setProductId(data.productId);
          setCustomerTypeId(data.customerTypeId);
          setEffectiveDate(data.effectiveDate);
          setDeposit(data.deposit);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getSimPriceById, simPriceId]);

  useEffect(() => {
    const date = new Date();
    const dd1 = date.getDate();
    const mm1 = date.getMonth() + 1;
    const yy1 = date.getFullYear();

    const effDate = `${dd1}/${mm1}/${yy1}`;

    setEffectiveDate(effDate);
  }, []);

  useEffect(() => {
    getCustomerType()
      .then((data) => {
        setCustomerTypeList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getCustomerType]);

  //Main
  return (
    <div className="sim-price-detail-container">
      <div className="sim-price-detail-form">
        <div className="sim-price-detail-from-component">
          {custormerTypeOption()}
          <div style={{ color: 'red', paddingLeft: 2 }}>{customerTypeIdError}</div>
          {priceInput()}
          {depositInput()}
          {displayOrderInput()}
        </div>
        <div className="sim-price-detail-from-component">
          {simOption()}
          <div style={{ color: 'red', paddingLeft: 2 }}>{productIdError}</div>
          {monthlySubscriptionFeeInput()}
          {config == EnumConfigSimPriceDetail.add || config == EnumConfigSimPriceDetail.edit
            ? effectiveDateInput()
            : null}
        </div>
      </div>
      <div>{btnComponent()}</div>
    </div>
  );
};

export default SimPriceDetail;
