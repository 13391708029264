import React, { useState, useEffect } from 'react';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumSimStatus,
  EnumSimStatusTitle,
  EventButton,
  ProductType,
  ProfileInfo,
  SimPriceType,
  SimStoreType,
  SimType,
} from 'src/api/models';
import { useGetProduct } from 'src/api/productApi';
import { useGetProductRealm } from 'src/api/productRealmApi';
import { useGetSimById, usePostSim, usePutSim } from 'src/api/simApi';
import { useGetSimPriceListByDateAndUserId } from 'src/api/simPriceApi';
import { useGetSimStore } from 'src/api/simStoreApi';
import { useGetUserList } from 'src/api/userApi';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import { useAddPopup } from 'src/state/application/hooks';
import './SimDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import ContactList from 'src/components/ContactList/ContactList';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Note from 'src/components/Note/Note';


interface ISimDetail {
  simId?: number;
  isDisable?: boolean;
  isSlide?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const SimDetail: React.FC<ISimDetail> = (props) => {

  const params = useParams<{ type: string; simId: string }>()
  const [simId, setSimId] = useState<number>();

  const [isDisable, setIsDisable] = useState(false);
  //State
  const [userName, setUserName] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [customerNameError, setCustomerNameError] = useState<string>();
  const [simPhoneNumber, setSimPhoneNumber] = useState<string>();
  const [simImei, setSimImei] = useState<string>();
  const [simImeiError, setSimImeiError] = useState<string>();
  const [productId, setProductId] = useState<number>();
  const [productIdError, setProductIdError] = useState<string>();
  const [simStoreList, setSimStoreList] = useState<SimStoreType[]>([]);
  const [simStoreId, setSimStoreId] = useState<number>();
  const [simStoreIdError, setSimStoreIdError] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [userIdError, setUserIdError] = useState<string>();
  const [status, setStatus] = useState<number>();
  const [simList, setSimList] = useState<ProductType[]>();

  const [keyword, setKeyword] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [userList, setUserList] = useState<ProfileInfo[]>();

  const [effectiveDate, setEffectiveDate] = useState<string>();
  const [monthlySubscriptionFee, setMonthlySubscriptionFee] = useState<number>();
  const [deposit, setDeposit] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [simPriceList, setSimPriceList] = useState<SimPriceType[]>([]);

  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  //End of state

  const lstStatus = [
    {
      value: EnumSimStatus.Active,
      title: EnumSimStatusTitle.Active,
      css: { color: 'green' },
    },
    {
      value: EnumSimStatus.Deactive,
      title: EnumSimStatusTitle.Deactive,
      css: { color: '#3a3131' },
    },
    {
      value: EnumSimStatus.Error,
      title: EnumSimStatusTitle.Error,
      css: { color: 'red' },
    },
    {
      value: EnumSimStatus.StopService,
      title: EnumSimStatusTitle.StopService,
      css: { color: 'yellow' },
    },
  ];


  //Function
  const navigate = props.isSlide || props.isPopup? undefined : useNavigate();
  const getRealm = useGetProductRealm();
  const getProduct = useGetProduct();
  const getSimById = useGetSimById();
  const postSim = usePostSim();
  const putSim = usePutSim();
  const addPopup = useAddPopup();
  const getUserList = useGetUserList();
  const getSimStore = useGetSimStore();
  const getSimPriceListByDateAndUserId = useGetSimPriceListByDateAndUserId();

  useEffect(() => {
    setSimId(props.simId || Number(params.simId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [props.simId, params.simId, props.isDisable, params.type]);


  //Validate
  const validateCustomerName = () => {
    let isContinue = true;
    if (!customerName || customerName == '') {
      isContinue = false;
      setCustomerNameError('Chưa nhập tên');
    } else setCustomerNameError(undefined);
    return isContinue;
  };

  const validateSimImeiAndSimPhoneNumber = () => {
    let isContinue = true;
    if ((!simImei || simImei == '') && (!simPhoneNumber || simPhoneNumber == '')) {
      isContinue = false;
      setSimImeiError('Nhập SĐT hoặc IMEI');
    } else setSimImeiError(undefined);
    return isContinue;
  };

  const validateProductId = () => {
    let isContinue = true;
    if (!productId || productId == 0) {
      isContinue = false;
      setProductIdError('Chưa chọn sim');
    } else setProductIdError(undefined);
    return isContinue;
  };

  const validateUserId = () => {
    let isContinue = true;
    if (!userId) {
      isContinue = false;
      setUserIdError('Chưa nhập CTV');
    } else setUserIdError(undefined);
    return isContinue;
  };

  const validateSimStoreId = () => {
    let isContinue = true;
    if (!simStoreId || simStoreId == 0) {
      isContinue = false;
      setSimStoreIdError('Chưa chọn nguồn gửi');
    } else setSimStoreIdError(undefined);
    return isContinue;
  };
  //End of validate

  const onSave = () => new Promise((resolve, reject) => {
    const isCustomerName = validateCustomerName();
    const isSimImei = validateSimImeiAndSimPhoneNumber();
    const isProductId = validateProductId();
    const isUserId = validateUserId();
    const isSimStoreId = validateSimStoreId();

    if (isCustomerName && isSimImei && simId && isProductId && isUserId && isSimStoreId) {
      const sim: SimType = {
        simId: simId,
        userId: userId,
        customerName: customerName,
        simPhoneNumber: simPhoneNumber,
        simImei: simImei,
        status: status,
        productId: productId,
        effectiveDate: effectiveDate,
        simStoreId: simStoreId,
        monthlySubscriptionFee: monthlySubscriptionFee,
        deposit: deposit,
      };

      const api =  props.simId? putSim : postSim;
      api(sim).then((res) => {
          setSimId(res.simId);
          addPopup({
            txn: {
              success: true,
              summary: props.simId? 'Sửa SIM thành công' : 'Tạo SIM thành công',
            },
          });
          resolve(true);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          reject(false);
        });

    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
      reject(false);
    }
  });



  //End of component

  //useEffect
  useEffect(() => {
    getRealm()
      .then((data) => {
        const _arr: number[] = [];
        data.map((value) => {
          if (value.isHidden) {
            _arr.push(Number(value.productRealmId));
          }
        });

        getProduct(null, 1, 9999, _arr)
          .then((data) => {
            setSimList(data.items);
          })
          .catch((error) => {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra',
                message: error.errorMessage,
              },
            });
          });
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getProduct, getRealm]);


  useEffect(() => {
    if (simId) {
      getSimById(simId)
        .then((data) => {
          setCustomerName(data.customerName);
          setSimPhoneNumber(data.simPhoneNumber);
          setProductId(data.productId);
          setUserId(data.userId);
          setStatus(data.status);
          setKeyword(data.userName);
          setEffectiveDate(data.effectiveDate);
          setSimStoreId(data.simStoreId);
          setSimImei(data.simImei);
          setDeposit(data.deposit);
          setMonthlySubscriptionFee(data.monthlySubscriptionFee);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getSimById, simId]);

  useEffect(() => {
    getSimStore()
      .then((data) => {
        setSimStoreList(data);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getSimStore]);

  useEffect(() => {
    if (keyword && keyword !== '') {
      getUserList(keyword)
        .then((data) => {
          setUserList(data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUserList(null);
    }
  }, [getUserList, keyword, userId]);

  useEffect(() => {
    const dateObj = new Date();
    const dd = dateObj.getDate();
    const mm = dateObj.getMonth() + 1;
    const yy = dateObj.getFullYear();
    const temp = `${yy}/${mm}/${dd}`;

    setEffectiveDate(temp);
  }, []);

  useEffect(() => {
    if (userId) {
      const date = new Date();
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yy = date.getFullYear();
      const temp = `${yy}/${mm}/${dd}`;

      getSimPriceListByDateAndUserId(temp, userId)
        .then((data) => {
          setSimPriceList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getSimPriceListByDateAndUserId, userId]);

  useEffect(() => {
    if (userId && simPriceList && productId && productId != 0) {
      const _simPrice = simPriceList.filter((value) => value.productId == productId)[0];
      setDeposit(_simPrice?.deposit);
      setMonthlySubscriptionFee(_simPrice?.monthlySubscriptionFee);
      setPrice(_simPrice?.price);
    }
  }, [productId, simPriceList, userId]);

  const listButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: () => navigate(-1),
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: () => setIsDisable(false),
      align: 'center',
    },
  ];

  const listButtonDis: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: () => navigate(-1),
      align: 'center',
    },
  ];

  //Main
  return (
    <>
      {props.isSlide || props.isPopup? null : (
        <ToolBar
          toolbarName={`Thông tin SIM ${simPhoneNumber? simPhoneNumber : simImei}`}
          listRightButton={isDisable ? listButton : listButtonDis}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
        />
      )}
      <div className='sim-detail-container'>
        <div className='sim-detail-info'>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Cộng tác viên"
              require={true}
              disabled={isDisable}
              value={userName}
            />
            {!isDisable && (
              <div className="i-tooltip guide">
                <span
                  className="material-icons info-guide"
                  onClick={() => setIsShowUserList(true)}
                  id="show-contact-icon"
                  ref={(el) => {
                    if (el) {
                      const boundingClient = el.getBoundingClientRect();
                      setIconX(boundingClient.left);
                      setIconY(boundingClient.y + boundingClient.height);
                    }
                  }}
                >
                  account_circle
                </span>
                <span className="tooltiptext">Chọn CTV đặt SIM</span>
              </div>
            )}
            {isShowUserList ? (
              <ContactList
                iconX={iconX}
                iconY={iconY}
                onHidden={() => setIsShowUserList(false)}
                onSelect={(user: ProfileInfo) => {
                  setUserId(user.userId);
                  setUserName(user.fullName);
                  setIsShowUserList(false);
                  setUserIdError(undefined);
                }}
              />
            ) : null}
            <div style={{ color: 'red', paddingLeft: 2 }}>{userIdError}</div>
          </div>
          <div className='sim-detail-row'>
            <CalendarInput width="300px" title='Ngày hiệu lực' value={effectiveDate} onChange={setEffectiveDate} />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Tên khách hàng"
              require={false}
              disabled={isDisable}
              value={customerName}
              type="text"
              onChange={setCustomerName}
              onBlur={validateCustomerName}
              error={customerNameError}
            />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Số điện thoại"
              require={false}
              disabled={isDisable}
              value={simPhoneNumber}
              type="text"
              onChange={setSimPhoneNumber}
              onBlur={validateSimImeiAndSimPhoneNumber}
              error={customerNameError}
            />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="IMEI"
              require={false}
              disabled={isDisable}
              value={simImei}
              type="text"
              onChange={setSimImei}
              onBlur={validateSimImeiAndSimPhoneNumber}
              error={simImeiError}
            />
          </div>
          <div className='sim-detail-row'>
            <SelectBoxComponent
              width="300px"
              require={true}
              onChange={setProductId}
              isDisable={isDisable}
              value={productId}
              data={simList}
              valueType={'productId'}
              titleType={'productName'}
              title="Chọn SIM"
              onBlur={validateProductId}
              error={productIdError}
            />
          </div>
        </div>
        <div className='sim-detail-info'>

          <div className='sim-detail-row'>
          <SelectBoxComponent
              width="300px"
              require={true}
              onChange={setSimStoreId}
              isDisable={isDisable}
              value={simStoreId}
              data={simStoreList}
              valueType={'simStoreId'}
              titleType={'simStoreTitle'}
              title="Chọn nguồn SIM"
              onBlur={validateSimStoreId}
              error={simStoreIdError}
            />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Tiền cọc"
              require={false}
              disabled={isDisable}
              value={deposit}
              type="number"
              onChange={setDeposit}
            />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Phí hòa mạng"
              require={false}
              disabled={isDisable}
              value={price}
              type="number"
              onChange={setPrice}
            />
          </div>
          <div className='sim-detail-row'>
            <Input
              width="300px"
              title="Cước tháng"
              require={false}
              disabled={isDisable}
              value={monthlySubscriptionFee}
              type="number"
              onChange={setMonthlySubscriptionFee}
            />
          </div>
          <div className='sim-detail-row'>
            <SelectBoxComponent
              width="300px"
              require={true}
              onChange={setStatus}
              isDisable={isDisable}
              value={status}
              data={lstStatus}
              valueType={'value'}
              titleType={'title'}
              title="Tr.thái"
            />
          </div>

          {isDisable ? null : (
            <div className='sim-detail-button'>
              <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
            </div>
          )}
        </div>
        {simId > 0 ? (
          <Note
            objectId={simId}
            functionId={EnumDisplayConfig.Sim_da_ban}
            recordUserId={userId}
          />
        ) : null}
      </div>
    </>
  );
};

export default SimDetail;
