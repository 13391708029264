import React, { useCallback, useEffect, useState } from 'react';
import { ServiceType } from 'src/api/models';
import { usePostService, usePutService, useGetServiceById } from 'src/api/serviceApi';

import { useAddPopup, useRemovePopup, useReloadTable } from 'src/state/application/hooks';
import './ServiceDetails.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IEditServiceView {
  serviceId: number;
  isDisable: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ServiceDetails: React.FC<IEditServiceView> = (props) => {
  //Function
  const postService = usePostService();
  const putService = usePutService();
  const getServiceById = useGetServiceById();
  const addPopup = useAddPopup();

  //State
  const [serviceId, setServiceId] = useState<number>(props.serviceId);
  const [isDisable, setDisable] = useState<boolean>(props.isDisable);
  const [serviceCode, setServiceCode] = useState<string>();
  const [serviceCodeError, setServiceCodeError] = useState<string>();
  const [serviceName, setServiceName] = useState<string>();
  const [serviceNameError, setServiceNameError] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();
  const [staticPageKey, setStaticPagekey] = useState<string>();

  //Init
  useEffect(() => {
    if(serviceId) {
      getServiceById(serviceId)
        .then((data) => {
          setServiceCode(data.serviceCode);
          setServiceName(data.serviceName);
          setDisplayOrder(data.displayOrder);
          setStaticPagekey(data.staticPageKey);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        })
    }
  }, [addPopup, getServiceById, serviceId]);

  const validateServiceCode = () => {
    setServiceCodeError(serviceCode && serviceCode != '' ? undefined : 'Chưa nhập mã dịch vụ');
    return serviceCode && serviceCode != '';
  };

  const validateServiceName = () => {
    setServiceNameError(
      serviceName && serviceName != '' ? undefined : 'Chưa nhập tên dịch vụ',
    );
    return serviceName && serviceName != '';
  };

  const onSave = () => new Promise((resolve, reject) => {
    if (validateServiceCode() && validateServiceName()) {
      const body : ServiceType = {
        displayOrder: displayOrder,
        serviceCode: serviceCode,
        serviceName: serviceName,
        staticPageKey: staticPageKey,
      };
      const isAdd = !props.serviceId;
      const api = isAdd ? postService(body) : putService(body);
      api
        .then((res: ServiceType) => {
          setServiceId(res.serviceId);
          setDisable(true);
          resolve(true);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra, vui lòng thử lại!',
            },
          });
          reject(false);
        });
    } else {
      addPopup(
        {
          error: {
            title: 'Chưa nhập đủ thông tin',
            message: `${serviceCodeError ?? ''}
            ${serviceNameError ?? ''}`,
          },
        },
        undefined,
        false,
        3000,
      );
      reject(false);
    }
  });
  //End of function

  return (
    <div className="container-fluid service-detail-container">
      <div className="service-detail-row">
        <Input
          title="Mã dịch vụ"
          require={true}
          value={serviceCode}
          onChange={setServiceCode}
          disabled={isDisable}
        />
      </div>
      <div className="service-detail-row">
        <Input
          title="Tên dịch vụ"
          require={true}
          value={serviceName}
          onChange={setServiceName}
          disabled={isDisable}
        />
      </div>
      <div className="service-detail-row">
        <Input
          title="Thứ tự"
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      <div className="service-detail-row">
        <Input
          title="Mã trang tĩnh"
          value={staticPageKey}
          onChange={setStaticPagekey}
          disabled={isDisable}
        />
      </div>
      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={!serviceId ? 'THÊM' : 'LƯU'}
          onClick={onSave}
          loader={true}
        />
      )}
    </div>
  );
};

export default ServiceDetails;
