/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { EnumAction, EnumShipOrderProgressStatus, ImageType, ShipOrderProgressStatusList, ShipOrderType, ShipPackageType } from 'src/api/models';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import { useAddPopup } from 'src/state/application/hooks';
import "./ShipPackageDetail.css";
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import ImageUpload from 'src/components/ImageUpload';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useDeleteShipPackageImage, useGetShipPackageById, usePostShipPackage, usePostShipPackageImage, usePutShipPackage } from 'src/api/shipPackageApi';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import { useGetShipOrderByCode } from 'src/api/shipOrderApi';
import DataScanList from 'src/components/DataScanList';
import { BASE_ORDER_URL } from 'src/constants';

interface IShipPackageDetail {
  shipPackageId?: number;
  shipOrders?: ShipOrderType[];
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  shipPackageCode?: string;
  newProgressStatus?: number;
  postProcess?: (...args: any[]) => void;
}

const ShipPackageDetail: React.FC<IShipPackageDetail> = (props) => {

  const [shipPackageId, setShipPackageId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const params = useParams<{type: string; shipPackageId: string }>();
  const [shipOrders, setShipOrders] = useState<ShipOrderType[]>([]);

  useEffect(() => {
    setShipPackageId(props.shipPackageId || Number(params.shipPackageId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
    if(props.shipOrders) changeShipOrders(props.shipOrders);
  }, [params.shipPackageId, params.type, props.isDisable, props.shipPackageId]);

  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();


  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [receiverAddress, setReceiverAddress] = useState<string>();
  const [shipPackageCode, setShipPackageCode] = useState<string>(props.shipPackageCode);
  const [weight, setWeight] = useState<number>();

  const [length, setLength] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>(EnumShipOrderProgressStatus.Waiting);
  const [shipInvoiceId, setShipInvoiceId] = useState<number>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  //End of state

  //Function
  const addPopup = useAddPopup();
  const getShipPackage = useGetShipPackageById();
  const postShipPackage = usePostShipPackage();
  const putShipPackage =  usePutShipPackage();
  const postShipPackageImage = usePostShipPackageImage();
  const deleteShipPackageImage = useDeleteShipPackageImage();
  const getShipOrderByCode = useGetShipOrderByCode();
  const qrReaderModal = useModal(QRCodeReader);

  const onAddImage = (file: File) => {
    if (file) {
      if (!shipPackageId) {
        setImageFiles([...imageFiles, file]);
      } else {
        postShipPackageImage(file, shipPackageId)
          .then((res) => {
            setImages([...images, res]);
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  const onDeleteShipPackageImage = (file: ImageType | File) => {
    if (file instanceof File) {
      setImageFiles(imageFiles.filter((f) => f != file));
    } else {
      deleteShipPackageImage(shipPackageId, file.fileId)
        .then(() => {
          setImages(images.filter((i) => i.fileId != file.fileId));
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    }
  };

  const onSuccess = (isAdd: boolean, res: ShipPackageType) => {
    addPopup({
      txn: {
        success: true,
        summary: !isAdd? 'Thay đổi kiện thành công' : 'Tạo kiện thành công',
      }
    });
    if(props.postProcess) props.postProcess(res);
  }

  const uploadImage = useCallback(
    (shipOrderId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return postShipPackageImage(formData, shipOrderId);
    },
    [postShipPackageImage],
  );

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_ORDER_URL}/ship-package/${shipPackageId}/${EnumViewType.Edit}`);
  };

  const changeShipOrders = (value: ShipOrderType[]) => {
    setShipOrders(value);
    const weight = value.reduce((total, item) => total += item.weight, 0);
    setWeight(weight - weight * 7 / 100);
  }

  //TODO
  const onSave = () => new Promise((resolve) => {
    const shipPackage: ShipPackageType = {
      shipPackageId: shipPackageId,
      shipPackageCode: shipPackageCode,
      weight: weight,
      height: height,
      width: width,
      length: length,
      receiverAddress: receiverAddress,
      receiverName: receiverName,
      receiverPhoneNumber: receiverPhoneNumber,
      progressStatus: props.newProgressStatus? props.newProgressStatus : progressStatus,
      shipOrders: shipOrders,
      shipInvoiceId: shipInvoiceId
    };
    const isAdd = !shipPackageId;
    const api = isAdd? postShipPackage(shipPackage) : putShipPackage(shipPackage);
    api.then(
      (res) => {
        setShipPackageId(res.shipPackageId);
        setIsDisable(true);
        if (isAdd) {
          const uploadApis = imageFiles.map((image) => uploadImage(res.shipPackageId, image));
          Promise.all(uploadApis)
            .then((imageRes) => {
              setImages([...images, ...imageRes]);
              onSuccess(isAdd, res);
              resolve(true);
            })
            .catch((error) => {
              addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Tải ảnh thất bại, vui lòng thử lại!',
                },
              });
              resolve(false);
            });
          setImageFiles([]);
        } else {
          onSuccess(isAdd, res);
          resolve(true);
        }
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        resolve(false);
      });
  });

  useEffect(() => {
    if(shipPackageId) {
      getShipPackage(shipPackageId).then((res) => {
        setShipPackageCode(res.shipPackageCode);
        setWeight(res.weight);
        setHeight(res.height);
        setWidth(res.width);
        setLength(res.length);
        setProgressStatus(res.progressStatus);
        setImages(res.images);
        setReceiverAddress(res.receiverAddress);
        setReceiverPhoneNumber(res.receiverPhoneNumber);
        setReceiverName(res.receiverName);
        setShipOrders(res.shipOrders);
        setShipInvoiceId(res.shipInvoiceId);
      })
    }
  }, [shipPackageId]);

  //Main
  return (
    <>
      {props.isSlide || props.isPopup ? null : (
        <ToolBar
          toolbarName={`Kiện hàng ${shipPackageCode}`}
          listRightButton={isDisable? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`shippackage-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
        <div className='shippackage-info'>
          <ImageUpload
            images={shipPackageId ? images : imageFiles}
            onDelete={onDeleteShipPackageImage}
            addImage={onAddImage}
            showOnlyOne={true}
          />
          <div className="shippackage-row">
            <Input
              width="360px"
              title="Mã phiếu"
              disabled={isDisable}
              value={shipPackageCode}
              onChange={setShipPackageCode}
              rightAction={() => {
                qrReaderModal.handlePresent({
                  readQR: (code: string) => {
                    setShipPackageCode(code);
                    qrReaderModal.handleDismiss();
                  }
                }, "QUÉT MÃ VẬN CHUYỂN")
              }}
              rightIcon='qr_code_scanner'
            />
          </div>

          <div className="shippackage-row">
            {props.newProgressStatus? <div className='inline'>
              <SelectBoxComponent
                require={true}
                onChange={setProgressStatus}
                isDisable={isDisable}
                title={'Trạng thái'}
                value={progressStatus}
                data={ShipOrderProgressStatusList}
                valueType={'value'}
                titleType={'title'}
              />
              <div className='inline' style={{marginTop: '8px'}}>
                <span className="material-icons" style={{margin: 'auto 5px'}}>arrow_forward</span>
                <div className='new-progress-status'>{ShipOrderProgressStatusList.find(i => i.value == props.newProgressStatus).title}</div>
              </div>
            </div>
            :
            <SelectBoxComponent
              width="360px"
              require={true}
              onChange={setProgressStatus}
              isDisable={isDisable}
              title={'Trạng thái'}
              value={progressStatus}
              data={ShipOrderProgressStatusList}
              valueType={'value'}
              titleType={'title'}
            />}
          </div>
          {/* <div className="shippackage-row">
            <Input
              width="360px"
              title="Tên người nhận"
              require={false}
              disabled={isDisable}
              value={receiverName}
              onChange={setReceiverName}
            />
          </div>
          <div className="shippackage-row">
            <Input
              width="360px"
              title="SĐT người nhận"
              require={false}
              disabled={isDisable}
              value={receiverPhoneNumber}
              onChange={setReceiverPhoneNumber}
            />
          </div>
          <div className="shippackage-row">
            <Input
              width="360px"
              title="Địa chỉ người nhận"
              require={false}
              disabled={isDisable}
              value={receiverAddress}
              onChange={setReceiverAddress}
            />
          </div> */}
          <div className="shippackage-row">
            <Input
              width="360px"
              title="Khối lượng (kg)"
              require={false}
              disabled={isDisable}
              value={weight / 1000}
              onChange={(value) => setWeight(value * 1000)}
              type='number'
            />
          </div>
          <div className="shippackage-row">
            <div className='inline'>
              <Input
                width="80px"
                title="D(cm)"
                require={false}
                disabled={isDisable}
                value={length / 10}
                onChange={(value) => setLength(value * 10)}
                type='number'
              />
              <Input
                width="80px"
                title="R(cm)"
                require={false}
                disabled={isDisable}
                value={width / 10}
                onChange={(value) => setWidth(value * 10)}
                type='number'
              />
              <Input
                width="80px"
                title="C(cm)"
                require={false}
                disabled={isDisable}
                value={height / 10}
                onChange={(value) => setHeight(value * 10)}
                type='number'
              />
              <div>
                <Input
                  width="120px"
                  title="V (khối)"
                  require={false}
                  disabled={true}
                  value={(width??0) * (height??0) * (length??0) / 1000000000}
                  type='number'
                />
              </div>
            </div>
          </div>
          <div className="shippackage-row">
            <DataScanList
              width="360px"
              title="DS đơn hàng"
              value={shipOrders}
              onChange={changeShipOrders}
              disabled={isDisable}
              api={getShipOrderByCode}
              name='billCode'
              onCopy={(value) => {
                const contents: string[] = [];
                const codes: string[] = [];
                value.forEach((shipOrder) => {
                  (shipOrder.nomalProduct?JSON.parse(shipOrder.nomalProduct) as NomalProductType[] : []).forEach((item) => {
                    if(item.product.match(/[^$,.\d]/)) {
                      contents.push(`${item.product}  ${item.quantity}`);
                    } else {
                      codes.push(`${item.product}  ${item.quantity}`);
                    }

                  })
                })
                navigator.clipboard.writeText(contents.join("\n") + codes.join("\n"));
              }}
              hasScan={true}
            />
          </div>
          {!isDisable && <div className="shippackage-row">
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
          </div>}
          {shipOrders.map(shipOrder => <div style={{ maxWidth: '360px' }} key={`shippackage-${shipOrder.billCode}`}>
            <div className='inline'><hr style={{flex: 'auto', margin: 'auto 5px'}}/><span>{shipOrder.billCode}</span></div>
            {(shipOrder.nomalProduct?JSON.parse(shipOrder.nomalProduct) as NomalProductType[] : []).map(item => <div style={{margin: '1px'}} className='inline' key={`product-${item.product}`}>
              <a style={{padding: '0 5px', maxWidth: '300px', background: 'gray', color: 'white', borderRadius: '3px', margin: 'auto 2px'}} href={item.product.includes("http")? item.product : '#'} title={item.product}>{item.product}</a>
              <span className='material-icons' style={{margin: 'auto 5px'}}>close</span>
              <span style={{margin: 'auto 5px'}}>{item.quantity}</span>
            </div>)}
          </div>)}
          
        </div>

      </div>
    </>
  );
};

export default ShipPackageDetail;
