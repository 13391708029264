import React, { useState, useEffect } from 'react';
import { ProfileInfo} from 'src/api/models';
import { usePutUpdateUser } from 'src/api/shipOrderApi';
import { useAddPopup } from 'src/state/application/hooks';
import './UpdateUser.css';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import ContactList from 'src/components/ContactList/ContactList';

interface IUpdateUser {
  checkListIds: number[];
  postProcess?: (...args: any[]) => void;
}

const UpdateUser: React.FC<IUpdateUser> = (props) => {
  const {checkListIds, postProcess} = props
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();

  const [isShowUserList, setIsShowUserList] = useState(false);
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);

  //Function
  const putUpdateUser = usePutUpdateUser();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve) => {
    putUpdateUser(checkListIds, userId)
      .then((res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Cập nhật TK gửi thành công',
          },
        });
        if(postProcess) postProcess();
        resolve(true);
      }).catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        resolve(false);
      });
  });

  const changeUser = (user: ProfileInfo) => {
    setUserId(user?.userId??undefined);
    setUserName(user?.fullName??undefined);
    setIsShowUserList(false);
  }

  //Main
  return (
    <div className="updateuser-container">
      <div className="updateuser-row">
        <Input
          width='360px'
          title="TK gửi"
          require={false}
          disabled={true}
          value={userName}
        />
        <div className="i-tooltip update-user-guide">
          {userId? <span className="material-icons send-account-clear" onClick={() => {
            changeUser(undefined);
          }}>clear</span> : null}
          <span
            className="material-icons info-guide"
            onClick={() => setIsShowUserList(true)}
            ref={(el) => {
              if (el) {
                const boundingClient = el.getBoundingClientRect();
                setIconX(boundingClient.left - window.innerWidth / 2);
                setIconY(boundingClient.y + boundingClient.height - window.innerHeight / 2 + 80 );
              }
            }}
          >
            account_circle
          </span>
          <span className="tooltiptext">Chọn người gửi</span>
        </div>

        {isShowUserList ? (
          <ContactList
            iconX={iconX}
            iconY={iconY}
            showAll={true}
            onHidden={() => setIsShowUserList(false)}
            onSelect={(user: ProfileInfo) => changeUser(user)}
          />
        ) : null}
      </div>
      <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
    </div>
  );
};

export default UpdateUser;
