import React, { useState, useEffect, useCallback } from 'react';
import { EnumAction, EnumDataType, SpecialSurchargeType } from 'src/api/models';
import { useAddPopup} from 'src/state/application/hooks';
import './SpecialSurcharge.css';
import { useGetSpecialSurchargeByShip } from 'src/api/specialSurchargeApi';
import Table from 'src/components/Table/Table';
import ButtonAction from 'src/components/ButtonAction/ButtonAction';


interface ISpecialSurcharge {
  specialShipId?: number;
  surchargeModal: any;
}

const SpecialSurcharge: React.FC<ISpecialSurcharge> = (props) => {
  const display = {
    header: [
      {
        code: 'minQuantity',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'S.lượng tối thiểu',
      },
      {
        code: 'specialSurcharge',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Phí đặc biệt',
      },
    ],
    actions: (item: SpecialSurchargeType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.specialSurchargeId),
        buttonClass: 'info',
        align: '',
      },
    ]
  };

  const [specialSurcharges, setSpecialSurcharges] = useState<SpecialSurchargeType[]>([]);

  const [reloadFlag, setReloadFlag] = useState(false);

  //End of state

  //Function
  const getSpecialSurchargeByShip = useGetSpecialSurchargeByShip();

  const addPopup = useAddPopup();

  useEffect(() => {
    getSpecialSurchargeByShip(props.specialShipId).then((res) => {
      setSpecialSurcharges(res)
    })
    .catch((error) => {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: error.errorMessage,
        },
      });
    });

  }, [addPopup, getSpecialSurchargeByShip, props.specialShipId, reloadFlag]);

  const onEdit = useCallback((specialSurchargeId: number) => {
    props.surchargeModal.handlePresent(
      {
        specialSurchargeId: specialSurchargeId,
        specialShipId: props.specialShipId,
        isDisable: false,
        postProcess: () => {
          setReloadFlag(!reloadFlag);
          props.surchargeModal.handleDismiss();
        },
      },
      'THAY ĐỔI',
    );
  }, [props.specialShipId, props.surchargeModal, reloadFlag]);

  const onAdd = useCallback(() => {
    props.surchargeModal.handlePresent(
      {
        specialShipId: props.specialShipId,
        isDisable: false,
        postProcess: () => {
          setReloadFlag(!reloadFlag);
          props.surchargeModal.handleDismiss();
        },
      },
      'THÊM MỚI',
    );
  }, [props.specialShipId, props.surchargeModal, reloadFlag]);

  const btnAdd = {
    name: 'Thêm',
    icon: 'add',
    actionType: EnumAction.Add,
    buttonClass: 'info order-tool-btn',
    action: onAdd,
    align: 'center',
  };

  //Main
  return (
    <>
      <div className='special-surcharge-header'>
        <span>DANH SÁCH PHỤ PHÍ</span>
        <ButtonAction isFile={false} button={btnAdd}/>
      </div>
      <Table
        display={display}
        isInput={false}
        data={specialSurcharges}
        allowCheckbox={false}
        isNo={false}
      />
    </>
  );
};

export default SpecialSurcharge;
