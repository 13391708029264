import './CodeInput.css';
import React, { useRef, useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import useModal from 'src/hooks/useModal';
import CodeInputModal from './CodeInputModal/CodeInputModal';

interface ICodeInput {
  value?: string;
  disabled?: boolean;
  language?: string;
  onChange: (value: string) => void;
}

const CodeInput: React.FC<ICodeInput> = (props) => {
  const {value, disabled, language, onChange} = props;

  const inputModal = useModal(CodeInputModal);

  return (
    <span className="material-icons" onClick={() => {
      inputModal.handlePresent({
        value: value,
        isDisable: disabled,
        language: language,
        postProcess: (value: string) => {
          onChange(value);
          inputModal.handleDismiss();
        }
      }, "NHẬP CODE")
    }}>code</span>
   
  );
};

export default CodeInput;
