import React, { useState, useEffect } from 'react';
import { useGetFunctionById, usePostFunction, usePutFunction } from 'src/api/functionApi';
import { FunctionType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import './FunctionDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IFunctionDetail {
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  functionId?: number;
  moduleId: number;
  postProcess: (...args: any[]) => any;
}

const FunctionDetail: React.FC<IFunctionDetail> = (props) => {
  //Value
  const [functionId, setFunctionId] = useState<number>();
  const [moduleId, setModuleId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  
  useEffect(() => {
    setFunctionId(props.functionId);
    setModuleId(props.moduleId);
    setIsDisable(props.isDisable);
  }, [])


  //State
  const [functionName, setFunctionName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  //Function
  const addPopup = useAddPopup();
  const getFunctionById = useGetFunctionById();
  const postFunction = usePostFunction();
  const putFunction = usePutFunction();

  const onSave = () => new Promise((resolve) => {
    if (functionName.trim().length > 0) {
      const body: FunctionType = {
        displayOrder: displayOrder,
        functionName: functionName,
        description: description,
        moduleId: moduleId,
        functionId: functionId,
      };

      


      const isAdd = !functionId;
      const api = isAdd? postFunction(body) : putFunction(body);
      api.then(
        (res) => {
          setModuleId(res.moduleId);
          setIsDisable(true);
          addPopup({
            txn: {
              success: true,
              summary: !isAdd? 'Thay đổi function thành công' : 'Tạo đơn function thành công',
            }
          });
          if(props.postProcess) props.postProcess(res);
          resolve(true);
         
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập tên function',
        },
      });
      resolve(false);
    }
  });

  useEffect(() => {
    if (functionId) {
      getFunctionById(functionId)
        .then((data) => {
          setFunctionName(data.functionName);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, functionId, getFunctionById]);

  //Main
  return (
    <div className="function-detail-container">
      <div className="function-detail-row">
        <Input
          width='300px'
          title='Tên function'
          require={true}
          disabled={isDisable}
          value={functionName}
          onChange={setFunctionName}
        />
      </div>
      <div className="function-detail-row">
        <Input
          width='300px'
          title='Mô tả'
          require={false}
          disabled={isDisable}
          value={description}
          onChange={setDescription}
        />
      </div>
      <div className="function-detail-row">
        <Input
          width='300px'
          title='Thứ tự'
          require={false}
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      {!isDisable && <div className="function-detail-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
      </div>}
    </div>
  );
};

export default FunctionDetail;
