import { useState } from 'react';
import React from 'react';
import ToolBar from 'src/components/ToolBar/ToolBar';
import './Module.css';
import { useDeleteModule, useGetModule } from 'src/api/moduleApi';
import { useEffect } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EventButton, FunctionType, ModuleType } from 'src/api/models';
import ModuleDetail from './ModuleDetail/ModuleDetail';
import { useDeleteFunction, useGetFunctionListByModuleId } from 'src/api/functionApi';
import FunctionDetail from './FunctionDetail/FunctionDetail';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

const Module: React.FC = () => {
  //State
  const [focusModule, setFocusModule] = useState(null);
  const [moduleList, setModuleList] = useState<ModuleType[]>(null);
  const [functionList, setFunctionList] = useState<FunctionType[]>(null);
  const [reloadFlag, setReloadFlag] = useState(false);

  //Function
  const addPopup = useAddPopup();
  const getModule = useGetModule();
  const deleteModule = useDeleteModule();
  const getFunctionByModuleId = useGetFunctionListByModuleId();
  const deleteFunction = useDeleteFunction();

  const moduleDetailModal = useModal(ModuleDetail);
  const functionDetailModal = useModal(FunctionDetail);
  const confirmModal = useModal(ConfirmModal);

  const onDeleteModule = (moduleId: number) => {
    const onConfirm = () => {
      deleteModule(Number(moduleId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa module thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa module này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA MODULE',
    );
  };

  const onDeleteFunction = (functionId: number) => {
    const onConfirm = () => {
      deleteFunction(Number(functionId))
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa function thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa chức năng này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA CHỨC NĂNG',
    );
  };

  const onEditFunction = (functionId: number) => {
    functionDetailModal.handlePresent({
      isDisable: false,
      postProcess: () => setReloadFlag(!reloadFlag),
      moduleId: focusModule,
      functionId: functionId
    }, "THAY ĐỔI CHỨC NĂNG");
  };

  const onAddFuntion = () => {
    functionDetailModal.handlePresent({
      isDisable: false,
      postProcess: () => setReloadFlag(!reloadFlag),
      moduleId: focusModule,
    }, "THÊM MỚI CHỨC NĂNG");
  };

  const onEditModule = (moduleId: number) => {
    moduleDetailModal.handlePresent({
      isDisable: false,
      postProcess: () => setReloadFlag(!reloadFlag),
      moduleId: moduleId,
    }, "THAY ĐỔI MODULE");
  };

  //Function in list button
  const onAddModuleNew = (e: MouseEvent) => {
    moduleDetailModal.handlePresent({
      isDisable: false,
      postProcess: () => setReloadFlag(!reloadFlag),
    }, "THÊM MỚI MODULE");
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddModuleNew,
      align: 'center',
    },
  ];

  useEffect(() => {
    getModule()
      .then((data) => {
        setModuleList(data);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getModule, reloadFlag]);

  useEffect(() => {
    if (focusModule) {
      getFunctionByModuleId(focusModule)
        .then((data) => {
          setFunctionList(data);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, focusModule, getFunctionByModuleId, reloadFlag]);




  //Main
  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH MODULE'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <div className="module-container">
        <fieldset className='module-list'>
          <legend>MODULE</legend>
          <table>
            <thead>
              <tr>
                <th>Tên</th>
                <th>Mô tả</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {moduleList?.map((value: ModuleType, index: number) => {
                return (
                  <tr  key={`module-${index}`} className={`module-name ${focusModule == value.moduleId ? 'focus' : ''}`} onClick={() => {
                    setFocusModule(value.moduleId);
                  }}>
                    <td>{index + 1}. {value.moduleName}</td>
                    <td>{value.description}</td>
                    <td className='inline'>
                      <div className="module-icon-edit" onClick={() => {
                        onEditModule(value.moduleId);
                        setFocusModule(value.moduleId);
                      }}>
                        <i className="fa fa-edit"></i>
                      </div>
                      <div className="module-icon-delete" onClick={() => {
                        onDeleteModule(value.moduleId);
                        setFocusModule(value.moduleId);
                      }}>
                        <i className="fas fa-trash"></i>
                      </div>
                      
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </fieldset>
        <fieldset className='module-list'>
          <legend>FUNCTION</legend>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Tên</th>
                <th>Mô tả</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {functionList?.map((value: FunctionType, index: number) => {
                return (
                  <tr key={`function-${value.functionId}`} className={`module-name ${index % 2 == 0 ? 'chan' : 'le'}`}>
                    <td>{value.functionId}</td>
                    <td>{value.functionName}</td>
                    <td>{value.description}</td>
                    <td className='inline'>
                      <div className="module-icon-edit" onClick={() => {
                        onEditFunction(value.functionId);
                      }}>
                        <i className="fa fa-edit"></i>
                      </div>
                      <div className="module-icon-delete" onClick={() => {
                        onDeleteFunction(value.functionId);
                      }}>
                        <i className="fas fa-trash"></i>
                      </div>
                      
                    </td>
                  </tr>
                )
              })}
              <tr className='module-name'>
                <td colSpan={4}>
                  <ButtonComponent className='add-funtion-btn' title='Thêm Function' icon='add' onClick={onAddFuntion}/>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default Module;
