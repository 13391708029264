import React, { useEffect, useState } from 'react';
import './DashBoard.css';
import { Navigate } from 'react-router-dom';
import useProfile from 'src/hooks/useProfile';
import { useGetUnpaidReport } from 'src/api/reportApi';
import { useAddPopup } from 'src/state/application/hooks';
import { number2vnmoney } from 'src/utils/numberUtils';

const DashBoard: React.FC = () => {
  //Value
  const profile = useProfile();
  const addPopup = useAddPopup();
  const getUnpaidReport = useGetUnpaidReport();
  const [unpaidData, setUnpaidData] = useState<[][]>([]);
  const [paid, setPaid] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [expedite, setExpedite] = useState<number>(0);

  useEffect(() => {
    getUnpaidReport()
      .then((res) => {
        setUnpaidData(res);
        setPaid(res.reduce((total, row) => total+=row.at(2), 0));
        setTotal(res.reduce((total, row) => total+=row.at(3), 0));
        setExpedite(res.reduce((total, row) => total+=row.at(4), 0));
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getUnpaidReport])


  //Main
  return !profile ? <Navigate to="/auth" /> : <div className="dashboard-container">
    <div className='unpaid-row inline'>
      <div className='unpaid-cell number'>STT</div>
      <div className='unpaid-cell name'><span>Đại lý</span></div>
      <div className='unpaid-cell paid'>Đã thu</div>
      <div className='unpaid-cell total'>Phải thu</div>
      <div className='unpaid-cell expedite'>Cần thu ngay</div>
    </div>
    <div className='unpaid-row inline'>
      <div className='unpaid-cell number'>-</div>
      <div className='unpaid-cell name'><span>Tổng</span></div>
      <div className='unpaid-cell paid'>{number2vnmoney(paid)}</div>
      <div className='unpaid-cell total'>{number2vnmoney(total)}</div>
      <div className='unpaid-cell expedite'>{number2vnmoney(expedite)}</div>
    </div>
    {unpaidData.map((row: [], indx) => <div className='unpaid-row inline' key={`row${indx}`}>
      <div className='unpaid-cell number'>{indx + 1}</div>
      <div className='unpaid-cell name'><span>{row.at(1)}</span></div>
      <div className='unpaid-cell paid'>{number2vnmoney(row.at(2))}</div>
      <div className='unpaid-cell total'>{number2vnmoney(row.at(3))}</div>
      <div className='unpaid-cell expedite'>{number2vnmoney(row.at(4))}</div>
    </div>)}


  </div>;
};

export default DashBoard;
