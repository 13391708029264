import React, { useState, useEffect } from 'react';
import {
  EnumProductOrderProgressStatus,
  EnumProductOrderProgressStatusTitle,
  ProductOrderType,
} from 'src/api/models';
import { usePutUpdateProductOrderProgressStatus } from 'src/api/productOrderApi';
import { useAddPopup, useReloadTable } from 'src/state/application/hooks';
import './UpdateProductOrderProgressStatus.css';

interface ISyncProductOrder {
  checkList: ProductOrderType[];
}

const UpdateProductOrderProgressStatus: React.FC<ISyncProductOrder> = (props) => {
  //State
  const [progressStatus, setProgressStatus] = useState<EnumProductOrderProgressStatus>(
    EnumProductOrderProgressStatus.Waiting,
  );
  const [productOrderIdList, setProductOrderIdList] = useState<number[]>(null);

  //Function
  const putUpdateProductOrderProgressStatus = usePutUpdateProductOrderProgressStatus();
  const addPopup = useAddPopup();
  const reloadTable = useReloadTable();

  const onUpdate = () => {
    putUpdateProductOrderProgressStatus(productOrderIdList, progressStatus)
      .then((data) => {
        if (data) {
          addPopup({
            txn: {
              success: true,
              summary: 'Cập nhật thành công',
            },
          });
          reloadTable();
        } else {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: 'Cập nhật thất bại',
            },
          });
          reloadTable();
        }
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        reloadTable();
      });
  };

  //Component
  const progressStatusComponent = () => {
    return (
      <div className="order-status-option">
        <span>Tr.thái đơn hàng</span>
        <select
          value={progressStatus}
          onChange={(event) => {
            setProgressStatus(Number(event.target.value));
          }}
          disabled={false}
        >
          <option value={EnumProductOrderProgressStatus.Waiting.toString()}>
            {EnumProductOrderProgressStatusTitle.Waiting}
          </option>
          <option value={EnumProductOrderProgressStatus.Bought.toString()}>
            {EnumProductOrderProgressStatusTitle.Bought}
          </option>
          <option value={EnumProductOrderProgressStatus.JapanStore.toString()}>
            {EnumProductOrderProgressStatusTitle.JapanStore}
          </option>
        </select>
      </div>
    );
  };

  const btnComponent = () => {
    return (
      <div
        className="add-order-btn"
        onClick={() => {
          onUpdate();
        }}
      ></div>
    );
  };
  //End of component

  useEffect(() => {
    if (props.checkList) {
      const temp: number[] = [];
      props.checkList.map((value) => {
        temp.push(value.productOrderId);
      });
      setProductOrderIdList(temp);
    }
  }, [props.checkList]);

  //Main
  return (
    <div className="add-order-container">
      <div className="add-order-form m-2">
        <div className="add-order-title">THÔNG TIN</div>
        <form>{progressStatusComponent()}</form>
        {btnComponent()}
      </div>
    </div>
  );
};

export default UpdateProductOrderProgressStatus;
