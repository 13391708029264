import React, { HTMLInputTypeAttribute, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { number2vnmoney } from 'src/utils/numberUtils';
import styled from 'styled-components';

interface InputProps<T> {
  width?: string;
  title?: string;
  require?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  placeholder?: string;
  onChange?: (value: T) => void;
  value?: T;
  type?: HTMLInputTypeAttribute;
  inputPadding?: string;
  padding?: string;
  onBlur?: (...args: any[]) => any;
  error?: string;
  rightIcon?: string;
  rightAction?: () => void;
  step?: number;
  focus?: boolean;
  currency?: string;
  onKeyDown?: (...args: any[]) => any;
  className?: string;
  isCopy?: boolean;
  align?: string;
}

const Input = <T extends string | ReadonlyArray<string> | number | undefined>(
  props: InputProps<T>,
) => {
  const {
    width,
    title,
    require,
    hasError,
    disabled,
    placeholder,
    onChange,
    value,
    type,
    inputPadding,
    padding,
    onBlur,
    error,
    rightIcon,
    rightAction,
    step,
    focus,
    currency,
    onKeyDown,
    className,
    isCopy,
    align
  } = props;
  const addPopup = useAddPopup();
  const scanInput = useRef<HTMLInputElement>();
  const [isShowCopy, setShowCopy] = useState<boolean>(false);

  useEffect(() => {
    if(focus) scanInput.current?.focus();
  }, [focus, props])

  return (
    <StyledInputWrapper width={width} padding={padding} className={className} onMouseEnter={() => setShowCopy(true)} onMouseLeave={() => setShowCopy(false)}>
      {(value || !placeholder) && title ? (
        <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle>
      ) : null}
      <StyledInput
        title={value?.toString()}
        hasError={hasError}
        disabled={disabled}
        placeholder={placeholder}
        width={width}
        spellCheck={false}
        onChange={(event) => {
          const nvalue = type == 'number'? Number((event.target as HTMLInputElement).value) : (event.target as HTMLInputElement).value;
          onChange(nvalue as T);
        }}
        padding={inputPadding}
        value={value || ''}
        type={type}
        onBlur={onBlur}
        step={step}
        ref={scanInput}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        onKeyDown={onKeyDown}
        align={align}
      ></StyledInput>
      {isCopy && isShowCopy? <StyledCopyIcon className='material-icons' onClick={() => {
        navigator.clipboard.writeText(value?.toString()??"");
        addPopup({
          txn: {
            success: true,
            summary: 'Đã copy vào clipboard',
          },
        });
      }}>content_copy</StyledCopyIcon> : null}
      {error? <div style={{ color: 'red', paddingLeft: 2 }}>{error}</div>: null}
      {rightIcon? <StyledIcon className='material-icons text-medium' onClick={rightAction} top={(value || !placeholder) && title ? '15%' : '25%'} >{rightIcon}</StyledIcon> : null}
    </StyledInputWrapper>
  );
};

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledInputWrapper = styled.fieldset<{ width?: string; padding?: string}>`
  border: 1px solid #dddcdc;
  flex: 1;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: ${({ padding }) => (padding ? padding : '0 10px 0px 10px')};
  border-radius: 5px;
  position: relative;
  max-width: 90vw;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const StyledCopyIcon = styled.i`
  font-size: 14px;
  position: absolute;
  left: 1px;
  top: 36%;
  cursor: pointer;
  backgroundColor: black;
`

const StyledIcon = styled.i<{ top: string }>`
  position: absolute;
  right: 15px;
  top: ${({ top }) => top};
  cursor: pointer;
`
const StyledInput = styled.input<{
  width?: string;
  hasError?: boolean;
  padding?: string;
  type: string;
  align?: string;
}>`
  color: ${({ theme, hasError }) => (hasError ? theme.color.danger : theme.color.primary)};
  text-align: ${({ type, align }) => (align? align : type == 'number' ? 'right' : 'left')};
  outline: none;
  border: none;
  width: 100%;
  /* min-width: ${({ width }) => (width ? `calc(${width} - 20px)` : '240px')}; */
  max-width: 80vw;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ padding }) => (padding ? padding : '5px')};
  appearance: textfield;
  font-weight: 400;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #dddcdc;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #dddcdc;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #dddcdc;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #dddcdc;
  }
`;

export default Input;
