import React from 'react';
import './LongLoading.css';


const LongLoading: React.FC = () => {

  return (
    <div className="import-loading">
      <i className="fas fa-spin fa-circle-notch"></i>
      <div className="import-loading-text">
        <p>Công việc này có thể mất một chút thời gian, vui lòng chờ trong giây lát!</p>
      </div>
    </div>
  );
};



export default LongLoading;
