import './CustomerDebtDetail.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CustomerDebtType } from 'src/api/models';
import { usePostCustomerDebt } from 'src/api/customerDebtApi';
import { useAddPopup } from 'src/state/application/hooks';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';

interface ICustomerDebtDetail {
  data?: CustomerDebtType;
  isPopup?: boolean;
  isSlide?: boolean;
  isDisable: boolean;
  postProcess?: (...args: any[]) => void;
}

const CustomerDebtDetail: React.FC<ICustomerDebtDetail> = (props) => {
  const {data, isPopup, isSlide, postProcess, isDisable} = props;

  const postCustomerDebt = usePostCustomerDebt();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [money, setMoney] = useState<number>();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve, reject) => {
    const body = {
      customerEmail: customerEmail,
      money: money
    }
    postCustomerDebt(body).then(
      (res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Tạo phiếu tiền nợ thành công',
          },
        });
        if(postProcess) postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        reject(false);
      });

  });


  useEffect(() => {
    console.log(data)
    if(data) {
      setCustomerEmail(data?.customerEmail);
      setMoney(data?.money);
    }
  }, [addPopup, data, props])


  return (
    <div className="customer-debt-detail-container">
      <div className="customer-debt-detail-row">
        <Input
          title="Email khách hàng"
          require={true}
          value={customerEmail}
          onChange={setCustomerEmail}
          disabled={isDisable}
        />
      </div>
      <div className="customer-debt-detail-row">
        <Input
          title="Số tiền"
          require={true}
          value={money}
          onChange={setMoney}
          disabled={isDisable}
          type='number'
        />
      </div>
      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={'THÊM'}
          onClick={onSave}
          loader={true}
        />
      )}
    </div>
  );
};

export default CustomerDebtDetail;
