import React, { useState, useEffect } from 'react';
import { useGetProductById } from 'src/api/productApi';
import './SimOrderDetailItem.css';

interface ISimOrderDetailItem {
  productId?: number;
  simPhoneNumber?: string;
  simImei?: string;
  deposit: number;
  monthlySubscriptionFee: number;
  onChangePhoneNumber?: (...args: any[]) => any;
  onChangeSimImei?: (...args: any[]) => any;
  onChangePrice: (...args: any[]) => any;
  onChangeDeposit: (...args: any[]) => any;
  onChangeMonthlySubscriptionFee: (...args: any[]) => any;
  onDeleteOrderItem?: (...args: any[]) => any;
  index: number;
  isDisable: boolean;
  isCreatedSim: boolean;
  price: number;
}

const SimOrderDetailItem: React.FC<ISimOrderDetailItem> = (props) => {
  //Value
  const productId = props.productId;
  const isDisable = props.isDisable;

  //State
  const [product, setProduct] = useState(null);
  const [simPhoneNumber, setSimPhoneNumber] = useState(props.simPhoneNumber);
  const [simImei, setSimImei] = useState(props.simImei);
  const [price, setPrice] = useState(props.price);
  const [deposit, setDeposit] = useState<any>(props.deposit);
  const [monthlySubscriptionFee, setMonthlySubscriptionFee] = useState<any>(
    props.monthlySubscriptionFee,
  );

  //Function
  const getProductById = useGetProductById();

  useEffect(() => {
    getProductById(productId)
      .then((data) => {
        setProduct(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getProductById, productId]);

  //Main
  return product ? (
    <div className="order-item-container">
      <div className="order-item-count">{props.index + 1}.</div>
      <div className="order-item-image">
        <img src={product.thumbAvatar} alt="avatar" />
      </div>
      <div className="order-item-detail">
        <div>
          <div className="order-item-title">{product.productName}</div>
          <div>
            {props.isCreatedSim ? (
              <span style={{ color: 'blue', fontWeight: 500, marginLeft: 10 }}>Đã đồng bộ</span>
            ) : (
              <span style={{ color: 'red', fontWeight: 500, marginLeft: 10 }}>
                Chưa đồng bộ
              </span>
            )}
          </div>
          <div className="order-item-btn-container">
            <div style={{ width: 69, marginRight: 10 }}>Giá:</div>
            <input
              className="order-item-quantity text-medium"
              type="text"
              value={price}
              onChange={(event) => {
                setPrice(Number(event.target.value));
                props.onChangePrice(props.index, Number(event.target.value));
              }}
              disabled={props.isDisable}
            />
          </div>
          <div className="order-item-btn-container">
            <div style={{ width: 69, marginRight: 10 }}>Tiền cọc:</div>
            <input
              className="order-item-quantity text-medium"
              type="number"
              value={deposit}
              onChange={(event) => {
                //TODO
                setDeposit(event.target.value);
                props.onChangePrice(props.index, Number(event.target.value));
              }}
              disabled={props.isDisable}
            />
          </div>
          <div className="order-item-btn-container">
            <div style={{ width: 69, marginRight: 10 }}>Cước:</div>
            <input
              className="order-item-quantity text-medium"
              type="number"
              value={monthlySubscriptionFee}
              onChange={(event) => {
                //TODO
                setMonthlySubscriptionFee(event.target.value);
                props.onChangePrice(props.index, Number(event.target.value));
              }}
              disabled={props.isDisable}
            />
          </div>
        </div>
        <div className="order-item-btn-container">
          <div style={{ width: 69, marginRight: 10 }}>SĐT:</div>
          <input
            className="order-item-quantity text-medium"
            type="text"
            value={simPhoneNumber}
            onChange={(event) => {
              setSimPhoneNumber(event.target.value);
              if (props.onChangePhoneNumber) {
                props.onChangePhoneNumber(props.index, event.target.value);
              }
            }}
            disabled={props.isDisable}
          />
        </div>
        <div className="order-item-btn-container">
          <div style={{ width: 69, marginRight: 10 }}>IMEI:</div>
          <input
            className="order-item-quantity text-medium"
            type="text"
            value={simImei}
            onChange={(event) => {
              setSimImei(event.target.value);
              if (props.onChangeSimImei) {
                props.onChangeSimImei(props.index, event.target.value);
              }
            }}
            disabled={props.isDisable}
          />
        </div>
      </div>
      {isDisable ? (
        <div
          className="order-item-btn-delete"
          onClick={() => {
            props.onDeleteOrderItem(props.index);
          }}
        >
          <i className="fas fa-trash"></i>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SimOrderDetailItem;
