import React from 'react';
import './HeaderBar.css';
import ButtonNavLink from 'src/components/ButtonNavLink/ButtonNavLink';
import useProfile from 'src/hooks/useProfile';
import { useRemoveProfileInfo } from 'src/state/application/hooks';
import { getFCMToken } from 'src/firebase';
import { useDeleteFirebaseToken } from 'src/api/firebaseTokenApi';
import ChatIcon from 'src/components/ChatIcon/ChatIcon';
import ContactIcon from 'src/components/ContactIcon/ContactIcon';
import FCMNotificationIcon from 'src/components/FCMNotificationIcon/FCMNotificationIcon';
import styled from 'styled-components';
import { isSupported } from 'src/utils/notificationUtils';
import { useNavigate } from 'react-router-dom';

const HeaderBar: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();
  //Function
  const removeProfileInfo = useRemoveProfileInfo();

  const deleteFirebaseToken = useDeleteFirebaseToken();

  const logout = () => {
    removeProfileInfo();
    if(isSupported())
      getFCMToken().then((fcmToken: string) => {
        if (fcmToken) {
          deleteFirebaseToken(fcmToken).then(() => {
            console.log('delete token ' + fcmToken);
          });
        }
      });
  };

  //Main
  return (
    profile && (
      <HeaderBarContainer>
        <HeaderBarName onClick={() => navigate('/')}>{window.innerWidth > 400? 'JVSCORP ADMIN' : 'JVSCORP'} </HeaderBarName>
        <FlexRowWrapper>
          <ChatIcon />
          <ContactIcon />
          <FCMNotificationIcon />
          <ButtonNavLink
            link={'/auth'}
            activeClass={'focus'}
            iconName={'logout'}
            name={'Đăng xuất'}
            backgroundColor={'red'}
            color={'black'}
            buttonClass={'info'}
            onClick={logout}
          />
        </FlexRowWrapper>
      </HeaderBarContainer>
    )
  );
};

export default HeaderBar;

const HeaderBarContainer = styled.div`
  margin: 0px 0px 2px 0px;
  font-weight: 700;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 5px 10px lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
  flex: auto;
`;

const HeaderBarName = styled.div`
  padding-left: 10px;
  cursor: pointer;
`;
