import React, { useEffect, useState } from 'react';
import './InvoiceReport.css';
import { Navigate } from 'react-router-dom';
import useProfile from 'src/hooks/useProfile';
import { useGetInvoiceReport } from 'src/api/reportApi';
import { useAddPopup } from 'src/state/application/hooks';
import { number2vnmoney } from 'src/utils/numberUtils';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Table from 'src/components/Table/Table';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumDataType } from 'src/api/models';

const InvoiceReport: React.FC = () => {
  //Value
  const addPopup = useAddPopup();
  const getInvoiceReport = useGetInvoiceReport();
  const [data, setData] = useState<{[key: string]: any}[]>([]);

  const header = {
    header: [
    {
      code: 'title',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Chuyến hàng',
    },

    {
      code: 'weight',
      dataType: EnumDataType.Decimal,
      isOptions: false,
      title: 'Cân nặng (kg)',
    },
    {
      code: 'volumn',
      dataType: EnumDataType.Decimal,
      isOptions: false,
      title: 'V (khối)',
    },
    {
      code: 'overweight',
      dataType: EnumDataType.Decimal,
      isOptions: false,
      title: 'Cân nặng quá khổ (kg)',
    },
    {
      code: 'total',
      dataType: EnumDataType.VND,
      isOptions: false,
      title: 'Tổng tiền (VNĐ)',
    },
    {
      code: 'paid',
      dataType: EnumDataType.VND,
      isOptions: false,
      title: 'Đã thanh toán (VNĐ)',
    }

  ] as TableColumnType[]
};

  useEffect(() => {
    getInvoiceReport()
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getInvoiceReport])


  //Main
  return (<>
    <ToolBar
      toolbarName={'BÁO CÁO THEO CHUYẾN HÀNG'}
      width={'100%'}
      backgroundColor={'#ebe9e9'}
      isPaging={false}
    />

    <Table
      display={header}
      isInput={false}
      data={data}
      onDoubleClick={(item) => console.log(item)}
      isNo={true}
    />
  </>);
};

export default InvoiceReport;
