
import './CalendarInput.css';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from '../Calendar/Calendar';
import styled from 'styled-components';

interface ICalendarInput {
  width?: string;
  title?: string;
  value: string;
  require?: boolean;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  isFixed?: boolean;
}

const CalendarInput = (props: ICalendarInput) => {

  const { width, title, value, require, onChange, isDisabled, isFixed } = props;

  return (
    <>
      <StyledInputWrapper width={width}>
        {title? <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle> : null}
        <div className={`calendar-input`}>
          {isDisabled ? <i className='fas fa-calendar-alt text-medium' /> : <Calendar
            align={EnumCalendarAlign.left}
            pos={EnumCalendarPos.bot}
            onChange={onChange}
            isFixed={isFixed}
          />}
          <label>{value??""}</label>
      </div>
      </StyledInputWrapper>


    </>
  );
};


const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledInputWrapper = styled.fieldset<{ width?: string }>`
  border: 1px solid #dddcdc;
  flex: 1;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 0 10px 0px 10px;
  border-radius: 5px;
  display: flex;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;



export default CalendarInput;

