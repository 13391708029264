/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ShipOrderScanner.css';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Input from 'src/components/Input';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useGetShipOrderByCode } from 'src/api/shipOrderApi';
import { useDebounce } from 'use-debounce';
import { useAddPopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ShipOrderDetail from '../ShipOrderDetail/ShipOrderDetail';
import { ADMIN } from 'src/common/constant/Constant';
import { EnumShipOrderProgressStatus } from 'src/api/models';
import MultiCodeReader from 'src/components/MultiCodeReader/MultiCodeReader';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

const ShipOrderScanner: React.FC = () => {
  //State
  const [status, setStatus] = useState<number>();
  const [title, setTitle] = useState<string>();
  const params = useParams<{status: string }>();
  useEffect(() => {
    const param = Number(params.status);
    setStatus(param);
    setTitle(param == EnumShipOrderProgressStatus.JapanStore? 'NHẬP KHO NHẬT BẢN' :
    param == EnumShipOrderProgressStatus.VietNamStore? 'NHẬP KHO VIỆT NAM' : 'GỬI HÀNG CHO KHÁCH');
  }, [params.status]);
  const [billCode, setBillCode] = useState<string>();
  const [billCodeDebound] = useDebounce(billCode, 500);
  const [jpCode, setJpCode] = useState<string>();
  const [inputJpCode, setInputJpCode] = useState<string>();
  const [inputBillCode, setInputBillCode] = useState<string>();
  const [jpCodeDebound] = useDebounce(jpCode, 500);
  const qrReaderModal = useModal(QRCodeReader);
  const multicodeReaderModal = useModal(MultiCodeReader);
  const navigate = useNavigate();
  const getShipOrderByCode = useGetShipOrderByCode();
  const addPopup = useAddPopup();

  const detailModal = useModal(ShipOrderDetail);

  useEffect(() => {
    const strCode = ((billCodeDebound || jpCodeDebound) as string)?.trim();
    search(strCode, billCodeDebound != undefined).then();
  }, [addPopup, billCodeDebound, jpCodeDebound, getShipOrderByCode, navigate])

  const onSearch = () => {
    const strCode = (inputBillCode || inputJpCode)?.trim();
    return search(strCode, inputBillCode != undefined);
  };

  const search = (strCode: string, isBillCode: boolean) => new Promise((resolve) => {
    if(isBillCode) {
      setInputBillCode(strCode);
      setInputJpCode(undefined);
    } else {
      setInputJpCode(strCode);
      setInputBillCode(undefined);
    }
    if(strCode?.length >= 8) {
      getShipOrderByCode(strCode).then((res) => {
        detailModal.handlePresent({
          shipOrderId: res.shipOrderId,
          isDisable: false,
          role: ADMIN,
          newProgressStatus: status,
          postProcess: () => {
            detailModal.handleDismiss();
            setBillCode(undefined);
          },
        }, title);
        resolve(true);
      }).catch((error) => {
        if(error.errorCode == "SHIP_ORDER_NOT_FOUND") {
          if(Number(params.status) == EnumShipOrderProgressStatus.JapanStore) {
            detailModal.handlePresent({
              isDisable: false,
              role: ADMIN,
              billCode: isBillCode? strCode : null,
              japanTrackingCode: !isBillCode? strCode : null,
              newProgressStatus: status,
              postProcess: () => {
                detailModal.handleDismiss();
                setBillCode(undefined);
              },
            }, title);
            resolve(true);
          } else {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra',
                message: "Mã vận chuyển / Mã vận chuyển JP không tồn tại",
              },
            })
            resolve(false);
          }
        } else {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          })
          resolve(false);
        }
      })
    } else if(strCode?.length > 0) {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: "Mã vận chuyển / Mã vận chuyển JP không đúng",
        },
      })
      resolve(false);
    } else {
      resolve(false);
    }
  });

  //Main
  return (
    <>
      <ToolBar
        toolbarName={title}
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
        isBack={true}
      />
      <div className='ship-order-scanner-container'>
        <div className='ship-order-scanner-row'>
          <Input
            width='320px'
            title="Mã phiếu vận chuyển"
            require={true}
            disabled={false}
            value={inputBillCode}
            onChange={setInputBillCode}
          />
          <span className="material-icons code-clear" onClick={() => {
            setInputBillCode(undefined);
            setBillCode(undefined);
          }}>clear</span>
          <div className="i-tooltip ship-order-scanner-qr">
            <span className="material-icons info-guide" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (code: string) => {
                  setBillCode(code);
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN")
            }}>qr_code</span>
          </div>

        </div>
        <div className='ship-order-scanner-row'>
          <Input
            width='320px'
            title="Mã vận chuyển JP"
            require={true}
            disabled={false}
            value={inputJpCode}
            onChange={setInputJpCode}
          />
          <span className="material-icons code-clear" onClick={() => {
            setInputJpCode(undefined);
            setJpCode(undefined);
          }}>clear</span>
          <div className="i-tooltip ship-order-scanner-qr">
            <span className="material-icons info-guide bar-code" onClick={() => {
              multicodeReaderModal.handlePresent({
                readCode: (code: string) => {
                  setJpCode(code);
                  multicodeReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN JP")
            }}>line_weight</span>

          </div>
        </div>
        <div className='ship-order-scanner-row'>
          <ButtonComponent icon="search" title={'TÌM KIẾM'} onClick={onSearch} loader={true} />
        </div>
      </div>
    </>
  );
};

export default ShipOrderScanner;
