import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import {
  HtmlSimpleParameterType,
  HtmlTableParameterType,
  ReportType,
  EnumAction,
  EventButton,
} from 'src/api/models';
import MonacoEditor from '@monaco-editor/react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { BASE_REPORT_URL, BASE_SETTING_URL } from 'src/constants';
import {
  useGetReportById,
  usePostReport,
  usePutReport,
} from 'src/api/reportApi';
import './ReportDetail.css';
import SimpleParameter from 'src/components/SimpleParameter';
import TableParameter from 'src/components/TableParameter';
import ReportInput from 'src/components/ReportInput';
import Checkbox from 'src/components/Checkbox';
import ReportHeader from 'src/components/ReportHeader';
import CodeInput from 'src/components/CodeInput';


const ReportDetail: React.FC = () => {

  //Value
  const params = useParams<{ type: string; reportId: string }>();
  const [reportId, setReportId] = useState(
    params?.reportId ? Number(params.reportId) : undefined,
  );
  const isAdd = Number(params.type) === EnumViewType.Edit && reportId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;

  //Function
  const getReportById = useGetReportById();
  const postReport = usePostReport();
  const putReport = usePutReport();
  const addPopup = useAddPopup();

  //Value
  const navigate = useNavigate();
  //State
  const [reportKey, setReportKey] = useState<string>();
  const [reportTitle, setReportTitle] = useState<string>();
  const [reportInput, setReportInput] = useState<string>();
  const [reportQuery, setReportQuery] = useState<string>();
  const [reportChar, setReportChar] = useState<string>('');
  const [displayOrder, setDisplayOrder] = useState<number>(null);
  const [reloadFlag, setReloadFlag] = useState(false);

  const [reportSimple, setReportSimple] = useState<string>();
  const [reportTable, setReportTable] = useState<string>();
  const [searchFlg, setSearchFlg] = useState(false);
  const [pagingFlg, setPagingFlg] = useState(false);

  const editorRef = useRef(null);


  useEffect(() => {
    if (!isAdd) {
      getReportById(reportId)
        .then((data) => {
          setReportKey(data.reportKey);
          setReportTitle(data.reportTitle);
          setReportQuery(data.reportQuery);
          setDisplayOrder(data.displayOrder);
          setReportInput(data.reportInput);
          setReportSimple(data.reportSimple);
          setReportTable(data.reportTable);
          setSearchFlg(data.searchFlg);
          setPagingFlg(data.pagingFlg);
          setReportChar(data.reportChar);
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
  }, [addPopup, getReportById, isAdd, reportId]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }



  const onSave = useCallback(() => {
    const reportQuery = editorRef.current.getValue();
    const report: ReportType = {
      reportId: reportId,
      displayOrder: displayOrder,
      reportKey: reportKey,
      reportTitle: reportTitle,
      reportQuery: reportQuery,
      reportSimple: reportSimple,
      reportTable: reportTable,
      reportInput: reportInput,
      searchFlg: searchFlg,
      pagingFlg: pagingFlg,
      reportChar: reportChar
    };
    const api = isAdd? postReport : putReport;

    api(report).then((r) => {
      setReportId(r.reportId);
      setReloadFlag(!reloadFlag);
      addPopup({
        txn: {
          success: true,
          summary: isAdd? 'Thêm thành công!' : 'Sửa thành công!',
        },
      });
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [addPopup, displayOrder, isAdd, postReport, putReport, reloadFlag, reportQuery, searchFlg, pagingFlg,
     reportId, reportInput, reportKey, reportSimple, reportTable, reportTitle, reportChar]);



  const listEditToolButton: EventButton[] = [
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: onSave,
      align: 'center',
    },
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: () => navigate(`${BASE_REPORT_URL}/${EnumViewType.Edit}/id/${reportId}`),
      align: 'center',
    }
  ];


  return (
    <>
      <ToolBar
        toolbarName={'MẪU BÁO CÁO'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        isBack={true}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />

      <div className='report-container'>
        <div className='report-info'>
          <div>
            <Input
              width='300px'
              title='Mã báo cáo'
              require={true}
              disabled={isDisable}
              value={reportKey}
              onChange={setReportKey}
            />
            <Input
              width='300px'
              title='Tiêu đề'
              require={true}
              disabled={isDisable}
              value={reportTitle}
              onChange={setReportTitle}
            />
            <Input
              width='300px'
              title='Thứ tự'
              require={true}
              disabled={isDisable}
              value={displayOrder}
              onChange={setDisplayOrder}
            />
            <Checkbox
              width='300px'
              value={searchFlg}
              disabled={isDisable}
              onChange={setSearchFlg}
              title='Tìm kiếm'
            />
             <Checkbox
              width='300px'
              value={pagingFlg}
              disabled={isDisable}
              onChange={setPagingFlg}
              title='Phân trang'
            />
            <span className='inline'>
              <span style={{marginRight: '10px'}}>Cấu hình biểu đồ</span>
              <CodeInput 
                value={reportChar} 
                disabled={false}
                language='json' 
                onChange={setReportChar} 
              />
            </span>
          </div>
          <fieldset className='report-input-area'>
            <legend>ĐẦU VÀO</legend>
            <ReportInput filters={reportInput? JSON.parse(reportInput) : []} onChange={(value) => setReportInput(JSON.stringify(value))} />
          </fieldset>
          <fieldset className='report-input-area'>
            <legend>THÔNG IN CHUNG</legend>
            <ReportHeader header={reportSimple? JSON.parse(reportSimple) : []} onChange={(value) => setReportSimple(JSON.stringify(value))}/>
          </fieldset>
        </div>
        <fieldset className='report-area'>
          <legend>BẢNG THÔNG TIN</legend>
          <ReportHeader header={reportTable? JSON.parse(reportTable) : []} onChange={(value) => setReportTable(JSON.stringify(value))}/>
          <div className='report-edit'>
            <MonacoEditor
              defaultLanguage="sql"
              value={reportQuery}
              onMount={handleEditorDidMount}
              options={{
                readOnly: isDisable,
              }}
              theme="vs-dark"
            />
          </div>
         
        </fieldset>
      

      </div>
    </>
  );
};

export default ReportDetail;
