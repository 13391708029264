import React, { useState, useEffect } from 'react';
import { EnumSimStatus, EnumSimStatusTitle, SimType } from 'src/api/models';
import { usePutUpdateSimProgressStatus } from 'src/api/simApi';
import { useAddPopup, useReloadTable } from 'src/state/application/hooks';
import './UpdateSimProgressStatus.css';

interface IUpdateSimProgressStatus {
  checkList: SimType[];
}

const UpdateSimProgressStatus: React.FC<IUpdateSimProgressStatus> = (props) => {
  //State
  const [progressStatus, setProgressStatus] = useState<EnumSimStatus>(EnumSimStatus.Active);
  const [simIdList, setSimIdList] = useState<number[]>(null);

  //Function
  const putUpdateSimProgressStatus = usePutUpdateSimProgressStatus();
  const addPopup = useAddPopup();
  const reloadTable = useReloadTable();

  const onUpdate = () => {
    putUpdateSimProgressStatus(simIdList, progressStatus)
      .then((data) => {
        if (data) {
          addPopup({
            txn: {
              success: true,
              summary: 'Cập nhật trạng thái thành công',
            },
          });
          reloadTable();
        } else {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: 'Cập nhật thất bại',
            },
          });
          reloadTable();
        }
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        reloadTable();
      });
  };

  //Component
  const progressStatusComponent = () => {
    return (
      <div className="order-status-option">
        <span>Tr.thái đơn sim</span>
        <select
          value={progressStatus}
          onChange={(event) => {
            setProgressStatus(Number(event.target.value));
          }}
          disabled={false}
        >
          <option value={EnumSimStatus.Active.toString()}>{EnumSimStatusTitle.Active}</option>
          <option value={EnumSimStatus.Deactive.toString()}>
            {EnumSimStatusTitle.Deactive}
          </option>
          <option value={EnumSimStatus.Error.toString()}>{EnumSimStatusTitle.Error}</option>
          <option value={EnumSimStatus.StopService.toString()}>
            {EnumSimStatusTitle.StopService}
          </option>
        </select>
      </div>
    );
  };

  const btnComponent = () => {
    return (
      <div
        className="add-order-btn"
        onClick={() => {
          onUpdate();
        }}
      ></div>
    );
  };
  //End of component

  useEffect(() => {
    if (props.checkList) {
      const temp: number[] = [];
      props.checkList.map((value) => {
        temp.push(value.simId);
      });
      setSimIdList(temp);
    }
  }, [props.checkList]);

  //Main
  return (
    <div className="add-order-container">
      <div className="add-order-form m-2">
        <div className="add-order-title">THÔNG TIN</div>
        <form>{progressStatusComponent()}</form>
        {btnComponent()}
      </div>
    </div>
  );
};

export default UpdateSimProgressStatus;
