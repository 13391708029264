import React from 'react';
import { MouseEvent } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ReceiverInfoType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useDeleteUserCustomerType } from 'src/api/userCustomerTypeApi';
import './ReceiverInfo.css';
import { useGetReceiverInfo } from 'src/api/receiverInfoApi';
import ReceiverInfoDetail, {
  EnumConfigReceiverInfoDetail,
} from './ReceiverInfoDetail/ReceiverInfoDetail';
import { useNavigate } from 'react-router-dom';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

//TODO
const ReceiverInfo: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteUserCustomerType = useDeleteUserCustomerType();
  const getReceiverInfo = useGetReceiverInfo();

  const navigate = useNavigate();


  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [keyword, setKeyword] = useState<string>();
  const [userId, setUserId] = useState<number>();

  //Local state
  const [count, setCount] = useState<number>();
  const [receiverInfoList, setReceiverInfoList] = useState<ReceiverInfoType[]>([]);

  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ReceiverInfoDetail);


  const display = {
    header: [
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Cộng tác viên',
      },
      {
        code: 'fullname',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên người dùng',
      },
      {
        code: 'phoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Số điện thoại',
      },
      {
        code: 'zipCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã bưu điện',
      },
      {
        code: 'address1',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 1',
      },
      {
        code: 'address2',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 2',
      },
      {
        code: 'address3',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 3',
      },
      {
        code: 'address4',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 4',
      },
      {
        code: 'facebook',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Facebook',
      },
      {
        code: 'zalo',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Zalo',
      },
    ] as TableColumnType[]
  };

  //Menucontext
  const onView = (receiverInfoId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigReceiverInfoDetail.view,
        receiverInfoId: receiverInfoId,
        postProcess: confirmModal.handleDismiss,
      },
      'CHI TIẾT THÔNG TIN NHẬN HÀNG',
    );
  };

  const onEdit = (receiverInfoId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigReceiverInfoDetail.edit,
        receiverInfoId: receiverInfoId,
        postProcess: confirmModal.handleDismiss,
      },
      'THAY ĐỔI THÔNG TIN NHẬN HÀNG',
    );
  };

  const onDelete = (receiverInfoId: number) => {
    const onConfirm = () => {
      deleteUserCustomerType(receiverInfoId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa thông tin nhận hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa thông tin nhận hàng này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA THÔNG TIN NHẬN HÀNG',
    );

  };


  const menuContext = (item: ReceiverInfoType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.receiverInfoId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.receiverInfoId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.receiverInfoId),
      buttonClass: 'info',
      align: '',
    },
  ];


  //Function in the listButton
  const onAddNewReceiverInfo = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumConfigReceiverInfoDetail.add,
        postProcess: confirmModal.handleDismiss,
      },
      'THÊM MỚI THÔNG TIN NHẬN HÀNG',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewReceiverInfo,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getReceiverInfo(page, size, keyword, userId)
      .then((data) => {
        setReceiverInfoList(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getReceiverInfo, keyword, page, size, userId]);

  //Main
  return (
    <>
    <ToolBar
      toolbarName={'DANH SÁCH THÔNG TIN NHẬN HÀNG'}
      listRightButton={listButton}
      width={'100%'}
      backgroundColor={'#ebe9e9'}
      count={count}
      onSearch={(value) => setKeyword(value)}
      onChangePage={setPage}
      onChangeSize={setSize}
      keyword={keyword}
      page={page}
      size={size}
      isPaging={true}
    />

    <Table
      display={display}
      isInput={false}
      data={receiverInfoList}
      menuContext={menuContext}
      allowCheckbox={true}
      onDoubleClick={(item) => onView(item.receiverInfoId)}
      isNo={true}
    />
  </>
  );
};
export default ReceiverInfo;
