import './ReportHeader.css';
import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import { DataTypeList, EnumDataType, ReportHeaderType } from 'src/api/models';
import Input from '../Input';
import SelectBoxComponent from '../SelectBoxComponent/SelectBoxComponent';
import Checkbox from '../Checkbox';
import CodeInput from '../CodeInput';
import ButtonComponent from '../ButtonComponent/ButtonComponent';

interface IReportHeader {
  header?: ReportHeaderType[];
  onChange: (value: ReportHeaderType[]) => void;
}

const ReportHeader: React.FC<IReportHeader> = (props) => {
  const {header, onChange} = props;

  return (  
    <div className="report-header-container">
      <table>
        <thead className='report-header-title'>
          <tr>
            <th style={{width: "134px"}}>Title</th>
            <th style={{width: "130px"}}>Code</th>
            <th style={{width: "70px"}}>DataType</th>
            <th style={{width: "46px"}}>Is.Opt</th>
            <th style={{width: "58px"}}>Options</th>
            <th style={{width: "53px"}}>Col.Css</th>
            <th style={{width: "52px"}}>Cel.Css</th>
            <th style={{width: "57px"}}>Convert</th>
            <th style={{width: "60px"}}>Ratio</th>
            <th style={{width: "54px"}}>Hidden</th>
            <th style={{width: "40px"}}></th>
          </tr>
        </thead>
        <tbody>
        {header?.map((row: ReportHeaderType, index: number) => {
          return (
            <tr key={`report-header-${index}`}>
              <td>
                <Input
                  width='120px'
                  require={false}
                  disabled={false}
                  value={row.title}
                  onChange={(value) => {
                    row.title = value;
                    onChange([...header]);
                  }}
                  inputPadding='1px'
                  padding='1px'
                  className='text-small'
                />
              </td>
              <td>
                <Input
                  width='120px'
                  require={false}
                  disabled={false}
                  value={row.code}
                  onChange={(value) => {
                    row.code = value;
                    onChange([...header]);
                  }}
                  inputPadding='1px'
                  padding='1px'
                  className='text-small'
                />
              </td>
              <td>
                <SelectBoxComponent
                  width='60px'
                  require={false}
                  onChange={(value) => {
                    row.dataType = value;
                    onChange([...header]);
                  }}
                  isDisable={false}
                  value={row.dataType}
                  data={DataTypeList}
                  valueType={'value'}
                  titleType={'title'}
                  inputPadding='1px'
                  padding='1px'
                  fontSize='small'
                />
              </td>
              <td>
                <Checkbox 
                  value={row.isOptions} 
                  disabled={false} 
                  onChange={(value) => {
                    row.isOptions = value;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={JSON.stringify(row.options)} 
                  disabled={false}
                  language='json' 
                  onChange={(value) => {
                    row.options = value? JSON.parse(value) : undefined;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={row.collumCss} 
                  disabled={false}
                  language='css' 
                  onChange={(value) => {
                    row.collumCss = value;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={row.cellCss} 
                  disabled={false}
                  language='css' 
                  onChange={(value) => {
                    row.cellCss = value;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={row.convert} 
                  disabled={false} 
                  language='javascript'
                  onChange={(value) => {
                    row.convert = value;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td>
                <Input
                  width='50px'
                  require={false}
                  disabled={false}
                  value={row.ratio}
                  onChange={(value) => {
                    row.ratio = value;
                    onChange([...header]);
                  }}
                  type='number'
                  inputPadding='1px'
                  padding='1px'
                  className='text-small'
                />
              </td>
              <td>
                <Checkbox 
                  value={row.isHidden} 
                  disabled={false} 
                  onChange={(value) => {
                    row.isHidden = value;
                    onChange([...header]);
                  }} 
                />
              </td>
              <td className='inline'>
                <div className="report-icon" onClick={() => onChange(header.filter(r => r != row))}>
                  <i className="fas fa-trash"></i>
                </div>
                <div className="report-icon" onClick={() => {
                  const indx = header.indexOf(row);
                  header.splice(indx, 0, {
                    code: undefined,
                    title: undefined,
                    dataType: EnumDataType.Text,
                    isOptions: false,
                    options: undefined,
                    collumCss: undefined,
                    cellCss: undefined,
                    ratio: undefined,
                    convert: undefined,
                  });
                  onChange([...header]);
                }}>
                  <i className="fa fa-plus"></i>
                </div>
              </td>
            </tr>
          )
        })}
          <tr>
            <td>
              <ButtonComponent title='Thêm' onClick={() => {
                onChange([...header, {
                  code: undefined,
                  title: undefined,
                  dataType: EnumDataType.Text,
                  isOptions: false,
                  options: undefined,
                  collumCss: undefined,
                  cellCss: undefined,
                  ratio: undefined,
                  convert: undefined,
                }]);
              }}/>
            </td>
          </tr>
        </tbody>
      </table>
    
    </div>
  );
};

export default ReportHeader;
