
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  DisbursementType,
} from './models';

/***********************************/
export const useGetDisbursements = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number,) => {
      return fetch({
        url: `sccore/rest-api/disbursement?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<DisbursementType>>;
    },
    [fetch],
  );
};

export const usePostDisbursement = () => {
  const fetch = useFetch();
  return useCallback(
    (disbursement: DisbursementType) => {
      return fetch({
        url: 'sccore/rest-api/disbursement',
        method: 'post',
        data: disbursement,
      }) as Promise<DisbursementType>;
    },
    [fetch],
  );
};

export const useGetDisbursementById = () => {
  const fetch = useFetch();
  return useCallback(
    (disbursementId: number) => {
      return fetch({
        url: `sccore/rest-api/disbursement/${disbursementId}`,
        method: 'get',
      }) as Promise<DisbursementType>;
    },
    [fetch],
  );
};

export const useGetDisbursementByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/disbursement/code/${code}`,
        method: 'get',
      }) as Promise<DisbursementType>;
    },
    [fetch],
  );
};

export const useDeleteDisbursement = () => {
  const fetch = useFetch();
  return useCallback(
    (disbursementId: number) => {
      return fetch({
        url: `sccore/rest-api/disbursement/${disbursementId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
