import './EmailList.css';
import React from 'react';
import {
  useAddPopup
} from 'src/state/application/hooks';
import { EmailType, EnumDataType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useGetEmailList } from 'src/api/emailApi';
import EmailListSlideBar from './EmailListSlideBar/EmailListSlideBar';
import useSlideBar from 'src/hooks/useSlideBar';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { useDebounce } from 'use-debounce';

const EmailList: React.FC = () => {

  const display = {
    header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày gửi',
      },
      {
        code: 'receiver',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người nhận',
      },
      {
        code: 'emailTemplateKey',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã email',
      }
    ] as TableColumnType[]
  };

  //Function
  const addPopup = useAddPopup();
  const getEmailList = useGetEmailList();

  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [emailList, setEmailList] = useState<EmailType[]>([]);

  const slideBar = useSlideBar(EmailListSlideBar);

  useEffect(() => {
    getEmailList(page, size, keyword)
      .then((data) => {
        setEmailList(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra!',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getEmailList, keywordDebound, page, size]);


  const onClick = (email: EmailType) => {
    slideBar.handlePresent({
      receiver: email.receiver,
      emailTitle: email.emailTitle,
      emailValue: email.emailValue,
    });
  };

  return (
    <>
      <ToolBar
        toolBarKey='JVSADMIN_EMAILS'
        toolbarName={'Danh sách email đã gửi'}
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />
      <Table
        display={display}
        isInput={false}
        data={emailList}
        onClick={onClick}
      />
    </>

  );
};
export default EmailList;
