import React, { useState, useEffect } from 'react';
import { EnumAction, EventButton } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import './PaymentDelete.css';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import useModal from 'src/hooks/useModal';
import Input from 'src/components/Input';
import { useApproveBill, useDeletePayment } from 'src/api/paymentApi';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

interface IPaymentDelete {
  paymentCode?: string;
  debt?: number;
  paymentId: number;
  postProcess?: (...args: any[]) => void;
}

const PaymentDelete: React.FC<IPaymentDelete> = (props) => {
  const {paymentId, debt, paymentCode, postProcess} = props
  //State
  const [money, setMoney] = useState<number>(debt);
  const confirmModal = useModal(ConfirmModal);
  //Function
  const deletePayment = useDeletePayment();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve) => {

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: () => {
          deletePayment(paymentId, money).then((res) => {
            addPopup({
              txn: {
                success: true,
                summary: 'Xóa hóa đơn thanh toán thành công',
              },
            });
            if(postProcess) postProcess();
          }).catch((error) => {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra',
                message: error.errorMessage,
              },
            });
            resolve(false);
          });
        },
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: `Bạn chắc chắn xóa hóa đơn thanh toán ${paymentCode}?`,
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÁC NHẬN XÓA HÓA ĐƠN',
    );



  });

  //Main
  return (
    <div className="payment-delete-container">
      <div className="payment-delete-row">
        <Input
          width="360px"
          title="Mã hóa đơn"
          require={false}
          disabled={true}
          value={paymentCode}
        />
        <Input
          width="360px"
          title="Khai báo nợ"
          require={false}
          disabled={false}
          value={money}
          onChange={setMoney}
          type='number'
        />
      </div>

      <ButtonComponent icon="delete" title={'XÓA THANH TOÁN'} onClick={onSave} loader={true} />
    </div>
  );
};

export default PaymentDelete;
