/* eslint-disable react-hooks/exhaustive-deps */
import './Product.css';
import React, { useCallback } from 'react';
import { MouseEvent } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EventButton, EnumDataType, ProductType } from 'src/api/models';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import ProductDetails from './ProductDetails/ProductDetails';
import { useNavigate } from 'react-router-dom';
import ProductGift from './ProductGift/ProductGift';
import { useGetAttributeList, useGetProductCategory } from 'src/api/productCategoryApi';
import { useGetProduct, useDeleteProduct } from 'src/api/productApi';
import { useGetProductRealm } from 'src/api/productRealmApi';
import { BASE_WEB_URL } from 'src/constants';
import ProductExcel from './ProductExcel/ProductExcel';
import ProductImportImages from './ProductImportImages/ProductImportImages';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import { FilterType } from 'src/components/FilterBox/FilterOptionBox';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useDebounce } from 'use-debounce';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import ListView from 'src/components/ListView/ListView';
import { EnumViewType } from 'src/common/enum/EnumViewType';


//Category
const Product: React.FC = () => {
  //Value

  const REALM_FILTER = 'realm';
  const REALM_FIELD_FILTER = 'productRealmId';

  const CATEGORY_FILTER = 'category';
  const CATEGORY_FIELD_FILTER = 'productCategoryId';

  const STOCK_STATUS_FILTER = 'stock-status';
  const STOCK_STATUS_FIELD_FILTER = 'value';

  const STOP_SELLING_FILTER = 'stop-selling-status';
  const STOP_SELLING_FIELD_FILTER = 'value';


  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>('');
  const [realmIds, setRealmIds] = useState<number[]>([]);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [filters, setFilters] = useState<FilterType<object>[]>([]);
  const [isStopSellingFlg, setStopSellingFlg] = useState<boolean>();
  const [isStockRemainFlg, setStockRemainFlg] = useState<boolean>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  const navigate = useNavigate();
  //Function
  const addPopup = useAddPopup();
  const getProduct = useGetProduct();
  const getRealm = useGetProductRealm();
  const getProductCategory = useGetProductCategory();
  const deleteProduct = useDeleteProduct();
  const getAttributeList = useGetAttributeList();

  const [reloadFlag, setReloadFlag] = useState(false);

  const detailModal = useModal(ProductDetails);
  const giftModal = useModal(ProductGift);
  const importImageModal = useModal(ProductImportImages);
  const excelModal = useModal(ProductExcel);
  const confirmModal = useModal(ConfirmModal);

  const [items, setItems] = useState([]);
  const [count, setCount] = useState<number>();

  const [header, setHeader] = useState<TableColumnType[]>([
    {
      code: 'productCategoryName',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Tên danh mục sản phẩm',
    },
    {
      code: 'productCode',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Mã sản phẩm',
    },
    {
      code: 'productName',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Tên sản phẩm',
    },
    {
      code: 'thumbAvatar',
      dataType: EnumDataType.Image,
      isOptions: false,
      title: 'Avatar',
    },
    {
      code: 'isHiddenSerial',
      dataType: EnumDataType.Boolean,
      isOptions: false,
      title: 'Ẩn Serial',
    },
    {
      code: 'stopSelling',
      dataType: EnumDataType.Boolean,
      isOptions: false,
      title: 'Ngừng bán',
    },
    {
      code: 'displayOrder',
      dataType: EnumDataType.Int,
      isOptions: false,
      title: 'Thứ tự',
      cellCss: {
        textAlign: 'center',
      },
    },
    {
      code: 'stockRemainQuantity',
      dataType: EnumDataType.Int,
      isOptions: false,
      title: 'S.lg hàng tồn',
    },
    {
      code: 'price',
      dataType: EnumDataType.JPY,
      isOptions: false,
      title: 'Giá tiền',
    },
  ]);

  const display: ItemDisplayType<ProductType> = {
    header: [
      {
        code: 'productCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      avatar: 'thumbAvatar',
      info: [
        [
          {
            code: 'productName',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },

        ],
        [
          {
            code: 'price',
            dataType: EnumDataType.JPY,
            isOptions: false,
            icon: 'shopping_cart',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'stockRemainQuantity',
            dataType: EnumDataType.Int,
            isOptions: false,
            cellCss: {
              margin: '0 5px',
            }
          }
        ],
      ]
    },
    bottom: [
      [
        {
          code: 'isHiddenSerial',
          title: 'Ẩn Serial',
          dataType: EnumDataType.Boolean,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
        {
          code: 'stopSelling',
          title: 'Ngừng bán',
          dataType: EnumDataType.Boolean,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
        {
          code: 'productCategoryName',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
            flex: 'auto',
            justifyContent: 'end'
          }
        },
      ]
    ],

    actions: (item: ProductType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.productId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.productId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  useEffect(() => {
    Promise.all([getAttributeList(), getRealm(), getProductCategory()]).then(
      ([attributes, realms, categories]) => {
        attributes.forEach((attribute) => {
          header.push({
            code: attribute.attributeName,
            dataType: attribute.attributeType,
            isOptions: false,
            title: attribute.attributeTitle,
          });
        });
        setHeader([...header]);
        setFilters([
          {
            data: realms,
            valueField: REALM_FIELD_FILTER,
            titleField: 'productRealmName',
            title: 'Loại SP',
            filterKey: REALM_FILTER,
          },
          {
            data: categories,
            valueField: CATEGORY_FIELD_FILTER,
            titleField: 'productCategoryName',
            title: 'Danh mục SP',
            filterKey: CATEGORY_FILTER,
          },
          {
            data: [
              {
                value: true,
                title: 'Tồn kho',
              },
              {
                value: false,
                title: 'Hết hàng',
              },
            ],
            valueField: STOCK_STATUS_FIELD_FILTER,
            titleField: 'title',
            title: 'T.thái kho',
            filterKey: STOCK_STATUS_FILTER,
          },
          {
            data: [
              {
                value: true,
                title: 'Ngừng bán',
              },
              {
                value: false,
                title: 'Đang bán',
              },
            ],
            valueField: STOP_SELLING_FIELD_FILTER,
            titleField: 'title',
            title: 'T.thái bán hàng',
            filterKey: STOP_SELLING_FILTER,
          }
        ]);
      },
    );
  }, []);




  useEffect(() => {
    getProduct((keywordDebound ?? '') as string, page, size, realmIds, categoryIds, isStopSellingFlg, isStockRemainFlg)
      .then((data) => {
        setCount(data.count);
        setItems(data.items);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    getProduct,
    reloadFlag,
    keywordDebound,
    page,
    size,
    realmIds,
    categoryIds,
    addPopup,
    isStockRemainFlg,
    isStopSellingFlg
  ]);

  //Menucontext
  const onView = (productId: number) => {
    detailModal.handlePresent(
      {
        productId: productId,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = useCallback((productId: number) => {
    detailModal.handlePresent(
      {
        productId: productId,
        isDisable: false,
        postProcess: (data: ProductType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.productId);
        },
      },
      'THAY ĐỔI',
    );
  }, [reloadFlag]);

  const onDelete = (productId: number) => {
    const onConfirm = () => {
      deleteProduct(productId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa sản phẩm thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa sản phẩm này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA SẢN PHẨM',
    );
  };

  const onStock = (productId: number) => {
    if (productId && productId > 0) {
      const url = `${BASE_WEB_URL}/product/${productId}/product-serial`;
      navigate(url);
    }
  };

  const onInventory = (productId: number) => {
    if (productId && productId > 0) {
      const url = `${BASE_WEB_URL}/inventory/${productId}`;
      navigate(url);
    }
  };

  const onDetail = (productId: number) => {
    if (productId && productId > 0) {
      const url = `${BASE_WEB_URL}/product/${productId}/${EnumViewType.View}`;
      navigate(url);
    }
  };

  const onGiftConfig = (productId: number) => {
    giftModal.handlePresent(
      {
        productId: productId,
      },
      'CHỈNH SỬA QUÀ TẶNG',
    );
  };

  const menuContext = (item: ProductType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onDetail(item.productId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: item.isHiddenSerial? 'Xuất nhập kho' : 'Chi tiết tồn kho',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => item.isHiddenSerial? onInventory(item.productId) : onStock(item.productId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.productId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.productId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Cấu hình quà tặng',
      icon: 'settings',
      actionType: EnumAction.Edit,
      action: () => onGiftConfig(item.productId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onAddProductNew = useCallback(() => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ProductType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.productId);
        },
      },
      'THÊM MỚI',
    );
  }, [reloadFlag]);

  const onOpenPopupProductImages = () => {
    importImageModal.handlePresent({
      postProcess: () => {
        importImageModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      },
    }, 'IMPORT ẢNH SẢN PHẨM');
  };

  const onOpenPopupProductExcel = () => {
    excelModal.handlePresent({}, 'EXCEL');
  };

  //Toolbar
  const listButton: EventButton[] = [

    {
      name: 'Ảnh và avatar',
      icon: 'image',
      actionType: EnumAction.View,
    buttonClass: 'info100 tool-btn',
      action: onOpenPopupProductImages,
      align: 'center',
    },
    {
      name: 'Excel',
      icon: 'view_list',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onOpenPopupProductExcel,
      align: 'center',
    },
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info tool-btn',
      action: onAddProductNew,
      align: 'center',
    },
  ];

  const onFilter = (res: { [filterKey: string]: any }) => {
    if (res[REALM_FILTER] != undefined) {
      const realmId = res[REALM_FILTER][REALM_FIELD_FILTER];
      setRealmIds([realmId]);
    } else {
      setRealmIds([]);
    }
    if (res[CATEGORY_FILTER] != undefined) {
      const categoryId = res[CATEGORY_FILTER][CATEGORY_FIELD_FILTER];
      setCategoryIds([categoryId]);
    } else {
      setCategoryIds([]);
    }
    if (res[STOCK_STATUS_FILTER] != undefined) {
      const isStockRemain = res[STOCK_STATUS_FILTER][STOCK_STATUS_FIELD_FILTER];
      setStockRemainFlg(isStockRemain);
    } else {
      setStockRemainFlg(undefined);
    }
    if (res[STOP_SELLING_FILTER] != undefined) {
      const isStopSelling = res[STOP_SELLING_FILTER][STOP_SELLING_FIELD_FILTER];
      setStopSellingFlg(isStopSelling);
    } else {
      setStopSellingFlg(undefined);
    }
  };

  return (
    <>
      <ToolBar
        toolBarKey='JVSADMIN_PRODUCTS'
        toolbarName={'Sản phẩm'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />

      {window.innerWidth > 600? <Table
        display={{
          header: header
        }}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.productId)}
      /> : <ListView
        display={display}
        isInput={false}
        data={items}
        onDoubleClick={(item) => onDetail(item.productId)}
        isNo={true}
      />}
    </>
  );
};
export default Product;
