/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CalendarInput from '../CalendarInput/CalendarInput';
import { PageData } from 'src/api/models';
import InfiniteScroll from 'react-infinite-scroll-component';
import Input from '../Input';
import { useDebounce } from 'use-debounce';

export interface FilterType<T> {
  data?: T[];
  api?: (...args: any[]) => Promise<PageData<T> | T[]>;
  valueField?: string;
  titleField?: string;
  title: string;
  filterKey: string;
  timestamp?: boolean;
  isSearch?: boolean;
  isPaging?: boolean;
  baseParam?: {[key: string]: any};
}

export interface FilterTypeProp<T> extends FilterType<T>{
  isStart: boolean;
  isNested: boolean;
  top: number;
  optionSelected?: T | string[];
  timestamp?: boolean;
  change: (...args: any[]) => any;
}


const FilterOptionBox = <T, > (props: FilterTypeProp<T>) => {
  const WIDTH = 260;
  const {
    isStart,
    isNested,
    top,
    optionSelected,
    data,
    api,
    valueField,
    titleField,
    filterKey,
    change,
    timestamp,
    isSearch,
    isPaging,
    baseParam
  } = props;
  const [options, setOptions] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState<string>('');
  const [keywordDebound] = useDebounce(keyword, 500);
  const [hasMore, setHasMore] = useState(true);
  const size = 20;

  useEffect(() => {
    setKeyword(undefined);
  }, [props])

  const fetchData = (reset: boolean) => {
    const cpage = reset ? 1 : page;
    const onLoad = isSearch? isPaging? baseParam? api(baseParam, keyword, cpage, size) : api(keyword, cpage, size) 
    : baseParam? api(baseParam, keyword) : api(keyword) : isPaging? baseParam? api(baseParam, cpage, size) : api(cpage, size)
    : baseParam? api(baseParam) : api();
    onLoad.then((res) => {
      const lstData = isPaging? (res as PageData<T>).items : res as T[];
      const nList = reset ?  lstData : [...options, ...lstData];
      setOptions(nList);
      if (lstData.length < size) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPage(cpage + 1);
      }
    })
    .catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    if(api && isSearch) fetchData(true);
  }, [keywordDebound]);

  useEffect(() => {
    if(data) {
      setOptions(data);
    } else if(api) {
      fetchData(true);
    }
  }, [data, api]);

  const gentOptions = () => {
    return options.map((option, index) => (
      <StyledFilterRow
        selected={optionSelected && eval(`option.${valueField}`) == eval(`optionSelected.${valueField}`)}
        key={`filteroption${index}`}
        onClick={() => {
          change(filterKey, option);
        }}
      >
        {eval(`option.${titleField}`)}
      </StyledFilterRow>)
    )
  }

  return (
    <StyledFilterBox isNested={isNested} isStart={isStart} top={top} width={WIDTH} id="filter-option-box">
    {
      isSearch && <StyledSearch>
        <Input
          width='100%'
          disabled={false}
          value={keyword}
          placeholder={'Bạn muốn tìm gì?'}
          onChange={setKeyword}
          rightIcon='search'
        />
      </StyledSearch>
    }
    {
      timestamp? <StyledTimeStamp >
        <CalendarInput width='100%' title='Từ ngày' value={optionSelected && (optionSelected  as string[]).length > 0?(optionSelected as string[])[0] : undefined} onChange={(value) => {
          change(filterKey, [value, optionSelected && (optionSelected  as string[]).length > 1?(optionSelected as string[])[1] : undefined]);
        }} isFixed={true}/>
        <CalendarInput width='100%' title='Đến ngày' value={optionSelected && (optionSelected  as string[]).length > 1?(optionSelected as string[])[1] : undefined} onChange={ (value) => {
          change(filterKey, [optionSelected && (optionSelected  as string[]).length > 0?(optionSelected as string[])[0] : undefined, value]);
        }} isFixed={true}/>
      </StyledTimeStamp> : isPaging? <InfiniteScroll
        dataLength={options.length}
        next={() => fetchData(false)}
        hasMore={hasMore}
        style={{ display: 'flex', flexDirection: 'column' }}
        scrollableTarget="filter-option-box"
        loader={<h4></h4>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
      >
        {gentOptions()}
      </InfiniteScroll> :
      gentOptions()
    }
    </StyledFilterBox>
  );
};

export default FilterOptionBox;

const StyledSearch = styled.div`
  padding: 5px;
  border-bottom: 1px solid lightgray;
  position: relative;
`;

const StyledTimeStamp = styled.div`
  padding: 5px;
`;

const StyledFilterRow = styled.div<{selected: boolean}>`
  vetical-align: middle;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding: 5px 10px;
  background-color: ${({selected}) => selected? `#53687e` : `white` };
  color: ${({selected}) => selected? `white` : `black` };
  :hover {
    background-color: ${({selected}) => selected? `#53687e` : `lightgray` };
  }
`;


const StyledFilterBox = styled.div<{width: number, isStart: boolean, top: number, isNested: boolean}>`
  ${({isNested}) => isNested?
  `
  position: inherit;
  overflow: none;
  padding-left: 2px` :
  `
  position: absolute;
  overflow: auto;
  max-height: 60vh; border:
  1px solid #256cb8;
  `};
  top: ${({top}) => `${top}px`};
  ${({width, isStart}) => `${isStart? 'left' : 'right'}: ${width}px`};
  width: ${({width}) => `${width}px`};
  box-shadow: gray 0px 4px 5px 0px;
  background-color: white;
`;
