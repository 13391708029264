
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  StockInspectionType,
} from './models';

/***********************************/
export const useGetStockInspections = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/stock-inspection?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<StockInspectionType>>;
    },
    [fetch],
  );
};

export const useGetStockRemainProducts = () => {
  const fetch = useFetch();
  return useCallback(
    () => {
      return fetch({
        url: `sccore/rest-api/stock-inspection/stock-remain`,
        method: 'get',
      }) as Promise<{[productCode: string]: number[]}>;
    },
    [fetch],
  );
};

export const usePostStockInspection = () => {
  const fetch = useFetch();
  return useCallback(
    (stockInspection: StockInspectionType) => {
      return fetch({
        url: 'sccore/rest-api/stock-inspection',
        method: 'post',
        data: stockInspection,
      }) as Promise<StockInspectionType>;
    },
    [fetch],
  );
};

export const usePutStockInspection = () => {
  const fetch = useFetch();
  return useCallback(
    (stockInspection: StockInspectionType) => {
      return fetch({
        url: 'sccore/rest-api/stock-inspection',
        method: 'put',
        data: stockInspection,
      }) as Promise<StockInspectionType>;
    },
    [fetch],
  );
};


export const useGetStockInspectionById = () => {
  const fetch = useFetch();
  return useCallback(
    (stockInspectionId: number) => {
      return fetch({
        url: `sccore/rest-api/stock-inspection/${stockInspectionId}`,
        method: 'get',
      }) as Promise<StockInspectionType>;
    },
    [fetch],
  );
};


export const useDeleteStockInspection = () => {
  const fetch = useFetch();
  return useCallback(
    (stockInspectionId: number) => {
      return fetch({
        url: `sccore/rest-api/stock-inspection/${stockInspectionId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
