
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  CustomerDebtType,
} from './models';

/***********************************/
export const useGetCustomerDebts = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number,) => {
      return fetch({
        url: `sccore/rest-api/customer-debt?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<CustomerDebtType>>;
    },
    [fetch],
  );
};


export const usePostCustomerDebt = () => {
  const fetch = useFetch();
  return useCallback(
    (customerDebt: CustomerDebtType) => {
      return fetch({
        url: 'sccore/rest-api/customer-debt',
        method: 'post',
        data: customerDebt,
      }) as Promise<CustomerDebtType>;
    },
    [fetch],
  );
};


export const useDeleteCustomerDebt = () => {
  const fetch = useFetch();
  return useCallback(
    (email: string) => {
      return fetch({
        url: `sccore/rest-api/customer-debt/${email}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
