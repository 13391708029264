/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ManifestPrinter.css';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Input from 'src/components/Input';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useDebounce } from 'use-debounce';
import { useAddPopup } from 'src/state/application/hooks';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Printed from 'src/components/Printed/Printed';
import Checkbox from 'src/components/Checkbox';
import { ManifestType } from 'src/api/models';
import { usePrintSubPackage } from 'src/api/manifestApi';
import { Editor } from '@tinymce/tinymce-react';
import Loading from 'src/components/Loading';

const ManifestPrinter: React.FC = () => {
  //State
  const [code, setCode] = useState<string>();
  const [inputCode, setInputCode] = useState<string>();
  const [data, setData] = useState<string>();
  const [codeDebound] = useDebounce(code, 500);
  const [isLoading, setLoading] = useState(false);
  const qrReaderModal = useModal(QRCodeReader);
  const navigate = useNavigate();
  const addPopup = useAddPopup();
  const printSubPackage = usePrintSubPackage();

  useEffect(() => {
    const strCode = codeDebound as string;
    if(strCode?.length >= 0) {
      setLoading(true);
      printSubPackage(strCode).then((res) => {
        setData(res);
      }).catch((error) => {
        reset();
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      })
    }
  }, [addPopup, codeDebound, printSubPackage, navigate])

  const reset = () => {
    setCode(undefined);
    setData(undefined);
  }

  const onSearch = () => {
    const strCode = inputCode?.trim();
    setCode(strCode);
    setInputCode(undefined);
  };

  //Main
  return (
    <>
      <ToolBar
        toolbarName='QUÉT IN NHÃN THEO QR'
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
        isBack={true}
      />
      <div className='manifest-printer-container'>
        <div className='manifest-printer-row'>
          <Input
            width='380px'
            title="Mã QR"
            require={true}
            disabled={false}
            value={inputCode}
            onChange={setInputCode}
          />
          <span className="material-icons code-clear" onClick={() => {
            setInputCode(undefined);
            setCode(undefined);
          }}>clear</span>
          <div className="i-tooltip manifest-printer-qr">
            <span className="material-icons info-guide" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (code: string) => {
                  setCode(code);
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ QR")
            }}>qr_code</span>
          </div>
        </div>
        <div className='manifest-printer-row'>
          <ButtonComponent icon="search" title={'TÌM KIẾM'} onClick={onSearch} />
        </div>
        {data? <div className='manifest-printer-data'>
          {isLoading && <div style={{margin: 'auto', width: 'fit-content', paddingTop: "10px"}}><Loading color='gray' size='40px'/></div>}
          <Editor
            disabled={false}
            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
            onInit={() => setLoading(false)}
            initialValue={data}
            init={{
              height: '100%',
              width: '100%',
              menubar: false,
              plugins: [],
              toolbar:
                'print',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </div> : null}
      </div>
    </>
  );
};

export default ManifestPrinter;
