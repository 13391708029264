/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ProductOrderStatus, ProductOrderType, PurchaseAccountType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import { useDeleteProductOrder, useExportProductOrderBill, useGetProductOrder } from 'src/api/productOrderApi';
import ProductOrderDetail from './ProductOrderDetail/ProductOrderDetail';
// import SyncProductOrder from './SyncProductOrder/SyncProductOrder';
import UpdateProductOrderProgressStatus from './UpdateProductOrderProgressStatus/UpdateProductOrderProgressStatus';
import { useDebounce } from 'use-debounce';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import PaymentExport from 'src/components/PaymentExport/PaymentExport';
import { useGetAdminPurchaseAccount } from 'src/api/purchaseAccountApi';

const ProductOrder: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [keyword, setKeyword] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<number>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  //Function
  const addPopup = useAddPopup();
  const getProductOrder = useGetProductOrder();
  const deleteProductOrder = useDeleteProductOrder();
  const exportProductOrderBill = useExportProductOrderBill();
  const getPurchaseAccount = useGetAdminPurchaseAccount();

  //Local state
  const [count, setCount] = useState<number>();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [rawData, setRawData] = useState<ProductOrderType[]>([]);
  const [productOrders, setProductOrders] = useState<ProductOrderType[]>([]);
  const [checkList, setCheckList] = useState<ProductOrderType[]>([]);
  const [purchaseAccount, setPurchaseAccount] = useState<{[purchaseAccountId: number]: PurchaseAccountType}>({});

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ProductOrderDetail);
  const slideBar = useSlideBar(ProductOrderDetail);
  // const syncModal = useModal(SyncProductOrder);
  const updateStatusModal = useModal(UpdateProductOrderProgressStatus);
  const exportBillModal = useModal(PaymentExport);

  const USER_FIELD_FILTER = 'userId';
  const STATUS_FIELD_FILTER = 'progressStatus';

  const USER_FILTER = 'USER_FILTER';
  const STATUS_FILTER = 'STATUS_FILTER';

  

  const filters = [
    {
      data: [],
      valueField: USER_FIELD_FILTER,
      titleField: 'userName',
      title: 'CTV',
      filterKey: USER_FILTER,
    },
    {
      data: ProductOrderStatus,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
  ];

  const display = {
    header: [
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái',
        options: ProductOrderStatus,
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã t.toán',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'CTV',
      },
      {
        code: 'accountId',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Tài khoản mua hộ',
        convert: (value: number) => {
          return value && purchaseAccount[value]?.accountName? <div className={'label-table'}>{purchaseAccount[value]?.accountName}</div> : null;
        },
      },
      {
        code: 'productUrl',
        dataType: EnumDataType.Link,
        isOptions: false,
        title: 'Link mua hàng',
      },
      {
        code: 'avatar',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Hình ảnh',
      },
      {
        code: 'product',
        dataType: EnumDataType.HTML,
        isOptions: false,
        convert: (value: string) => {
          if(value) {
            const items = JSON.parse(value) as NomalProductType[];
            return <>
              {items.map((item, index) => <div className='inline'><div className='label-table' title={item.product}>{item.product}</div> x <div>{item.quantity}</div> </div>)}
            </>
          }
          return null;
        },
        title: 'Sản phẩm',
      },
      {
        code: 'productPrice',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giá',
      },
      {
        code: 'orderPercent',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Phí order (%)',
      },
      {
        code: 'orderFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Phí order',
      },
      {
        code: 'transportFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Phí vận chuyển JP',
      },
      {
        code: 'paymentFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Phí thanh toán',
      },
      {
        code: 'discount',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giảm giá',
      },
      {
        code: 'data.productPrice + data.orderFee + data.transportFee + data.paymentFee - data.discount',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Tổng tiền (¥)',
        isFormula: true
      },
      {
        code: 'exchangeRate',
        dataType: EnumDataType.Decimal,
        isOptions: false,
        title: 'Tỷ giá (đ/¥)',
      },
      {
        code: '(data.productPrice + data.orderFee + data.transportFee + data.paymentFee - data.discount) * data.exchangeRate',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Tổng tiền (đ)',
        isFormula: true
      },
      {
        code: 'japanTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn Nhật Bản',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
      {
        code: 'receiverName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người nhận',
      },
      {
        code: 'receiverPhoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'SĐT nhận',
      },
      {
        code: 'receiverAddress',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ nhận',
      },
    ] as TableColumnType[]
  };


  //Menucontext
  const onView = (productOrderId: number) => {
    const url = `${BASE_ORDER_URL}/product-order/${productOrderId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (productOrderId: number) => {
    const url = `${BASE_ORDER_URL}/product-order/${productOrderId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onDelete = (productOrderId: number) => {
    const onConfirm = () => {
      deleteProductOrder(productOrderId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn mua hộ thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn mua hộ này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN MUA HỘ',
    );
  };

  const menuContext = (item: ProductOrderType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.productOrderId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.productOrderId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.productOrderId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClick = (productOrderId: number) => {
    slideBar.handlePresent({
      productOrderId: productOrderId,
      postProcess: () => setReloadFlag(!reloadFlag)
    });
  };

  //Function in the listButton
  const onAddNewProductOrder = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ProductOrderType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI ĐƠN MUA HỘ',
    );
  };

  const onAddNewShipOrder = (e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn mua hộ',
        },
      });
    } else {
      // syncModal.handlePresent(
      //   {
      //     checkList: checkList
      //   },
      //   'ĐỒNG BỘ ĐƠN MUA HỘ VỚI ĐƠN VẬN CHUYỂN',
      // );
    }
  };

  const onUpdateProductOrderProgressStatus = (e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn mua hộ',
        },
      });
    } else {
      updateStatusModal.handlePresent(
        {
          checkList: checkList
        },
        'CẬP NHẬT TRẠNG THÁI ĐƠN MUA HỘ',
      );
    }
  };

  const openExport = useCallback((checkListIds: number[]) => {
    exportBillModal.handlePresent(
      {
        checkListIds: checkListIds,
        exportApi: exportProductOrderBill,
        postProcess: () => {
          exportBillModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'XUẤT HÓA ĐƠN',
    );
  }, [reloadFlag])

  const onExportBill = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn mua hộ',
        },
      });
    } else {
      const checkListIds = checkList.map(i => i.productOrderId);
      let message = "";
      if(checkList.some(i => i.paymentCode?.length > 0)) message += 'Tồn tại đơn mua hộ đã có mã thanh toán. ';
      if(checkList.some(i => !i.productPrice )) message += 'Tồn tại đơn mua hộ chưa có giá tiền. ';
      if(checkList.some(i => !i.exchangeRate )) message += 'Tồn tại đơn mua hộ chưa có tỷ giá. ';
      if(message.length > 0) {
        message += 'Bạn vẫn muốn thực hiện tạo hóa đơn thanh toán?';
        const listButton: EventButton[] = [
          {
            name: 'Xác nhận',
            icon: 'check',
            actionType: EnumAction.Confirm,
            action: () => openExport(checkListIds),
            buttonClass: 'info',
            align: 'center',
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'info',
            align: 'center',
          },
        ];
        confirmModal.handlePresent(
          {
            question: message,
            listActionButton: listButton,
            postProcess: confirmModal.handleDismiss,
          },
          'TẠO HÓA ĐƠN MUA HỘ',
        );
      } else {
        openExport(checkListIds);
      }
    }
  }, [checkList, openExport, reloadFlag]);

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewProductOrder,
      align: 'center',
    },
    {
      name: 'Tạo đơn ship',
      icon: 'edit_square',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onAddNewShipOrder,
      align: 'center',
    },
    {
      name: 'Cập nhật trạng thái',
      icon: 'sync_alt',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onUpdateProductOrderProgressStatus,
      align: 'center',
    },
    {
      name: 'Xuất hóa đơn',
      icon: 'payments',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onExportBill,
      align: 'center',
    },
  ];


  const onFilter = (res: { [filterKey: string]: any }) => {
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
  };
  //End of toolbar

  //Function
  const onClickCheckbox = (productOrder: ProductOrderType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != productOrder);
      setCheckList(nCheckList);
      setProductOrders(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.productOrderId == i.productOrderId),
        ),
      );
    } else {
      if(checkList.length > 0 && productOrder.userId != checkList[0].userId) {
        addPopup({
          txn: {
            success: false,
            summary: 'Không cùng 1 CTV, chọn lại',
          },
        });
      }
      setCheckList([...checkList, productOrder]);
      setProductOrders(productOrders.filter((o) => o != productOrder));
    }
  };

  useEffect(() => {
    getPurchaseAccount(null, -1, -1).then((res) => {
      setPurchaseAccount(res.items.reduce((result, item) => result[item.purchaseAccountId] = item, {} as {[purchaseAccountId: number]: PurchaseAccountType}));
    });
  }, [])

  //useEffect
  useEffect(() => {
    getProductOrder((keywordDebound ?? '') as string, page, size, userId, progressStatus)
      .then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setProductOrders(
          data.items.filter(
            (i) => !checkList.some((c) => c.productOrderId == i.productOrderId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    keywordDebound,
    page,
    progressStatus,
    reloadFlag,
    size,
    userId,
  ]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, [reloadFlag]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH ĐƠN MUA HỘ'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />

      <Table
        display={display}
        isInput={false}
        checkedData={checkList}
        data={productOrders}
        menuContext={menuContext}
        allowCheckbox={true}
        onDoubleClick={(item) => onView(item.productOrderId)}
        onClickCheckbox={onClickCheckbox}
        onClick={(item) => onClick(item.productOrderId)}
        isNo={true}
      />
    </>
  );
};
export default ProductOrder;
