/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ProfileInfo, ReceiverOrderType, SalesCollatoraborType } from 'src/api/models';
import {
  useGetSalesCollatoraborById,
  usePostSalesCollatorabor,
  usePutSalesCollatorabor,
} from 'src/api/salesCollatoraborApi';
import { useAddPopup } from 'src/state/application/hooks';
import './SalesCollatoraborDetail.css';
import Input from 'src/components/Input/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import ContactList from 'src/components/ContactList/ContactList';
import SelectSearchComponent from 'src/components/SelectSearchComponent/SelectSearchComponent';
import { useGetReceiverOrder, useGetReceiverOrderById } from 'src/api/receiverOrderApi';
import InputDataList from 'src/components/InputDataList';

interface ISalesCollatoraborDetail {
  salesCollatoraborId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const SalesCollatoraborDetail: React.FC<ISalesCollatoraborDetail> = (props) => {


  //Value
  const [salesCollatoraborId, setSalesCollatoraborId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [seaPrice, setSeaPrice] = useState<number>();
  const [airPrice, setAirPrice] = useState<number>();
  const [volumeSeaPrice, setVolumeSeaPrice] = useState<number>();
  const [volumeAirPrice, setVolumeAirPrice] = useState<number>();
  const [overSeaPrice, setOverSeaPrice] = useState<number>();
  const [overAirPrice, setOverAirPrice] = useState<number>();
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);
  const [address, setAddress] = useState<any[]>([]);
  const [receiverOrder, setReceiverOrder] = useState<ReceiverOrderType>();
  //End of state

  useEffect(() => {
    setSalesCollatoraborId(props.salesCollatoraborId);
    setIsDisable(props.isDisable);
  }, [props.isDisable, props.salesCollatoraborId]);

  //Function
  const getSalesCollatoraborById = useGetSalesCollatoraborById();
  const addPopup = useAddPopup();
  const putSalesCollatorabor = usePutSalesCollatorabor();
  const postSalesCollatorabor = usePostSalesCollatorabor();
  const getReceiverOrder = useGetReceiverOrder();
  const getReceiverOrderById = useGetReceiverOrderById();

  const onSave = () => {

    if (userId > 0) {
      const SalesCollatorabor: SalesCollatoraborType = {
        salesCollatoraborId: salesCollatoraborId,
        userId: userId,
        seaPrice: seaPrice,
        airPrice: airPrice,
        overAirPrice: overAirPrice,
        overSeaPrice: overSeaPrice,
        volumeAirPrice: volumeAirPrice,
        volumeSeaPrice: volumeSeaPrice,
        receiverOrderId: receiverOrder?.receiverOrderId,
        address: JSON.stringify(address),
      };
      const api = salesCollatoraborId? putSalesCollatorabor : postSalesCollatorabor;
      api(SalesCollatorabor).then(
        (res) => {
          setSalesCollatoraborId(res.salesCollatoraborId);
          setIsDisable(true);
          addPopup({
            txn: {
              success: true,
              summary: salesCollatoraborId? 'Thay đổi đại lý nhận hàng thành công' : 'Tạo đại lý nhận hàng thành công',
            }
          });
          if(props.postProcess) props.postProcess(res);
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa mã đại lý nhận hàng',
        },
      });
    }
  };



  useEffect(() => {
    if (salesCollatoraborId) {
      getSalesCollatoraborById(salesCollatoraborId)
        .then((data) => {
          setSalesCollatoraborId(data.salesCollatoraborId);
          setUserName(data.userName);
          setSeaPrice(data.seaPrice);
          setAirPrice(data.airPrice)
          setVolumeSeaPrice(data.volumeSeaPrice);
          setVolumeAirPrice(data.volumeAirPrice);
          setOverAirPrice(data.overAirPrice);
          setOverSeaPrice(data.overSeaPrice);
          setUserId(data.userId);
          setAddress(data.address?JSON.parse(data.address):[]);
          if(data.receiverOrderId) {
            getReceiverOrderById(data.receiverOrderId).then((r) => {
              setReceiverOrder(r);
            }).catch(() => {
              setReceiverOrder(undefined);
            })
          }
        })
        .catch((e) => {
          addPopup({ error: { title: 'Đã có lỗi xảy ra', message: e.errorMessage } });
        });
    }
  }, [salesCollatoraborId]);
  //Main
  return (
    <div className={`sales-collatorabor-container`}>
      <div className="sales-collatorabor-row">
        <Input
          width='360px'
          title="CTV vận chuyển"
          require={false}
          disabled={isDisable}
          value={userName}
        />
        {!isDisable && (
          <div className="i-tooltip order-guide">
            <span
              className="material-icons info-guide"
              onClick={() => setIsShowUserList(true)}
              ref={(el) => {
                if (el) {
                  const boundingClient = el.getBoundingClientRect();
                  setIconX(boundingClient.left - (props.isPopup? window.innerWidth / 2 - 20 : 0));
                  setIconY(boundingClient.y + boundingClient.height - (props.isPopup? window.innerHeight / 11 : 0));
                }
              }}
            >
              account_circle
            </span>
            <span className="tooltiptext">Chọn CTV</span>
          </div>
        )}
        {isShowUserList ? (
          <ContactList
            iconX={iconX}
            iconY={iconY}
            showAll={true}
            onHidden={() => setIsShowUserList(false)}
            onSelect={(user: ProfileInfo) => {
              setUserId(user.userId);
              setUserName(user.fullName);
              setIsShowUserList(false);
            }}
          />
        ) : null}
      </div>
      <div className="sales-collatorabor-row">
        <SelectSearchComponent
          width="360px"
          require={false}
          value={receiverOrder}
          onChange={(value: ReceiverOrderType) => setReceiverOrder(value)}
          onLoad={getReceiverOrder}
          isDisable={isDisable}
          title={'Đại lý gửi hàng'}
          titleField={'title'}
        />
      </div>
      <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước Air / kg"
          require={false}
          disabled={isDisable}
          value={airPrice}
          onChange={setAirPrice}
          type='number'
        />
      </div>
      <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước Sea / kg"
          require={false}
          disabled={isDisable}
          value={seaPrice}
          onChange={setSeaPrice}
          type='number'
        />
      </div>
      <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước Air / khối"
          require={false}
          disabled={isDisable}
          value={volumeAirPrice}
          onChange={setVolumeAirPrice}
          type='number'
        />
      </div>
      <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước Sea / khối"
          require={false}
          disabled={isDisable}
          value={volumeSeaPrice}
          onChange={setVolumeSeaPrice}
          type='number'
        />
      </div>
       <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước quá khổ Air / kg"
          require={false}
          disabled={isDisable}
          value={overAirPrice}
          onChange={setOverAirPrice}
          type='number'
        />
      </div>
      <div className="sales-collatorabor-row">
        <Input
          width="360px"
          title="Cước quá khổ Sea / kg"
          require={false}
          disabled={isDisable}
          value={overSeaPrice}
          onChange={setOverSeaPrice}
          type='number'
        />
      </div>
      <div  className="sales-collatorabor-row">
        <InputDataList
          width="360px"
          title="Thông tin nhận hàng"
          data={address}
          onChange={setAddress}
          disabled={isDisable}
          fields={[{
            name: "name",
            title: "Tên"
          }, {
            name: "phone",
            title: "SĐT"
          }, {
            name: "address",
            title: "Địa chỉ"
          }]}

        />
      </div>

      {!isDisable && <div className="sales-collatorabor-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} />
      </div>}
    </div>
  );
};

export default SalesCollatoraborDetail;
