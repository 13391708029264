import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, StoreType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import StoreDetail, { EnumIStoreDetailConfig } from './StoreDetail/StoreDetail';
import { useDeleteStore, useGetStore } from 'src/api/storeApi';
import './Store.css';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useModal from 'src/hooks/useModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const Store: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const [count, setCount] = useState<number>();
  const [storeList, setStoreList] = useState<StoreType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const addPopup = useAddPopup();
  const getStore = useGetStore();
  const deleteStore = useDeleteStore();


  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(StoreDetail);

  const display = {
    header: [
      {
        code: 'storeCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã nhà kho',
      },
      {
        code: 'storeTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'longitude',
        dataType: EnumDataType.Decimal,
        isOptions: false,
        title: 'Kinh độ',
      },
      {
        code: 'latitude',
        dataType: EnumDataType.Decimal,
        isOptions: false,
        title: 'Vĩ độ',
      },
    ] as TableColumnType[]
  };

  //Menucontext
  const onView = (storeId: number) => {
    detailModal.handlePresent(
      {
        config: EnumIStoreDetailConfig.view,
        storeId: storeId,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (storeId: number) => {
    detailModal.handlePresent(
      {
        config: EnumIStoreDetailConfig.edit,
        storeId: storeId,
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (storeId: number) => {
    const onConfirm = () => {
      deleteStore(storeId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa địa chỉ thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa địa chỉ này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐỊA CHỈ KHO',
    );
  };

  const menuContext = (item: StoreType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.storeId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.storeId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.storeId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext

  //Function in the listButton
  const onAddNewStore = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumIStoreDetailConfig.add,
      },
      'THÊM MỚI',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewStore,
      align: 'center',
    },
  ];

  //End of toolbar

  //useEffect
  useEffect(() => {
    getStore()
      .then((data) => {
        setStoreList(data);
        setCount(data.length);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getStore, reloadFlag]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH ĐỊA CHỈ KHO'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={storeList}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.storeId)}
        isNo={true}
      />
    </>
  );
};
export default Store;
