/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ManifestEditor.css';
import { EnumDataType, ManifestType } from 'src/api/models';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';
import Table from 'src/components/Table/Table';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import { number2vnmoney } from 'src/utils/numberUtils';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { useCreateManifest } from 'src/api/manifestApi';
import { useAddPopup } from 'src/state/application/hooks';

interface IManifestEditor {
  manifest: ManifestType[];
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ManifestEditor: React.FC<IManifestEditor> = (props) => {
  const {manifest, postProcess} = props;

  const createManifest = useCreateManifest();
  const addPopup  = useAddPopup();

  const display = {
    header: [
      {
        code: 'qr',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'QR',
      },
      {
        code: 'hawb',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'HAWB',
      },
      {
        code: 'weight',
        dataType: EnumDataType.Decimal,
        isOptions: false,
        title: 'WEIGHT',
      },
      {
        code: 'vnd',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'VND',
      },
      {
        code: 'products',
        dataType: EnumDataType.HTML,
        isOptions: false,
        convert: (value: NomalProductType[]) => {
          return (<div>
            {value?.map((item, index) => (
              <div key={`option${index}`} style={{minWidth: '300px'}}>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.product}</span>
                x<span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.quantity}</span>
                {item?.price?<span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{number2vnmoney(item?.price)}</span> : null}
              </div>
            ))}
          </div>
          );
        },
        title: 'PRODUCTS',
      },
      {
        code: 'receiver',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'RECEIVER',
      },
      {
        code: 'address',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'ADDRESS',
      },
      {
        code: 'phoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'PHONENUMBER',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'NOTE',
      },
    ] as TableColumnType[],
  };

  const onSave = () => new Promise((resolve, reject) => {
    createManifest(manifest).then(
      (res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Tạo manifest thành công',
          },
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        reject(false);
      });

  });


  //Main
  return (
    <>
      <ButtonComponent className="manifest-editor-button" icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
      <Table
        display={display}
        isInput={false}
        data={manifest}
      />
    </>
  );
};

export default ManifestEditor;
