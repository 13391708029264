import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { ImageType, PageData } from './models';
import { ShipInvoiceType } from './models';

export const useGetShipInvoice = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number, progressStatus: number) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice?keyword=${keyword??''}&page=${page}&size=${size}${
          progressStatus? `&progressStatus=${progressStatus}` : ''
        }`,
        method: 'get',
      }) as Promise<PageData<ShipInvoiceType>>;
    },
    [fetch],
  );
};

export const useGetShipInvoiceById = () => {
  const fetch = useFetch();
  return useCallback(
    (shipInvoiceId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice/${shipInvoiceId}`,
        method: 'get',
      }) as Promise<ShipInvoiceType>;
    },
    [fetch],
  );
};

export const useGetShipInvoiceByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice/code/${code}`,
        method: 'get',
      }) as Promise<ShipInvoiceType>;
    },
    [fetch],
  );
};

export const useDeleteShipInvoice = () => {
  const fetch = useFetch();
  return useCallback(
    (shipInvoiceId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice/${shipInvoiceId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostShipInvoice = () => {
  const fetch = useFetch();
  return useCallback(
    (shipInvoice: ShipInvoiceType) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice`,
        method: 'post',
        data: shipInvoice,
      }) as Promise<ShipInvoiceType>;
    },
    [fetch],
  );
};

export const usePutShipInvoice = () => {
  const fetch = useFetch();
  return useCallback(
    (shipInvoice: ShipInvoiceType) => {
      return fetch({
        url: `sccore/rest-api/ship-invoice`,
        method: 'put',
        data: shipInvoice,
      }) as Promise<ShipInvoiceType>;
    },
    [fetch],
  );
};

//End of Ship Invoice
