import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, SpecialShipType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import './SpecialShip.css';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useModal from 'src/hooks/useModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import SpecialShipDetail from './SpecialShipDetail/SpecialShipDetail';
import { useDeleteSpecialShip, useGetSpecialShip } from 'src/api/specialShipApi';
import useSlideBar from 'src/hooks/useSlideBar';
import SpecialSurcharge from './SpecialSurcharge/SpecialSurcharge';
import SpecialSurchargeDetail from './SpecialSurchargeDetail/SpecialSurchargeDetail';

const SpecialShip: React.FC = () => {
  //Value
  const [count, setCount] = useState<number>();
  const [specialShipList, setSpecialShipList] = useState<SpecialShipType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const addPopup = useAddPopup();
  const getSpecialShip = useGetSpecialShip();
  const deleteSpecialShip = useDeleteSpecialShip();


  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(SpecialShipDetail);
  const slideBar = useSlideBar(SpecialSurcharge);

  const surchargeModal = useModal(SpecialSurchargeDetail);

  const display = {
    header: [
      {
        code: 'specialShipName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên mặt hàng',
      },
      {
        code: 'unit',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Đơn vị',
      },
    ] as TableColumnType[]
  };

  //Menucontext
  const onView = (specialShipId: number) => {
    detailModal.handlePresent(
      {
        specialShipId: specialShipId,
        isDisable: true
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (specialShipId: number) => {
    detailModal.handlePresent(
      {
        specialShipId: specialShipId,
        isDisable: false
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (specialShipId: number) => {
    const onConfirm = () => {
      deleteSpecialShip(specialShipId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa mặt hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa mặt hàng này?',
        listActionButton: listButton,
        postProcess: () => {
          setReloadFlag(!reloadFlag);
          confirmModal.handleDismiss();
        }
      },
      'XÓA MẶT HÀNG',
    );
  };

  const menuContext = (item: SpecialShipType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.specialShipId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.specialShipId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.specialShipId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext

  //Function in the listButton
  const onAddNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: () => {
          setReloadFlag(!reloadFlag);
          detailModal.handleDismiss();
        },
      },
      'THÊM MỚI',
    );
  };

  const onClick = (item: SpecialShipType) => {
    slideBar.handlePresent({
      specialShipId: item.specialShipId,
      surchargeModal: surchargeModal
    })
  }

  useEffect(() => {
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNew,
      align: 'center',
    },
  ];

  //End of toolbar

  //useEffect
  useEffect(() => {
    getSpecialShip()
      .then((data) => {
        setSpecialShipList(data);
        setCount(data.length);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getSpecialShip, reloadFlag]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH MẶT HÀNG ĐẶC BIỆT'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={specialShipList}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.storeId)}
        isNo={true}
        onClick={onClick}
      />
    </>
  );
};
export default SpecialShip;
