import './SimPrice.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup
} from 'src/state/application/hooks';
import { CustomerType, EnumAction, EnumDataType, EventButton, SimPriceType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useDeleteSimPrice, useGetSimPriceHistory } from 'src/api/simPriceApi';
import SimPriceDetail, { EnumConfigSimPriceDetail } from './SimPriceDetail/SimPriceDetail';
import SimPriceSlideBar from './SimPriceSlideBar/SimPriceSlideBar';
import ChooseDateDetail from './ChooseDateDetail/ChooseDateDetail';
import SendSimPriceDetail from './SendSimPriceDetail/SendSimPriceDetail';
import { useGetCustomerType } from 'src/api/customerTypeApi';
import ChooseCustomerTypeDetail from './ChooseCustomerTypeDetail/ChooseCustomerTypeDetail';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useModal from 'src/hooks/useModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const SimPrice: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteSimPrice = useDeleteSimPrice();
  const getSimPriceHistory = useGetSimPriceHistory();
  const getCustomerType = useGetCustomerType();

  //Local state
  const [keyword, setKeyword] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(50);
  const [count, setCount] = useState<number>();
  const [simPriceList, setSimPriceList] = useState<SimPriceType[]>([]);

  const [reloadFlag, setReloadFlag] = useState(false);
  const [date, setDate] = useState<string>();
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [customerTypeList, setCustomerTypeList] = useState<CustomerType[]>();


  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(SimPriceDetail);
  const slideBar = useSlideBar(SimPriceSlideBar);
  const dateModal = useModal(ChooseDateDetail);
  const customerModal = useModal(ChooseCustomerTypeDetail);
  const sendSimPriceModal = useModal(SendSimPriceDetail);


  const display = {
    header: [
      {
        code: 'product.productCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã sim',
      },
      {
        code: 'product.productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên sản phẩm',
      },
      {
        code: 'customerType.customerTypeTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Đối tượng',
      },
      {
        code: 'customerName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên khách hàng',
      },
      {
        code: 'product.productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Loại sim',
      },
      {
        code: 'price',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giá bán',
      },
      {
        code: 'deposit',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Tiền cọc',
      },
      {
        code: 'monthlySubscriptionFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Cước hàng tháng',
      },
      {
        code: 'effectiveDate',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày hiệu lực',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };


  const onChangeDate = (d: string) => {
    setDate(d);
  };

  const onChangeCustomerType = (customerTypeId: number) => {
    if (customerTypeList && customerTypeList?.length > 0) {
      const temp = customerTypeList.filter((value) => value.customerTypeId == customerTypeId);
      setCustomerType(temp[0]);
    }
  };

  //Menucontext
  const onView = (simPriceId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimPriceDetail.view,
        simPriceId: simPriceId,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (simPriceId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimPriceDetail.edit,
        simPriceId: simPriceId,
      },
      'THAY ĐỔI',
    );
  };

  const onUpdate = (simPriceId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimPriceDetail.update,
        simPriceId: simPriceId,
      },
      'CẬP NHẬT GIAÁ',
    );
  };

  const onDelete = (simPriceId: number) => {
    const onConfirm = () => {
      deleteSimPrice(simPriceId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa giá này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA GIÁ BẢN GHI GIÁ SIM',
    );

  };

  const menuContext = (item: SimPriceType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.simPriceId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.simPriceId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Cập nhật giá',
      icon: 'update',
      actionType: EnumAction.Edit,
      action: () => onUpdate(item.simPriceId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.simPriceId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext

  const onClick = (simPrice: SimPriceType) => {
    slideBar.handlePresent({
      simPriceId: simPrice.simPriceId,
    });
  };

  //Function in the listButton
  const onAddSimPriceNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimPriceDetail.add
      },
      'THÊM GIÁ',
    );
  };

  const onChooseDate = (e: MouseEvent) => {
    dateModal.handlePresent(
      {
        onChange: onChangeDate
      },
      'CHỌN NGÀY',
    );
  };

  //TODO
  const onChooseCustomerType = (e: MouseEvent) => {
    customerModal.handlePresent(
      {
        onChange: onChangeCustomerType,
        customerTypeList: customerTypeList,
        customerTypeId: customerType ? customerType.customerTypeId : null
      },
      'CHỌN LOẠI KHÁCH HÀNG',
    );
  };

  const onSendSimPrice = (e: MouseEvent) => {
    sendSimPriceModal.handlePresent(
      {
        onChange: onChangeCustomerType,
        customerTypeList: customerTypeList,
        customerTypeId: customerType ? customerType.customerTypeId : null
      },
      'GỬI BẢNG GIÁ',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddSimPriceNew,
      align: 'center',
    },
    {
      name: `${date ? date : 'Tất cả'}`,
      icon: 'calendar_month',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onChooseDate,
      align: 'center',
    },
    {
      name: `${customerType ? customerType.customerTypeTitle : 'Tất cả'}`,
      icon: 'groups',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onChooseCustomerType,
      align: 'center',
    },
    {
      name: `Gửi bảng giá`,
      icon: 'send',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onSendSimPrice,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    if (date && customerType) {
      getSimPriceHistory(date, customerType.customerTypeId)
        .then((data) => {
          setSimPriceList(data);
          setCount(data.length);
          setPage(1);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, customerType, date, getSimPriceHistory, reloadFlag]);

  useEffect(() => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();
    const temp = `${yy}/${mm}/${dd}`;
    setDate(temp);
  }, []);

  useEffect(() => {
    getCustomerType()
      .then((r) => {
        setCustomerTypeList(r);
      })
      .catch((error) => {
        console.log(error);
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getCustomerType]);

  useEffect(() => {
    if (customerTypeList && customerTypeList?.length > 0) {
      setCustomerType(customerTypeList[0]);
    }
  }, [customerTypeList]);

  useEffect(() => {
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  return (
    <>
      <ToolBar
        toolbarName={'BẢNG GIÁ'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      <Table
        display={display}
        isInput={false}
        data={simPriceList}
        menuContext={menuContext}
        allowCheckbox={true}
        onDoubleClick={(item) => onView(item.simId)}
        onClick={onClick}
        isNo={true}
      />
    </>
  );
};
export default SimPrice;
