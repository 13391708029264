import './MonthlySubscriptionFee.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { useEffect, useState } from 'react';
import { useGetUserList } from 'src/api/userApi';
import Table from 'src/components/Table/Table';
import { EnumDataType, ProfileInfo, SimType } from 'src/api/models';
import {
  useDeductMonthlySubscriptionFeeMoney,
  useGetMonthlySubscriptionFeeByUserId,
  useSendMonthlySubscriptionFeeEmail,
} from 'src/api/simApi';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const MonthlySubscriptionFee: React.FC = () => {
  //Define
  const display = {
    header: [
      {
        code: 'product.productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên sản phẩm',
      },
      {
        code: 'customerName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên khách hàng',
      },
      {
        code: 'monthlySubscriptionFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Cước',
      },
      {
        code: 'simPhoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Số điện thoại',
      },
    ] as TableColumnType[]
  };


  //State
  const [userId, setUserId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalMoney, setTotalMoney] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [keywordShow, setKeywordShow] = useState('');
  const [userList, setUserList] = useState<ProfileInfo[]>(null);
  const [sims, setSims] = useState<SimType[]>([]);

  const [isSendEmail, setIsSendEmail] = useState(false);

  const [isShowUserList, setIsShowUserList] = useState(true);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [elmThang, setElmThang] = useState([]);
  const [elmNam, setElmNam] = useState([]);

  //Function
  const addPopup = useAddPopup();
  const getUserList = useGetUserList();
  const getMonthlySubscriptionFeeByUserId = useGetMonthlySubscriptionFeeByUserId();
  const sendMonthlySubscriptionFeeEmail = useSendMonthlySubscriptionFeeEmail();
  const deductMoney = useDeductMonthlySubscriptionFeeMoney();

  const onChangeToMonthType = (mm: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${mm}/${yy}`;

    if (mm < 12) {
      const temp = new Date(`${yy}/${Number(mm) + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const sendEmail = () => {
    if (userId && fromDate && toDate) {
      sendMonthlySubscriptionFeeEmail(userId, fromDate, toDate)
        .then((r) => {
          if (r) {
            addPopup({
              txn: {
                success: true,
                summary: 'Đã gửi mail báo cước tháng',
              },
            });
            onShowDebit();
          } else {
            addPopup({
              txn: {
                success: false,
                summary: 'Có lỗi xảy ra!',
              },
            });
          }
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: e.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn CTV',
        },
      });
    }
  };

  const onDeductMoney = () => {
    deductMoney(userId, fromDate, toDate)
      .then((r) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Trừ cước tháng thành công',
          },
        });
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  };

  const onShowDebit = () => {
    if (userId && fromDate && toDate) {
      getMonthlySubscriptionFeeByUserId(userId, fromDate, toDate)
        .then((data) => {
          addPopup({
            txn: {
              success: true,
              summary: 'Hiển thị thành công!',
            },
          });
          setTotalMoney(data.totalMoney);
          setIsSendEmail(data.sendEmail);
          setSims(data.sims);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa đủ thông tin',
        },
      });
    }
  };

  //useEffect
  useEffect(() => {
    const numberOfMiliSecond = 86400000;

    const date = new Date();
    const mm1 = date.getMonth() + 1;
    const yy1 = date.getFullYear();

    const fDate = `1/${mm1}/${yy1}`;

    if (mm1 < 12) {
      const a = new Date(`${yy1}/${mm1 + 1}/1`);
      const b = new Date(a.getTime() - 1 * numberOfMiliSecond);
      const dd2 = b.getDate();

      const tDate = `${dd2}/${mm1}/${yy1}`;
      setToDate(tDate);
    } else {
      const tDate = `31/12/${yy1}`;
      setToDate(tDate);
    }
    setFromDate(fDate);

    setYear(yy1);
    setMonth(mm1);
  }, []);

  useEffect(() => {
    if (keyword || keyword == '') {
      console.log(keyword);
      getUserList(keyword)
        .then((data) => {
          setUserList(data.items);
          setIsShowUserList(true);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    } else {
      setIsShowUserList(false);
    }
  }, [addPopup, getUserList, keyword]);

  useEffect(() => {
    const eThang = [],
      eNam = [];
    for (let i = 2021; i < 2030; i++) {
      const elm = <option value={i}>{i}</option>;
      eNam.push(elm);
    }
    for (let i = 1; i <= 12; i++) {
      const elm = <option value={i}>{i}</option>;
      eThang.push(elm);
    }

    setElmNam(eNam);
    setElmThang(eThang);
  }, []);
  //End of useEffect

  //Main
  return (
    <div className="debit-container">
      <div className="debit-slide-container">
        <div className="debit-title-component text-medium">Tìm kiếm</div>
        <div className="debit-slide-from-date title">Từ ngày</div>
        <div className="debit-slide-from-date">
          <div className="MonthlySubscriptionFee input">{fromDate}</div>
        </div>
        <div className="debit-slide-from-date title">Đến ngày</div>
        <div className="debit-slide-from-date">
          <div className="MonthlySubscriptionFee input">{toDate}</div>
        </div>
        <div className="debit-slide-from-date">
          <div className="debit-select-component">
            <span style={{ marginRight: 10 }}>Tháng</span>
            <select
              value={month}
              onChange={(event) => {
                setMonth(event.target.value);
                onChangeToMonthType(Number(event.target.value), year);
              }}
            >
              {elmThang.length > 0 ? elmThang : null}
            </select>
          </div>
          <div className="debit-select-component">
            <span style={{ marginRight: 10 }}>Năm</span>
            <select
              value={year}
              onChange={(event) => {
                setYear(event.target.value);
                onChangeToMonthType(month, Number(event.target.value));
              }}
            >
              {elmNam.length > 0 ? elmNam : null}
            </select>
          </div>
        </div>
        <div className="debit-slide-from-date title">Chọn CTV</div>
        <div className="debit-slide-from-date input">
          <input
            type="text"
            value={keywordShow}
            onChange={(event) => {
              setKeyword(event.target.value);
              setKeywordShow(event.target.value);
            }}
          />
        </div>
        {isShowUserList ? (
          <div className="debit-slide-user-list">
            {userList
              ? userList.map((value, index) => {
                  return (
                    <div
                      className={`debit-slide-user ${index % 2 == 0 ? 'chan' : 'le'}`}
                      onClick={() => {
                        setUserId(value.userId);
                        setKeyword(null);
                        setKeywordShow(value.fullName);
                      }}
                    >
                      <div> {value.fullName}</div>
                      <div className="value-email">
                        <i>{value.email}</i>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        <div className="debit-slide-from-date btn">
          <div className="debit-btn-show" onClick={onShowDebit}>
            Xem
          </div>
        </div>
      </div>
      <div className="debit-main-container">
        <div className="debit-title-component text-medium">Cước hàng tháng</div>
        <div>
          <div className="debit-main-component">
            <div className="debit-main-detail">
              Tổng cước cần đóng: <span style={{ color: 'red' }}>{totalMoney}¥</span>
            </div>
            <div className="debit-main-detail">
              <div className="debit-btn-show" onClick={sendEmail}>
                <i className="fas fa-mail-bulk"></i>
                <span style={{ marginLeft: 10 }}>Gửi mail thông báo đóng cước</span>
              </div>
              {isSendEmail ? (
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <i className="fas fa-check" style={{ color: 'blue' }}></i>
                  <span style={{ color: 'blue' }}>Đã gửi</span>
                </div>
              ) : (
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <i className="fas fa-times" style={{ color: 'red' }}></i>{' '}
                  <span style={{ color: 'red' }}>Chưa gửi</span>
                </div>
              )}
              <div className="debit-btn-show deduct" onClick={onDeductMoney}>
                <i className="fas fa-mail-bulk"></i>
                <span style={{ marginLeft: 10 }}>Trừ cước</span>
              </div>
            </div>
          </div>
          <div className="debit-sim-order-detail">
            <div className="debit-title-component text-medium">Danh sách sim đến hạn đóng cước</div>
            <Table
              display={display}
              isInput={false}
              data={sims}
              allowCheckbox={true}
              isNo={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MonthlySubscriptionFee;
