import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ReportData.css';
import { Navigate, useParams } from 'react-router-dom';
import { useGetInputData, useGetInputPagingData, useGetReportById, useGetReportData, useGetReportPagingData } from 'src/api/reportApi';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Table from 'src/components/Table/Table';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumDataType, ReportHeaderType, ReportInputType, ReportType } from 'src/api/models';
import { useDebounce } from 'use-debounce';
import DataTypeRender from 'src/components/DataTypeRender';
import { FilterType } from 'src/components/FilterBox/FilterOptionBox';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionItem,
} from 'chart.js';
import { Bar, Bubble, Chart, Doughnut, Line, Pie, PolarArea, Radar, Scatter, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ReportCharType {
  type?: string;
  option?: any;
  convert?: any;
}

const ReportData: React.FC = () => {

  const params = useParams<{ reportId: string }>();
  const [reportId, setReportId] = useState(
    params?.reportId ? Number(params.reportId) : undefined,
  );

  //Value
  const addPopup = useAddPopup();
  const getInputPagingData = useGetInputPagingData();
  const getInputData = useGetInputData();
  const getReportData = useGetReportData();
  const getReportPagingData = useGetReportPagingData();
  const getReportById = useGetReportById();
  const [report, setReport] = useState<ReportType>();
  const [data, setData] = useState<{[key: string]: any}[]>([]);
  const [extraInfo, setExtraInfo] = useState<{[key: string]: any}>({});
  const [inputs, setInputs] = useState<ReportInputType[]>([]);
  const [filters, setFilters] = useState<FilterType<object>[]>([]);
  const [header, setHeader] = useState<TableColumnType[]>([]);
  const [extraConfig, setExtraConfig] = useState<TableColumnType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const [count, setCount] = useState<number>();
  const [parameters, setParameters] = useState<{[key: string]: any}>({});
  const [charConfig, setCharConfig] = useState<ReportCharType[]>([]);
  const [charZoom, setCharZoom] = useState(false);

  const onFilter = (res: { [filterKey: string]: any }) => {
    setPage(1);
    filters.forEach((item, index) => {
      if(res[item.filterKey] != undefined) {
        if(inputs[index].inputName.length == 1) {
          parameters[inputs[index].inputName[0]] = res[item.filterKey]['value'];
        } else if(inputs[index].inputName.length > 1) {
          inputs[index].inputName.forEach((code, indx) => {
            parameters[code] = res[item.filterKey][indx];
          })
        }
      } else {
        if(inputs[index].inputName.length == 1) {
          parameters[inputs[index].inputName[0]] = undefined;
        } else if(inputs[index].inputName.length > 1) {
          inputs[index].inputName.forEach((code, indx) => {
            parameters[code] = undefined;
          })
        }
      }
    });
    setParameters({...parameters});
  }

  useEffect(() => {
    getReportById(reportId)
    .then((res) => {
      const inputs: ReportInputType[] = res.reportInput? JSON.parse(res.reportInput): [];
      setInputs(inputs);
      setFilters(inputs.map((input, index) => {
        return {
          api: input.timestamp || input.data? undefined : input.isPaging? getInputPagingData : getInputData,
          baseParam: {
            reportId: reportId,
            number: index
          },
          isSearch: input.isSearch,
          isPaging: input.isPaging,
          data: input.data,
          valueField: 'value',
          titleField: 'title',
          title: input.title,
          filterKey: `FILTER_${index}`,
          timestamp: input.timestamp
        };
      }));
      const columns: ReportHeaderType[] = JSON.parse(res.reportTable);
      const header: TableColumnType[] = [];
      columns.forEach((column) => {
        if(!column.isHidden) header.push({
          code: column.code,
          dataType: column.dataType,
          isOptions: column.isOptions,
          title: column.title,
          cellCss: column.cellCss? JSON.parse(column.cellCss) : undefined,
          collumCss: column.collumCss? JSON.parse(column.collumCss) : undefined,
          options: column.options,
          ratio: column.ratio,
          convert: column.convert? (value) => { return eval(column.convert);} : undefined
        });
      })
      setHeader(header);
      const extraConfig: ReportHeaderType[] = res.reportSimple? JSON.parse(res.reportSimple) : [];
      setExtraConfig(extraConfig.map((column) => {
        return {
          code: column.code,
          dataType: column.dataType,
          isOptions: column.isOptions,
          title: column.title,
          cellCss: column.cellCss? JSON.parse(column.cellCss) : undefined,
          collumCss: column.collumCss? JSON.parse(column.collumCss) : undefined,
          options: column.options,
          ratio: column.ratio,
          convert: column.convert? (value) => { return eval(column.convert);} : undefined
        }
      }));
      const charConfig = res.reportChar? JSON.parse(res.reportChar) : [];
      setCharConfig(charConfig);
      setReport(res);
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [addPopup, getReportById])

  useEffect(() => { 
    if(report) {
      if(report.searchFlg) parameters['keyword'] = keywordDebound??"";
      console.log(parameters);
      if(report.pagingFlg) {
        getReportPagingData(reportId, parameters, page, size).then(res => {
          setData(res.items);
          setCount(res.count);
          setExtraInfo(res.extraInfo);
        }).catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
      } else {
        getReportData(reportId, parameters).then(res => {
          setData(res);
          setCount(res.length);
        }).catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
      }
    }
  }, [report, size, page, keywordDebound, parameters])


  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: any) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    getDatasetAtEvent(chart, event);
    getElementAtEvent(chart, event);
    getElementsAtEvent(chart, event);
  };

  const renderChar = useCallback((config: ReportCharType, index: number) => {
    const func = new Function("data", config.convert );
    const charData = func(data);
    switch(config.type) {
      case 'GradientChar':
        return <Chart ref={chartRef} type='line' data={charData} />;
      case 'Chart':
        return <Chart ref={chartRef} type='bar' onClick={onClick} options={config.option} data={charData} />;
      case 'Bubble':
        return <Bubble options={config.option} data={charData} />;
      case 'Scatter':
        return <Scatter options={config.option} data={charData} />;
      case 'Radar':
        return <Radar data={charData} />;
      case 'PolarArea':
        return <PolarArea data={charData} />;
      case 'charData':
        return <Doughnut data={charData} />;
      case 'Pie':
        return <Pie data={charData} />;
      case 'Line':
        return <Line options={config.option} data={charData} />;
      case 'Bar':
        return <Bar key={`char${index}`} options={config.option} data={charData}/>;
      default:
        return null;
    }
   
  }, [data])

  //Main
  return (report? <>
    <ToolBar
      toolbarName={report?.reportTitle}
      toolBarKey={`JVSREPORT${report?.reportKey}`}
      width={'100%'}
      backgroundColor={'#ebe9e9'}
      isPaging={report?.pagingFlg}
      isBack={true}
      filters={filters}
      onFilter={onFilter}
      count={count}
      onSearch={report?.searchFlg? (value) => setKeyword(value) : undefined}
      onChangePage={setPage}
      onChangeSize={setSize}
      keyword={keyword}
      page={page}
      size={size}

    />
    <div className='report-body'>
      <Table
        display={{
          header: header
        }}
        isInput={false}
        data={data}
        onDoubleClick={(item) => console.log(item)}
        isNo={true}
      />  
      {data.length > 0 && charConfig?.length > 0 ? <ReportChar charZoom={charZoom}>
        <span className='material-icons' onClick={() => setCharZoom(!charZoom)}>{charZoom? 'zoom_in_map' : 'zoom_out_map'}</span>
        {charConfig.map((config, indx) => {
          return renderChar(config, indx);
        })}
      </ReportChar> : null}
    </div>
    <div className='report-footer'>
      {extraConfig.map((item) => <div className='inline' key={`extrainfo${item.code}`}>
          <span>{item.title}: </span>
          <DataTypeRender value={extraInfo[item.code]} column={item}/> 
        </div>
      )}
    </div>
  </> : null);
};

export default ReportData;

const ReportChar = styled.div<{charZoom: boolean}>`
padding: 0px 20px;
width: ${({charZoom}) => charZoom? '100vw' : '35vw'};
}
`
