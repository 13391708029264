import React, { useState } from 'react';
import { EventButton } from 'src/api/models';
import styled from 'styled-components';
import './InputModal.css';
import Input from '../Input';

interface IInputModal {
  width: string;
  height: string;
  fields: InputType[];
  listActionButton?: EventButton[];
  postProcess?: (...args: any[]) => void;
}

export type InputType = {
  name: string;
  title: string;
  type?: string;
}


const InputModal: React.FC<IInputModal> = (props) => {

  const [data, setData] = useState<{[name: string]: any}>({});

  const elmButton = props.listActionButton
    ? props.listActionButton.map((button: EventButton, index: number) => {
        return (
          <button
            key={`inputpopupbutton${index}`}
            className={`input-modal-button action-button ${button.buttonClass??''}`}
            onClick={() => {
              if (button.action) button.action(data);
              if (props.postProcess) props.postProcess();
            }}
          >
            <span className="material-icons">{button.icon}</span>
            {button.name}
          </button>
        );
      })
    : null;

  return (
    <WrapperContainer width={props.width} height={props.height}>
      <WrapperRow width={'100%'} height={'50%'}>
        {props.fields?.map((field) => (
          <Input
            key={`input${field.name}`}
            width="360px"
            title={field.title}
            require={false}
            disabled={false}
            value={data[field.name]}
            onChange={(value) => {
              data[field.name] = value;
              setData({...data});
            }}
            type={field.type??"text"}
          />
        ))}
      </WrapperRow>
      <WrapperColumn>
        {elmButton}
      </WrapperColumn>
    </WrapperContainer>
  );
};

export default InputModal;

const WrapperContainer = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || 'fit-content'};
  background-color: white;
`;

const WrapperColumn = styled.div<{ width?: string; height?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  padding: 10px;
`;
const WrapperRow = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;
