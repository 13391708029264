
import { useCallback } from 'react';
import { useFetch } from './backend-api';

export const useGetReferral = () => {
  const fetch = useFetch();
  return useCallback((userId: number) => {
    return fetch({
      url: `sccore/rest-api/referral/${userId}`,
      method: 'get',
    }) as Promise<string>;
  }, [fetch]);
};

export const usePostUserReferral = () => {
  const fetch = useFetch();
  return useCallback(
    (referralCode: string) => {
      return fetch({
        url: `sccore/rest-api/referral/${referralCode}`,
        method: 'post',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePrintUserReferral= () => {
  const fetch = useFetch(false, false, false);
  return useCallback(
    (userId: number) => {
      return fetch({
        url: `sccore/rest-api/referral/${userId}/print`,
        method: 'post',
      }) as Promise<string>;
    },
    [fetch],
  );
};