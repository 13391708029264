import { SimPriceDebitType, SimType } from 'src/api/models';
/************************************/
/********                    ********/
/******       Sim Order      ********/
/******   Writen by HuyLV      ****/
/********                   ********/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  ProductType,
  SimOrderType,
  ImageType,
  EnumSimOrderProgressStatus,
} from './models';

/***********************************/
export const useGetSimList = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/product?realmIds=1`,
      method: 'get',
    }) as Promise<PageData<ProductType>>;
  }, [fetch]);
};

export const useGetAdminSimOrderList = () => {
  const fetch = useFetch();
  return useCallback(
    (
      page: number,
      size: number,
      keyword: string,
      userId: number | string,
      productId: string | number,
      simStoreId: number | string,
      progressStatus: number | string,
    ) => {
      return fetch({
        url: `sccore/rest-api/sim-order?page=${page}&size=${size}&keyword=${keyword}${
          userId ? `&userId=${userId}` : ''
        }${productId ? `&productId=${productId}` : ''}${
          simStoreId ? `&simStoreId=${simStoreId}` : ''
        }${progressStatus ? `&progressStatus=${progressStatus}` : ''}`,
        method: 'get',
      }) as Promise<PageData<SimOrderType>>;
    },
    [fetch],
  );
};

export const useGetAgencySimOrderList = () => {
  const fetch = useFetch();
  return useCallback(
    (page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/sim-order/me?page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<SimOrderType>>;
    },
    [fetch],
  );
};

export const useGetSimOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-order/${simOrderId}`,
        method: 'get',
      }) as Promise<SimOrderType>;
    },
    [fetch],
  );
};

export const usePostAgencySimOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrder: SimOrderType) => {
      return fetch({
        url: `sccore/rest-api/sim-order`,
        method: 'post',
        data: simOrder,
      }) as Promise<SimOrderType>;
    },
    [fetch],
  );
};

export const usePostAdminSimOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrder: SimOrderType) => {
      return fetch({
        url: `sccore/rest-api/sim-order/admin`,
        method: 'post',
        data: simOrder,
      }) as Promise<SimOrderType>;
    },
    [fetch],
  );
};

export const usePutSimOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrder: SimOrderType) => {
      return fetch({
        url: `sccore/rest-api/sim-order`,
        method: 'put',
        data: simOrder,
      }) as Promise<SimOrderType>;
    },
    [fetch],
  );
};

export const useDeleteSimOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-order/admin/${simOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostSimOrderBillImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (simOrderId: number, data: any) => {
      return fetch({
        url: `sccore/rest-api/sim-order/image/${simOrderId}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useExportCsvFile = () => {
  const fetch = useFetch(false, false, true);
  return useCallback(
    (simOrderIdList: number[]) => {
      return fetch({
        url: `sccore/rest-api/sim-order/export-csv`,
        method: 'post',
        data: simOrderIdList,
      }) as Promise<Blob>;
    },
    [fetch],
  );
};

export const useSyncOrderToSim = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-order/${simOrderId}/sync-order-to-sim`,
        method: 'post',
      }) as Promise<SimType[]>;
    },
    [fetch],
  );
};

export const useGetSimOrderDebit = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const data = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim-order/debit`,
        method: 'post',
        data: data,
      }) as Promise<SimPriceDebitType>;
    },
    [fetch],
  );
};

export const useSendSimOrderDebitEmail = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const data = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim-order/debit/email`,
        method: 'post',
        data: data,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePutUpdateSimOrderProgressStatus = () => {
  const fetch = useFetch();
  return useCallback(
    (simOrderIdList: any, progressStatus: EnumSimOrderProgressStatus) => {
      return fetch({
        url: `sccore/rest-api/sim-order/update-status/${progressStatus}`,
        method: 'put',
        data: simOrderIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of sim order
