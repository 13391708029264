/************************************/
/********                    ********/
/********    Sim news   ********/
/******   Writen by LuanPT  ****/
/********                   ********/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { ImageType, SimNewCommentType, SimNewsType } from './models';
import { PageData } from 'src/api/models';

/***********************************/
export const useGetSimNews = () => {
  const fetch = useFetch();
  return useCallback(
    (page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news?page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<SimNewsType>>;
    },
    [fetch],
  );
};

export const useGetSimNewsById = () => {
  const fetch = useFetch();
  return useCallback(
    (simNewsId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/${simNewsId}`,
        method: 'get',
      }) as Promise<SimNewsType>;
    },
    [fetch],
  );
};

export const usePostSimNews = () => {
  const fetch = useFetch();
  return useCallback(
    (simNews: SimNewsType) => {
      return fetch({
        url: 'sccore/rest-api/sim-news',
        method: 'post',
        data: simNews,
      }) as Promise<SimNewsType>;
    },
    [fetch],
  );
};

export const usePutSimNews = () => {
  const fetch = useFetch();
  return useCallback(
    (simNews: SimNewsType) => {
      return fetch({
        url: `sccore/rest-api/sim-news`,
        method: 'put',
        data: simNews,
      }) as Promise<SimNewsType>;
    },
    [fetch],
  );
};

export const useGetCommentBySimNewsId = () => {
  const fetch = useFetch();
  return useCallback(
    (simNewsId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/${simNewsId}/comment`,
        method: 'get',
      }) as Promise<SimNewCommentType[]>;
    },
    [fetch],
  );
};

export const usePostSimNewsComment = () => {
  const fetch = useFetch();
  return useCallback(
    (comment: SimNewCommentType) => {
      return fetch({
        url: `sccore/rest-api/sim-news/comment`,
        method: 'post',
        data: comment,
      }) as Promise<SimNewCommentType>;
    },
    [fetch],
  );
};

export const usePutSimNewsComment = () => {
  const fetch = useFetch();
  return useCallback(
    (comment: SimNewCommentType) => {
      return fetch({
        url: `sccore/rest-api/sim-news/comment`,
        method: 'put',
        data: comment,
      }) as Promise<SimNewCommentType>;
    },
    [fetch],
  );
};

export const useGetSimNewsCommentByParentId = () => {
  const fetch = useFetch();
  return useCallback(
    (parentId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/parent/${parentId}/comment`,
        method: 'get',
      }) as Promise<SimNewCommentType[]>;
    },
    [fetch],
  );
};

export const useDeleteSimNewsComment = () => {
  const fetch = useFetch();
  return useCallback(
    (simCommentId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/comment/${simCommentId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useDeleteSimNews = () => {
  const fetch = useFetch();
  return useCallback(
    (simNewId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/${simNewId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGetSimNewsImage = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/sim-news/image`,
      method: 'get',
    }) as Promise<ImageType[]>;
  }, [fetch]);
};

export const useAddSimNewsImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data: any) => {
      return fetch({
        url: `sccore/rest-api/sim-news/image`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteSimNewsImage = () => {
  const fetch = useFetch();
  return useCallback(
    (fileId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-news/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of sim news
