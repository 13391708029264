import './FilterBox.css';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FilterOptionBox, { FilterType } from './FilterOptionBox';

interface FilterBoxProps {
  filters: FilterType<object>[];
  condition: {[filterKey: string]: any};
  onFilter: (...args: any[]) => void;
}

const FilterBox = (props: FilterBoxProps) => {
  const WIDTH = 260;
  const {
    filters,
    onFilter,
    condition,
  } = props;


  const el = useRef<HTMLDivElement>();
  const [selected, setSelected] = useState<FilterType<object>>();
  const [isStart, setStart] = useState(false);
  const [isNested, setNested] = useState(true);
  const [isShow, setShow] = useState<boolean>();

  const [optionSelected, setOptionSelected] = useState<{[filterKey: string]: any}>({});
  const [parentTop, setParentTop] = useState(0);
  const [top, setTop] = useState<number>(0);

  const onChangeOption = (filterKey: string, value: any) => {
    if(optionSelected[filterKey] == value) {
      optionSelected[filterKey] = undefined;
    } else {
      optionSelected[filterKey] = value;
    }
    setOptionSelected({...optionSelected});
  }

  const onChangeTimeStamp = (filterKey: string, value: any) => {
    optionSelected[filterKey] = value;
    setOptionSelected({...optionSelected});
  }

  useEffect(() => {
    setOptionSelected({...condition});
  }, [condition]);

  return (
    <WrapperContainer ref={el}>
      {condition? Object.entries(condition).filter(([key, value]) => value != undefined).map(([key, value]) => {
        const filter = props.filters.find(f => f.filterKey == key);
        return (
          <StyledCondition key={key}>
            <div>{filter.title}</div>
            <div>{filter.timestamp? `${value[0]??'∞'} - ${value[1]??'∞'}`: eval(`value.${filter.titleField}`)}</div>
            <StyledClearCondition className="material-icons" onClick={() => {
               condition[key] = undefined;
               onFilter({...condition});
            }}>clear</StyledClearCondition>
          </StyledCondition>
        );
      }) : null}


      <StyledFilter show={isShow} onClick={() => {
        if(!isShow && el.current) {
          const boundingClient = el.current.getBoundingClientRect();
          setParentTop(boundingClient.y);
          setStart(boundingClient.x < window.innerWidth - boundingClient.x);
          setNested(boundingClient.x < window.innerWidth - boundingClient.x && (window.innerWidth - boundingClient.x < 2 * WIDTH)
          || boundingClient.x >= window.innerWidth - boundingClient.x && boundingClient.x < 2 * WIDTH);

          setOptionSelected({...condition});
        }
        setShow(!isShow);
      }}>
        <StyledFilterLabel className="material-icons">filter_alt</StyledFilterLabel>
        <StyledFilterLabel>Bộ lọc</StyledFilterLabel>
      </StyledFilter>
      {isShow ? <StyledFilterBox isStart={isStart} width={WIDTH}>
        <StyledFilterHeader>BỘ LỌC<span style={{float: 'right'}} className="material-icons" onClick={() => setShow(false)}>clear</span></StyledFilterHeader>
        <StyledFilterContent>

          {filters.map((item: FilterType<object>, index: number) => {
            const isSelected = item == selected;
            return (
              <div key={`filterrow${index}`}>
                <StyledFilterRow selected={isSelected} onClick={(e) => {
                  if(item == selected) {
                    setSelected(undefined);
                  } else {
                    setTop((e.target as HTMLElement).getBoundingClientRect().y - parentTop);
                    setSelected(item);
                  }
                }}>
                  <StyledFilterIcon selected={isSelected}/>
                  <span>
                    {item.title}
                    <StyledOptionSelect selected={isSelected}>
                      {item.timestamp? optionSelected[item.filterKey]? `${optionSelected[item.filterKey][0]??'∞'} - ${optionSelected[item.filterKey][1]??'∞'}` : 'Không giới hạn'
                      : optionSelected[item.filterKey]? eval(`optionSelected[item.filterKey].${item.titleField}`): 'Tất cả'}
                      {optionSelected[item.filterKey]?
                        <StyledClearIcon className="material-icons" onClick={() => {
                          optionSelected[item.filterKey] = undefined;
                          setOptionSelected({...optionSelected});
                        }}>clear</StyledClearIcon> : null}
                    </StyledOptionSelect>
                  </span>
                </StyledFilterRow>
                {isShow && isSelected && isNested?
                  <FilterOptionBox
                    key={`filterbox${index}`}
                    isStart={isStart}
                    isNested={isNested}
                    top={top}
                    change={selected.timestamp? onChangeTimeStamp : onChangeOption}
                    {...selected}
                    timestamp={selected.timestamp}
                    optionSelected={optionSelected[selected.filterKey]}
                  /> : null}
              </div>
            )

          })}
          <StyledFooter>
            <StyledFilterButton onClick={() => {
              onFilter(optionSelected);
              setShow(false);
            }}>Lọc</StyledFilterButton>
          </StyledFooter>
        </StyledFilterContent>
      </StyledFilterBox> : null}
      {isShow && selected && !isNested?
      <FilterOptionBox
        isStart={isStart}
        isNested={isNested}
        top={top}
        change={selected.timestamp? onChangeTimeStamp : onChangeOption}
        timestamp={selected.timestamp}
        {...selected}
        optionSelected={optionSelected[selected.filterKey]}
      /> : null}

    </WrapperContainer>
  );
};

export default FilterBox;



const StyledCondition = styled.div`
  position: relative;
  background-color: #53687e;
  color: white;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px;
  text-align: center;
`;

const StyledClearIcon = styled.span`
  position: absolute;
  bottom: 12px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
`;

const StyledClearCondition = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
`;

const StyledOptionSelect = styled.span<{selected: boolean}>`
  position: relative;
  background-color: ${({selected}) => selected? `white` : `#53687e` };
  color: ${({selected}) => selected? `black` : `white` };
  border-radius: 5px;
  padding: 2px 8px;
  margin-left: 4px;
`;

const StyledFilterHeader = styled.div`
  text-align: center;
  background-color: #256cb8;
  color: white;
  font-weight: 500;
  width: 100%;
  padding: 5px;
`;

const StyledFooter = styled.div`
  background-color: white;
  width: 100%;
  padding: 5px;
`;

const StyledFilterButton = styled.button`
  background-color: #256cb8;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  width: 100%;
`;

const WrapperContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledFilterContent = styled.div`
  background-color: gray;
`;

const StyledFilterBox = styled.div<{width: number, isStart: boolean}>`
  position: absolute;
  top: 50px;
  ${({isStart}) => `${isStart? 'left' : 'right'}: 0px`};
  width: ${({width}) => `${width}px`};
  box-shadow: gray 0px 4px 5px 0px;
  border: 1px solid #256cb8;
  overflow: auto;
  max-height: 60vh;
`;


const StyledFilter = styled.button<{show: boolean}>`
  height: fit-content;
  margin: auto 0px;
  border: 1px solid #dddcdc;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  ${({show}) => show? `background-color: #256cb8; color: white;` : `background-color: white`};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  outline:none;
  :hover {
    background-color: ${({show}) => show? '#256cb8' : 'lightgray'};
  };
  :focus {
    outline:none;
    border: 1px solid #dddcdc;
  }
`;

const StyledFilterLabel = styled.div`
  vetical-alignt: center;
`;


const StyledFilterIcon = styled.div<{selected: boolean}>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({selected}) => selected? `white` : `#53687e` };
  margin: auto 10px auto 0;
`;


const StyledFilterRow = styled.div<{selected: boolean}>`
  vetical-align: middle;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding: 8px 10px;
  background-color: ${({selected}) => selected? `#53687e` : `white` };
  color: ${({selected}) => selected? `white` : `black` };
  :hover {
    background-color: ${({selected}) => selected? `#53687e` : `lightgray` };
  }
`;
