import { uuidv4 } from '@firebase/util';
import React, { useRef } from 'react';
import './HtmlToImage.css';
import { HtmlToImageItem } from './HtmlToImageItem/HtmlToImageItem';

interface IHtmlToImage {
  items: {value:string; name?: string} [];
}

const HtmlToImage: React.FC<IHtmlToImage> = (props) => {
  const printedRef = useRef();

  return (
    <div className="htmltoimage-content">
      <div id='htmltoimage-content' ref={printedRef}>
        {props?.items?.map((item) => (
          <HtmlToImageItem key={uuidv4()} value={item.value} name={item.name}/>
        ))}
      </div>
    </div>
  );
};

export default HtmlToImage;
