import './Debit.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { useEffect, useState } from 'react';
import { useGetSimOrderDebit, useSendSimOrderDebitEmail } from 'src/api/simOrderApi';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import { useGetUserList } from 'src/api/userApi';
import Table from 'src/components/Table/Table';
import { EnumDataType, ProfileInfo, SimOrderDetailsType } from 'src/api/models';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

enum EnumDateType {
  Tuan = 1,
  Thang = 2,
  Quy = 3,
  Nam = 4,
}

enum EnumDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}


const Debit: React.FC = () => {
  //Define
  const display = {
    header: [
      {
        code: 'product.productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên sản phẩm',
      },
      {
        code: 'price',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giá bán',
      },
      {
        code: 'paymentStatus',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tr.thái t.toán',
      },
      {
        code: 'simPhoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Số điện thoại',
      },
    ] as TableColumnType[]
  };


  //State
  const [userId, setUserId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalMoney, setTotalMoney] = useState(0);
  const [totalOtherMoney, setTotalOtherMoney] = useState(0);

  const [paidOtherMoney, setPaidOtherMoney] = useState(0);
  const [paidMoney, setPaidMoney] = useState(0);

  const [remainUnpaidMoney, setRemainUnpaidMoney] = useState(0);
  const [remainUnpaidOtherMoney, setRemainUnpaidOtherMoney] = useState(0);

  const [keyword, setKeyword] = useState('');
  const [keywordShow, setKeywordShow] = useState('');
  const [userList, setUserList] = useState<ProfileInfo[]>([]);
  const [simOrderDetailList, setSimOrderDetailList] = useState<SimOrderDetailsType[]>([]);

  const [isSendEmail, setIsSendEmail] = useState(false);

  const [isShowUserList, setIsShowUserList] = useState(true);
  const [dateType, setDateType] = useState<EnumDateType>(EnumDateType.Tuan);

  const [week, setWeek] = useState(null);
  const [month, setMonth] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [year, setYear] = useState(null);

  const [elmTuan, setElmTuan] = useState([]);
  const [elmThang, setElmThang] = useState([]);
  const [elmQuy, setElmQuy] = useState([]);
  const [elmNam, setElmNam] = useState([]);

  //Function
  const addPopup = useAddPopup();
  const getSimOrderDebit = useGetSimOrderDebit();
  const sendSimOrderDebitEmail = useSendSimOrderDebitEmail();
  const getUserList = useGetUserList();

  const onChangeToMonthType = (mm: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${mm}/${yy}`;

    if (mm < 12) {
      const temp = new Date(`${yy}/${Number(mm) + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const onChangeToQuarterType = (qq: number, yy: number) => {
    const numberOfMiliSecond = 86400000;
    const fDate = `1/${qq * 3 - 2}/${yy}`;

    if (qq < 4) {
      const temp = new Date(`${yy}/${qq * 3 + 1}/1`);
      const to_date = new Date(temp.getTime() - 1 * numberOfMiliSecond);

      const dd2 = to_date.getDate();
      const mm2 = to_date.getMonth() + 1;
      const yy2 = to_date.getFullYear();

      const tDate = `${dd2}/${mm2}/${yy2}`;

      setFromDate(fDate);
      setToDate(tDate);
    } else {
      const tDate = `31/12/${year}`;
      setFromDate(fDate);
      setToDate(tDate);
    }
  };

  const onChantoYearType = (yy: number) => {
    const fDate = `1/1/${yy}`;
    const tDate = `31/12/${yy}`;

    setFromDate(fDate);
    setToDate(tDate);
  };

  const onChangeDateType = (dType: EnumDateType) => {
    if (dType == EnumDateType.Tuan) {
      onChangeToWeekType(week, year);
    } else if (dType == EnumDateType.Thang) {
      onChangeToMonthType(month, year);
    } else if (dType == EnumDateType.Quy) {
      onChangeToQuarterType(quarter, year);
    } else if (dType == EnumDateType.Nam) {
      onChantoYearType(year);
    }
  };

  const onChangeToWeekType = (ww: number, yy: number) => {
    let count: number;
    const numberOfMiliSecond = 86400000;
    const a = new Date(`${yy}/1/1`);
    const toDay = new Date();

    const day = toDay.getDay();

    switch (day) {
      case EnumDay.Monday:
        count = 0;
        break;
      case EnumDay.Tuesday:
        count = 1;
        break;
      case EnumDay.Wednesday:
        count = 2;
        break;
      case EnumDay.Thursday:
        count = 3;
        break;
      case EnumDay.Friday:
        count = 4;
        break;
      case EnumDay.Saturday:
        count = 5;
        break;
      case EnumDay.Sunday:
        count = 6;
        break;
    }

    const b = new Date(toDay.getTime() - count * numberOfMiliSecond);
    const numberOfWeek = Math.floor((b.getTime() - a.getTime()) / numberOfMiliSecond / 7);
    const c = new Date(b.getTime() - numberOfWeek * 7 * numberOfMiliSecond);

    const fDate = new Date(c.getTime() + (ww - 1) * 7 * numberOfMiliSecond);
    const tDate = new Date(fDate.getTime() + 6 * numberOfMiliSecond);
    const dd1 = fDate.getDate();
    const mm1 = fDate.getMonth() + 1;
    const yy1 = fDate.getFullYear();
    const dd2 = tDate.getDate();
    const mm2 = tDate.getMonth() + 1;
    const yy2 = tDate.getFullYear();

    const temp1 = `${dd1}/${mm1}/${yy1}`;
    const temp2 = `${dd2}/${mm2}/${yy2}`;
    setFromDate(temp1);
    setToDate(temp2);
  };

  const onChangeFromDate = (fDate: string) => {
    setFromDate(fDate);
  };

  const onChangeToDate = (tDate: string) => {
    setToDate(tDate);
  };

  const sendEmail = () => {
    if (fromDate && toDate && userId) {
      sendSimOrderDebitEmail(userId, fromDate, toDate)
        .then((r) => {
          if (r) {
            addPopup({
              txn: {
                success: true,
                summary: 'Đã gửi mail thông báo công nợ',
              },
            });
            onShowDebit();
          } else {
            addPopup({
              txn: {
                success: false,
                summary: 'Có lỗi xảy ra!',
              },
            });
          }
        })
        .catch((e) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: e.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa đủ thông tin',
        },
      });
    }
  };

  const onShowDebit = () => {
    if (userId && fromDate && toDate) {
      getSimOrderDebit(userId, fromDate, toDate)
        .then((data) => {
          setTotalMoney(data.totalMoney);
          setTotalOtherMoney(data.totalOtherMoney);

          setPaidMoney(data.paidMoney);
          setPaidOtherMoney(data.paidOtherMoney);

          setRemainUnpaidMoney(data.remainUnpaidMoney);
          setRemainUnpaidOtherMoney(data.remainUnpaidOtherMoney);

          setSimOrderDetailList(data.simOrderDetails);
          setIsSendEmail(data.sendEmail);
          addPopup({
            txn: {
              success: true,
              summary: 'Hiển thị thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa đủ thông tin',
        },
      });
    }
  };

  //useEffect
  useEffect(() => {
    const numberOfMiliSecond = 86400000;
    let count: number;
    let monthTemp, quarterTemp;

    const date = new Date();
    const dd1 = date.getDate();
    const mm1 = date.getMonth() + 1;
    const yy1 = date.getFullYear();
    const day = date.getDay();

    switch (day) {
      case EnumDay.Monday:
        count = 0;
        break;
      case EnumDay.Tuesday:
        count = 1;
        break;
      case EnumDay.Wednesday:
        count = 2;
        break;
      case EnumDay.Thursday:
        count = 3;
        break;
      case EnumDay.Friday:
        count = 4;
        break;
      case EnumDay.Saturday:
        count = 5;
        break;
      case EnumDay.Sunday:
        count = 6;
        break;
    }

    switch (mm1) {
      case 1:
      case 2:
      case 3:
        quarterTemp = 1;
        break;
      case 4:
      case 5:
      case 6:
        quarterTemp = 2;
        break;
      case 7:
      case 8:
      case 9:
        quarterTemp = 3;
        break;
      case 10:
      case 11:
      case 12:
        quarterTemp = 4;
        break;
    }

    switch (mm1) {
      case 1:
        monthTemp = 1;
        quarterTemp = 1;
        break;
      case 2:
        monthTemp = 2;
        quarterTemp = 1;
        break;
      case 3:
        monthTemp = 3;
        quarterTemp = 1;
        break;
      case 4:
        monthTemp = 4;
        quarterTemp = 2;
        break;
      case 5:
        monthTemp = 5;
        quarterTemp = 2;
        break;
      case 6:
        monthTemp = 6;
        quarterTemp = 2;
        break;
      case 7:
        monthTemp = 7;
        quarterTemp = 3;
        break;
      case 8:
        monthTemp = 8;
        quarterTemp = 3;
        break;
      case 9:
        monthTemp = 9;
        quarterTemp = 3;
        break;
      case 10:
        monthTemp = 10;
        quarterTemp = 4;
        break;
      case 11:
        monthTemp = 11;
        quarterTemp = 4;
        break;
      case 12:
        monthTemp = 12;
        quarterTemp = 4;
        break;
    }

    const a = new Date(`${yy1}/1/1`);

    const fDay = new Date(date.getTime() - count * numberOfMiliSecond);
    const numberOfWeek = Math.floor((fDay.getTime() - a.getTime()) / numberOfMiliSecond / 7);

    const dd2 = fDay.getDate();
    const mm2 = fDay.getMonth() + 1;
    const yy2 = fDay.getFullYear();

    const tTemp = `${dd1}/${mm1}/${yy1}`;
    const fTemp = `${dd2}/${mm2}/${yy2}`;
    setFromDate(fTemp);
    setToDate(tTemp);

    setWeek(numberOfWeek + 1);
    setYear(yy1);
    setMonth(monthTemp);
    setQuarter(quarterTemp);
  }, []);

  useEffect(() => {
    if (keyword || keyword == '') {
      console.log(keyword);
      getUserList(keyword)
        .then((data) => {
          setUserList(data.items);
          setIsShowUserList(true);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
        });
    } else {
      setIsShowUserList(false);
    }
  }, [addPopup, getUserList, keyword]);

  useEffect(() => {
    const eTuan = [],
      eThang = [],
      eQuy = [],
      eNam = [];
    for (let i = 2021; i < 2030; i++) {
      const elm = <option value={i}>{i}</option>;
      eNam.push(elm);
    }
    for (let i = 1; i <= 52; i++) {
      const elm = <option value={i}>{i}</option>;
      eTuan.push(elm);
    }
    for (let i = 1; i <= 4; i++) {
      const elm = <option value={i}>{i}</option>;
      eQuy.push(elm);
    }
    for (let i = 1; i <= 12; i++) {
      const elm = <option value={i}>{i}</option>;
      eThang.push(elm);
    }

    setElmNam(eNam);
    setElmTuan(eTuan);
    setElmQuy(eQuy);
    setElmThang(eThang);
  }, []);
  //End of useEffect

  //Main
  return (
    <div className="debit-container">
      <div className="debit-slide-container">
        <div className="debit-title-component text-medium">Tìm kiếm</div>
        <div className="debit-slide-from-date title">Từ ngày</div>
        <div className="debit-slide-from-date">
          <div className="debit-slide-from-date-input">{fromDate}</div>
          <Calendar
            align={EnumCalendarAlign.left}
            pos={EnumCalendarPos.bot}
            onChange={onChangeFromDate}
          />
        </div>
        <div className="debit-slide-from-date title">Đến ngày</div>
        <div className="debit-slide-from-date">
          <div className="debit-slide-from-date-input">{toDate}</div>
          <Calendar
            align={EnumCalendarAlign.left}
            pos={EnumCalendarPos.bot}
            onChange={onChangeToDate}
          />
        </div>
        <div className="debit-slide-from-date title">Chọn nhanh</div>
        <div className="debit-slide-from-date btn">
          <div
            className="debit-radio-btn"
            onClick={() => {
              setDateType(EnumDateType.Tuan);
              onChangeDateType(EnumDateType.Tuan);
            }}
          >
            <input type="radio" checked={dateType == EnumDateType.Tuan} />
            <span style={{ marginLeft: 5 }}>Tuần</span>
          </div>
          <div
            className="debit-radio-btn"
            onClick={() => {
              setDateType(EnumDateType.Thang);
              onChangeDateType(EnumDateType.Thang);
            }}
          >
            <input type="radio" checked={dateType == EnumDateType.Thang} />
            <span style={{ marginLeft: 5 }}>Tháng</span>
          </div>
          <div
            className="debit-radio-btn"
            onClick={() => {
              setDateType(EnumDateType.Quy);
              onChangeDateType(EnumDateType.Quy);
            }}
          >
            <input type="radio" checked={dateType == EnumDateType.Quy} />
            <span style={{ marginLeft: 5 }}>Quý</span>
          </div>
          <div
            className="debit-radio-btn"
            onClick={() => {
              setDateType(EnumDateType.Nam);
              onChangeDateType(EnumDateType.Nam);
            }}
          >
            <input type="radio" checked={dateType == EnumDateType.Nam} />
            <span style={{ marginLeft: 5 }}>Năm</span>
          </div>
        </div>
        <div className="debit-slide-from-date">
          {dateType == EnumDateType.Thang ? (
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Tháng</span>
              <select
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                  onChangeToMonthType(Number(event.target.value), year);
                }}
              >
                {elmThang.length > 0 ? elmThang : null}
              </select>
            </div>
          ) : dateType == EnumDateType.Quy ? (
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Quý</span>
              <select
                value={quarter}
                onChange={(event) => {
                  setQuarter(event.target.value);
                  onChangeToQuarterType(Number(event.target.value), year);
                }}
              >
                {elmQuy.length > 0 ? elmQuy : null}
              </select>
            </div>
          ) : dateType == EnumDateType.Tuan ? (
            <div className="debit-select-component">
              <span style={{ marginRight: 10 }}>Tuần</span>
              <select
                value={week}
                onChange={(event) => {
                  setWeek(event.target.value);
                  onChangeToWeekType(Number(event.target.value), year);
                }}
              >
                {elmTuan.length > 0 ? elmTuan : null}
              </select>
            </div>
          ) : null}
          <div className="debit-select-component">
            <span style={{ marginRight: 10 }}>Năm</span>
            <select
              value={year}
              onChange={(event) => {
                setYear(event.target.value);
                if (dateType == EnumDateType.Tuan) {
                  onChangeToWeekType(week, Number(event.target.value));
                } else if (dateType == EnumDateType.Thang) {
                  onChangeToMonthType(month, Number(event.target.value));
                } else if (dateType == EnumDateType.Quy) {
                  onChangeToQuarterType(quarter, Number(event.target.value));
                } else if (dateType == EnumDateType.Nam) {
                  onChantoYearType(Number(event.target.value));
                }
              }}
            >
              {elmNam.length > 0 ? elmNam : null}
            </select>
          </div>
        </div>
        <div className="debit-slide-from-date title">Chọn CTV</div>
        <div className="debit-slide-from-date input">
          <input
            type="text"
            value={keywordShow}
            onChange={(event) => {
              setKeyword(event.target.value);
              setKeywordShow(event.target.value);
            }}
          />
        </div>
        {isShowUserList ? (
          <div className="debit-slide-user-list">
            {userList
              ? userList.map((value, index) => {
                  return (
                    <div
                      className={`debit-slide-user ${index % 2 == 0 ? 'chan' : 'le'}`}
                      onClick={() => {
                        setUserId(value.userId);
                        setKeyword(null);
                        setKeywordShow(value.fullName);
                      }}
                    >
                      <div> {value.fullName}</div>
                      <div className="value-email">
                        <i>{value.email}</i>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        <div className="debit-slide-from-date btn">
          <div className="debit-btn-show" onClick={onShowDebit}>
            Xem
          </div>
        </div>
      </div>
      <div className="debit-main-container">
        <div className="debit-title-component text-medium">Công nợ</div>
        <div>
          <div className="debit-main-component">
            <div className="debit-main-detail">
              Tổng tiền trong kỳ: <span style={{ color: 'blue' }}>{totalMoney}¥</span>
            </div>
            <div className="debit-main-detail">
              Tổng tiền bì thư trong kỳ:{' '}
              <span style={{ color: 'blue' }}>{totalOtherMoney}¥</span>
            </div>
            <div className="debit-main-detail">
              Tiền nợ đầu kì: <span style={{ color: 'red' }}>{remainUnpaidMoney}¥</span>
            </div>
            <div className="debit-main-detail">
              Tiền nợ bì thư đầu kì:{' '}
              <span style={{ color: 'red' }}>{remainUnpaidOtherMoney}¥</span>
            </div>
          </div>
          <div className="debit-main-component">
            <div className="debit-main-detail">
              Tiền đã thanh toán trong kì: <span style={{ color: 'blue' }}>{paidMoney}¥</span>
            </div>
            <div className="debit-main-detail">
              Tiền bì thư đã thanh toán trong kì:{' '}
              <span style={{ color: 'blue' }}>{paidOtherMoney}¥</span>
            </div>
          </div>
          <div className="debit-main-component">
            <div className="debit-main-detail">
              Tổng tiền còn nợ:{' '}
              <span style={{ color: 'red' }}>
                {remainUnpaidMoney +
                  remainUnpaidOtherMoney +
                  totalMoney +
                  totalOtherMoney -
                  paidMoney -
                  paidOtherMoney}
                ¥
              </span>
            </div>
            <div className="debit-main-detail">
              <div className="debit-btn-show" onClick={sendEmail}>
                <i className="fas fa-mail-bulk"></i>
                <span style={{ marginLeft: 10 }}>Gửi mail công nợ</span>
              </div>
              {isSendEmail ? (
                <div style={{ marginLeft: 10 }}>
                  <i className="fas fa-check" style={{ color: 'blue' }}></i>
                  <span style={{ color: 'blue' }}>Đã gửi</span>
                </div>
              ) : (
                <div style={{ marginLeft: 10 }}>
                  <i className="fas fa-times" style={{ color: 'red' }}></i>{' '}
                  <span style={{ color: 'red' }}>Chưa gửi</span>
                </div>
              )}
            </div>
          </div>
          <div className="debit-sim-order-detail">
            <div className="debit-title-component text-medium">Danh sách sản phẩm trong kì</div>
            <Table
              display={display}
              isInput={false}
              data={simOrderDetailList}
              allowCheckbox={true}
              isNo={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Debit;
