import React, { useEffect, useRef, useState } from 'react';
import './Printed.css';
import { Editor } from '@tinymce/tinymce-react';
import Loading from '../Loading/Loading';


interface IPrinted {
  values: string[];
}

const Printed: React.FC<IPrinted> = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState<string>("");
  useEffect(() => {
    const content = props.values.reduce((result, item) => result += `<div style='page-break-before: always; clear:both'/>${item}</div>`, "");
    setContent(content);
  }, [props]);

  return (
    <div className="printed-content">
      {isLoading && <div style={{margin: 'auto', width: 'fit-content', paddingTop: "10px"}}><Loading color='gray' size='40px'/></div>}
      <Editor
        disabled={false}
        apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
        onInit={() => setLoading(false)}
        initialValue={content}
        init={{
          height: '100%',
          width: '100%',
          menubar: false,
          plugins: [],
          toolbar:
            'print',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
};

export default Printed;
