import './PurchaseAccount.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, PurchaseAccountType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import {
  useDeletePurchaseAccount,
  useGetAdminPurchaseAccount,
} from 'src/api/purchaseAccountApi';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import PurchaseAccountDetail from './PurchaseAccountDetail/PurchaseAccountDetail';
import { EnumViewType } from 'src/common/enum/EnumViewType';

const PurchaseAccount: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const [count, setCount] = useState<number>();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [purchaseAccountList, setPurchaseAccountList] = useState<PurchaseAccountType[]>([]);

  //Function
  const addPopup = useAddPopup();
  const getAdminPurchaseAccount = useGetAdminPurchaseAccount();
  const deletePurchaseAccount = useDeletePurchaseAccount();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(PurchaseAccountDetail);
  const slideBar = useSlideBar(PurchaseAccountDetail);


  const display = {
    header: [
      {
        code: 'purchasePage',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Loại tài khoản',
      },
      {
        code: 'accountName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên đăng nhập',
      },
      {
        code: 'accountPassword',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mật khẩu',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
    ] as TableColumnType[]
  };

  //Menucontext
  const onView = (purchaseAccountId: number) => {
    const url = `${BASE_ORDER_URL}/purchase-account/${purchaseAccountId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (purchaseAccountId: number) => {
    const url = `${BASE_ORDER_URL}/purchase-account/${purchaseAccountId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (purchaseAccountId: number) => {
    const onConfirm = () => {
      deletePurchaseAccount(purchaseAccountId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa tài khoản thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa tài khoản này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA TÀI KHOẢN',
    );
  };

  const menuContext = (item: PurchaseAccountType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.purchaseAccountId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.purchaseAccountId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.purchaseAccountId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClick = (purchaseAccountId: number) => {
    slideBar.handlePresent({
      purchaseAccountId: purchaseAccountId
    })
  };

  //Function in the listButton
  const onAddNewPurchaseAccount = (e: MouseEvent) => {
    detailModal.handlePresent({
      isDisable: false,
      postProcess: (data: PurchaseAccountType) => {
        detailModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      },
    }, 'THÊM TÀI KHOẢN MUA HỘ')
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewPurchaseAccount,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getAdminPurchaseAccount(keyword, page, size)
      .then((data) => {
        setPurchaseAccountList(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getAdminPurchaseAccount, keyword, page, reloadFlag, size]);


  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH TÀI KHOẢN MUA HỘ'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      <Table
        display={display}
        isInput={false}
        data={purchaseAccountList}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.purchaseAccountId)}
        onClick={(item) => onClick(item.purchaseAccountId)}
        isNo={true}
      />
    </>
  );
};
export default PurchaseAccount;
