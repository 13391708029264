
import Cart from 'src/components/Cart/Cart';
import './ExportBillPreview.css';
import React, { useEffect, useState } from 'react';
import { ExportBillType, OrderRequirementType } from 'src/api/models';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { usePostExportBill } from 'src/api/exportBillApi';
import { useAddPopup } from 'src/state/application/hooks';
import Input from 'src/components/Input';
import { date2string, getCurrentDate } from 'src/utils/formatTime';

interface IExportBillPreview {
  orders: OrderRequirementType[];
  postProcess?: (...args: any[]) => void;
}

const ExportBillPreview: React.FC<IExportBillPreview> = (props) => {
  const now = getCurrentDate();
  const [note, setNote] = useState<string>();
  const postExportBill = usePostExportBill();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve, reject) => {
    const body: ExportBillType = {
      orderId: JSON.stringify(props.orders.map(o => o.orderRequirementId)),
      product: JSON.stringify([]),
      note: note
    };

    postExportBill(body).then(
      (res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Tạo phiếu yêu cầu xuất kho thành công',
          },
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        reject(false);
      });

  });
 

  //Main
  return (
    <div className='export-bill-preview-container'>
       <div className='export-bill-preview-row'>
        <Input
          width="100%"
          title="Ngày xuất"
          require={false}
          disabled={true}
          value={now}
        />
      </div>
      <div className='export-bill-preview-row'>
        <Input
          width="100%"
          title="Ghi chú"
          require={false}
          disabled={false}
          value={note}
          onChange={setNote}
        />
      </div>
      <div className='export-bill-preview-row'>
        <ButtonComponent width='100%' icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
      </div>
      <div className='export-bill-preview-row'>
        <Cart orders={props.orders} />
      </div>
    </div>
    
  );
};

export default ExportBillPreview;
