import React, { useState, useEffect } from 'react';
import { ModuleType } from 'src/api/models';
import { useGetModuleById, usePostModule, usePutModule } from 'src/api/moduleApi';
import { useAddPopup } from 'src/state/application/hooks';
import './ModuleDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IModuleDetail {
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  moduleId?: number;
  postProcess: (...args: any[]) => any;
}

const ModuleDetail: React.FC<IModuleDetail> = (props) => {

  const [moduleId, setModuleId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  
  useEffect(() => {
    setModuleId(props.moduleId);
    setIsDisable(props.isDisable);
  }, [])

  //State
  const [moduleName, setModuleName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  //Function
  const addPopup = useAddPopup();
  const postModule = usePostModule();
  const putModule = usePutModule();
  const getModuleById = useGetModuleById();

  const onSave = () => new Promise((resolve) => {
    if (moduleName.trim().length > 0) {
      const body: ModuleType = {
        moduleId: moduleId,
        displayOrder: displayOrder,
        moduleName: moduleName,
        description: description,
      };
      const isAdd = !moduleId;
      const api = isAdd? postModule(body) : putModule(body);
      api.then(
        (res) => {
          setModuleId(res.moduleId);
          setIsDisable(true);
          addPopup({
            txn: {
              success: true,
              summary: !isAdd? 'Thay đổi module thành công' : 'Tạo đơn module thành công',
            }
          });
          if(props.postProcess) props.postProcess(res);
          resolve(true);
         
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập tên module',
        },
      });
      resolve(false);
    }
  });

  //End of component
  useEffect(() => {
    if (moduleId) {
      getModuleById(moduleId)
        .then((data) => {
          setModuleName(data.moduleName);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getModuleById, moduleId]);

  //Main
  return (
    <div className="module-detail-container">
      <div className="module-detail-row">
        <Input
          width='300px'
          title='Tên module'
          require={true}
          disabled={isDisable}
          value={moduleName}
          onChange={setModuleName}
        />
      </div>
      <div className="module-detail-row">
        <Input
          width='300px'
          title='Mô tả'
          require={false}
          disabled={isDisable}
          value={description}
          onChange={setDescription}
        />
      </div>
      <div className="module-detail-row">
        <Input
          width='300px'
          title='Thứ tự'
          require={false}
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      {!isDisable && <div className="module-detail-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
      </div>}
    </div>
  );
};

export default ModuleDetail;
