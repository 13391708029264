import './Manifest.css';
import { MouseEvent } from 'react';
import { EnumAction, EnumDataType, EventButton, ManifestType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useGetManifest, useDeleteManifest } from 'src/api/manifestApi';
import useModal from 'src/hooks/useModal';
import { useAddPopup } from 'src/state/application/hooks';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import ManifestImportExcel from './ManifestImportExcel/ManifestImportExcel';
import { useDebounce } from 'use-debounce';
import { useConfiguration } from 'src/contexts/ConfigProvider/ConfigProvider';


const Manifest: React.FC = () => {
  //Function

  const addPopup = useAddPopup();
  const getManifest = useGetManifest();
  const deleteManifest = useDeleteManifest();
  const { resourceUrl } = useConfiguration();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [items, setItems] = useState<ManifestType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const importModal = useModal(ManifestImportExcel);
  const confirmModal = useModal(ConfirmModal);

  const display = {
    header: [
      {
        code: 'qr',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'QR',
      },
      {
        code: 'qr',
        dataType: EnumDataType.HTML,
        isOptions: false,
        convert: (qr: string) => {
          return <img className="cell-image" src={`${resourceUrl}/images/qr_code/${qr}.PNG`} alt="image" />;
        },
        title: 'QR',
      },
      {
        code: 'hawb',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'HAWB',
      },
      {
        code: 'pk',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'PK',
      },
      {
        code: 'dest',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'DEST',
      },
      {
        code: 'weight',
        dataType: EnumDataType.Decimal,
        isOptions: false,
        title: 'WEIGHT',
      },
      {
        code: 'vnd',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'VND',
      },
      {
        code: 'receiver',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'RECEIVER',
      },
      {
        code: 'descriptions',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'DESCRIPTIONS',
      },
      {
        code: 'address',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'ADDRESS',
      },
      {
        code: 'phoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'PHONENUMBER',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'NOTE',
      },
    ] as TableColumnType[],
  };

  //Get all Manifest
  useEffect(() => {
    getManifest(keywordDebound as string, page, size).then((res) => {
      setCount(res.count);
      setItems(res.items);
    });
  }, [getManifest, keywordDebound, page, size, reloadFlag]);

  

  const onDelete = (qr: string) => {
    const onConfirm = () => {
      deleteManifest(qr)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa bản ghi manifest thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa bản ghi này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA DỊCH VỤ',
    );
  };

  const menuContext = (item: ManifestType) => [
   
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.qr),
      buttonClass: 'info',
      align: '',
    },
  ];


  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Import Manifest',
      icon: 'file_upload',
      actionType: EnumAction.Add,
      buttonClass: 'info tool-btn',
      action: () => importModal.handlePresent({
        postProcess: () => setReloadFlag(!reloadFlag)
      }, 'NHẬP MANIFEST'),
      align: 'center',
    },
  ];

  return (
    <>
      <ToolBar
        toolBarKey='JVSADMIN_MANIFEST'
        toolbarName={'Manifest'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      <Table
        display={display}
        isInput={false}
        data={items}
        menuContext={menuContext}
      />
    </>
  );
};

export default Manifest;
