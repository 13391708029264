import React, { useState, useEffect } from 'react';
import { RoleType } from 'src/api/models';
import { useGetRoleById, usePostRole, usePutRole } from 'src/api/roleApi';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import './RoleDetail.css';

interface IRoleDetail {
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  roleId?: number;
  postProcess: (...args: any[]) => any;
}

const RoleDetail: React.FC<IRoleDetail> = (props) => {

  const [roleId, setRoleId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  
  useEffect(() => {
    setRoleId(props.roleId);
    setIsDisable(props.isDisable);
  }, [])

  //State
  const [roleName, setRoleName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();
  const [roleType, setRoleType] = useState(0);

  //Function
  const addPopup = useAddPopup();
  const postRole = usePostRole();
  const putRole = usePutRole();
  const getRoleById = useGetRoleById();

  const onSave = () => new Promise((resolve) => {
    if (roleName.trim().length > 0) {
      const body: RoleType = {
        roleId: roleId,
        roleName: roleName,
        description: description,
        displayOrder: displayOrder,
        roleType: roleType,
      };
      const isAdd = !roleId;
      const api = isAdd? postRole(body) : putRole(body);
      api.then(
        (res) => {
          setRoleId(res.roleId);
          setIsDisable(true);
          addPopup({
            txn: {
              success: true,
              summary: !isAdd? 'Thay đổi role thành công' : 'Tạo đơn role thành công',
            }
          });
          if(props.postProcess) props.postProcess(res);
          resolve(true);
         
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập tên role',
        },
      });
      resolve(false);
    }
  });


  useEffect(() => {
    if (roleId) {
      getRoleById(roleId)
        .then((data) => {
          setRoleName(data.roleName);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setRoleType(data.roleType);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getRoleById, roleId]);

  //Main
  return (
    <div className="role-detail-container">
      <div className="role-detail-row">
        <Input
          width='300px'
          title='Tên role'
          require={true}
          disabled={isDisable}
          value={roleName}
          onChange={setRoleName}
        />
      </div>
      <div className="role-detail-row">
        <Input
          width='300px'
          title='Mô tả'
          require={false}
          disabled={isDisable}
          value={description}
          onChange={setDescription}
        />
      </div>
      <div className="role-detail-row">
        <Input
          width='300px'
          title='Thứ tự'
          require={false}
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      {!isDisable && <div className="role-detail-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
      </div>}
    </div>
  );
};

export default RoleDetail;
