import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, SimNewsType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { BASE_SIM_URL } from 'src/constants';
import { useDeleteSimNews, useGetSimNews } from 'src/api/simNewsApi';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useModal from 'src/hooks/useModal';

const SimNews: React.FC = () => {
  const display = {
    header: [
      {
        code: 'simNewAvatar',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Avatar',
      },
      {
        code: 'simNewTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'simNewValue',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Giá trị',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };

  const navigate = useNavigate();

  //Function
  const addPopup = useAddPopup();
  const getSimNews = useGetSimNews();
  const deleteSimNews = useDeleteSimNews();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [data, setData] = useState<SimNewsType[]>([]);
  const [count, setCount] = useState<number>();

  const confirmModal = useModal(ConfirmModal);

  useEffect(() => {
    getSimNews(page, size)
      .then((data) => {
        setData(data.items);
        setCount(data.count);
      })
      .catch(() => {
        alert('Có lỗi xảy ra vui lòng thử lại sau');
      });
  }, [getSimNews, page, reloadFlag, size]);

  //Menucontext
  const onView = (simNewId: number) => {
    openDetail(EnumViewType.View, simNewId);
  };

  const onEdit = (simNewId: number) => {
    openDetail(EnumViewType.Edit, simNewId);
  };

  const onDelete = (simNewId: number) => {
    const onConfirm = () => {
      deleteSimNews(simNewId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa tin tức thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa tin này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA TIN TỨC',
    );
  };

  //When click right mouse
  const menuContext = (item: SimNewsType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.simNewId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.simNewId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.simNewId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext

  const openDetail = (type: EnumViewType, simNewId: number) => {
    const url = `${BASE_SIM_URL}/sim-news/${type}/id/${simNewId}`;
    navigate(url);
  };

  const onAddNew = () => {
    openDetail(EnumViewType.Edit, 0);
  };

  const listRightButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100',
      action: onAddNew,
      align: 'center',
    },
  ];
  //End of toolbar

  return (
    <>
      <ToolBar
        toolbarName={'Danh sách tin tức SIM'}
        listRightButton={listRightButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={true}
        count={count}
        page={page}
        size={size}
        onChangePage={setPage}
        onChangeSize={setSize}
      />
      <Table
        display={display}
        isInput={false}
        data={data}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.simNewId)}
      />
    </>
  );
};

export default SimNews;
