/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MouseEvent } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ReceiverOrderType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import './ReceiverOrder.css';
import { useDeleteReceiverOrder, useGetReceiverOrder, usePrintReceiverOrder, usePrintReceiverOrderV10, usePrintReceiverOrderV40 } from 'src/api/receiverOrderApi';
import { useDebounce } from 'use-debounce';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import ReceiverOrderDetail from './ReceiverOrderDetail/ReceiverOrderDetail';
import InputModal from 'src/components/InputModal/InputModal';
import Printed from 'src/components/Printed/Printed';
import { useGetReferral } from 'src/api/referralApi';

//TODO
const ReceiverOrder: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteReceiverOrder = useDeleteReceiverOrder();
  const getReceiverOrder = useGetReceiverOrder();
  const printReceiverOrder = usePrintReceiverOrder();
  const printReceiverOrderV40 = usePrintReceiverOrderV40();
  const printReceiverOrderV10 = usePrintReceiverOrderV10();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  //Local state
  const [count, setCount] = useState<number>();
  const [rawData, setRawData] = useState<ReceiverOrderType[]>([]);
  const [receiverOrders, setReceiverOrders] = useState<ReceiverOrderType[]>([]);
  const [checkList, setCheckList] = useState<ReceiverOrderType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ReceiverOrderDetail);
  const numberModal = useModal(InputModal);
  const printedModal = useModal(Printed);

  const display = {
    header: [
      {
        code: 'receiverOrderCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã đại lý',
      },
      {
        code: 'title',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'TK đăng ký',
      },
      {
        code: 'picName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'PIC',
      },
      {
        code: 'airPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Air / kg',
      },
      {
        code: 'seaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Sea / kg',
      },
      {
        code: 'volumeAirPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Air / khối',
      },
      {
        code: 'volumeSeaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Sea / khối',
      },
      {
        code: 'overAirPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước quá khổ Air / kg',
      },
      {
        code: 'overSeaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước quá khổ Sea / kg',
      },
      {
        code: 'address',
        dataType: EnumDataType.HTML,
        isOptions: false,
        convert: (value: string) => {
          return (<div>
            {(value? JSON.parse(value) as [] : []).map((item, index) => (
              <div key={`option${index}`}>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.name}</span>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.phone}</span>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.address}</span>
              </div>
            ))}
          </div>
          );
        },
        title: 'Địa chỉ',
      },
    ] as TableColumnType[],
  
  };

  //Menucontext
  const onView = (receiverOrderId: number) => {
    detailModal.handlePresent(
      {
        receiverOrderId: receiverOrderId,
        isDisable: true,
        postProcess: detailModal.handleDismiss,
      },
      'CHI TIẾT THÔNG TIN ĐẠI LÝ',
    );
  };

  const onEdit = (receiverOrderId: number) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        receiverOrderId: receiverOrderId,
        postProcess: () => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'THAY ĐỔI THÔNG TIN ĐẠI LÝ',
    );
  };

  const onDelete = (receiverOrderId: number) => {
    const onConfirm = () => {
      deleteReceiverOrder(receiverOrderId)
        .then(() => {
          const idx = checkList.findIndex(i => i.receiverOrderId == receiverOrderId);
          if(idx >= 0) {
            checkList.splice(idx, 1);
            setCheckList([...checkList]);
          }
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa thông tin đại lý thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đại lý vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐẠI LÝ VẬN CHUYỂN',
    );

  };


  const menuContext = (item: ReceiverOrderType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.receiverOrderId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.receiverOrderId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.receiverOrderId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClickCheckbox = (item: ReceiverOrderType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != item);
      setCheckList(nCheckList);
      setReceiverOrders(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.receiverOrderId == i.receiverOrderId),
        ),
      );
    } else {
      setCheckList([...checkList, item]);
      setReceiverOrders(receiverOrders.filter((o) => o != item));
    }
  };

  //Function in the listButton
  const onAddNewReceiverOrder = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: () => {
          confirmModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'THÊM MỚI ĐẠI LÝ VẬN CHUYỂN',
    );
  };

  const onPrint = (page: number, calback: (receiverOrderIds: number[], page: number) => Promise<string[]>) => {
    const ids = checkList.map(r => r.receiverOrderId);
    calback(ids, page)
      .then((res) => {
        printedModal.handlePresent(
          {
            values: res,
          },
          'IN PHIẾU THÔNG TIN VẬN CHUYỂN',
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  };

  const printAction = (calback: (receiverOrderIds: number[], page: number) => Promise<string[]>) => {
    if(checkList.length > 0) {
      numberModal.handlePresent({
        fields: [{
          name: 'pageQuantity',
          title: 'Số lượng trang in / đại lý',
          type: 'number'
        }],
        listActionButton: [
          {
            name: 'Xác nhận',
            icon: 'check',
            actionType: EnumAction.Confirm,
            buttonClass: 'info300',
            align: 'center',
            action: (value: {[name: string]: number}) => onPrint(value['pageQuantity'], calback)
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'info100',
            align: 'center',
          },

        ],
        postProcess: numberModal.handleDismiss
      }, "SỐ LƯỢNG BẢN IN");
    } else {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: "Vui lòng chọn đại lý trước khi in.",
        },
      });
    }

  }

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewReceiverOrder,
      align: 'center',
    },
    {
      name: 'In V6',
      icon: 'print',
      actionType: EnumAction.View,
      action: () => printAction(printReceiverOrder),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'In V40',
      icon: 'print',
      actionType: EnumAction.View,
      action: () => printAction(printReceiverOrderV40),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'In V10',
      icon: 'print',
      actionType: EnumAction.View,
      action: () => printAction(printReceiverOrderV10),
      buttonClass: 'info',
      align: 'left',
    },
  ];


  //useEffect
  useEffect(() => {
    getReceiverOrder((keywordDebound as string)??"", page, size)
      .then((data) => {
        const nCheckList = data.items.filter(i => checkList.some((c) => c.receiverOrderId == i.receiverOrderId));
        setCheckList(nCheckList);
        setRawData(data.items);
        setReceiverOrders(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getReceiverOrder, keywordDebound, page, size, reloadFlag]);

  //Main
  return (
    <>
    <ToolBar
      toolbarName={'DANH SÁCH ĐẠI LÝ VẬN CHUYỂN'}
      listRightButton={listButton}
      width={'100%'}
      backgroundColor={'#ebe9e9'}
      count={count}
      onSearch={(value) => setKeyword(value)}
      onChangePage={setPage}
      onChangeSize={setSize}
      keyword={keyword}
      page={page}
      size={size}
      isPaging={true}
    />

    <Table
      display={display}
      isInput={false}
      data={receiverOrders}
      menuContext={menuContext}
      checkedData={checkList}
      allowCheckbox={true}
      onDoubleClick={(item) => onView(item.receiverOrderId)}
      onClickCheckbox={onClickCheckbox}
      isNo={true}
    />
  </>
  );
};
export default ReceiverOrder;
