/************************************/
/********                    ********/
/******       Sim Price     ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { SendSimPriceToUserType, SimPriceType } from './models';

export const useGetSimPriceHistory = () => {
  const fetch = useFetch();
  return useCallback(
    (date?: string, customerTypeId?: number) => {
      if (date) {
        const temp = {
          date: date,
        };
        return fetch({
          url: `sccore/rest-api/sim-price/date?customerTypeId=${customerTypeId}`,
          method: 'post',
          data: temp,
        }) as Promise<SimPriceType[]>;
      } else {
        return fetch({
          url: `sccore/rest-api/sim-price?customerTypeId=${customerTypeId}`,
          method: 'get',
        }) as Promise<SimPriceType[]>;
      }
    },
    [fetch],
  );
};

export const useGetSimPriceById = () => {
  const fetch = useFetch();
  return useCallback(
    (simPriceId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-price/${simPriceId}`,
        method: 'get',
      }) as Promise<SimPriceType>;
    },
    [fetch],
  );
};

export const useGetSimPriceListByDateAndUserId = () => {
  const fetch = useFetch();
  return useCallback(
    (date: string, userId: number) => {
      const temp = {
        date: date,
      };
      return fetch({
        url: `sccore/rest-api/sim-price/date/user?userId=${userId}`,
        method: 'post',
        data: temp,
      }) as Promise<SimPriceType[]>;
    },
    [fetch],
  );
};

export const useGetSimPriceEditHistory = () => {
  const fetch = useFetch();
  return useCallback(
    (customerTypeId: number, productId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-price/customer-type/${customerTypeId}/product/${productId}`,
        method: 'get',
      }) as Promise<SimPriceType[]>;
    },
    [fetch],
  );
};

export const usePostSimPrice = () => {
  const fetch = useFetch();
  return useCallback(
    (simPrice: SimPriceType) => {
      return fetch({
        url: `sccore/rest-api/sim-price`,
        method: 'post',
        data: simPrice,
      }) as Promise<SimPriceType>;
    },
    [fetch],
  );
};

export const usePutSimPrice = () => {
  const fetch = useFetch();
  return useCallback(
    (simPrice: SimPriceType) => {
      return fetch({
        url: `sccore/rest-api/sim-price`,
        method: 'put',
        data: simPrice,
      }) as Promise<SimPriceType>;
    },
    [fetch],
  );
};

export const useDeleteSimPrice = () => {
  const fetch = useFetch();
  return useCallback(
    (simPriceId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-price/${simPriceId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useSendSimPriceEmail = () => {
  const fetch = useFetch();
  return useCallback(
    (data: SendSimPriceToUserType) => {
      return fetch({
        url: `sccore/rest-api/sim-price/email`,
        method: 'post',
        data: data,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of sim price
