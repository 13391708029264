/************************************/
/********                    ********/
/******   Ship Order   ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { EnumShipOrderProgressStatus, ImageType, ManifestType, PageData, PaymentType } from './models';
import { ShipOrderType } from './models';

export const useGetShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number, progressStatus: number, minDate: string, maxDate: string, receiverOrderId: number, transportType: number, isPaid: boolean, userId: number, shipInvoiceId: number, vietnamShipper: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order?keyword=${keyword ?? ''}&page=${page}&size=${size}${progressStatus != undefined ? `&progressStatus=${progressStatus}` : ''}${vietnamShipper != undefined ? `&vietnamShipper=${vietnamShipper}` : ''}${minDate ? `&minDate=${minDate}` : ''}${maxDate ? `&maxDate=${maxDate}` : ''}${receiverOrderId ? `&receiverOrderId=${receiverOrderId}` : ''}${transportType != undefined ? `&transportType=${transportType}` : ''}${isPaid != undefined ? `&isPaid=${isPaid}` : ''}${userId != undefined ? `&userId=${userId}` : ''}${shipInvoiceId != undefined ? `&invoiceId=${shipInvoiceId}` : ''}`,
        method: 'get',
      }) as Promise<PageData<ShipOrderType>>;
    },
    [fetch],
  );
};

export const useGetReceiveShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number, progressStatus: number, minDate: string, maxDate: string, receiverOrderId: number, transportType: number, isPaid: boolean, userId: number, shipInvoiceId: number, vietnamShipper: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/receive?keyword=${keyword ?? ''}&page=${page}&size=${size}${progressStatus != undefined ? `&progressStatus=${progressStatus}` : ''}${vietnamShipper != undefined ? `&vietnamShipper=${vietnamShipper}` : ''}${minDate ? `&minDate=${minDate}` : ''}${maxDate ? `&maxDate=${maxDate}` : ''}${receiverOrderId ? `&receiverOrderId=${receiverOrderId}` : ''}${transportType != undefined ? `&transportType=${transportType}` : ''}${isPaid != undefined ? `&isPaid=${isPaid}` : ''}${userId != undefined ? `&userId=${userId}` : ''}${shipInvoiceId != undefined ? `&invoiceId=${shipInvoiceId}` : ''}`,
        method: 'get',
      }) as Promise<PageData<ShipOrderType>>;
    },
    [fetch],
  );
};

export const useGetShipOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}`,
        method: 'get',
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useGetShipOrderByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/ship-order/code/${code}`,
        method: 'get',
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useDeleteShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/admin`,
        method: 'post',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const usePutShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/admin`,
        method: 'put',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};


export const useReceiverShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/receiver`,
        method: 'put',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const useGetMyShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/me?keyword=${keyword}&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ShipOrderType>>;
    },
    [fetch],
  );
};

export const usePostMyShipOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrder: ShipOrderType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/me`,
        method: 'post',
        data: shipOrder,
      }) as Promise<ShipOrderType>;
    },
    [fetch],
  );
};

export const usePostShipOrderImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data: any, shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/image/${shipOrderId}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteShipOrderImage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number, fileId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePutUpdateShipOrderProgressStatus = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderIdList: any, progressStatus: EnumShipOrderProgressStatus) => {
      return fetch({
        url: `sccore/rest-api/ship-order/update-status/${progressStatus}`,
        method: 'put',
        data: shipOrderIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useExportShipOrder = () => {
  const fetch = useFetch(false, false, true);
  return useCallback((ids: number[]) => {
    return fetch({
      url: `sccore/rest-api/ship-order/file/export`,
      method: 'post',
      data: ids
    }) as Promise<Blob>;
  }, [fetch]);
};

export const useExportManifest = () => {
  const fetch = useFetch();
  return useCallback((ids: number[]) => {
    return fetch({
      url: `sccore/rest-api/ship-order/gen-manifest`,
      method: 'post',
      data: ids
    }) as Promise<ManifestType[]>;
  }, [fetch]);
};



export const usePutUpdateShipOrderPaid = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderIdList: any) => {
      return fetch({
        url: `sccore/rest-api/ship-order/update-paid`,
        method: 'put',
        data: shipOrderIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};


export const usePutUpdateUser = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderIdList: any, userId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/update-user/${userId}`,
        method: 'put',
        data: shipOrderIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useExportShipOrderBill = () => {
  const fetch = useFetch();
  return useCallback(
    (payment: PaymentType) => {
      return fetch({
        url: `sccore/rest-api/ship-order/export-bill`,
        method: 'post',
        data: payment,
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};

export const usePrintSubQR = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number, number: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/${shipOrderId}/print-subqr?number=${number}`,
        method: 'post',
      }) as Promise<string[]>;
    },
    [fetch],
  );
};

export const usePrintVNShip = () => {
  const fetch = useFetch();
  return useCallback(
    (shipOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-order/print-vn-ship/${shipOrderId}`,
        method: 'post',
      }) as Promise<string>;
    },
    [fetch],
  );
};
//End of Ship Order
