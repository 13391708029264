import React, { useState } from 'react';
import { CustomerType } from 'src/api/models';
import './ChooseCustomerTypeDetail.css';

interface ICalendar {
  onChange: (...args: any[]) => any;
  customerTypeList: CustomerType[];
  customerTypeId: number;
}

const ChooseCustomerTypeDetail: React.FC<ICalendar> = (props) => {
  //Value
  const customerTypeList = props.customerTypeList;

  //State
  const [customerTypeId, setCustomerTypeId] = useState(props.customerTypeId);

  //Component
  const customerTypeIdOption = () => {
    return (
      <div>
        <select
          value={customerTypeId}
          className="sim-price-detail-option"
          onChange={(event) => {
            if (event.target.value != 'default') {
              setCustomerTypeId(Number(event.target.value));
              event.preventDefault();
            }
          }}
        >
          <option value={'default'}>Loại khách hàng</option>
          {customerTypeList
            ? customerTypeList.map((value) => {
                return <option value={value.customerTypeId}>{value.customerTypeTitle}</option>;
              })
            : null}
        </select>
      </div>
    );
  };

  //Main
  return (
    <div className="sim-price-calendar-container sim-price">
      <div>{customerTypeIdOption()}</div>
      <div
        className="choose-detail-btn"
        onClick={() => {
          props.onChange(customerTypeId);
        }}
      >
        Chọn
      </div>
    </div>
  );
};

export default ChooseCustomerTypeDetail;
