import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

console.log("SUPPORT NOTIFICATION", isSupported());

if (isSupported() && Notification.permission !== 'granted') {
  Notification.requestPermission().then((status) => {
    console.log('Notification permission status:', status);
  });
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>,
);

serviceWorker.unregister();

//serviceWorker.register();
