import { EnumStockInspectionStatus } from "src/common/enum/EnumStockInspectionStatus";

export type StatisticType = 'price' | 'supply' | 'marketcap';

export type OptionType = {
  title: string;
  value: any;
  css?: any;
};

export type PopupContent = {
  txn?: {
    success: boolean;
    summary?: string;
  };
  error?: {
    message: string;
    title: string;
  };
  context?: {
    width?: string;
    height?: string;
    listActionButton: EventButton[];
    posX?: string;
    posY?: string;
  };
};

export type PopupList = Array<{
  key: string;
  //show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export type ProfileInfo = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  userId?: number;
  birthday?: string;
  email?: string;
  fullName?: string;
  loginName?: string;
  telephone?: string;
  avata?: string;
  avataUrl?: string;
  password?: string;
  displayOrder?: number;
  groupId?: number;
  langId?: number;
  currentPassword?: string;
  confirmPassword?: string;
  mainAddress?: string;
  facebookId?: string;
  googleId?: string;
  appleId?: string;
  twitterId?: string;
  cmtnd?: string;
  cccd?: string;
  mobile?: string;
  sex?: null;
  occupation?: null;
  fbAccessToken?: string;
  googleAccessToken?: string;
  appleAccessToken?: string;
  cover?: string;
  loginNameHash?: string;
  refreshToken?: null;
  otp?: string;
};

export type Profile = {
  accessToken: string;
  refreshToken: string;
  info?: ProfileInfo;
};

export type PageData<T> = {
  count: number;
  items: T[];
  extraInfo?: {[key: string]: any};
};

//Change password
export type ChangePasswordResult = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  userId?: number;
  birthday?: string;
  email: string;
  fullName?: string;
  groupId?: number;
  langId?: number;
  loginName?: string;
  password?: string;
  currentPassword?: string;
  confirmPassword?: string;
  telephone?: string | number;
  mainAddress?: string;
  facebookId?: string;
  googleId?: string;
  twitterId?: string;
  cmtnd?: null;
  cccd?: null;
  mobile?: null;
  avata?: null;
  avataUrl?: string;
  sex?: string;
  occupation?: string;
  fbAccessToken?: string;
  googleAccessToken?: string;
  cover?: string;
  loginNameHash?: string;
  refreshToken?: string;
};

export type NfcUser = {
  title: string;
  description: string;
  company: string;
  birthday: number;
  displayOrder: number;
};

export type NfcChannel = {
  nfcChannelId?: number;
  channelTypeId: number;
  personalUrl: string;
  displayOrder: number;
};

export type NfcInfo = {
  nfcId: number;
  nfcKey: string;
  thumbAvatar: string;
  nfcUser: NfcUser;
  nfcChannels: NfcChannel[];
};

export type RealmType = {
  productRealmId?: number;
  productRealmCode: string;
  productRealmName: string;
  displayOrder?: number;
  isHidden?: boolean;
  description?: string;
};

export type ChannelTypeInfo = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  displayOrder: number;
  channelTypeId: number;
  channelName: string;
  icon: string;
  channelUrl: string;
};

export enum EnumAction {
  View = 'View',
  Delete = 'Delete',
  Edit = 'Edit',
  Add = 'Add',
  Confirm = 'Confirm',
  Cancel = 'Cancel',
}

export type EventButton = {
  name?: string;
  icon: string;
  actionType: EnumAction;
  action?: (...args: any[]) => any;
  buttonClass?: string;
  align: string;
  file?: boolean;
};

//Category
export type ProductCategoryAttributeType = {
  productCategoryId?: number;
  attributeTitle?: string;
  attributeName?: string;
  isShowProduct?: number;
  isShowProductSerial?: number;
  isShowProductSerialDetail?: number;
  attribute?: ProductAttributeResultType;
};

export type ProductAttributeResultType = {
  attributeTitle?: string;
  attributeName?: string;
  attributeType?: number;
};

export type ProductCategoryType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  productCategoryId?: number;
  productRealmId?: number;
  productCategoryCode?: string;
  productCategoryName?: string;
  thumbAvatar?: string;
  avatar?: string;
  productRealm?: RealmType;
};

export interface CartItemType {
  key?: string;
  productId?: number;
  quantity: number;
}

//Product
export type ProductType = {
  productId?: number;
  productCategory?: ProductCategoryType;
  productCategoryId?: number;
  productCode?: string;
  productName?: string;
  price?: number;
  monthlySubscriptionFee?: number;
  phoneNumber?: string;
  specifications?: string;
  description?: string;
  cpu?: string;
  ram?: string;
  ssd?: string;
  hdd?: string;
  gpu?: string;
  display?: string;
  thumbAvatar?: string;
  avatar?: string;
  images?: ImageType[];
  isStockRemain?: boolean;
  stockRemainQuantity?: number;
  introContent?: string;
  discountPercent?: number;
  displayOrder?: number;
  isHiddenSerial?: boolean;
  stopSelling?: boolean;
  hot?: boolean;
};

export enum EnumProductSerialStatus {
  Stocking = 1,
  Sold = 2,
}

export enum EnumInventoryType {
  Input = 0,
  Output = 1,
}

export type InventoryType = {
  inventoryId?: number;
  productId: number;
  inventoryType: number;
  quantity: number;
  effectiveDate?: string;
  productName?: string;
  productCode?: string;
  orderId?: string;
  status?: number;
};


export type ExportBillType = {
  createdAt?: string;
  exportBillId?: number;
  product: string;
  orderId?: string;
  status?: number;
  note?: string;
  createdBy?: string;
};

export type ImageType = {
  fileId: number;
  fileTypeId: number;
  fileName: string;
  fileUrl: string;
  thumbUrl: string;
};

export type ProductGiftType = {
  productGiftId?: number;
  productId: number;
  productGiftValue: string;
};

//Product Serial
export type ProductSerialType = {
  productSerialId?: number;
  productId?: number;
  productCode?: string;
  productName?: string;
  serial?: string;
  price?: number;
  monthlySubscriptionFee?: number;
  phoneNumber?: number;
  specifications?: string;
  description?: string;
  cpu?: string;
  ram?: string;
  ssd?: string;
  hdd?: string;
  display?: string;
  gpu?: string;
  status?: number;
  thumbAvatar?: string;
  avatar?: string;
  images?: ImageType[];
  effectiveDate?: string;
  expirationDate?: string;
  displayOrder?: number;
};

//Receiver info
export type ReceiverInfoType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  receiverInfoId?: number;
  userId?: number;
  fullname?: string;
  phoneNumber?: string;
  zipCode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  facebook?: string;
  zalo?: string;
};

//Company info
export type CompanyInfoType = {
  companyInfoId: number;
  companyInfoKey: string;
  companyInfoTitle: string;
  companyInfoValue: string;
  href?: string;
  displayOrder?: number;
};

//Static page
export type StaticPageType = {
  staticPageId: number;
  staticPageKey: string;
  staticPageTitle: string;
  staticPageValue: string;
  description: string;
  displayOrder?: number;
};

//Guide Page
export type GuidePageType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  guidePageId?: number;
  guidePageKey?: string;
  guidePageTitle?: string;
  guidePageValue?: string;
  description?: string;
  guideAvatar?: string;
};

//Order requirement
export enum EnumReceiveTime {
  Any = 1,
  Morning = 2,
  Twelve_Fifteen = 3,
  Fifteen_Eightteen = 4,
  Eightteen_TwentyOne = 5,
}

export enum EnumReceiveTimeTitle {
  Any = 'Giờ bất kì',
  Morning = 'Trong buổi sáng',
  Twelve_Fifteen = '12h-15h',
  Fifteen_Eightteen = '15h-18h',
  Eightteen_TwentyOne = '18h-21h',
}

export type OrderRequirementDetailsItemType = {
  orderRequirementDetailId?: number;
  productSerialId?: number;
  productSerial?: ProductSerialType;
  productId?: number;
  quantity?: number;
  product?: ProductType;
  price?: number;
  extraAmount?: number;
};

//TODO
export enum EnumOrderRequirementProgressStatus {
  Waiting = 1,
  Sent = 2,
  Received = 3,
  Error = 4,
}

//TODO
export enum EnumOrderRequirementProgressStatusTitle {
  Waiting = 'Chờ gửi',
  Sent = 'Xuất kho',
  Received = 'Đã nhận',
  Error = 'Lỗi gửi',
}

export type OrderRequirementType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  orderRequirementId?: number;
  userId?: number;
  userName?: string;
  receiverFullname?: string;
  receiverPhoneNumber?: string;
  receiverZipCode?: string;
  receiverAddress1?: string;
  receiverAddress2?: string;
  receiverAddress3?: string;
  receiverAddress4?: string;
  receiverFacebook?: string;
  receiverZalo?: string;
  distance?: number;
  shipFee?: number;
  discount?: number;
  receiverTime1?: EnumReceiveTime;
  receiverDate1?: string;
  receiverTime2?: EnumReceiveTime;
  receiverDate2?: string;
  trackingCode?: string;
  orderRequirementNote?: string;
  paymentMethod?: number;
  progressStatus?: EnumOrderRequirementProgressStatus;
  orderRequirementDetails?: OrderRequirementDetailsItemType[];
  isPaid?: boolean;
  paymentCode?: string;
  commission?: boolean;
  referralId?: number;
  referralName?: string;
};

//News
export type NewsType = {
  newId?: number;
  newAvatar?: string;
  newTitle?: string;
  newValue?: string;
  description?: string;
  displayOrder?: number;
};

//Comment
export type CommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  commentId?: number;
  newId?: number;
  parentId?: number;
  commentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

//Service
export type ServiceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  serviceId?: number;
  serviceCode?: string;
  serviceName?: string;
  staticPageKey?: string;
};

//Customer type
export type BusinessType = {
  businessType: number;
  businessTypeTitle: string;
};

export type CustomerType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  customerTypeId?: number;
  customerTypeCode?: string;
  customerTypeTitle?: string;
  feePercent?: number;
  salesTarget?: number;
  discountPercent?: number;
};

export type UserCustomerType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  userCustomerTypeId?: number;
  customerTypeId?: number;
  userId?: number;
  businessType?: number;
  businessTypeTitle?: string;
  effectiveDate?: string;
  customerType?: CustomerType;
  user?: ProfileInfo;
};

//Module
export type ModuleType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  functionId?: number;
  moduleId?: number;
  moduleName?: string;
  description?: string;
};

//Function
export type FunctionType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  functionId?: number;
  moduleId?: number;
  functionName?: string;
  description?: string;
};

//Role
export type RoleType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  roleId?: number;
  roleName?: string;
  roleType?: number;
  description?: string;
};

export enum EnumActionType {
  View = 1,
  Add = 2,
  Edit = 4,
  Delete = 8,
  Confirm = 16,
  All = 32,
}

export type FuncionActionType = {
  View?: boolean;
  Add?: boolean;
  Confirm?: boolean;
  Edit?: boolean;
  Delete?: boolean;
}

export type RolePermisionType = {
  roleId?: number;
  functionId?: number;
  permision?: number;
  actions?: FuncionActionType;
};

//API service
export enum EnumHttpMethod {
  GET = 1,
  POST = 2,
  PUT = 3,
  DELETE = 4,
}

export type ApiType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  apiId?: number;
  router?: string;
  methodId?: EnumHttpMethod;
  actionTypeId?: EnumActionType;
};

export type MapApiType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  apiId?: number;
  functionId?: number;
};

//Display
export enum EnumDisplayConfig {
  Loai_san_pham = 2,
  Danh_muc_san_pham = 3,
  San_pham = 4,
  Dich_vu = 5,
  Banner = 6,
  Logo = 7,
  Thong_tin = 8,
  Hinh_anh_cong_ty = 9,
  Trang_thong_tin_tinh = 10,
  Danh_sach_dat_hang = 11,
  Tin_tuc = 12,
  Quan_ly_tai_khoan = 1,
  Cau_hinh_phan_quyen = 21,
  Phan_quyen = 22,
  Sim_order = 13,
  Sim_da_ban = 14,
  Lich_su_bang_gia = 23,
  Mau_email = 24,
  Cong_no = 25,
  ProductOrder = 26,
  ShipOrder = 27,
  PurchaseAccount = 28,
  AdvertisingBanner = 29,
  PrintedTemplate = 30,
  MyOrder = 31,
  SendSimEmail = 32,
  SimGuide = 33,
  SimNew = 34,
  ReceiverShipOrder = 35,
  ShipInvoice = 36,
  ShipPackage = 37,
  ShipOrderDeclaration = 38,
  ImportJapanStock = 39,
  ImportVNStock = 40,
  SentToCustomer = 41,
  ShipAgent = 42,
  ShipCollatorabor = 43,
  Payment = 44,
  Disbursement = 45,
  CustomerDebt = 46

}

export enum EnumModuleId {
  SYSTEM = 1,
  WEB = 2,
  SIM = 3,
}




export type HtmlSimpleParameterType = {
  parameterName: string;
  description?: string;
  dataType: EnumDataType;
};

export type HtmlColumnsType = {
  columnTitle?: string;
  columnName?: string;
  dataType?: EnumDataType;
  columnCss?: string;
  cellCss?: string;
};

export type HtmlTableParameterType = {
  tableName?: string;
  columns: HtmlColumnsType[];
  tableCss?: string;
  rowCss?: string;
};

export type EmailTemplateType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  emailTemplateId?: number;
  emailTemplateKey?: string;
  emailTemplateTitle?: string;
  emailTemplateValue?: string;
  emailSimpleParameter?: HtmlSimpleParameterType[];
  emailTableParameter?: HtmlTableParameterType[];
  description?: string;
};

//Printed Template
export type PrintedTemplateType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  printedTemplateId?: number;
  printedTemplateKey?: string;
  printedTemplateTitle?: string;
  printedTemplateValue?: string;
  printedSimpleParameter?: HtmlSimpleParameterType[];
  printedTableParameter?: HtmlTableParameterType[];
  description?: string;
};

export enum EnumDataType {
  Text = 1,
  Int = 2,
  Date = 3,
  PhoneNumber = 4,
  Email = 5,
  Boolean = 6,
  Percentage = 7,
  BigInt = 8,
  JPY = 9,
  Month = 10,
  QuarterOfYear = 11,
  Year = 12,
  DateRange = 13,
  Image = 14,
  Option = 15,
  HTML = 16,
  Ratio = 17,
  Link = 18,
  Decimal = 19,
  VND = 20
}

export const DataTypeList = [
  {
    value: EnumDataType.BigInt,
    title: 'BigInt',
  },
  {
    value: EnumDataType.Boolean,
    title: 'Boolean',
  },
  {
    value: EnumDataType.Date,
    title: 'Date',
  },
  {
    value: EnumDataType.DateRange,
    title: 'DateRange',
  },
  {
    value: EnumDataType.Decimal,
    title: 'Decimal',
  },
  {
    value: EnumDataType.Email,
    title: 'Email',
  },
  {
    value: EnumDataType.HTML,
    title: 'HTML',
  },
  {
    value: EnumDataType.Image,
    title: 'Image',
  },
  {
    value: EnumDataType.Int,
    title: 'Int',
  },
  {
    value: EnumDataType.JPY,
    title: 'JPY',
  },
  {
    value: EnumDataType.Link,
    title: 'Link',
  },
  {
    value: EnumDataType.Month,
    title: 'Month',
  },
  {
    value: EnumDataType.Option,
    title: 'Option',
  },
  {
    value: EnumDataType.Percentage,
    title: 'Percentage',
  },
  {
    value: EnumDataType.PhoneNumber,
    title: 'PhoneNumber',
  },
  {
    value: EnumDataType.QuarterOfYear,
    title: 'QuarterOfYear',
  },
  {
    value: EnumDataType.Ratio,
    title: 'Ratio',
  },
  {
    value: EnumDataType.Text,
    title: 'Text',
  },
  {
    value: EnumDataType.VND,
    title: 'VND',
  },
  {
    value: EnumDataType.Year,
    title: 'Year',
  },
];

export type NotificationParameterType = {
  parameterName: string;
  description?: string;
  dataType: EnumDataType;
};

export type NotificationTemplateType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  notificationTemplateId?: number;
  notificationTemplateKey?: string;
  notificationTemplateTitle?: string;
  notificationTemplateValue?: string;
  notificationParameter?: NotificationParameterType[];
  description?: string;
};

//Note
export type NoteType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  noteId?: number;
  userId?: number;
  functionId?: number;
  objectId?: number;
  noteContent?: string;
  avataUrl?: string;
  fullName?: string;
};

export type EmailType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  emailId?: number;
  emailTemplateId?: number;
  emailTemplateKey?: string;
  emailTitle?: string;
  emailValue?: string;
  receiver?: string;
  description?: string;
};

//send sim price to user
export type SendSimPriceToUserType = {
  fromDate: string;
  userIds: number[];
};

//Noti
export enum EnumNotificationStatus {
  Wait = 0,
  Seen = 1,
  Read = 2,
}

export enum EnumNotiTemplateKey {
  REPORT_SIM_ERROR = 'REPORT_SIM_ERROR',
  STOP_SERVICE = 'STOP_SERVICE',
  NOTE = 'NOTE',
  CREATE_PRODUCT_ORDER = 'CREATE_PRODUCT_ORDER',
  CREATE_SHIP_ORDER = 'CREATE_SHIP_ORDER',
  UPDATE_PRODUCT_ORDER = 'UPDATE_PRODUCT_ORDER',
  UPDATE_SHIP_ORDER = 'UPDATE_SHIP_ORDER',
  CREATE_ORDER_REQUIREMENT = 'CREATE_ORDER_REQUIREMENT',
  CHAT = 'CHAT',
}

export type FirebaseTokenType = {
  firebaseTokenId: number;
  userName: string;
  deviceId: string;
  firebaseToken: string;
};

export type NotificationType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  notificationId?: number;
  notificationTitle?: string;
  notificationValue?: string;
  receiverId?: number;
  notificationTemplateId?: number;
  notificationTemplateKey?: EnumNotiTemplateKey;
  status?: EnumNotificationStatus;
  fullName?: string;
  avataUrl?: string;
  notificationData?: string;
};

export type MyNotificationType = {
  data: PageData<NotificationType>;
  badge: number;
};

export type ParameterEnumType = {
  parameterTitle?: string;
  parameterKey?: string;
  dataType?: EnumDataType;
};

export type ParameterType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  parameterId?: number;
  parameterTitle?: string;
  parameterKey?: string;
  parameterValue?: string;
  effectiveDate?: string;
};

//Account balance
export type AccountBalanceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  accountHistoryId?: number;
  userId?: number;
  accountActionType?: number;
  moneyAmount?: number;
  note?: string;
  accountActionTitle?: string;
  coefficient?: number;
  data?: string;
};

export type AccountBalanceMoneyType = {
  userId: number;
  moneyAmount: number;
  note?: string;
};

export type AccountBalanceListType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  userId?: number;
  moneyAmount?: number;
  user?: ProfileInfo;
};

//PurchaseAccount
export type PurchaseAccountType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  purchaseAccountId?: number;
  email?: string;
  purchasePage?: string;
  accountName?: string;
  accountPassword?: string;
  description?: string;
  note?: string;
};

//Store
export type StoreType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  storeId?: number;
  storeCode?: string;
  storeTitle?: string;
  longitude?: number;
  latitude?: number;
};

export type SpecialShipType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  specialShipId?: number;
  specialShipName: string;
  unit: string;
}

export type SpecialSurchargeType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  specialSurchargeId?: number;
  specialShipId: number;
  specialSurcharge: number;
  minQuantity: number;
}

//Address
export type ShipInfoType = {
  pref: string;
  city: string;
  town: string;

  distance: number;
  shipFee: number;
};

//Excel
export enum EnumDuplicateAction {
  Ignore = 1,
  Update = 2,
  Error = 3,
}

export type ImportExcelDataType = {
  sheetName: string;
  fromRowNum: number;
  toRowNum: number;
  duplicateAction: EnumDuplicateAction;
};

export type RoomUserType = {
  roomId: string;
  userId: number;
  fullName: string;
  avatarUrl: string;
  status: number;
};

export interface MessageData {
  value: string;
  type: number;
}

export interface MessageInfo {
  type: number;
  data: MessageData;
  from: number;
}

export interface ExtendDataModel {
  functionId?: number;
  objectId?: number;
  url?: string;
  name: string;
  description?: string;
  message?: string;
}

export enum EnumChatStatus {
  Waiting = 0,
  Sent = 1,
}

export type RoomType = {
  roomId: string;
  roomName: string;
  roomType: number;
  roomUsers: RoomUserType[];
  isCollapse?: boolean;
  extendData?: ExtendDataModel;
};

export type MessageType = {
  messageId: number;
  roomId: string;
  userId: number;
  messageValue: string;
  messageType: number;
};


//Sim store
export type SimStoreType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simStoreId?: number;
  simStoreTitle?: string;
  simStoreAddress?: string;
};

//SIM ORDER
export enum EnumSimOrderProgressStatus {
  Waiting = 1,
  Sent = 2,
  Error = 3,
}

export enum EnumSimOrderProgressStatusTitle {
  Waiting = 'Chờ gửi',
  Sent = 'Đã gửi',
  Error = 'Lỗi gửi',
}

export type SimOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  fullname: string;
  phoneNumber: string;
  zipCode: string;
  address1?: string;
  address2: string;
  address3?: string;
  address4?: string;
  note?: string;
  progressStatus?: EnumSimOrderProgressStatus;
  simOrderDetails?: SimOrderDetailsType[];
  displayOrder?: number;
  simOrderId?: number;
  userId?: number;
  userName?: string;
  image?: string;
  thumbImage?: string;
  isCheck?: boolean;
  trackingCode?: string;
  simStoreId?: number;
  simStore?: SimStoreType;
};

export type SimOrderDetailsType = {
  simOrderDetailId?: number;
  productId: number;
  quantity?: number;
  product?: ProductType;
  simPhoneNumber?: string;
  simImei?: string;
  isCreatedSim?: boolean;
  price?: number;
  deposit?: number;
  monthlySubscriptionFee?: number;
};

//SIM
export enum EnumSimStatus {
  Active = 1,
  Deactive = 2,
  Error = 3,
  StopService = 4,
}

export enum EnumSimStatusTitle {
  Active = 'Đang hoạt động',
  Deactive = 'Tạm dừng',
  Error = 'Lỗi sim',
  StopService = 'Kết thúc HĐ',
}

export type SimType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simId?: number;
  userId?: number;
  simStoreId?: number;
  userName?: string;
  customerName?: string;
  simPhoneNumber?: string;
  simImei?: string;
  status?: EnumSimStatus;
  productId?: number;
  price?: number;
  monthlySubscriptionFee?: number;
  effectiveDate?: string;
  product?: ProductType;
  simStore?: SimStoreType;
  deposit?: number;
};

//Sim price
export type SimPriceDebitType = {
  totalMoney?: number;
  paidMoney?: number;
  remainUnpaidMoney?: number;
  simOrderDetails?: SimOrderDetailsType[];
  sendEmail?: boolean;
  paidOtherMoney?: number;
  totalOtherMoney?: number;
  remainUnpaidOtherMoney?: number;
};

export type SimPriceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simPriceId?: number;
  customerTypeId?: number;
  productId?: number;
  price?: number;
  monthlySubscriptionFee?: number;
  deposit?: number;
  effectiveDate?: string;
  product?: ProductType;
  customerType?: CustomerType;
};

//Sim news
export type SimNewsType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simNewId?: number;
  simNewAvatar?: string;
  simNewTitle?: string;
  simNewValue?: string;
  description?: string;
  commentLength?: number;
};

export type SimNewCommentType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  simCommentId?: number;
  simNewId?: number;
  parentId?: number;
  simCommentValue?: string;
  commentLength?: number;
  avataUrl?: string;
  fullName?: string;
};

export type ProductOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  productOrderId?: number;
  userId?: number;
  userName?: string;
  isPaid?: boolean;
  productUrl?: string;
  product?: string;
  productPrice?: number;
  orderFee?: number;
  orderPercent?: number;
  description?: string;
  note?: string;
  avatar?: string;
  progressStatus?: EnumProductOrderProgressStatus;
  japanTrackingCode?: string;
  shipOrderId?: number;
  transportType?: number;
  paymentCode?: string;
  discount?: number;
  exchangeRate?: number;
  receiverAddress?: string;
  receiverPhoneNumber?: string;
  receiverName?: string;
  transportFee?: number;
  accountId?: number;
  paymentFee?: number;
};


export enum EnumProductOrderProgressStatus {
  Waiting = 1,
  Bought = 2,
  JapanStore = 3,
}

export enum EnumProductOrderProgressStatusTitle {
  Waiting = 'Chờ mua',
  Bought = 'Đã mua',
  JapanStore = 'Đã về kho Nhật Bản',
}

export const ProductOrderStatus = [
  {
    value: EnumProductOrderProgressStatus.Waiting,
    title: EnumProductOrderProgressStatusTitle.Waiting,
    css: { color: '#3a3131' },
  },
  {
    value: EnumProductOrderProgressStatus.Bought,
    title: EnumProductOrderProgressStatusTitle.Bought,
    css: { color: 'blue' },
  },
  {
    value: EnumProductOrderProgressStatus.JapanStore,
    title: EnumProductOrderProgressStatusTitle.JapanStore,
    css: { color: 'green' },
  },
];

export type ShipOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  shipOrderId?: number;
  userId?: number;
  userName?: string;
  receiverOrderId?: number;
  receiverOrderName?: string;
  price?: number;
  japanTrackingCode?: string;
  vietnamTrackingCode?: string;
  trackingCode?: string;
  weight?: number;
  height?: number;
  length?: number;
  width?: number;
  description?: string;
  customerNote?: string;
  transportFee?: number;
  note?: string;
  progressStatus?: EnumShipOrderProgressStatus;
  productOrderIds?: number[];
  billCode?: string;
  senderName?: string;
  senderAddress?: string;
  receiverAddress?: string;
  isPaid?: boolean;
  specialProduct?: string;
  nomalProduct?: string;
  receiverPhoneNumber?: string;
  receiverName?: string;
  images?: ImageType[];
  transportType?: number;
  shipPackageId?: number;
  paymentType?: number;
  conversionCode?: string;
  paymentCode?: string;
  discount?: number;
  cod?: boolean;
  subNumber?: number;
  subInfo?: string;
  vietnamShipper?: number;
  vietnamShipfee?: number;
};

export enum EnumVNShipper {
  NinjaVan = 0,
  BEST = 1,
}

export enum EnumVNShipperTitle {
  NinjaVan = "Ninja Van",
  BEST = "BEST",
}

export const VNShippers = [
  {
    value: EnumVNShipper.NinjaVan,
    title: EnumVNShipperTitle.NinjaVan,
  },
  {
    value: EnumVNShipper.BEST,
    title: EnumVNShipperTitle.BEST,
  }
];


export enum EnumShipOrderProgressStatus {
  Waiting = 0,
  JapanStore = 1,
  Flying = 2,
  ThroughCustoms = 3,
  VietNamStore = 4,
  Contact = 5,
  Finish = 6,
}


export enum EnumShipOrderProgressStatusTitle {
  Waiting = 'Đang chờ',
  JapanStore = 'Đến kho Nhật Bản',
  Flying = 'Đang bay',
  ThroughCustoms = 'Chờ thông quan',
  VietNamStore = 'Đến kho Việt Nam',
  Contact = 'Liên hệ khách hàng',
  Finish = 'Hoàn thành',
}

export enum EnumExportBillStatus {
  Waiting = 0,
  Exported = 1,
}

export const ExportBillStatus = [
  {
    value: EnumExportBillStatus.Waiting,
    title: "Chờ xuất",
    css: { color: '#3a3131' }
  },
  {
    value: EnumExportBillStatus.Exported,
    title: "Đã xuất",
    css: { color: 'blue' }
  }
]

export const ShipOrderProgressStatusList = [
  {
    value: EnumShipOrderProgressStatus.Waiting,
    title: EnumShipOrderProgressStatusTitle.Waiting,
    css: { color: '#3a3131' },
  },
  {
    value: EnumShipOrderProgressStatus.JapanStore,
    title: EnumShipOrderProgressStatusTitle.JapanStore,
    css: { color: 'blue' },
  },
  {
    value: EnumShipOrderProgressStatus.Flying,
    title: EnumShipOrderProgressStatusTitle.Flying,
    css: { color: 'violet' },
  },
  {
    value: EnumShipOrderProgressStatus.ThroughCustoms,
    title: EnumShipOrderProgressStatusTitle.ThroughCustoms,
    css: { color: 'yellow' },
  },
  {
    value: EnumShipOrderProgressStatus.VietNamStore,
    title: EnumShipOrderProgressStatusTitle.VietNamStore,
    css: { color: 'darkorange' },
  },
  {
    value: EnumShipOrderProgressStatus.Contact,
    title: EnumShipOrderProgressStatusTitle.Contact,
    css: { color: 'red' },
  },
  {
    value: EnumShipOrderProgressStatus.Finish,
    title: EnumShipOrderProgressStatusTitle.Finish,
    css: { color: 'green' },
  },
];

export enum EnumTransportType {
  HCMAir = 0,
  HNAir = 1,
  HCMSea = 2,
  HNSea = 3,
}

export enum EnumTransportTypeTitle {
  HCMAir = "Đường bay HCM",
  HNAir = "Đường bay Hà Nội",
  HCMSea = "Đường biển HCM",
  HNSea = "Đường biển Hà Nội",
}



export const TransportType = [
  {
    value: EnumTransportType.HCMAir,
    title: EnumTransportTypeTitle.HCMAir,
    css: { color: 'blue' },
  },
  {
    value: EnumTransportType.HNAir,
    title: EnumTransportTypeTitle.HNAir,
    css: { color: '#3a3131' },
  },
  {
    value: EnumTransportType.HCMSea,
    title: EnumTransportTypeTitle.HCMSea,
    css: { color: 'yellow' },
  },
  {
    value: EnumTransportType.HNSea,
    title: EnumTransportTypeTitle.HNSea,
    css: { color: 'yellow' },
  }
];


export enum EnumPaymentType {
  NormalWeight = 0,
  NormalVolume = 1,
  ConvertWeight = 2,
  OverWeight = 3,
}

export enum EnumPaymentTypeTitle {
  NormalWeight = "Khối lượng thực",
  NormalVolume = "Thể tích thực",
  ConvertWeight = "Khối lượng quy đổi",
  OverWeight = "Khối lượng quá khổ",
}



export const PaymentTypeList = [
  {
    value: EnumPaymentType.NormalWeight,
    title: EnumPaymentTypeTitle.NormalWeight,
    css: { color: 'blue' },
  },
  {
    value: EnumPaymentType.NormalVolume,
    title: EnumPaymentTypeTitle.NormalVolume,
    css: { color: '#3a3131' },
  },
  {
    value: EnumPaymentType.ConvertWeight,
    title: EnumPaymentTypeTitle.ConvertWeight,
    css: { color: 'yellow' },
  },
  {
    value: EnumPaymentType.OverWeight,
    title: EnumPaymentTypeTitle.OverWeight,
    css: { color: 'yellow' },
  }
];


export type MonthlySubscriptionFeeType = {
  totalMoney: number;
  sims: SimType[];
  sendEmail: boolean;
};

export type ReceiverOrderType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  receiverOrderId?: number;
  receiverOrderCode?: string;
  title?: string;
  address?: string;
  airPrice?: number;
  seaPrice?: number;
  volumeAirPrice?: number;
  volumeSeaPrice?: number;
  overAirPrice?: number;
  overSeaPrice?: number;
  userId?: number;
  pic?: number;
  userName?: string;
  picName?: string;
};

export type ShipPackageType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  shipPackageId?: number;
  shipPackageCode?: string;
  weight?: number;
  height?: number;
  length?: number;
  width?: number;
  note?: string;
  progressStatus?: EnumShipOrderProgressStatus;
  specialProduct?: string;
  nomalProduct?: string;
  receiverPhoneNumber?: string;
  receiverName?: string;
  receiverAddress?: string;
  images?: ImageType[];
  shipOrders?: ShipOrderType[];
  shipInvoiceId?: number;
};

export type ShipInvoiceType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  shipInvoiceId?: number;
  shipInvoiceCode?: string;
  expectedDate?: string;
  note?: string;
  progressStatus?: EnumShipOrderProgressStatus;
  shipPackages?: ShipPackageType[];
};

export type SalesCollatoraborType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  salesCollatoraborId?: number;
  userId?: number;
  userName?: string;
  airPrice?: number;
  seaPrice?: number;
  volumeAirPrice?: number;
  volumeSeaPrice?: number;
  overAirPrice?: number;
  overSeaPrice?: number;
  receiverOrderName?: string;
  receiverOrderId?: number;
  address?: string;
};


export type PaymentType = {
  paymentId?: number;
  object?: string;
  bill?: number[];
  money?: number;
  status?: number;
  expirationDate?: string;
  paymentCode?: string;
  customerName?: string;
  paymentContent?: string;
  customerEmail?: string;
  debt?: number;
  objectType?: number;
};

export type StockInspectionType = {
  stockInspectionId?: number;
  status?: number;
  stockInspectionContent?: string;
  createdAt?: string;
};


export const StockInspectionStatus = [
  {
    value: EnumStockInspectionStatus.Inspecting,
    title: 'Đang kiểm kê',
    css: { color: 'blue' },
  },
  {
    value: EnumStockInspectionStatus.Inspected,
    title: 'Đã kiểm kê',
    css: { color: 'yellow' },
  },
  {
    value: EnumStockInspectionStatus.Done,
    title: 'Hoàn thành',
    css: { color: 'green' },
  },
];


export type CustomerDebtType = {
  customerEmail?: string;
  money?: number;
}


export type PackageInfoType = {
  shipPackageCode?: string;
  shipInvoiceCode?: number;
}


export type ReportType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  reportId?: number;
  reportKey?: string;
  reportTitle?: string;
  reportQuery?: string;
  reportSimple?: string;
  reportTable?: string;
  reportInput?: string;
  searchFlg?: boolean;
  pagingFlg?: boolean;
  reportChar?: string;
};

export type DisbursementType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  disbursementId?: number;
  money?: number;
  disbursementContent?: string;
  bill?: number[];
};

export type ReportInputDataType =  {
  value: any;
  title: string;
}


export type ReportInputType =  {
  data?: ReportInputDataType[];
  query?: string;
  inputName?: string[];
  dataType?: EnumDataType;
  title: string;
  timestamp?: boolean;
  isSearch?: boolean;
  isPaging?: boolean;
}

export type ReportHeaderType =  {
  code: string;
  title: string;
  dataType: EnumDataType;
  isOptions: boolean;
  options?: OptionType[];
  collumCss?: string;
  cellCss?: string;
  ratio?: number;
  convert?: string;
  isHidden?: boolean;
}

export type ShortLinkType =  {
  shortlink: any;
  href: string;
}

export type MenuType = {
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  displayOrder?: number;
  menuId?: number;
  icon?: string;
  title?: string;
  href?: string;
  parentId?: number;
};

export type ManifestType = {
  qr?: string;
  hawb?: string;
  pk?: number;
  weight?: number;
  vnd?: number;
  dest?: string;
  receiver?: string;
  descriptions?: string;
  address?: string;
  phoneNumber?: string;
  note?: string;
};