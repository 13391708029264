/* eslint-disable react-hooks/exhaustive-deps */
import './OrderRequirement.css';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import {
  EnumAction,
  EnumDataType,
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  EnumReceiveTime,
  EnumReceiveTimeTitle,
  EventButton,
  ExportBillType,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
} from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Table from 'src/components/Table/Table';
import {
  useDeleteOrderRequirement,
  useDeleteOrderRequirementForAdmin,
  useDownLoadOrderRequirementExcel,
  useExportSaleBill,
  useGetMyOrderRequirement,
  useGetOrderRequirement,
  usePrintJPBill,
  usePrintOrderRequirement,
  usePutUpdateOrderRequirementProgressStatus,
} from 'src/api/orderRequirementApi';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_WEB_URL } from 'src/constants';
import UpdateStatus from '../../components/UpdateStatus/UpdateStatus';
import Printed from 'src/components/Printed/Printed';
import useModal from 'src/hooks/useModal';
import useSlideBar from 'src/hooks/useSlideBar';
import OrderRequirementDetail from './OrderRequirementDetail/OrderRequirementDetail';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { ADMIN, SALE } from 'src/common/constant/Constant';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useDebounce } from 'use-debounce';
import { number2jpmoney } from 'src/utils/numberUtils';
import ExportBillPreview from './ExportBillPreview/ExportBillPreview';
import PaymentExport from 'src/components/PaymentExport/PaymentExport';

const OrderRequirement: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  const params = useParams<{ role: string }>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const lstStatus = [
    {
      value: EnumOrderRequirementProgressStatus.Waiting,
      title: EnumOrderRequirementProgressStatusTitle.Waiting,
      css: { color: '#3a3131' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Sent,
      title: EnumOrderRequirementProgressStatusTitle.Sent,
      css: { color: 'blue' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Received,
      title: EnumOrderRequirementProgressStatusTitle.Received,
      css: { color: 'green' },
    },
    {
      value: EnumOrderRequirementProgressStatus.Error,
      title: EnumOrderRequirementProgressStatusTitle.Error,
      css: { color: 'red' },
    },
  ];
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  const [progressStatus, setProgressStatus] = useState<EnumOrderRequirementProgressStatus>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const STATUS_FIELD_FILTER = 'value';
  const RECEIVER_AT_FILTER = "RECEIVER_AT_FILTER";
  const STATUS_FILTER = 'STATUS_FILTER';
  const filters = [
    {
      title: 'Ngày nhận hàng',
      filterKey: RECEIVER_AT_FILTER,
      timestamp: true
    },
    {
      data: lstStatus,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
  ];

  const receiveTimes = [
    {
      value: EnumReceiveTime.Any,
      title: EnumReceiveTimeTitle.Any
    },
    {
      value: EnumReceiveTime.Morning,
      title: EnumReceiveTimeTitle.Morning
    },
    {
      value: EnumReceiveTime.Twelve_Fifteen,
      title: EnumReceiveTimeTitle.Twelve_Fifteen
    },
    {
      value: EnumReceiveTime.Fifteen_Eightteen,
      title: EnumReceiveTimeTitle.Fifteen_Eightteen
    },
    {
      value: EnumReceiveTime.Eightteen_TwentyOne,
      title: EnumReceiveTimeTitle.Eightteen_TwentyOne
    }
  ]

  const display: ItemDisplayType<OrderRequirementType> = {
    header: [
      {
        code: 'receiverFullname',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        options: lstStatus,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      data: 'orderRequirementDetails',
      avatar: 'product.thumbAvatar',
      info: [
        [
          {
            code: 'product.productName',
            dataType: EnumDataType.Text,
            isOptions: false,
          },
          {
            code: 'product.productCode',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              justifyContent: 'end',
              flex: 'auto'
            }
          },
          {
            code: 'isPad',
            dataType: EnumDataType.Boolean,
            isOptions: false,
            title: 'Thanh toán',
          },
        ],
        [
          {
            code: 'quantity',
            dataType: EnumDataType.BigInt,
            isOptions: false,
            icon: 'shopping_cart',
            cellCss: {
              margin: '0 5px'
            }
          },
          {
            code: 'price',
            dataType: EnumDataType.JPY,
            isOptions: false,
            icon: 'payments',
            cellCss: {
              margin: '0 5px'
            }
          },
          {
            code: 'extraAmount',
            dataType: EnumDataType.JPY,
            isOptions: false,
            icon: 'add_circle',
            cellCss: {
              margin: '0 5px'
            }
          },
        ]
      ]
    },
    bottom: [
      [
        {
          code: 'receiverAddress1',
          dataType: EnumDataType.Text,
          isOptions: false,
          icon: 'place',
          cellCss: {
            marginLeft: '5px'
          }
        },
        {
          code: 'receiverAddress2',
          dataType: EnumDataType.Text,
          isOptions: false,
        },
        {
          code: 'receiverAddress3',
          dataType: EnumDataType.Text,
          isOptions: false,
        },
        {
          code: 'receiverAddress4',
          dataType: EnumDataType.Text,
          isOptions: false,
        },
      ],
      [
        {
          code: 'receiverDate1',
          dataType: EnumDataType.Text,
          isOptions: false,
          icon: 'local_shipping',
          cellCss: {
            marginLeft: '5px'
          }
        },
        {
          code: 'receiverTime1',
          dataType: EnumDataType.Text,
          isOptions: true,
          options: receiveTimes,
          cellCss: {
            background: 'gray',
            borderRadius: '5px',
            color: 'white'
          }
        },
      ],
      [
        {
          code: 'receiverDate2',
          dataType: EnumDataType.Text,
          isOptions: false,
          icon: 'local_shipping',
          cellCss: {
            marginLeft: '5px'
          }
        },
        {
          code: 'receiverTime2',
          dataType: EnumDataType.Text,
          isOptions: true,
          options: receiveTimes,
          cellCss: {
            background: 'gray',
            borderRadius: '5px',
            color: 'white'
          }
        },
      ]
    ],
    actions: (item: OrderRequirementType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.orderRequirementId),
        buttonClass: 'info',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Edit,
        action: () => onDelete(item.orderRequirementId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  const tableDisplay = {
    header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo',
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã t.toán',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên người mua',
      },
      {
        code: 'referralName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người giới thiệu',
      },
      {
        code: 'receiverFullname',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên người nhận',
      }, 
      {
        code: 'receiverFacebook',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Facebook',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái',
        options: lstStatus,
      },
      {
        code: 'isPaid',
        dataType: EnumDataType.Boolean,
        isOptions: false,
        title: 'Th.toán',
      },
      {
        code: 'commission',
        dataType: EnumDataType.Boolean,
        isOptions: false,
        title: 'Trả hoa hồng',
      },
      {
        code: 'shipFee',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Phí ship',
      },
      {
        code: 'discount',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Giảm giá',
      },
      {
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Tổng tiền',
        convert: (data: OrderRequirementType) => {
          return <div className='text-right'>{number2jpmoney(data.orderRequirementDetails.reduce((total, item) => total+=(item.price * item.quantity), 0) + data.shipFee - data.discount)}</div>
        }
      },
      {
        code: 'receiverDate1',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày nhận hàng 1',
      },
      {
        code: 'receiverTime1',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Thời gian nhận hàng 1',
        options: receiveTimes
      },
      {
        code: 'receiverDate2',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày nhận hàng 2',

      },
      {
        code: 'receiverTime2',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Thời nhận hàng 2',
        options: receiveTimes
      },
      {
        code: 'receiverZipCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã bưu điện',
      },
      {
        code: 'receiverAddress1',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 1',
      },
      {
        code: 'receiverAddress2',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 2',
      },
      {
        code: 'receiverAddress3',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 3',
      },
      {
        code: 'receiverAddress4',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 4',
      },
      {
        code: 'orderRequirementNote',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
    ]
  };

  //State
  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);

  const [rawData, setRawData] = useState<OrderRequirementType[]>([]);
  const [orderRequirements, setOrderRequirements] = useState<OrderRequirementType[]>([]);
  const [checkList, setCheckList] = useState<OrderRequirementType[]>([]);

  //Function
  const addPopup = useAddPopup();
  const getOrderRequirement = useGetOrderRequirement();
  const getMyOrderRequirement = useGetMyOrderRequirement();
  const deleteOrderRequirementForAdmin= useDeleteOrderRequirementForAdmin();
  const deleteOrderRequirement = useDeleteOrderRequirement();
  const downLoadOrderRequirementExcel = useDownLoadOrderRequirementExcel();
  const printOrderRequirement = usePrintOrderRequirement();
  const printJPBill = usePrintJPBill();
  const putUpdateOrderRequirementProgressStatus = usePutUpdateOrderRequirementProgressStatus();
  const exportSaleBill = useExportSaleBill();
  const detailModal = useModal(OrderRequirementDetail);

  const updateStatusModal = useModal(UpdateStatus);
  const printedModal = useModal(Printed);
  const slideBar = useSlideBar(OrderRequirementDetail);
  const confirmModal = useModal(ConfirmModal);
  const exportModal = useModal(ExportBillPreview);
  const exportBillModal = useModal(PaymentExport);
  
  useEffect(() => {
    setIsAdmin(params.role == ADMIN);
  }, [params.role]);

  const onClickCheckbox = (orderRequirement: OrderRequirementType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != orderRequirement);
      setCheckList(nCheckList);
      setOrderRequirements(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.orderRequirementId == i.orderRequirementId),
        ),
      );
    } else {
      setCheckList([...checkList, orderRequirement]);
      setOrderRequirements(orderRequirements.filter((o) => o != orderRequirement));
    }
  };

  const onClick = (orderRequirement: OrderRequirementType) => {
    slideBar.handlePresent({
      orderRequirementId: orderRequirement.orderRequirementId,
      isAdmin: isAdmin,
      postProcess: (data: OrderRequirementType) => {
        setReloadFlag(!reloadFlag);
      },
    });
  };
  //End of function

  //Menucontext
  const onView = (orderRequirementId: number) => {
    const url = `${BASE_WEB_URL}/order-requirement/${isAdmin?ADMIN:SALE}/${orderRequirementId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (orderRequirementId: number) => {
    const url = `${BASE_WEB_URL}/order-requirement/${isAdmin?ADMIN:SALE}/${orderRequirementId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (orderRequirementId: number) => {
    const onConfirm = () => {
      const api = isAdmin? deleteOrderRequirementForAdmin : deleteOrderRequirement;
      api(orderRequirementId)
        .then(() => {
          const idx = checkList.findIndex(i => i.orderRequirementId == orderRequirementId);
          if(idx >= 0) {
            checkList.splice(idx, 1);
            setCheckList([...checkList]);
          }
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn hàng này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN HÀNG',
    );
  };

  const menuContext = (item: OrderRequirementType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.orderRequirementId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.orderRequirementId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.orderRequirementId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const validateCheckList = () => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn hàng',
        },
      });
      return false;
    } else {
      return true;
    }
  };

  //Toolbar

  const onExportBill = () => {
    if (validateCheckList()) {
      exportModal.handlePresent({
        orders: checkList,
        postProcess: (data: ExportBillType) => {
          window.open(`${BASE_WEB_URL}/export-bill/${data.exportBillId}/${EnumViewType.View}`);
          exportModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      }, "TẠO PHIẾU XUẤT HÀNG");
    }
  }

  const onUpdateOrderRequirementProgressStatus = (e: MouseEvent) => {
    if (validateCheckList()) {
      updateStatusModal.handlePresent(
        {
          listStatus: lstStatus,
          title: 'Trạng thái đơn hàng',
          postProcess: (status: EnumOrderRequirementProgressStatus) => {
            const ids = checkList.map((i) => i.orderRequirementId);
            updateStatusModal.handleDismiss();
            putUpdateOrderRequirementProgressStatus(ids, status)
              .then((data) => {
                addPopup({
                  txn: {
                    success: true,
                    summary: 'Cập nhật trạng thái thành công',
                  },
                });
                setReloadFlag(!reloadFlag);
              })
              .catch((error) => {
                addPopup({
                  error: {
                    title: 'Đã có lỗi xảy ra',
                    message: error.errorMessage,
                  },
                });
              });
          },
        },
        'CẬP NHẬT TRẠNG THÁI',
      );
    }
  };

  const onExport = (e: MouseEvent) => {
    if (validateCheckList()) {
      const orderRequirementIds = checkList.map((o) => o.orderRequirementId);
      downLoadOrderRequirementExcel(orderRequirementIds)
        .then((data) => {
          const url = window.URL.createObjectURL(data);
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute('download', 'DSDonHang.xlsx');
          tempLink.click();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const onPrint = (e: MouseEvent) => {
    if (validateCheckList()) {
      const orderRequirementIds = checkList.map((o) => o.orderRequirementId);
      printOrderRequirement(orderRequirementIds)
        .then((res) => {
          printedModal.handlePresent(
            {
              values: res,
            },
            'IN ĐƠN HÀNG',
          );
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const onPrintJPBill = (e: MouseEvent) => {
    if (validateCheckList()) {
      const orderRequirementIds = checkList.map((o) => o.orderRequirementId);
      printJPBill(orderRequirementIds)
        .then((res) => {
          printedModal.handlePresent(
            {
              values: res,
            },
            'IN PHIẾU THU TIỀN',
          );
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  };

  const onAddOrderRequirement = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        isAdmin: isAdmin,
        postProcess: (data: OrderRequirementType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.orderRequirementId);
        },
      },
      'THÊM MỚI'
    );
  };

  
  const openExport = useCallback((checkListIds: number[]) => {
    exportBillModal.handlePresent(
      {
        checkListIds: checkListIds,
        exportApi: exportSaleBill,
        postProcess: () => {
          exportBillModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'XUẤT HÓA ĐƠN',
    );
  }, [reloadFlag])

  const onPaymentBill = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn vận chuyển',
        },
      });
    } else {
      const checkListIds = checkList.map(i => i.orderRequirementId);
      let message = "";
      if(checkList.some(i => i.paymentCode?.length > 0)) message += 'Tồn tại đơn đã có mã thanh toán. ';
      if(message.length > 0) {
        message += 'Bạn vẫn muốn thực hiện tạo hóa đơn thanh toán?';
        const listButton: EventButton[] = [
          {
            name: 'Xác nhận',
            icon: 'check',
            actionType: EnumAction.Confirm,
            action: () => openExport(checkListIds),
            buttonClass: 'info',
            align: 'center',
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'info',
            align: 'center',
          },
        ];
        confirmModal.handlePresent(
          {
            question: message,
            listActionButton: listButton,
            postProcess: confirmModal.handleDismiss,
          },
          'TẠO HÓA ĐƠN ĐỒ CŨ',
        );
      } else {
        openExport(checkListIds);
      }
    }
  }, [checkList, openExport, reloadFlag]);

  const listButton: EventButton[] = isAdmin? [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info order-tool-btn',
      action: onAddOrderRequirement,
      align: 'center',
    },
    {
      name: 'Cập nhật trạng thái',
      icon: 'sync_alt',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 order-tool-btn',
      action: onUpdateOrderRequirementProgressStatus,
      align: 'center',
    },

    {
      name: 'Export đơn hàng',
      icon: 'file_download',
      actionType: EnumAction.View,
      buttonClass: 'info order-tool-btn',
      action: onExport,
      align: 'center',
    },
    {
      name: 'Tạo phiếu xuất kho',
      icon: 'ios_share',
      actionType: EnumAction.View,
      buttonClass: 'info100 order-tool-btn',
      action: onExportBill,
      align: 'center',
    },
    {
      name: 'Xuất hóa đơn',
      icon: 'payments',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onPaymentBill,
      align: 'center',
    },
    {
      name: 'In đơn hàng',
      icon: 'print',
      actionType: EnumAction.View,
      buttonClass: 'info order-tool-btn',
      action: onPrint,
      align: 'center',
    },
    {
      name: 'In phiếu thu tiền',
      icon: 'print',
      actionType: EnumAction.View,
      buttonClass: 'info200 order-tool-btn',
      action: onPrintJPBill,
      align: 'center',
    }
  ] : [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info order-tool-btn',
      action: onAddOrderRequirement,
      align: 'center',
    }
  ];
  //End of toolbar

  useEffect(() => {
    const api = isAdmin? getOrderRequirement((keywordDebound ?? '') as string, page, size, progressStatus, minDate, maxDate) : getMyOrderRequirement((keywordDebound ?? '')as string, page, size, progressStatus, minDate, maxDate) ;
    api.then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setOrderRequirements(
          data.items.filter(
            (i) => !checkList.some((c) => c.orderRequirementId == i.orderRequirementId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [isAdmin, addPopup, getOrderRequirement, keywordDebound, page, progressStatus, reloadFlag, minDate, maxDate, size]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  const onFilter = (res: { [filterKey: string]: any }) => {
    if (res[RECEIVER_AT_FILTER] != undefined) {
      const value = res[RECEIVER_AT_FILTER] as string[];
      setMinDate(value.length > 0?value[0] : undefined);
      setMaxDate(value.length > 1?value[1] : undefined);
    } else {
      setMinDate(undefined);
      setMaxDate(undefined);
    }
    
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
  };

  const onClickCheckAll = () => {
    if(orderRequirements.length > 0) {
      setCheckList([...checkList, ...orderRequirements]);
      setOrderRequirements([]);
    } else {
      setCheckList([]);
      setOrderRequirements([...rawData]);
    }
  };

  return (
    <>
      <ToolBar
        toolBarKey='JVSADMIN_ORDERREQUIREMENTS'
        toolbarName={isAdmin? 'DANH SÁCH ĐẶT HÀNG' : 'ĐƠN HÀNG CỦA TÔI'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />

      {window.innerWidth > 600? <Table
        display={tableDisplay}
        isInput={false}
        checkedData={checkList}
        data={orderRequirements}
        menuContext={menuContext}
        allowCheckbox={isAdmin}
        onDoubleClick={(item) => onView(item.orderRequirementId)}
        onClickCheckbox={onClickCheckbox}
        onClick={onClick}
        onClickCheckAll={onClickCheckAll}
        isNo={true}
      /> : <ListView
        display={display}
        isInput={false}
        checkedData={checkList}
        onClickCheckAll={onClickCheckAll}
        data={orderRequirements}
        allowCheckbox={isAdmin}
        onDoubleClick={(item) => onView(item.orderRequirementId)}
        onClickCheckbox={onClickCheckbox}
        isNo={true}
      />}
    </>
  );
};

export default OrderRequirement;
