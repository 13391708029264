import React, { useState, useEffect } from 'react';
import { SimStoreType } from 'src/api/models';
import { useGetSimStoreById, usePostSimStore, usePutSimStore } from 'src/api/simStoreApi';
import { useAddPopup, useReloadTable, useRemovePopup } from 'src/state/application/hooks';
import './SimStoreDetail.css';

export enum EnumConfigSimStoreDetail {
  add = 1,
  edit = 2,
  view = 3,
}

interface ISimPriceDetail {
  config: EnumConfigSimStoreDetail;
  simStoreId?: number;
}

const SimStoreDetail: React.FC<ISimPriceDetail> = (props) => {
  //Value
  const config = props.config;
  const simStoreId = props.simStoreId;

  //State
  const [simStoreTitle, setSimStoreTitle] = useState(null);
  const [simStoreTitleError, setSimStoreTitleError] = useState(null);

  const [simStoreAddress, setSimStoreAddress] = useState(null);
  const [simStoreAddressError, setSimStoreAddressError] = useState(null);

  const [displayOrder, setDisplayOrder] = useState(null);

  const [focusInput, setFocusInput] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  //End of state

  //Function
  const reloadTable = useReloadTable();
  const removePopup = useRemovePopup();
  const addPopup = useAddPopup();
  const postSimStore = usePostSimStore();
  const putSimStore = usePutSimStore();
  const getSimStoreById = useGetSimStoreById();

  const validateSimStoreTitle = () => {
    let isContinue = true;

    if (!simStoreTitle || simStoreTitle == '') {
      isContinue = false;
      setSimStoreTitleError('Chưa nhập tiêu đề');
    } else setSimStoreTitleError(null);

    return isContinue;
  };

  const validateSimStoreAddress = () => {
    let isContinue = true;

    if (!simStoreAddress || simStoreAddress == '') {
      isContinue = false;
      setSimStoreAddressError('Chưa nhập địa chỉ');
    } else setSimStoreAddressError(null);

    return isContinue;
  };

  const onPostSimStore = () => {
    const isSimStoreTitle = validateSimStoreTitle();
    const isSimStoreAddress = validateSimStoreAddress();
    if (isSimStoreTitle && isSimStoreAddress) {
      const _temp: SimStoreType = {
        displayOrder: displayOrder,
        simStoreTitle: simStoreTitle,
        simStoreAddress: simStoreAddress,
      };
      postSimStore(_temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Thêm nguồn sim thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  const onPutSimStore = () => {
    const isSimStoreTitle = validateSimStoreTitle();
    const isSimStoreAddress = validateSimStoreAddress();
    if (isSimStoreTitle && isSimStoreAddress && Number(simStoreId)) {
      const _temp: SimStoreType = {
        displayOrder: displayOrder,
        simStoreTitle: simStoreTitle,
        simStoreAddress: simStoreAddress,
        simStoreId: simStoreId,
      };
      putSimStore(_temp)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Sửa nguồn sim thành công',
            },
          });
          reloadTable();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
    }
  };

  const handlePressInput = (event: any) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);

    if (event.key.toLowerCase() === 'enter') {
      if (focusInput >= 1 && focusInput <= 2) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      }
    } else if (event.key.toLowerCase() === 'arrowdown') {
      if (focusInput >= 1 && focusInput <= 2) {
        form.elements[index + 1].focus();
        setFocusInput(focusInput + 1);
        event.preventDefault();
      }
    } else if (event.key.toLowerCase() === 'arrowup') {
      if (focusInput <= 3 && focusInput >= 2) {
        setFocusInput(focusInput - 1);
        form.elements[index - 1].focus();
        event.preventDefault();
      }
    }
  };

  //Component
  //1
  const simStoreTitleInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 1 ? 'focus-input' : ''} ${
            simStoreTitle ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Tiêu đề</div>
          <input
            type="text"
            value={simStoreTitle}
            onChange={(event) => {
              setSimStoreTitle(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(1);
            }}
            onBlur={() => {
              validateSimStoreTitle();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 1}
            onKeyDown={handlePressInput}
            disabled={isDisable}
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{simStoreTitleError}</div>
      </>
    );
  };

  //2
  const simStoreAddressInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 2 ? 'focus-input' : ''} ${
            simStoreAddress ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Địa chỉ</div>
          <input
            type="text"
            value={simStoreAddress}
            onChange={(event) => {
              setSimStoreAddress(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(2);
            }}
            onBlur={() => {
              validateSimStoreAddress();
              setFocusInput(null);
            }}
            autoFocus={focusInput == 2}
            onKeyDown={handlePressInput}
            disabled={isDisable}
            placeholder="Thông tin liên hệ"
          />
        </div>
        <div style={{ color: 'red', paddingLeft: 2 }}>{simStoreAddressError}</div>
      </>
    );
  };

  //4
  const displayOrderInput = () => {
    return (
      <>
        <div
          className={`sim-price-detail-input ${focusInput == 3 ? 'focus-input' : ''} ${
            displayOrder ? 'validate-input' : ''
          }`}
        >
          <div className="sim-price-detail-input-title">Thứ tự</div>
          <input
            type="text"
            value={displayOrder}
            onChange={(event) => {
              setDisplayOrder(event.target.value);
            }}
            onFocus={() => {
              setFocusInput(3);
            }}
            onBlur={() => {
              setFocusInput(null);
            }}
            autoFocus={focusInput == 3}
            onKeyDown={handlePressInput}
            placeholder="Nên thêm để quản lý tốt hơn"
          />
        </div>
      </>
    );
  };

  const btnComponent = () => {
    if (config == EnumConfigSimStoreDetail.add) {
      return (
        <div
          className="sim-price-detail-btn"
          onClick={() => {
            onPostSimStore();
          }}
        ></div>
      );
    } else if (config == EnumConfigSimStoreDetail.edit) {
      return (
        <div
          className="edit-order-btn"
          onClick={() => {
            onPutSimStore();
          }}
        ></div>
      );
    }
  };

  //End of component

  //useEffect
  useEffect(() => {
    if (config == EnumConfigSimStoreDetail.view) {
      setIsDisable(true);
    }
  }, [config]);

  useEffect(() => {
    if (simStoreId) {
      getSimStoreById(simStoreId)
        .then((data) => {
          setSimStoreTitle(data.simStoreTitle);
          setSimStoreAddress(data.simStoreAddress);
          setDisplayOrder(data.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getSimStoreById, simStoreId]);

  //Main
  return (
    <div className="sim-price-detail-container">
      <div className="sim-price-detail-form">
        <form className="sim-price-detail-from-component">
          {simStoreTitleInput()}
          {simStoreAddressInput()}
          {displayOrderInput()}
        </form>
      </div>
      <div>{btnComponent()}</div>
    </div>
  );
};

export default SimStoreDetail;
