import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { EnumShipOrderProgressStatus, ImageType, PackageInfoType, PageData } from './models';
import { ShipPackageType } from './models';

export const useGetShipPackage = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number, progressStatus: number, shipInvoiceId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package?keyword=${keyword??''}&page=${page}&size=${size}${progressStatus != undefined?`&progressStatus=${progressStatus}`:''}${shipInvoiceId != undefined?`&invoiceId=${shipInvoiceId}`:''}`,
        method: 'get',
      }) as Promise<PageData<ShipPackageType>>;
    },
    [fetch],
  );
};

export const useGetShipPackageById = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackageId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/${shipPackageId}`,
        method: 'get',
      }) as Promise<ShipPackageType>;
    },
    [fetch],
  );
};

export const useGetShipPackageByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/ship-package/code/${code}`,
        method: 'get',
      }) as Promise<ShipPackageType>;
    },
    [fetch],
  );
};

export const useGetShipPackageInfo = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackageIds: number[]) => {
      return fetch({
        url: `sccore/rest-api/ship-package/info`,
        method: 'post',
        data: shipPackageIds
      }) as Promise<{[shipPackageId: number]: PackageInfoType}>;
    },
    [fetch],
  );
};

export const useDeleteShipPackage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackageId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/${shipPackageId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostShipPackage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackage: ShipPackageType) => {
      return fetch({
        url: `sccore/rest-api/ship-package`,
        method: 'post',
        data: shipPackage,
      }) as Promise<ShipPackageType>;
    },
    [fetch],
  );
};

export const usePutShipPackage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackage: ShipPackageType) => {
      return fetch({
        url: `sccore/rest-api/ship-package`,
        method: 'put',
        data: shipPackage,
      }) as Promise<ShipPackageType>;
    },
    [fetch],
  );
};

export const usePostShipPackageImage = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (data: any, shipPackageId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/image/${shipPackageId}`,
        method: 'post',
        data: data,
      }) as Promise<ImageType>;
    },
    [fetch],
  );
};

export const useDeleteShipPackageImage = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackageId: number, fileId: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/${shipPackageId}/image/${fileId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePrintShipPackageV40 = () => {
  const fetch = useFetch();
  return useCallback(
    (prefix: string, start: number, page: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/print-v40?prefix=${prefix??''}&start=${start}&number=${page}`,
        method: 'get',
      }) as Promise<string[]>;
    },
    [fetch],
  );
};

export const usePrintShipPackageV5 = () => {
  const fetch = useFetch();
  return useCallback(
    (prefix: string, start: number, page: number) => {
      return fetch({
        url: `sccore/rest-api/ship-package/print-v5?prefix=${prefix??''}&start=${start}&number=${page}`,
        method: 'get',
      }) as Promise<string[]>;
    },
    [fetch],
  );
};

export const usePrintDelivery = () => {
  const fetch = useFetch();
  return useCallback(
    (ids: number[]) => {
      return fetch({
        url: `sccore/rest-api/ship-package/print-delivery`,
        method: 'post',
        data: ids
      }) as Promise<string[]>;
    },
    [fetch],
  );
};

export const useExportShipPackage = () => {
  const fetch = useFetch(false, false, true);
  return useCallback((ids: number[]) => {
    return fetch({
      url: `sccore/rest-api/ship-package/file/export`,
      method: 'post',
      data: ids
    }) as Promise<Blob>;
  }, [fetch]);
};

export const usePutUpdateShipPackageProgressStatus = () => {
  const fetch = useFetch();
  return useCallback(
    (shipPackageIdList: any, progressStatus: EnumShipOrderProgressStatus) => {
      return fetch({
        url: `sccore/rest-api/ship-package/update-status/${progressStatus}`,
        method: 'put',
        data: shipPackageIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of Ship Package
