import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { EnumDuplicateAction, ManifestType, PageData } from './models';

export const useGetManifest = () => {
  const fetch = useFetch();
  return useCallback((keyword: string, page: number, size: number) => {
    return fetch({
      url: `sccore/rest-api/manifest?keyword=${keyword??''}&page=${page}&size=${size}`,
      method: 'get',
    }) as Promise<PageData<ManifestType>>;
  }, [fetch]);
};

export const useCreateManifest = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ManifestType[]) => {
      return fetch({
        url: `sccore/rest-api/manifest`,
        method: 'post',
        data: data
      }) as Promise<ManifestType[]>;
    },
    [fetch],
  );
};

export const useDeleteManifest = () => {
  const fetch = useFetch();
  return useCallback(
    (qr: string) => {
      return fetch({
        url: `sccore/rest-api/manifest/${qr}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useImportManifestExcel = () => {
  const fetch = useFetch(false, true);
  return useCallback(
    (
      file: any,
      sheetName: string,
      fromRowNum: number,
      toRowNum: number,
      duplicateAction: EnumDuplicateAction,
    ) => {
      return fetch({
        url: `sccore/rest-api/manifest/import?sheetName=${sheetName}&fromRowNum=${fromRowNum}&toRowNum=${toRowNum}&duplicateAction=${duplicateAction}`,
        method: 'post',
        data: file,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePrintSubPackage = () => {
  const fetch = useFetch();
  return useCallback(
    (qr: string) => {
      return fetch({
        url: `sccore/rest-api/manifest/print/${qr}`,
        method: 'post',
      }) as Promise<string>;
    },
    [fetch],
  );
};
//End of Manifest
