import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { AccountBalanceListType, EnumAction, EnumDataType, EventButton } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import useQuery from 'src/hooks/useQuery';
import { BASE_SETTING_URL } from 'src/constants';
import AccountBalanceDetail, {
  EnumConfigAccountBalanceDetail,
} from '../AccountBalanceDetail/AccountBalanceDetail';
import { useGetAccountBalanceList } from 'src/api/accountBalanceApi';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const AccountBalanceList: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const getAccountBalanceList = useGetAccountBalanceList();

  //Local state
  const [count, setCount] = useState<number>();
  const [accountBalanceList, setAccountBalanceList] = useState<AccountBalanceListType[]>([]);

  const accountBalanceModal = useModal(AccountBalanceDetail);


  const display = {
    header: [
      {
        code: 'user.fullName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên',
      },
      {
        code: 'user.email',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Email',
      },
      {
        code: 'moneyAmount',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Số tiền',
      },
    ] as TableColumnType[],
  };


  const onView = (userId: number) => {
    const url = `${BASE_SETTING_URL}/account-balance?userId=${userId}`;
    navigate(url);
  };


  //Function in the listButton
  const onAddMoney = () => {
    accountBalanceModal.handlePresent(
      {
        config: EnumConfigAccountBalanceDetail.add
      },
      'NẠP TIỀN',
    );
  };

  const onDeductMoney = () => {
    accountBalanceModal.handlePresent(
      {
        config: EnumConfigAccountBalanceDetail.deduct
      },
      'TRỪ TIỀN',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Nạp tiền',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddMoney,
      align: 'center',
    },
    {
      name: 'Trừ tiền',
      icon: 'remove',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onDeductMoney,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getAccountBalanceList([])
      .then((data) => {
        setCount(data.count);
        setAccountBalanceList(data.items);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getAccountBalanceList, keyword]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH TÀI KHOẢN TIỀN'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        keyword={keyword}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={accountBalanceList}
        allowCheckbox={true}
        onDoubleClick={(item) => onView(item.userId)}
        isNo={true}
      />
    </>
  );
};
export default AccountBalanceList;
