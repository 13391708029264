import { EnumSimStatus, MonthlySubscriptionFeeType } from './models';
/************************************/
/********                    ********/
/******         Sim        ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, SimType } from './models';

export const useGetAdminSim = () => {
  const fetch = useFetch();
  return useCallback(
    (
      keyword: string,
      page: number,
      size: number,
      userId: number | string,
      productId: string | number,
      simStoreId: string | number,
      status: string | number,
    ) => {
      return fetch({
        url: `sccore/rest-api/sim?page=${page}&size=${size}${
          keyword && keyword != '' ? `&keyword=${keyword}` : ''
        }${userId ? `&userId=${userId}` : ''}${productId ? `&productId=${productId}` : ''}${
          simStoreId ? `&simStoreId=${simStoreId}` : ''
        }${status ? `&status=${status}` : ''}`,
        method: 'get',
      }) as Promise<PageData<SimType>>;
    },
    [fetch],
  );
};

export const useGetAgencySim = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/sim/me`,
      method: 'get',
    }) as Promise<PageData<SimType>>;
  }, [fetch]);
};

export const useGetSimById = () => {
  const fetch = useFetch();
  return useCallback(
    (simId: number) => {
      return fetch({
        url: `sccore/rest-api/sim/${simId}`,
        method: 'get',
      }) as Promise<SimType>;
    },
    [fetch],
  );
};

export const usePostSim = () => {
  const fetch = useFetch();
  return useCallback(
    (sim: SimType) => {
      return fetch({
        url: `sccore/rest-api/sim`,
        method: 'post',
        data: sim,
      }) as Promise<SimType>;
    },
    [fetch],
  );
};

export const usePutSim = () => {
  const fetch = useFetch();
  return useCallback(
    (sim: SimType) => {
      return fetch({
        url: `sccore/rest-api/sim`,
        method: 'put',
        data: sim,
      }) as Promise<SimType>;
    },
    [fetch],
  );
};

export const useDeleteSim = () => {
  const fetch = useFetch();
  return useCallback(
    (simId: number) => {
      return fetch({
        url: `sccore/rest-api/sim/${simId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGetMonthlySubscriptionFeeByUserId = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const temp = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim/monthly-subscription-fee`,
        method: 'post',
        data: temp,
      }) as Promise<MonthlySubscriptionFeeType>;
    },
    [fetch],
  );
};

export const useSendMonthlySubscriptionFeeEmail = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const temp = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim/monthly-subscription-fee/email`,
        method: 'post',
        data: temp,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useDeductMonthlySubscriptionFeeMoney = () => {
  const fetch = useFetch();
  return useCallback(
    (userId: number, fromDate: string, toDate: string) => {
      const temp = {
        userId: userId,
        fromDate: fromDate,
        toDate: toDate,
      };
      return fetch({
        url: `sccore/rest-api/sim/deduct/monthly-subscription-fee`,
        method: 'post',
        data: temp,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useExportSim = () => {
  const fetch = useFetch(false, false, true);
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/sim/file/export`,
      method: 'get',
    }) as Promise<Blob>;
  }, [fetch]);
};

export const usePutUpdateSimProgressStatus = () => {
  const fetch = useFetch();
  return useCallback(
    (simIdList: any, progressStatus: EnumSimStatus) => {
      return fetch({
        url: `sccore/rest-api/sim/update-status/${progressStatus}`,
        method: 'put',
        data: simIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
//End of sim
