/************************************/
/********                    ********/
/******       Sim Store     ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { SimStoreType } from './models';

export const useGetSimStore = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/sim-store`,
      method: 'get',
    }) as Promise<SimStoreType[]>;
  }, [fetch]);
};

export const useGetSimStoreById = () => {
  const fetch = useFetch();
  return useCallback(
    (simStoreId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-store/${simStoreId}`,
        method: 'get',
      }) as Promise<SimStoreType>;
    },
    [fetch],
  );
};

export const usePostSimStore = () => {
  const fetch = useFetch();
  return useCallback(
    (simStore: SimStoreType) => {
      return fetch({
        url: `sccore/rest-api/sim-store`,
        method: 'post',
        data: simStore,
      }) as Promise<SimStoreType>;
    },
    [fetch],
  );
};

export const usePutSimStore = () => {
  const fetch = useFetch();
  return useCallback(
    (simStore: SimStoreType) => {
      return fetch({
        url: `sccore/rest-api/sim-store`,
        method: 'put',
        data: simStore,
      }) as Promise<SimStoreType>;
    },
    [fetch],
  );
};

export const useDeleteSimStore = () => {
  const fetch = useFetch();
  return useCallback(
    (simStoreId: number) => {
      return fetch({
        url: `sccore/rest-api/sim-store/${simStoreId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

//End of sim store
