import React, { useState } from 'react';
import { EventButton } from 'src/api/models';
import styled from 'styled-components';
import './ConfirmModal.css';
import Input from '../Input';

interface IConfirmModal {
  width: string;
  height: string;
  question?: string;
  listActionButton?: EventButton[];
  title?: string;
  hasInput?: boolean;
  inputTitle?: string;
  type?: string;
  postProcess?: (...args: any[]) => void;
}

const ConfirmModal: React.FC<IConfirmModal> = (props) => {
  const [value, setValue] = useState<string|number>();
  const elmButton = props.listActionButton
    ? props.listActionButton.map((button: EventButton, index: number) => {
        return (
          <button
            key={`confirmpopupbutton${index}`}
            className={`confirm-button action-button ${button.buttonClass}`}
            onClick={() => {
              if (button.action) props.hasInput? button.action(value) : button.action();
              if (props.postProcess) props.postProcess();
            }}
          >
            <span className="material-icons">{button.icon}</span>
            {button.name}
          </button>
        );
      })
    : null;

  return (
    <WrapperContainer width={props.width} height={props.height}>
      <WrapperColumn>
        <WrapperRow width={'100%'} height={'50%'}>
          {props.question}
        </WrapperRow>
        <WrapperRow>
        {props.hasInput? <Input width='100%' title={props.inputTitle} value={value} type={props.type} onChange={setValue}/> : null}
        
        </WrapperRow>
        <WrapperRow width={'100%'} height={'50%'}>
          {elmButton}
        </WrapperRow>
      </WrapperColumn>
    </WrapperContainer>
  );
};

export default ConfirmModal;

const WrapperContainer = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || 'fit-content'};
  background-color: white;
`;

const WrapperColumn = styled.div<{ width?: string; height?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  transition: 1s;
`;
const WrapperRow = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  transition: 1s;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;
