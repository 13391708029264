import './Menu.css';
import { MouseEvent } from 'react';
import { EnumAction, EnumDataType, EventButton, MenuType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import MenuDetail from './MenuDetail/MenuDetail';
import { useGetAllMenu, useDeleteMenu } from 'src/api/menuApi';
import useModal from 'src/hooks/useModal';
import { useAddPopup } from 'src/state/application/hooks';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const Menu: React.FC = () => {
  //Function

  const addPopup = useAddPopup();
  const getAllMenu = useGetAllMenu();
  const deleteMenu = useDeleteMenu();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [items, setItems] = useState<MenuType[]>([]);

  const detailModal = useModal(MenuDetail);
  const confirmModal = useModal(ConfirmModal);

  const display = {
    header: [
      {
        code: 'menuId',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'ID',
      },
      {
        code: 'icon',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Biểu tượng',
      },
      {
        code: 'title',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'href',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên dịch vụ',
      },
      {
        code: 'parentId',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'ID menu cha',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[],
  };

  //Get all Menu
  useEffect(() => {
    getAllMenu().then((data) => {
      setItems(data);
    });
  }, [getAllMenu, reloadFlag]);

  //Menucontext
  const onView = (menuId: number) => {
    detailModal.handlePresent(
      {
        menuId: menuId,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (menuId: number) => {
    detailModal.handlePresent(
      {
        menuId: menuId,
        isDisable: false,
        postProcess: (data: MenuType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.menuId);
        },
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (menuId: number) => {
    const onConfirm = () => {
      deleteMenu(menuId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa dịch vụ thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa dịch vụ này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA DỊCH VỤ',
    );
  };

  const menuContext = (item: MenuType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.menuId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.menuId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.menuId),
      buttonClass: 'info',
      align: '',
    },
  ];

  //Function in the listButton
  const onAddMenuNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: MenuType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.menuId);
        },
      },
      'THÊM MỚI',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info sim-order-tool-btn',
      action: onAddMenuNew,
      align: 'center',
    },
  ];

  return (
    <>
      <ToolBar
        toolbarName={'Menu hiển thị'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.menuId)}
      />
    </>
  );
};

export default Menu;
