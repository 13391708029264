/* eslint-disable react-hooks/exhaustive-deps */
import './Payment.css';
import React, { useCallback } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumAction, EnumDataType, EnumDisplayConfig, EnumPaymentType, EventButton, PaymentType } from 'src/api/models';
import { useApproveBill, useDeletePayment, useGetPayments } from 'src/api/paymentApi';
import { EnumPaymentStatus } from 'src/common/enum/EnumPaymentStatus';
import PaymentDetail from './PaymentDetail/PaymentDetail';
import PaymentApprove from './PaymentApprove/PaymentApprove';
import PaymentDelete from './PaymentDelete/PaymentDelete';
import { EnumPaymentBillType } from 'src/common/enum/EnumPaymentBillType';


const Payment: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const getPayments = useGetPayments();
  const deleteModal = useModal(PaymentDelete);
  const detailModal = useModal(PaymentDetail);
  const approveModal = useModal(PaymentApprove);

  const lstStatus = [
    {
      value: EnumPaymentStatus.Waiting,
      title: 'Chờ thanh toán',
      css: { color: 'red' },
    },
    {
      value: EnumPaymentStatus.Paid,
      title: 'Đã thanh toán',
      css: { color: 'blue' },
    },
  ];

  const lstType = [
    {
      value: EnumPaymentBillType.TRANSPORT,
      title: 'Vận chuyển',
      css: { color: 'blue' },
    },
    {
      value: EnumPaymentBillType.PRODUCTORDER,
      title: 'Mua hộ',
      css: { color: 'green' },
    },
    {
      value: EnumPaymentBillType.PRODUCTSALE,
      title: 'Đồ cũ',
      css: { color: 'pink' },
    },
  ];

  const display = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo',
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã thanh toán',
      },
      {
        code: 'objectType',
        dataType: EnumDataType.Int,
        isOptions: true,
        title: 'Loại hóa đơn',
        options: lstType
      },
      {
        code: 'object',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Đơn vch',
        convert: (value: string) => {
          return (<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {(value? JSON.parse(value) as [] : []).map((item, index) => (
                <div key={`option${index}`} style={{margin: "2px", width: 'fit-content', borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item}</div>
            ))}
          </div>
          );
        },
      },
      {
        code: 'customerName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên khách',
      },
      {
        code: 'customerEmail',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Email',
      },
      {
        code: 'money',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Số tiền',
      },
      {
        code: 'debt',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Nợ cũ',
      },
      {
        code: 'expirationDate',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Hạn thanh toán',
      },
      {
        code: 'status',
        dataType: EnumDataType.Int,
        isOptions: true,
        title: 'Trạng thái',
        options: lstStatus
      },
    ] as TableColumnType[]
  };

  const onView = (paymentId: number) => {
    detailModal.handlePresent(
      {
        paymentId: paymentId,
        isDisable: true,
      },
      'CHI TIẾT THANH TOÁN',
    );
  };

  const onDelete = (payment: PaymentType) => {
    deleteModal.handlePresent({
      paymentId: payment.paymentId,
      paymentCode: payment.paymentCode,
      debt: payment.debt,
      postProcess: () => {
        deleteModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      }
    }, "XÁC NHẬN THANH TOÁN");
  };


  const acceptPayment = useCallback((item?: PaymentType) => {
    approveModal.handlePresent({
      paymentCode: item?.paymentCode??undefined,
      postProcess: () => {
        approveModal.handleDismiss();
        setReloadFlag(!reloadFlag);
      }
    }, "XÁC NHẬN THANH TOÁN");
  }, [reloadFlag])

  const menuContext = (item: PaymentType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.View,
      action: () => {
        onView(item.paymentId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Delete,
      action: () => onDelete(item),
      buttonClass: 'info',
      align: '',
    },
    item.status == EnumPaymentStatus.Waiting && {
      name: 'Chấp nhận thanh toán',
      icon: 'check_circle_outline',
      actionType: EnumAction.Confirm,
      action: acceptPayment,
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Copy Link',
      icon: 'content_copy',
      actionType: EnumAction.View,
      action: () => {
        navigator.clipboard.writeText(`https://order.jvscorp.jp/payment/${item.paymentCode}`);
        addPopup({
          txn: {
            success: true,
            summary: 'Đã copy đường dẫn vào Clipboard',
          }
        });
      },
      buttonClass: 'info',
      align: '',
    },
  ];


  const actions = [
    {
      name: 'Xác nhận thanh toán',
      icon: 'verified',
      actionType: EnumAction.Confirm,
      buttonClass: 'info100 order-tool-btn',
      action: acceptPayment,
      align: 'center',
    },
  ]

  useEffect(() => {
    getPayments(keyword, page, size)
      .then((data) => {
        setPayments(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getPayments,
    reloadFlag,
    keyword,
    page,
    size
  ]);

  return (
    <>
      <ToolBar
        functionId={EnumDisplayConfig.Payment}
        toolbarName={'DANH SÁCH THANH TOÁN'}
        listRightButton={actions}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
      />

      <Table
        functionId={EnumDisplayConfig.Payment}
        display={display}
        isInput={false}
        data={payments}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.paymentId)}
        isNo={true}
      />
    </>
  );
};
export default Payment;
