import './ReportInput.css';
import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import { DataTypeList, EnumDataType, ReportInputType } from 'src/api/models';
import Input from '../Input';
import SelectBoxComponent from '../SelectBoxComponent/SelectBoxComponent';
import Checkbox from '../Checkbox';
import CodeInput from '../CodeInput';
import ButtonComponent from '../ButtonComponent/ButtonComponent';

interface IReportInput {
  filters?: ReportInputType[];
  onChange: (value: ReportInputType[]) => void;
}

const ReportInput: React.FC<IReportInput> = (props) => {
  const {filters, onChange} = props;

  return (
    <div className='report-input-container'>
      <table >
        <thead className='report-input-title'>
          <tr>
            <th style={{width: "133px"}}>Title</th>
            <th style={{width: "130px"}}>Input</th>
            <th style={{width: "130px"}}>DataType</th>
            <th style={{width: "57px"}}>T.stamp</th>
            <th style={{width: "51px"}}>Paging</th>
            <th style={{width: "50px"}}>Search</th>
            <th style={{width: "47px"}}>Query</th>
            <th style={{width: "39px"}}>Data</th>
            <th style={{width: "40px"}}></th>
          </tr>
        </thead>
        <tbody>
        {filters?.map((row: ReportInputType, index: number) => {
          return (
            <tr key={`report-input-row-${index}`}>
              <td>
                <Input
                  width='120px'
                  require={false}
                  disabled={false}
                  value={row.title}
                  onChange={(value) => {
                    row.title = value;
                    onChange([...filters]);
                  }}
                  inputPadding='1px'
                  padding='1px'
                  className='text-small'
                />
              </td>
              <td>
                <Input
                  width='120px'
                  require={false}
                  disabled={false}
                  value={row.inputName?.join(",")}
                  onChange={(value) => {
                    row.inputName = value.split(",");
                    onChange([...filters]);
                  }}
                  inputPadding='1px'
                  padding='1px'
                  className='text-small'
                />
              </td>
              <td>
                <SelectBoxComponent
                  width='60px'
                  require={false}
                  onChange={(value) => {
                    row.dataType = value;
                    onChange([...filters]);
                  }}
                  isDisable={false}
                  value={row.dataType}
                  data={DataTypeList}
                  valueType={'value'}
                  titleType={'title'}
                  inputPadding='1px'
                  padding='1px'
                  fontSize='small'
                />
              </td>
              <td>
                <Checkbox 
                  value={row.timestamp} 
                  disabled={false} 
                  onChange={(value) => {
                    row.timestamp = value;
                    onChange([...filters]);
                  }} 
                />
              </td>
              <td>
                <Checkbox 
                  value={row.isPaging} 
                  disabled={false} 
                  onChange={(value) => {
                    row.isPaging = value;
                    onChange([...filters]);
                  }} 
                />
              </td>
              <td>
                <Checkbox 
                  value={row.isSearch} 
                  disabled={false} 
                  onChange={(value) => {
                    row.isSearch = value;
                    onChange([...filters]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={row.query} 
                  disabled={false} 
                  language='sql'
                  onChange={(value) => {
                    row.query = value;
                    onChange([...filters]);
                  }} 
                />
              </td>
              <td>
                <CodeInput 
                  value={JSON.stringify(row.data)} 
                  disabled={false}
                  language='json' 
                  onChange={(value) => {
                    row.data = value? JSON.parse(value) : undefined;
                    onChange([...filters]);
                  }} 
                />
              </td>
              <td className='inline'>
                <div className="report-icon" onClick={() => onChange(filters.filter(r => r != row))}>
                  <i className="fas fa-trash"></i>
                </div>
                <div className="report-icon" onClick={() => {
                  const indx = filters.indexOf(row);
                  filters.splice(indx, 0,  {title: undefined, data: undefined, inputName: undefined, isPaging: false, isSearch: false, query:undefined, timestamp: false});
                  onChange([...filters]);
                }}>
                  <i className="fa fa-plus"></i>
                </div>
              </td>
            </tr>
          )
        })}
        <tr>
          <td>
            <ButtonComponent className='report-input-button' title='Thêm' onClick={() => {
              onChange([...filters, {title: undefined, data: undefined, inputName: undefined, isPaging: false, isSearch: false, query:undefined, timestamp: false}]);
            }}/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportInput;
