
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, ReceiverOrderType } from './models';

export const useGetReceiverOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword?: string, page?: number, size?: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<ReceiverOrderType>>;
    },
    [fetch],
  );
};

export const useGetReceiverOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/${receiverOrderId}`,
        method: 'get',
      }) as Promise<ReceiverOrderType>;
    },
    [fetch],
  );
};

export const useGetReceiverOrderByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/code/${code}`,
        method: 'get',
      }) as Promise<ReceiverOrderType>;
    },
    [fetch],
  );
};

export const usePostReceiverOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrder: ReceiverOrderType) => {
      return fetch({
        url: `sccore/rest-api/receiver-order`,
        method: 'post',
        data: receiverOrder,
      }) as Promise<ReceiverOrderType>;
    },
    [fetch],
  );
};

export const usePutReceiverOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrder: ReceiverOrderType) => {
      return fetch({
        url: `sccore/rest-api/receiver-order`,
        method: 'put',
        data: receiverOrder,
      }) as Promise<ReceiverOrderType>;
    },
    [fetch],
  );
};

export const useDeleteReceiverOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/${receiverOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePrintReceiverOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrderIds: number[], page: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/print?number=${page}`,
        method: 'post',
        data: receiverOrderIds
      }) as Promise<string[]>;
    },
    [fetch],
  );
};

export const usePrintReceiverOrderV40 = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrderIds: number[], page: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/print-v40?number=${page}`,
        method: 'post',
        data: receiverOrderIds
      }) as Promise<string[]>;
    },
    [fetch],
  );
};


export const usePrintReceiverOrderV10 = () => {
  const fetch = useFetch();
  return useCallback(
    (receiverOrderIds: number[], page: number) => {
      return fetch({
        url: `sccore/rest-api/receiver-order/print-v10?number=${page}`,
        method: 'post',
        data: receiverOrderIds
      }) as Promise<string[]>;
    },
    [fetch],
  );
};
