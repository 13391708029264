/* eslint-disable react-hooks/exhaustive-deps */
import './CustomerDebt.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumAction, EnumDataType, EventButton, CustomerDebtType } from 'src/api/models';
import { useDeleteCustomerDebt, useGetCustomerDebts } from 'src/api/customerDebtApi';
import CustomerDebtDetail from './CustomerDebtDetail/CustomerDebtDetail';

const CustomerDebt: React.FC = () => {

  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [customerDebts, setCustomerDebts] = useState<CustomerDebtType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const detailModal = useModal(CustomerDebtDetail);
  const confirmModal = useModal(ConfirmModal);
  const deleteCustomerDebt = useDeleteCustomerDebt();
  const getCustomerDebts = useGetCustomerDebts();


  const display = {
      header: [
      {
        code: 'customerEmail',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Email',
      },
      {
        code: 'money',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Số tiền',
      },
    ] as TableColumnType[]
  };

  const onView = (item: CustomerDebtType) => {
    detailModal.handlePresent(
      {
        data: item,
        isDisable: true,
      },
      'CHI TIẾT TIỀN NỢ',
    );
  };


  const onDelete = (customerDebt: CustomerDebtType) => {
    const onConfirm = () => {
      deleteCustomerDebt(customerDebt.customerEmail)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa tiền nợ thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };
    const listButton = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'cancel',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa bản ghi nợ này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA BẢN GHI NỢ',
    );
  }

  const menuContext = (item: CustomerDebtType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item),
      buttonClass: 'info',
      align: '',
    },
  ];

  useEffect(() => {
    getCustomerDebts(keyword, page, size)
      .then((data) => {
        setCustomerDebts(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getCustomerDebts,
    reloadFlag,
    keyword,
    page,
    size
  ]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH TIỀN NỢ CỦA KHÁCH'}
        listRightButton={[
          {
            name: 'Thêm',
            icon: 'add',
            actionType: EnumAction.Add,
            buttonClass: 'info100 tool-btn',
            action: () =>
              detailModal.handlePresent(
                {
                  isDisable: false,
                  postProcess: (data: CustomerDebtType) => {
                    detailModal.handleDismiss();
                    setReloadFlag(!reloadFlag);
                    onView(data);
                  },
                },
                'THÊM MỚI',
              ),
            align: 'center',
          },
        ]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
      />

      <Table
        display={display}
        isInput={false}
        data={customerDebts}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item)}
        isNo={true}
      />
    </>
  );
};

export default CustomerDebt;
