import React, { useCallback, useEffect, useState } from 'react';
import { ShortLinkType } from 'src/api/models';
import {
  usePostShortLink,
  usePutShortLink,
  useGetShortLinkById,
} from 'src/api/shortlinkApi';

import { useAddPopup } from 'src/state/application/hooks';
import './ShortLinkDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { hr } from 'date-fns/locale';

interface IShortLinkDetail {
  shortlink?: string;
  href?: string;
  isDisable: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ShortLinkDetail: React.FC<IShortLinkDetail> = (props) => {
  //Function
  const postShortLink = usePostShortLink();
  const putShortLink = usePutShortLink();
  const addPopup = useAddPopup();

  //State
  const [shortlink, setShortlink] = useState<string>(props.shortlink);
  const [isDisable, setDisable] = useState<boolean>(props.isDisable);
  const [href, setHref] = useState<string>(props.href);

  const onSave = () =>
    new Promise((resolve, reject) => {
      const body: ShortLinkType = {
        shortlink: shortlink,
        href: href
      };
      const isAdd = !props.shortlink;
      const api = isAdd ? postShortLink(body) : putShortLink(body);
      api
        .then((res: ShortLinkType) => {
          setShortlink(res.shortlink);
          setDisable(true);
          resolve(true);
          if (props.postProcess) props.postProcess(res);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra, vui lòng thử lại!',
            },
          });
          reject(false);
        });
      
    });
  //End of function

  return (
    <div className="container-fluid shortlink-detail-container">
      <div className="shortlink-detail-row">
        <Input title="Mã" require={true} value={shortlink} onChange={setShortlink} disabled={isDisable} />
      </div>
      <div className="shortlink-detail-row">
        <Input title="Link" require={true}  value={href} onChange={setHref} disabled={isDisable} />
      </div>
      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={!shortlink ? 'THÊM' : 'LƯU'}
          onClick={onSave}
          loader={true}
        />
      )}
    </div>
  );
};

export default ShortLinkDetail;
