import React, { useCallback, useEffect, useState } from 'react';
import { MenuType } from 'src/api/models';
import { usePostMenu, usePutMenu, useGetMenuById } from 'src/api/menuApi';

import { useAddPopup } from 'src/state/application/hooks';
import './MenuDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';

interface IMenuDetail {
  menuId: number;
  isDisable: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const MenuDetail: React.FC<IMenuDetail> = (props) => {
  //Function
  const postMenu = usePostMenu();
  const putMenu = usePutMenu();
  const getMenuById = useGetMenuById();
  const addPopup = useAddPopup();

  //State
  const [menuId, setMenuId] = useState<number>(props.menuId);
  const [isDisable, setDisable] = useState<boolean>(props.isDisable);
  const [title, setTitle] = useState<string>();
  const [titleError, setTitleError] = useState<string>();
  const [icon, setIcon] = useState<string>();
  const [href, setHref] = useState<string>();
  const [parentId, setParentId] = useState<number>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  //Init
  useEffect(() => {
    if (menuId) {
      getMenuById(menuId)
        .then((data) => {
          setTitle(data.title);
          setIcon(data.icon);
          setHref(data.href);
          setDisplayOrder(data.displayOrder);
          setParentId(data.parentId);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    }
  }, [addPopup, getMenuById, menuId]);

  const validateTitle = () => {
    setTitleError(title && title != '' ? undefined : 'Chưa nhập tiêu đề');
    return title && title != '';
  };

  const onSave = () =>
    new Promise((resolve, reject) => {
      if (validateTitle()) {
        const body: MenuType = {
          menuId: menuId,
          displayOrder: displayOrder,
          title: title,
          href: href,
          parentId: parentId,
          icon: icon,
        };
        const isAdd = !props.menuId;
        const api = isAdd ? postMenu(body) : putMenu(body);
        api
          .then((res: MenuType) => {
            setMenuId(res.menuId);
            setDisable(true);
            resolve(true);
            if (props.postProcess) props.postProcess(res);
          })
          .catch((error) => {
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra, vui lòng thử lại!',
              },
            });
            reject(false);
          });
      } else {
        addPopup(
          {
            error: {
              title: 'Chưa nhập đủ thông tin',
              message: `${titleError ?? ''}
            ${titleError ?? ''}`,
            },
          },
          undefined,
          false,
          3000,
        );
        reject(false);
      }
    });
  //End of function

  return (
    <div className="container-fluid menu-detail-container">
      <div className="menu-detail-row">
        <Input
          title="Tiêu đề"
          require={true}
          value={title}
          onChange={setTitle}
          disabled={isDisable}
        />
      </div>
      <div className="menu-detail-row">
        <Input
          title="Biểu tượng"
          require={true}
          value={icon}
          onChange={setIcon}
          disabled={isDisable}
        />
      </div>
      <div className="menu-detail-row">
        <Input title="Đường dẫn" value={href} onChange={setHref} disabled={isDisable} />
      </div>
      <div className="menu-detail-row">
        <Input
          title="Id menu cha"
          value={parentId}
          onChange={setParentId}
          disabled={isDisable}
          type="number"
        />
      </div>
      <div className="menu-detail-row">
        <Input
          title="Thứ tự"
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>

      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={!menuId ? 'THÊM' : 'LƯU'}
          onClick={onSave}
          loader={true}
        />
      )}
    </div>
  );
};

export default MenuDetail;
