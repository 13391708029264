import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import {
  EnumAction,
  EnumDataType,
  EventButton,
  NotificationParameterType,
  NotificationTemplateType,
} from 'src/api/models';
import MonacoEditor from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import Tabs from 'src/components/Tabs';
import { BASE_SETTING_URL } from 'src/constants';
import {
  useGetNotificationTemplateById,
  usePostNotificationTemplate,
  usePutNotificationTemplate,
} from 'src/api/notificatoinTemplateApi';
import useModal from 'src/hooks/useModal';
import OtherImageUpload from 'src/components/OtherImageUpload';

enum TabKey {
  Content,
  SourceCode,
}

const NotificationTemplateDetail: React.FC = () => {

  //Value
  const params = useParams<{ type: string; notificationTemplateId: string }>();
  const [notificationTemplateId, setNotificationTemplateId] = useState(
    params?.notificationTemplateId ? Number(params.notificationTemplateId) : undefined,
  );

  const isAdd = Number(params.type) === EnumViewType.Edit && notificationTemplateId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;


  //Function
  const getNotificaionTemplateById = useGetNotificationTemplateById();
  const postNotificationTemplate = usePostNotificationTemplate();
  const putNotificationTemplate = usePutNotificationTemplate();
  const addPopup = useAddPopup();

  //Value
  const navigate = useNavigate();
  const tinyEditorRef = useRef(null);

  //State
  const [notificationTemplateKey, setNotificationTemplateKey] = useState<string>();
  const [notificationTemplateTitle, setNotificationTemplateTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [value, setValue] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tab, setTab] = useState(TabKey.Content);

  //Param state
  const [notificationParameter, setNotificationParameter] =
    useState<NotificationParameterType[]>([]);

  const editorRef = useRef(null);
  const imageModal = useModal(OtherImageUpload);

  useEffect(() => {
    if (!isAdd) {
      getNotificaionTemplateById(notificationTemplateId)
        .then((data) => {
          setNotificationTemplateKey(data.notificationTemplateKey);
          setNotificationTemplateTitle(data.notificationTemplateTitle);
          setValue(data.notificationTemplateValue);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setNotificationParameter(data.notificationParameter);
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
  }, [
    addPopup,
    isAdd,
    reloadFlag,
    getNotificaionTemplateById,
    notificationTemplateId,
  ]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const onChangeParam = (value: string, index: number, type: string) => {
    if (notificationParameter) {
      const temp = notificationParameter.filter(() => true);
      if (type == 'PARAMETERNAME') {
        temp[index].parameterName = value;
      } else if (type == 'DESCRIPTION') {
        temp[index].description = value;
      } else if (type == 'DATATYPE') {
        temp[index].dataType = Number(value);
      }
      setNotificationParameter(temp);
    }
  };

  const onDeleteParam = (index: number) => {
    if (notificationParameter) {
      const temp = notificationParameter.filter(() => true);
      temp.splice(index, 1);
      setNotificationParameter(temp);
    }
  };

  const onAddParam = () => {
    if (notificationParameter) {
      const arr = notificationParameter.filter(() => true);
      const temp: NotificationParameterType = {
        parameterName: '',
        description: '',
        dataType: EnumDataType.Text,
      };
      arr.push(temp);
      setNotificationParameter(arr);
    } else {
      const arr: NotificationParameterType[] = [
        {
          parameterName: '',
          description: '',
          dataType: EnumDataType.Text,
        },
      ];
      setNotificationParameter(arr);
    }
  };



  const onSave = useCallback(() => {
    const pageValue = tab == TabKey.SourceCode ? editorRef.current.getValue() : tinyEditorRef.current.getContent();
    const notificationTemplate: NotificationTemplateType = {
      notificationTemplateId: notificationTemplateId,
      displayOrder: displayOrder,
      notificationTemplateKey: notificationTemplateKey,
      notificationTemplateTitle: notificationTemplateTitle,
      notificationTemplateValue: pageValue,
      notificationParameter: notificationParameter,
      description: description,
    };

    const api = isAdd? postNotificationTemplate : putNotificationTemplate;

    api(notificationTemplate).then((r) => {
      setNotificationTemplateId(r.notificationTemplateId);
      setReloadFlag(!reloadFlag);
      addPopup({
        txn: {
          success: true,
          summary: isAdd? 'Thêm thành công!' : 'Sửa thành công!',
        },
      });
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [tab, notificationTemplateId, displayOrder, notificationTemplateKey, notificationTemplateTitle, notificationParameter, description, isAdd, postNotificationTemplate, putNotificationTemplate, reloadFlag, addPopup]);



  const listEditToolButton: EventButton[] = [
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: onSave,
      align: 'center',
    },
    {
      name: 'D.sách ảnh',
      icon: 'image',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-page-tool-btn cloud_upload-btn',
      action: () => {
        imageModal.handlePresent({}, 'DANH SÁCH ẢNH')
      },
      align: 'center',
    }
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: () => navigate(`${BASE_SETTING_URL}/notification-template/${EnumViewType.Edit}/id/${notificationTemplateKey}`),
      align: 'center',
    }
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        if (tab == TabKey.SourceCode) {
          const content = editorRef.current.getValue();
          setValue(content);
        }
        setTab(TabKey.Content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        if (tab == TabKey.Content) {
          const content = tinyEditorRef.current.getContent();
          setValue(content);
        }
        setTab(TabKey.SourceCode);
      },
    },
  ];

  //Component
  const paramComponent = () => {
    return (
      <div className="email-template-detail-param-container">
        <div className="simple-param-container">
          <div className="simple-param">
            <div className="simple-param-component title">parameterName</div>
            <div className="simple-param-component title">description</div>
            <div className="simple-param-component title">dataType</div>
            <div className="email-template-btn-component title"></div>
          </div>
          {notificationParameter
            ? notificationParameter.map((value: NotificationParameterType, index: number) => {
                return (
                  <div className="simple-param">
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.parameterName}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'PARAMETERNAME');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.description}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'DESCRIPTION');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <select
                        value={value.dataType}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'DATATYPE');
                        }}
                      >
                        <option value={EnumDataType.Text}>Text</option>
                        <option value={EnumDataType.Int}>Int</option>
                        <option value={EnumDataType.Date}>Date</option>
                        <option value={EnumDataType.PhoneNumber}>
                          PhoneNumber
                        </option>
                        <option value={EnumDataType.Email}>Email</option>
                        <option value={EnumDataType.Boolean}>
                          Boolean
                        </option>
                        <option value={EnumDataType.Percentage}>
                          Percentage
                        </option>
                        <option value={EnumDataType.BigInt}>BigInt</option>
                        <option value={EnumDataType.JPY}>
                        JPY
                        </option>
                        <option value={EnumDataType.Month}>Month</option>
                        <option value={EnumDataType.QuarterOfYear}>
                          QuarterOfYear
                        </option>
                        <option value={EnumDataType.Year}>Year</option>
                        <option value={EnumDataType.DateRange}>
                          DateRange
                        </option>
                      </select>
                    </div>
                    <div
                      className="email-template-btn-component title"
                      onClick={() => {
                        onDeleteParam(index);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="simple-param">
            <div className="email-template-add-btn" onClick={onAddParam}>
              Add param
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ToolBar
        toolbarName={'MẪU THÔNG BÁO'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        isBack={true}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      {paramComponent()}
      <div className='page-content'>
        <div className='page-content-info'>
          <div className='page-content-input'>
            <Input
              title='Mã thông báo'
              require={true}
              disabled={isDisable}
              value={notificationTemplateKey}
              onChange={setNotificationTemplateKey}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Tiêu đề'
              require={true}
              disabled={isDisable}
              value={notificationTemplateTitle}
              onChange={setNotificationTemplateTitle}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Thứ tự'
              require={true}
              disabled={isDisable}
              value={displayOrder}
              onChange={setDisplayOrder}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Mô tả'
              require={true}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>

        </div>

        <Tabs activeTab={tab} tabs={tabs} />

        {tab == TabKey.SourceCode ? (
          <MonacoEditor
            height="690px"
            defaultLanguage="html"
            value={value}
            onMount={handleEditorDidMount}
            options={{
              readOnly: isDisable,
            }}
          />
        ) : (
          <Editor
            disabled={isDisable}
            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
            initialValue={value}
            init={{
              min_height: 690,
              menubar: true,
              plugins: ['preview', 'lists', 'advlist', 'anchor', 'link', 'autolink', 'autoresize', 'charmap', 'code', 'codesample',
              'directionality', 'emoticons', 'fullscreen', 'image', 'insertdatetime', 'media', 'nonbreaking', 'pagebreak', 'quickbars',
              'searchreplace', 'table', 'visualblocks', 'visualchars', 'wordcount', 'help'],
              toolbar:
                'preview | undo redo | forecolor | formatselect | bold italic backcolor | blocks | blockquote | hr | ' +
                'align | bullist numlist outdent indent | alignjustify | visualchars | wordcount' +
                'link | charmap | code | ltr rtl | emoticons | fullscreen | image | table |' +
                'nonbreaking | pagebreak | print | view |searchreplace | visualblocks | insertdatetime | media | paste | wordcount |' +
                'rotateleft rotateright | ' +
                'removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        )}
      </div>
    </>
  );
};

export default NotificationTemplateDetail;
