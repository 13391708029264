import { BASE_ORDER_URL, BASE_REPORT_URL, BASE_SETTING_URL, BASE_SIM_URL, BASE_WEB_URL } from 'src/constants';
import Realm from 'src/views/Realm';
import Category from 'src/views/Category/Category';
import ProductSerial from 'src/views/ProductSerial/ProductSerial';
import Product from 'src/views/Product/Product';
import Banner from 'src/views/Banner/Banner';
import AdvertisingBanner from 'src/views/AdvertisingBanner/AdvertisingBanner';
import Info from 'src/views/Info/Info';
import CompanyImage from 'src/views/CompanyImage/CompanyImage';
import StaticPageDetails from 'src/views/StaticPage/StaticPageDetails/StaticPageDetails';
import StaticPage from 'src/views/StaticPage/StaticPage';
import NewsDetails from 'src/views/News/NewsDetails/NewsDetails';
import News from 'src/views/News/News';
import OrderRequirementDetail from 'src/views/OrderRequirement/OrderRequirementDetail/OrderRequirementDetail';
import OrderRequirement from 'src/views/OrderRequirement/OrderRequirement';
import User from 'src/views/User/User';
import AccountBalanceList from 'src/views/AccountBalance/AccountBalanceList/AccountBalanceList';
import CustomerTypeScreen from 'src/views/CustomerTypeScreen/CustomerTypeScreen';
import UserCustomerTypeScreen from 'src/views/UserCustomerTypeScreen/UserCustomerTypeScreen';
import ReceiverInfo from 'src/views/ReceiverInfo/ReceiverInfo';
import AccountBalance from 'src/views/AccountBalance/AccountBalance';
import Store from 'src/views/Store/Store';
import Module from 'src/views/Module/Module';
import Role from 'src/views/Role/Role';
import ApiScreen from 'src/views/ApiScreen/ApiScreen';
import EmailTemplate from 'src/views/EmailTemplate/EmailTemplate';
import EmailTemplateDetail from 'src/views/EmailTemplate/EmailTemplateDetail/EmailTemplateDetail';
import PrintedTemplateDetail from 'src/views/PrintedTemplate/PrintedTemplateDetail/PrintedTemplateDetail';
import PrintedTemplate from 'src/views/PrintedTemplate/PrintedTemplate';
import NotificationTemplateDetail from 'src/views/NotificationTemplate/NotificationTemplateDetail/NotificationTemplateDetail';
import NotificationTemplate from 'src/views/NotificationTemplate/NotificationTemplate';
import Parameter from 'src/views/Parameter/Parameter';
import DashBoard from 'src/views/DashBoard';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useProfile from 'src/hooks/useProfile';
import { useEffect } from 'react';
import ProductDetails from 'src/views/Product/ProductDetails/ProductDetails';
import Logo from 'src/views/Logo/Logo';
import Login from 'src/views/AuthScreen/Login/Login';
import ConfirmRegister from 'src/views/AuthScreen/ConfirmRegister/ConfirmRegister';
import ForgotPassword from 'src/views/AuthScreen/ForgotPassword/ForgotPassword';
import Register from 'src/views/AuthScreen/Register/Register';
import ConfirmForgotPassword from 'src/views/AuthScreen/ConfirmForgotPassword/ConfirmForgotPassword';
import Verify from 'src/views/AuthScreen/ChangePassword/ChangePassword';
import Service from 'src/views/Service/Service';
import SimOrder from 'src/views/SimOrder/SimOrder';
import EmailList from 'src/views/EmailList/EmailList';
import SimNewsDetail from 'src/views/SimNews/SimNewsDetail/SimNewsDetail';
import SimNews from 'src/views/SimNews/SimNews';
import Sim from 'src/views/Sim/Sim';
import SimPrice from 'src/views/SimPrice/SimPrice';
import SimStore from 'src/views/SimStore/SimStore';
import ShipOrder from 'src/views/ShipOrder/ShipOrder';
import ProductOrder from 'src/views/ProductOrder/ProductOrder';
import PurchaseAccount from 'src/views/PurchaseAccount/PurchaseAccount';
import GuidePage from 'src/views/GuidePage/GuidePage';
import GuidePageDetails from 'src/views/GuidePage/GuidePageDetails/GuidePageDetails';
import Debit from 'src/views/Debit/Debit';
import MonthlySubscriptionFee from 'src/views/MonthlySubscriptionFee/MonthlySubscriptionFee';
import SimOrderDetail from 'src/views/SimOrder/SimOrderDetail/SimOrderDetail';
import SimDetail from 'src/views/Sim/SimDetail/SimDetail';
import Inventory from 'src/views/Inventory/Inventory';
import ReceiverShipOrder from 'src/views/ShipOrder/ReceiverShipOrder/ReceiverShipOrder';
import SpecialShip from 'src/views/SpecialShip/SpecialShip';
import ShipOrderDetail from 'src/views/ShipOrder/ShipOrderDetail/ShipOrderDetail';
import ReceiverOrder from 'src/views/ReceiverOrder/ReceiverOrder';
import ShipOrderScanner from 'src/views/ShipOrder/ShipOrderScanner/ShipOrderScanner';
import ShipPackage from 'src/views/ShipPackage/ShipPackage';
import ShipPackageDetail from 'src/views/ShipPackage/ShipPackageDetail/ShipPackageDetail';
import ShipInvoice from 'src/views/ShipInvoice/ShipInvoice';
import ShipInvoiceDetail from 'src/views/ShipInvoice/ShipInvoiceDetail/ShipInvoiceDetail';
import ShipOrderDeclaration from 'src/views/ShipOrder/ShipOrderDeclaration/ShipOrderDeclaration';
import SalesCollatorabor from 'src/views/SalesCollatorabor/SalesCollatorabor';
import Payment from 'src/views/Payment/Payment';
import CustomerDebt from 'src/views/CustomerDebt/CustomerDebt';
import InvoiceReport from 'src/views/Report/InvoiceReport';
import ReportDetail from 'src/views/Report/ReportDetail/ReportDetail';
import Report from 'src/views/Report/Report';
import Disbursement from 'src/views/Disbursement/Disbursement';
import ReportData from 'src/views/Report/ReportData';
import ShortLink from 'src/views/ShortLink/ShortLink';
import ProductOrderDetail from 'src/views/ProductOrder/ProductOrderDetail/ProductOrderDetail';
import StockInspection from 'src/views/StockInspection/StockInspection';
import ExportBill from 'src/views/ExportBill/ExportBill';
import ExportBillDetail from 'src/views/ExportBill/ExportBillDetail/ExportBillDetail';
import Menu from 'src/views/Menu/Menu';
import Manifest from 'src/views/Manifest/Manifest';
import ManifestPrinter from 'src/views/Manifest/ManifestPrinter/ManifestPrinter';

const AppRouters: React.FC = () => {
  //Value
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!profile && !location.pathname.includes("/auth")) navigate('/auth');
  }, [location.pathname, navigate, profile]);

  //Main
  return (
    <Routes>
      <Route path="/auth/confirm-register" element={<ConfirmRegister />} />
      <Route path="/auth/forgot-password" element={<ForgotPassword />} />
      <Route path="/auth/register" element={<Register />} />
      <Route path="/auth/confirm-forgot-password" element={<ConfirmForgotPassword />} />
      <Route path="/auth/change-password" element={<Verify />} />
      <Route path="/auth" element={<Login />} />
      <Route path="/" element={<DashBoard />} />
      <Route key={'route-realm'} path={`${BASE_WEB_URL}/realm`} element={<Realm />} />
      <Route key={'route-category'} path={`${BASE_WEB_URL}/category`} element={<Category />} />
      <Route key={'route-product-serial'} path={`${BASE_WEB_URL}/product/:productId/product-serial`} element={<ProductSerial />} />
      <Route key={'route-inventory'} path={`${BASE_WEB_URL}/inventory`} element={<Inventory />} />
      <Route key={'route-product-inventory'} path={`${BASE_WEB_URL}/inventory/:productId`} element={<Inventory />} />
      <Route key={'route-product'} path={`${BASE_WEB_URL}/product`} element={<Product />} />
      <Route key={'route-product-detail'} path={`${BASE_WEB_URL}/product/:productId/:type`} element={<ProductDetails />} />
      <Route key={'route-banner'} path={`${BASE_WEB_URL}/banner`} element={<Banner />} />
      <Route key={'route-menu'} path={`${BASE_WEB_URL}/menu`} element={<Menu />} />
      <Route key={'route-manifest'} path={`${BASE_ORDER_URL}/manifest`} element={<Manifest />} />
      <Route key={'route-manifest-printer'} path={`${BASE_ORDER_URL}/manifest-printer`} element={<ManifestPrinter />} />
      <Route key={'route-banner'} path={`${BASE_WEB_URL}/advertising-banner`} element={<AdvertisingBanner />} />
      <Route key={'route-logo'} path={`${BASE_WEB_URL}/logo`} element={<Logo />} />
      <Route key={'route-info'} path={`${BASE_WEB_URL}/info`} element={<Info />} />
      <Route key={'route-company-image'} path={`${BASE_WEB_URL}/company-image`} element={<CompanyImage />} />
      <Route key={'route-static-page-details'} path={`${BASE_WEB_URL}/static-page/:type/id/:staticPageId`} element={<StaticPageDetails />} />
      <Route key={'route-static-page'} path={`${BASE_WEB_URL}/static-page`} element={<StaticPage />} />
      <Route key={'route-news-details'} path={`${BASE_WEB_URL}/news/:type/id/:newId`} element={<NewsDetails />} />
      <Route key={'route-news'} path={`${BASE_WEB_URL}/news`} element={<News />} />
      <Route key={'route-service'} path={`${BASE_WEB_URL}/service`} element={<Service />} />
      <Route key={'route-order-requirement-detail'} path={`${BASE_WEB_URL}/order-requirement/:role/:orderRequirementId/:type`} element={<OrderRequirementDetail />} />
      <Route key={'route-order-requirement'} path={`${BASE_WEB_URL}/order-requirement/:role`} element={<OrderRequirement />} />
      <Route key={'route-export-bill'} path={`${BASE_WEB_URL}/export-bill`} element={<ExportBill />} />
      <Route key={'route-export-bill-detail'} path={`${BASE_WEB_URL}/export-bill/:exportBillId/:type`} element={<ExportBillDetail />} />
      <Route key={'route-user'} path={`${BASE_SETTING_URL}/user`} element={<User />} />
      <Route key={'route-account-balance-list'} path={`${BASE_SETTING_URL}/account-balance-list`} element={<AccountBalanceList />} />
      <Route key={'route-customer-type'} path={`${BASE_SETTING_URL}/customer-type`} element={<CustomerTypeScreen />} />
      <Route key={'route-user-customer-type'} path={`${BASE_SETTING_URL}/user-customer-type`} element={<UserCustomerTypeScreen />} />
      <Route key={'route-receiver-info'} path={`${BASE_SETTING_URL}/receiver-info`} element={<ReceiverInfo />} />
      <Route key={'route-store'} path={`${BASE_SETTING_URL}/store`} element={<Store />} />
      <Route key={'route-account-balance'} path={`${BASE_SETTING_URL}/account-balance`} element={<AccountBalance />} />
      <Route key={'route-module'} path={`${BASE_SETTING_URL}/module`} element={<Module />} />
      <Route key={'route-role'} path={`${BASE_SETTING_URL}/role`} element={<Role />} />
      <Route key={'route-role'} path={`${BASE_SETTING_URL}/api`} element={<ApiScreen />} />
      <Route key={'route-email-template-detail'} path={`${BASE_SETTING_URL}/email-template/:type/id/:emailTemplateId`} element={<EmailTemplateDetail />} />
      <Route key={'route-email-template'} path={`${BASE_SETTING_URL}/email-template`} element={<EmailTemplate />} />
      <Route key={'route-printed-template-detail'} path={`${BASE_SETTING_URL}/printed-template/:type/id/:printedTemplateId`} element={<PrintedTemplateDetail />} />
      <Route key={'route-printed-template'} path={`${BASE_SETTING_URL}/printed-template`} element={<PrintedTemplate />} />
      <Route key={'route-notification-template-detail'} path={`${BASE_SETTING_URL}/notification-template/:type/id/:notificationTemplateId`} element={<NotificationTemplateDetail />} />
      <Route key={'route-notification-template'} path={`${BASE_SETTING_URL}/notification-template`} element={<NotificationTemplate />} />
      <Route key={'route-parameter'} path={`${BASE_SETTING_URL}/parameter`} element={<Parameter />} />
      <Route key={'route-sim-order-detail'} path={`${BASE_SIM_URL}/sim-order/:simOrderId/:type`} element={<SimOrderDetail />} />
      <Route key={'route-sim-order'} path={`${BASE_SIM_URL}/sim-order`}  element={<SimOrder />} />
      <Route key={'route-list-email'} path={`${BASE_SIM_URL}/list-email`}  element={<EmailList />} />
      <Route key={'route-guide-page-details'} path={`${BASE_SIM_URL}/guide-page/:type/id/:guidePageId`} element={<GuidePageDetails />} />
      <Route key={'route-guide-page'} path={`${BASE_SIM_URL}/guide-page`} element={<GuidePage />} />
      <Route key={'route-sim-news-details'} path={`${BASE_SIM_URL}/sim-news/:type/id/:simNewId`} element={<SimNewsDetail />} />
      <Route key={'route-sim-news'} path={`${BASE_SIM_URL}/sim-news`} element={<SimNews />} />
      <Route key={'route-sim-detail'} path={`${BASE_SIM_URL}/sim/:simId/:type`} element={<SimDetail />} />
      <Route key={'route-sim'} path={`${BASE_SIM_URL}/sim`} element={<Sim />} />
      <Route key={'route-sim-price'} path={`${BASE_SIM_URL}/sim-price`} element={<SimPrice />} />
      <Route key={'route-sim-store'} path={`${BASE_SIM_URL}/sim-store`} element={<SimStore />} />
      <Route key={'route-sim-oder-debit'} path={`${BASE_SIM_URL}/debit`} element={<Debit />} />
      <Route key={'route-monthly-subscription-fee'} path={`${BASE_SIM_URL}/monthly-subscription-fee`} element={<MonthlySubscriptionFee />} />
      <Route key={'route-product-order-detail'} path={`${BASE_ORDER_URL}/product-order/:productOrderId/:type`} element={<ProductOrderDetail />} />
      <Route key={'route-product-order'} path={`${BASE_ORDER_URL}/product-order`} element={<ProductOrder />} />
      <Route key={'route-ship-order-detail'} path={`${BASE_ORDER_URL}/ship-order/:role/:shipOrderId/:type`} element={<ShipOrderDetail />} />
      <Route key={'route-ship-order-scanner'} path={`${BASE_ORDER_URL}/ship-order-scanner/:status`}  element={<ShipOrderScanner />} />
      <Route key={'route-ship-ordrer'} path={`${BASE_ORDER_URL}/ship-order/:role`} element={<ShipOrder />} />
      <Route key={'route-ship-package'} path={`${BASE_ORDER_URL}/ship-package`} element={<ShipPackage />} />
      <Route key={'route-ship-declaration'} path={`${BASE_ORDER_URL}/ship-order-declaration`} element={<ShipOrderDeclaration />} />
      <Route key={'route-ship-package-detail'} path={`${BASE_ORDER_URL}/ship-package/:shipPackageId/:type`} element={<ShipPackageDetail />} />
      <Route key={'route-ship-invoice'} path={`${BASE_ORDER_URL}/ship-invoice`} element={<ShipInvoice />} />
      <Route key={'route-ship-invoice-detail'} path={`${BASE_ORDER_URL}/ship-invoice/:shipInvoiceId/:type`} element={<ShipInvoiceDetail />} />
      <Route key={'route-transfer-agent'} path={`${BASE_SETTING_URL}/transfer-agent`} element={<ReceiverOrder />} />
      <Route key={'route-sales-collatorabor'} path={`${BASE_SETTING_URL}/sales-collatorabor`} element={<SalesCollatorabor />} />
      <Route key={'route-receiver-ship-ordrer'} path={`${BASE_ORDER_URL}/receiver-ship-order`} element={<ReceiverShipOrder />} />
      <Route key={'route-purchase-account'} path={`${BASE_ORDER_URL}/purchase-account`}  element={<PurchaseAccount />} />
      <Route key={'route-special-ship'} path={`${BASE_SETTING_URL}/special-ship`}  element={<SpecialShip />} />
      <Route key={'route-payment'} path={`${BASE_ORDER_URL}/payment`}  element={<Payment />} />
      <Route key={'route-disbursement'} path={`${BASE_ORDER_URL}/disbursement`}  element={<Disbursement />} />
      <Route key={'route-customer-debt'} path={`${BASE_ORDER_URL}/customer-debt`}  element={<CustomerDebt />} />
      <Route key={'route-report-invoice'} path={`${BASE_REPORT_URL}/invoice`}  element={<InvoiceReport />} />
      <Route key={'route-report-detail'} path={`${BASE_REPORT_URL}/:type/id/:reportId`} element={<ReportDetail />} />
      <Route key={'route-report'} path={`${BASE_REPORT_URL}`} element={<Report />} />
      <Route key={'route-report-data'} path={`${BASE_REPORT_URL}/data/:reportId`}  element={<ReportData />} />
      <Route key={'route-stock-inspection'} path={`${BASE_WEB_URL}/stock-inspection`}  element={<StockInspection />} />
      <Route key={'route-shortlink'} path={`shortlink`}  element={<ShortLink />} />
    </Routes>
  );
};

export default AppRouters;
