/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MouseEvent } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, SalesCollatoraborType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useDeleteUserCustomerType } from 'src/api/userCustomerTypeApi';
import './SalesCollatorabor.css';
import { useGetSalesCollatorabor } from 'src/api/salesCollatoraborApi';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import SalesCollatoraborDetail from './SalesCollatoraborDetail/SalesCollatoraborDetail';
import InputModal from 'src/components/InputModal/InputModal';
import Printed from 'src/components/Printed/Printed';

//TODO
const SalesCollatorabor: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteUserCustomerType = useDeleteUserCustomerType();
  const getSalesCollatorabor = useGetSalesCollatorabor();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  //Local state
  const [count, setCount] = useState<number>();
  const [rawData, setRawData] = useState<SalesCollatoraborType[]>([]);
  const [salesCollatorabors, setSalesCollatorabors] = useState<SalesCollatoraborType[]>([]);
  const [checkList, setCheckList] = useState<SalesCollatoraborType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(SalesCollatoraborDetail);
  const numberModal = useModal(InputModal);
  const printedModal = useModal(Printed);

  const display = {
    header: [

      {
        code: 'userId',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'ID',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên',
      },
      {
        code: 'email',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Email',
      },
      {
        code: 'receiverOrderName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Đại lý',
      },
      {
        code: 'airPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Air / kg',
      },
      {
        code: 'seaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Sea / kg',
      },
      {
        code: 'volumeAirPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Air / khối',
      },
      {
        code: 'volumeSeaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước Sea / khối',
      },
      {
        code: 'overAirPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước quá khổ Air / kg',
      },
      {
        code: 'overSeaPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Cước quá khổ Sea / kg',
      },
      {
        code: 'address',
        dataType: EnumDataType.HTML,
        isOptions: false,
        convert: (value: string) => {
          return (<div>
            {(value? JSON.parse(value) as [] : []).map((item, index) => (
              <div key={`option${index}`}>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.name}</span>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.phone}</span>
                <span style={{margin: "2px", borderRadius: "5px", padding: "2px 5px", background: "black", color: "orange"}}>{item?.address}</span>
              </div>
            ))}
          </div>
          );
        },
        title: 'Địa chỉ',
      },
    ] as TableColumnType[]
  };

  //Menucontext
  const onView = (salesCollatoraborId: number) => {
    detailModal.handlePresent(
      {
        salesCollatoraborId: salesCollatoraborId,
        isDisable: true,
        postProcess: confirmModal.handleDismiss,
      },
      'CHI TIẾT THÔNG TIN CTV',
    );
  };

  const onEdit = (salesCollatoraborId: number) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        salesCollatoraborId: salesCollatoraborId,
        postProcess: () => {
          confirmModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'THAY ĐỔI THÔNG TIN CTV',
    );
  };

  const onDelete = (salesCollatoraborId: number) => {
    const onConfirm = () => {
      deleteUserCustomerType(salesCollatoraborId)
        .then(() => {
          const idx = checkList.findIndex(i => i.salesCollatoraborId == salesCollatoraborId);
          if(idx >= 0) {
            checkList.splice(idx, 1);
            setCheckList([...checkList]);
          }
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa thông tin CTV thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa ctv vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA CTV VẬN CHUYỂN',
    );

  };


  const menuContext = (item: SalesCollatoraborType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.salesCollatoraborId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.salesCollatoraborId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.salesCollatoraborId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClickCheckbox = (item: SalesCollatoraborType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != item);
      setCheckList(nCheckList);
      setSalesCollatorabors(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.salesCollatoraborId == i.salesCollatoraborId),
        ),
      );
    } else {
      setCheckList([...checkList, item]);
      setSalesCollatorabors(salesCollatorabors.filter((o) => o != item));
    }
  };

  //Function in the listButton
  const onAddNewSalesCollatorabor = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: () => {
          confirmModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'THÊM MỚI CTV VẬN CHUYỂN',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewSalesCollatorabor,
      align: 'center',
    }
  ];


  //useEffect
  useEffect(() => {
    getSalesCollatorabor(page, size)
      .then((data) => {
        const nCheckList = data.items.filter(i => checkList.some((c) => c.salesCollatoraborId == i.salesCollatoraborId));
        setCheckList(nCheckList);
        setRawData(data.items);
        setSalesCollatorabors(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getSalesCollatorabor, page, size, reloadFlag]);

  //Main
  return (
    <>
    <ToolBar
      toolbarName={'DANH SÁCH CTV VẬN CHUYỂN'}
      listRightButton={listButton}
      width={'100%'}
      backgroundColor={'#ebe9e9'}
      count={count}
      onChangePage={setPage}
      onChangeSize={setSize}
      page={page}
      size={size}
      isPaging={true}
    />

    <Table
      display={display}
      isInput={false}
      data={salesCollatorabors}
      menuContext={menuContext}
      checkedData={checkList}
      allowCheckbox={true}
      onDoubleClick={(item) => onView(item.salesCollatoraborId)}
      onClickCheckbox={onClickCheckbox}
      isNo={true}
    />
  </>
  );
};
export default SalesCollatorabor;
