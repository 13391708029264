/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL, BASE_WEB_URL } from 'src/constants';
import './ExportBill.css';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { useDebounce } from 'use-debounce';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ListView from 'src/components/ListView/ListView';
import Printed from 'src/components/Printed/Printed';
import InputModal from 'src/components/InputModal/InputModal';
import { EnumAction, EnumDataType, EnumExportBillStatus, EventButton, ExportBillStatus, ExportBillType } from 'src/api/models';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useDeleteExportBill, useGetExportBills } from 'src/api/exportBillApi';
import ExportBillDetail from './ExportBillDetail/ExportBillDetail';

//TODO
const ExportBill: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const header = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'status',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái',
        options: ExportBillStatus,
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
      {
        code: 'createdBy',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người tạo',
      },
    ] as TableColumnType[]
  };

  const display: ItemDisplayType<ExportBillType> = {
    header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      },
      {
        code: 'status',
        dataType: EnumDataType.Text,
        isOptions: true,
        options: ExportBillStatus,
      },
    ],
    detail: {
      info: [
        [
          {
            code: 'note',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              flex: 'auto'
            }
          }
        ]
      ]
    },

    actions: (item: ExportBillType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.exportBillId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.exportBillId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  //State
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [count, setCount] = useState<number>(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [rawData, setRawData] = useState<ExportBillType[]>([]);
  const [exportBills, setExportBills] = useState<ExportBillType[]>([]);
  const [checkList, setCheckList] = useState<ExportBillType[]>([]);
  const [status, setStatus] = useState<EnumExportBillStatus>();
  //Function
  const addPopup = useAddPopup();
  const getExportBill = useGetExportBills();
  const deleteExportBill = useDeleteExportBill();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ExportBillDetail);
  const slideBar = useSlideBar(ExportBillDetail);

  const onClickCheckbox = (exportBill: ExportBillType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != exportBill);
      setCheckList(nCheckList);
      setExportBills(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.exportBillId == i.exportBillId),
        ),
      );
    } else {
      setCheckList([...checkList, exportBill]);
      setExportBills(exportBills.filter((o) => o != exportBill));
    }
  };

  //Menucontext
  const onView = (exportBillId: number) => {
    const url = `${BASE_WEB_URL}/export-bill/${exportBillId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (exportBillId: number) => {
    const url = `${BASE_WEB_URL}/export-bill/${exportBillId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (exportBillId: number) => {
    const onConfirm = () => {
      deleteExportBill(exportBillId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn xuất kho thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa phiếu xuất kho này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA PHIẾU XUẤT KHO',
    );
  };


  const menuContext = (item: ExportBillType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.exportBillId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.exportBillId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.exportBillId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClick = (exportBill: ExportBillType) => {
    slideBar.handlePresent(
      {
        exportBillId: exportBill.exportBillId,
        postProcess: () => setReloadFlag(!reloadFlag)
      }
    );
  };

  const listButton: EventButton[] = [
  ];

  //useEffect
  useEffect(() => {
    getExportBill(page, size, status).then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setExportBills(
          data.items.filter(
            (i) => !checkList.some((c) => c.exportBillId == i.exportBillId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getExportBill, page, status, reloadFlag, size]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH PHIẾU XUẤT'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onChangePage={setPage}
        onChangeSize={setSize}
        page={page}
        size={size}
        isPaging={true}
      />

      {window.innerWidth > 600? <Table
          display={header}
          isInput={false}
          checkedData={checkList}
          data={exportBills}
          menuContext={menuContext}
          allowCheckbox={true}
          onDoubleClick={(item) => onView(item.exportBillId)}
          onClickCheckbox={onClickCheckbox}
          onClick={onClick}
          isNo={true}
        /> : <ListView
          display={display}
          isInput={false}
          data={exportBills}
          onDoubleClick={(item) => onView(item.exportBillId)}
          isNo={true}
        />}
      </>
  );
};
export default ExportBill;
