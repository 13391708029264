/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Input from '../Input/Input';
import { useAddPopup } from 'src/state/application/hooks';


interface NomalProductProps {
  width?: string;
  title?: string;
  disabled?: boolean;
  require?: boolean;
  onChange?: (value: NomalProductType[]) => void;
  nomalProduct?: NomalProductType[];
  noneBorder?: boolean;
  inputPrice?: boolean;
}

export type NomalProductType = {
  product: string;
  quantity: number;
  price?: number;
}

const NomalProduct = (props: NomalProductProps) => {
  const { width, title, disabled, require, onChange, nomalProduct, noneBorder, inputPrice } = props;
  const [inputCode, setInputCode] = useState<string>();
  const addPopup = useAddPopup();
  const onInsert = (v: string) => {
    const code = v?.trim();
    if(code?.length > 0) {
      const product = nomalProduct.find(p => p.product == code);
      if(product) {
        product.quantity++;
        onChange([...nomalProduct]);
      } else {
        onChange([...nomalProduct, {product: code, quantity: 1}]);
      }
    }
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if(event.key == 'Enter') {
      onInsert(inputCode);
      setInputCode(undefined);
    }
  }

  return (
    <StyledOptionWrapper width={width} noneBorder={noneBorder}>
      {title ? (
        <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle>
      ) : null}
      <StyledOptionContainer width={width}>
        {nomalProduct.map((option, index) => (
          <StyledOption key={`option${index}`}>
            <Input
              value={option.product}
              onChange={(value) => {
                option.product = value;
                onChange([...nomalProduct]);
              }}
              isCopy={true}
            />
            {inputPrice? <StyledPrice 
              type='number'
              value={option.price}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value;
                option.price = value? isNaN(Number(value))? 0 : Number(value) : 0;
                onChange([...nomalProduct]);
              }}
              onBlur={() => {
                if(option.quantity < 1) {
                  onChange(nomalProduct.filter((o) => o != option));
                }
              }}
            /> : null} 
            <StyledMultiplicationIcon className="material-icons" onClick={() => {
              if(option.quantity > 1) {
                option.quantity--;
                onChange([...nomalProduct]);
              } else {
                onChange(nomalProduct.filter((o) => o != option));
              }
            }}>arrow_drop_down</StyledMultiplicationIcon>
            <StyledQuantity
              type='number'
              value={option.quantity}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value;
                option.quantity = value? isNaN(Number(value))? 0 : Number(value) : 0;

                if(option.quantity > 100000000) {
                  addPopup({
                    error: {
                      message: 'Số lượng không đúng. Vui lòng kiểm tra lại!',
                      title: 'Đã có lỗi xảy ra!',
                    },
                  });
                } else {
                  onChange([...nomalProduct]);
                }
              }}
              onBlur={() => {
                if(option.quantity < 1) {
                  onChange(nomalProduct.filter((o) => o != option));
                }
              }}
            />
            <StyledMultiplicationIcon className="material-icons" onClick={() => {
              option.quantity++;
              onChange([...nomalProduct]);
            }}>arrow_drop_up</StyledMultiplicationIcon>
            {!disabled && (
              <StyledClearIcon
                className="material-icons"
                onClick={() => {
                  onChange(nomalProduct.filter((o) => o != option));
                }}
              >
                clear
              </StyledClearIcon>
            )}


          </StyledOption>
        ))}
        {!disabled && (
          <StyledTool>
            <StyledAddIcon className="material-icons" onClick={() => {
              onInsert(inputCode);
              setInputCode(undefined);
            }}>
              add_circle
            </StyledAddIcon>
            <Input
              value={inputCode}
              disabled={disabled}
              onChange={setInputCode}
              type='text'
              onKeyDown={onKeyDown}
            />
          </StyledTool>

        )}
      </StyledOptionContainer>
    </StyledOptionWrapper>
  );
};

const StyledAddIcon = styled.span`
  cursor: pointer;
  margin: auto 5px auto 0;
`
const StyledTool = styled.div`
  margin: 5px 0 0 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`
const StyledMultiplicationIcon = styled.span`
  margin: auto 0;
  cursor: pointer;
`

const StyledQuantity = styled.input`
  margin: auto;
  width: 50px;
  outline: none;
  border: 2px solid gray;
  padding: 0 5px;
  border-radius: 5px;
`
const StyledPrice = styled.input`
  margin: auto 0 auto 4px;
  width: 90px;
  outline: none;
  border: 2px solid gray;
  padding: 0 5px;
  border-radius: 5px;
`
const StyledClearIcon = styled.span`
  position: absolute;
  top: -6px;
  left: -6px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledOptionWrapper = styled.fieldset<{ width?: string; noneBorder?: boolean; }>`
  ${({ noneBorder }) => (noneBorder? "" : "border: 1px solid #dddcdc;")}
  flex: 1;
  height: fit-content;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 0 10px 0px 10px;
  border-radius: 5px;
  max-width: 90vw;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const StyledOptionContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  min-width: ${({ width }) => (width ? `calc(${width} - 20px)` : '240px')};
  max-width: 80vw;
  padding: 5px 0;
`;

const StyledOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2px;
  margin: 2px 3px;
`;


export default NomalProduct;
