import './ProductExcel.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import {
  useDownLoadFullProductExcelTemplate,
  useDownLoadProductExcelTemplate,
  useExportProductExcel,
} from 'src/api/productApi';
import ProductImportExcel from './ProductImportExcel/ProductImportExcel';
import useModal from 'src/hooks/useModal';


const ProductExcel: React.FC = () => {
  //Function
  const addPopup = useAddPopup();

  const downLoadProductExcelTemplate = useDownLoadProductExcelTemplate();
  const downLoadFullProductExcelTemplate = useDownLoadFullProductExcelTemplate();
  const exportProductExcel = useExportProductExcel();

  const productImportModal = useModal(ProductImportExcel);


  const onDownloadProductExcelTemplate = () => {

    downLoadProductExcelTemplate()
      .then((data) => {
        console.log(data);
        const productRealmTemplate = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = productRealmTemplate;
        tempLink.setAttribute('download', 'product-template.xlsx');
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  };

  const onDownloadFullProductExcelTemplate = () => {

    downLoadFullProductExcelTemplate()
      .then((data) => {
        console.log(data);
        const productRealmTemplate = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = productRealmTemplate;
        tempLink.setAttribute('download', 'product-template.xlsx');
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  };

  const onExportProductExcel = () => {

    exportProductExcel()
      .then((data) => {
        console.log(data);
        const productRealmTemplate = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = productRealmTemplate;
        tempLink.setAttribute('download', 'products.xlsx');
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  };

  const onImportProductExcel = (isFull: boolean) => {
    productImportModal.handlePresent({
      isFull: isFull
    }, "IMPORT SẢN PHẨM");
  };

  //End of function

  //Component
  const buttonComponent = () => {
    return (
      <div className="product-excel-container">
        <div className="row">
          <div className="col-sm-12">
            <button
              className="btn-add-realm m-3 bg-success"
              onClick={() => {
                onExportProductExcel();
              }}
            >
              Export DS sản phẩm
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <button
              className="btn-add-realm m-3"
              onClick={() => {
                onDownloadProductExcelTemplate();
              }}
            >
              Download template
            </button>
          </div>
          <div className="col-sm-6">
            <button
              className="btn-add-realm m-3"
              onClick={() => {
                onImportProductExcel(false);
              }}
            >
              Import DS sản phẩm
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <button
              className="btn-add-realm m-3"
              onClick={() => {
                onDownloadFullProductExcelTemplate();
              }}
            >
              Download template tổng hợp
            </button>
          </div>
          <div className="col-sm-6">
            <button
              className="btn-add-realm m-3"
              onClick={() => {
                onImportProductExcel(true);
              }}
            >
              Import DS sản phẩm tổng hợp
            </button>
          </div>
        </div>
      </div>
    );
  };
  //End of component

  //Main
  return <div className="realm-import-excel_contaier">{buttonComponent()}</div>;
};

export default ProductExcel;
