/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ReceiverShipOrder.css';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Input from 'src/components/Input';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useGetShipOrderByCode } from 'src/api/shipOrderApi';
import { useDebounce } from 'use-debounce';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EnumShipOrderProgressStatus, EventButton, ShipOrderType } from 'src/api/models';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ShipOrderDetail from '../ShipOrderDetail/ShipOrderDetail';

const ReceiverShipOrder: React.FC = () => {
  //State
  const [code, setCode] = useState<string>();
  const [codeDebound] = useDebounce(code, 500);
  const qrReaderModal = useModal(QRCodeReader);
  const navigate = useNavigate();
  const getShipOrderByCode = useGetShipOrderByCode();
  const addPopup = useAddPopup();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ShipOrderDetail);

  useEffect(() => {
    const strCode = codeDebound as string;
    if(strCode?.length >= 8) getShipOrderByCode(strCode).then((res) => {
      if(res.progressStatus == EnumShipOrderProgressStatus.Waiting) {
        detailModal.handlePresent({
          shipOrderId: res.shipOrderId,
          isDisable: false,
          isReceiver: true,
          postProcess: (data: ShipOrderType) => {
            detailModal.handleDismiss();
            setCode(undefined);
          },
        }, "TIẾP NHẬN ĐƠN VẬN CHUYỂN");
      } else {
        const onConfirm = () => {
          navigate(`${BASE_ORDER_URL}/ship-order/${res.shipOrderId}/${EnumViewType.View}`)
        };
        const listButton: EventButton[] = [
          {
            name: 'Tiếp tục',
            icon: 'check',
            actionType: EnumAction.Confirm,
            action: onConfirm,
            buttonClass: 'info',
            align: 'center',
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'info',
            align: 'center',
          },
        ];
        confirmModal.handlePresent(
          {
            question: 'Đơn vận chuyển đã được tiếp nhận trước đó. Bạn có muốn chuyển tới thông tin đơn không?',
            listActionButton: listButton,
            postProcess: confirmModal.handleDismiss,
          },
          'ĐƠN VẬN CHUYỂN ĐÃ TIẾP NHẬN',
        );
      }
    }).catch((error) => {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: error.errorMessage,
        },
      });
    })
  }, [addPopup, codeDebound, getShipOrderByCode, navigate])

  //Main
  return (
    <>
      <ToolBar
        toolbarName='TIẾP NHẬN ĐƠN VẬN CHUYỂN'
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
        isBack={true}
      />
      <div className='receiver-ship-order-container'>
        <div className='receiver-ship-order-row'>
          <Input
            width='320px'
            title="Mã phiếu / Mã vận chuyển JP"
            require={true}
            disabled={false}
            value={code}
            onChange={setCode}
          />
          <div className="i-tooltip receiver-shiporder-qr">
            <span className="material-icons info-guide" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (code: string) => {
                  setCode(code);
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN")
            }}>qr_code</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiverShipOrder;
