/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Input from '../Input/Input';
import { useAddPopup } from 'src/state/application/hooks';

interface InputDataListProps<T> {
  width?: string;
  title?: string;
  disabled?: boolean;
  require?: boolean;
  onChange?: (value: T[]) => void;
  data?: T[];
  fields?: InputDataField[];
}

export type InputDataField = {
  name?: string;
  title?: string;
}

const InputDataList = <T, > (props: InputDataListProps<T>) => {
  const { width, title, disabled, require, onChange, data, fields } = props;
  const [value, setValue] = useState<any>();
  const addPopup = useAddPopup();

  return (
    <StyledOptionWrapper width={width}>
      {title ? (
        <StyledTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
        </StyledTitle>
      ) : null}
      <StyledOptionContainer width={width}>
        {data.map((item, index) => (
          <StyledOption key={`option${index}`}>
            {fields && fields.length > 0? fields.map((field) => <Input
              key={`option${index}${field.name}`}
              value={eval(`item?.${field.name}??""`)}
              disabled={true}
            />) : <Input
              value={item as string}
              disabled={true}/>}
            {!disabled && (
              <StyledClearIcon
                className="material-icons"
                onClick={() => {
                  onChange(data.filter((i) => i != item));
                }}
              >
                clear
              </StyledClearIcon>
            )}

          </StyledOption>
        ))}
        {!disabled && (<StyledTool>
          <StyledAddIcon className="material-icons" onClick={() => {
            onChange([...data, value as T]);
            setValue(undefined);
          }}>
            add_circle
          </StyledAddIcon>
          {fields && fields.length > 0? fields.map((field) => <Input
            key={`input${field.name}`}
            title={field.title}
            value={eval(`value?.${field.name}??""`)}
            disabled={disabled}
            onChange={(v) => {
              const nValue = value? {...value} : {};
              nValue[field.name] = v;
              setValue(nValue);
            }}
            type='text'
          />) : <Input
            value={value}
            disabled={disabled}
            onChange={(v) => {
              setValue(v);
            }}
            type='text'/>}
        </StyledTool>)}
      </StyledOptionContainer>
    </StyledOptionWrapper>
  );
};

const StyledTool = styled.div`
  margin: 5px 0 0 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const StyledCopyIcon = styled.span`
  cursor: pointer;
  margin: 5px;
  position: absolute;
  right: 10px;
  top: 0;
  background: white;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  &:active {
    opacity: 0;
    transition: all 0.8s;
  }
  &:hover {
    color: gray;
  }

`

const StyledAddIcon = styled.span`
  cursor: pointer;
  margin: auto 5px auto 0;
`

const StyledClearIcon = styled.span`
  position: absolute;
  top: -2px;
  left: 0px;
  color: white;
  background-color: #f13838;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const StyledOptionWrapper = styled.fieldset<{ width?: string }>`
  border: 1px solid #dddcdc;
  flex: 1;
  height: fit-content;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 0 10px 0px 10px;
  border-radius: 5px;
`;

const StyledTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const StyledOptionContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  min-width: ${({ width }) => (width ? `calc(${width} - 20px)` : '240px')};
  max-width: 80vw;
  padding: 5px 0;
`;

const StyledOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2px;
  margin: 2px 3px;
`;


export default InputDataList;
