
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { SpecialShipType } from './models';

export const useGetSpecialShip = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/special-ship`,
      method: 'get',
    }) as Promise<SpecialShipType[]>;
  }, [fetch]);
};

export const useGetSpecialShipById = () => {
  const fetch = useFetch();
  return useCallback(
    (specialShipId: number) => {
      return fetch({
        url: `sccore/rest-api/special-ship/${specialShipId}`,
        method: 'get',
      }) as Promise<SpecialShipType>;
    },
    [fetch],
  );
};

export const usePostSpecialShip = () => {
  const fetch = useFetch();
  return useCallback(
    (specialShip: SpecialShipType) => {
      return fetch({
        url: `sccore/rest-api/special-ship`,
        method: 'post',
        data: specialShip,
      }) as Promise<SpecialShipType>;
    },
    [fetch],
  );
};

export const usePutSpecialShip = () => {
  const fetch = useFetch();
  return useCallback(
    (specialShip: SpecialShipType) => {
      return fetch({
        url: `sccore/rest-api/special-ship`,
        method: 'put',
        data: specialShip,
      }) as Promise<SpecialShipType>;
    },
    [fetch],
  );
};

export const useDeleteSpecialShip = () => {
  const fetch = useFetch();
  return useCallback(
    (specialShipId: number) => {
      return fetch({
        url: `sccore/rest-api/special-ship/${specialShipId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

//End of  SpecialShip
