/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ShipOrderDeclaration.css';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Input from 'src/components/Input';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useGetShipOrderByCode, usePrintSubQR, usePutShipOrder } from 'src/api/shipOrderApi';
import { useDebounce } from 'use-debounce';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumShipOrderProgressStatus, ShipOrderType } from 'src/api/models';
import { useNavigate } from 'react-router-dom';
import NomalProduct from 'src/components/NomalProduct';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import ImageUpload from 'src/components/ImageUpload';
import SpecialProduct from 'src/components/SpecialProduct';
import { SpecialProductType } from 'src/components/SpecialProduct/SpecialProduct';
import Printed from 'src/components/Printed/Printed';
import Checkbox from 'src/components/Checkbox';
import { InlineRow } from 'src/components/Layout';
import { randomvnmoney } from 'src/utils/numberUtils';

const ShipOrderDeclaration: React.FC = () => {
  //State
  const [code, setCode] = useState<string>();
  const [data, setData] = useState<ShipOrderType>();
  const [codeDebound] = useDebounce(code, 500);
  const qrReaderModal = useModal(QRCodeReader);
  const navigate = useNavigate();
  const getShipOrderByCode = useGetShipOrderByCode();
  const addPopup = useAddPopup();
  const putShipOrder =  usePutShipOrder();
  const [nomalProduct, setNomalProduct] = useState<NomalProductType[]>([]);
  const [specialProduct, setSpecialProduct] = useState<SpecialProductType[]>([]);
  const [transportFee, setTransportFee] = useState<number>();
  const [nomalFee, setNomalFee] = useState<number>();
  const [description, setDescription] = useState<string>();
  const [isConsignment, setConsignment] = useState<boolean>(false);
  const [isTaxRefunt, setTaxRefunt] = useState<boolean>(false);
  const [subNumber, setSubNumber] = useState<number>();
  const [subInfo, setSubInfo] = useState<{[subQR: string]: {weight: number; price: number; products: NomalProductType[]}}>({});
  
  const printedModal = useModal(Printed);
  const printSubQR = usePrintSubQR();

  useEffect(() => {
    const strCode = codeDebound as string;
    if(strCode?.length >= 8) getShipOrderByCode(strCode).then((res) => {
      setData(res);
      setNomalProduct(res.nomalProduct?JSON.parse(res.nomalProduct):[]);
      const special = res.specialProduct?JSON.parse(res.specialProduct) as SpecialProductType[]:[];
      setSpecialProduct(special);
      const specialFee = special? special.filter(p => p.price > 0 && p.quantity > 0).reduce((total, p) => total += (p.price * p.quantity), 0) : 0;
      setTransportFee(res.transportFee);
      setNomalFee((res.transportFee??0) - specialFee);
      setDescription(res.description);
      setSubNumber(res.subNumber);
      setConsignment(res.subNumber > 0);
      setSubInfo(res.subInfo?JSON.parse(res.subInfo):{});
    }).catch((error) => {
      reset();
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: error.errorMessage,
        },
      });
    })
  }, [addPopup, codeDebound, getShipOrderByCode, navigate])

  const reset = () => {
    setCode(undefined);
    setData(undefined);
    setNomalProduct(undefined);
  }

  const changeSpecialProduct = (value: SpecialProductType[]) => {
    const newSpecialFee = value? value.filter(p => p.price > 0 && p.quantity > 0).reduce((total, p) => total += (p.price * p.quantity), 0) : 0;
    setTransportFee(nomalFee + newSpecialFee);
    setSpecialProduct(value);
  }

  const onSave = () => new Promise((resolve) => {
    const shipOrder: ShipOrderType = {...data};
    shipOrder.nomalProduct = JSON.stringify(nomalProduct);
    shipOrder.specialProduct = JSON.stringify(specialProduct);
    shipOrder.transportFee = transportFee;
    shipOrder.description = description;
    shipOrder.subNumber = isConsignment? subNumber : undefined;
    shipOrder.subInfo = isConsignment? JSON.stringify(subInfo): undefined;
    putShipOrder(shipOrder).then((res) => {
      reset();
      addPopup({
        txn: {
          success: true,
          summary: 'Khai báo hàng hóa thành công',
        }
      });
      resolve(true);
    })
    .catch((error) => {
      addPopup({
        error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
      });
      resolve(false);
    });
  });

  const mergeInvoice = (value: {[subQR: string]: {weight: number; price: number; products: NomalProductType[]}}) => {
    const nProducts: NomalProductType[] = [];
    Object.entries(value).map(([subQR, info]) => {
      info.products.forEach((item) => {
        const product = nProducts.find(p => p.product == item.product);
        if(product) {
          product.quantity += item.quantity;
        } else {
          nProducts.push({product: item.product, quantity: item.quantity});
        }
      })
    });
    setNomalProduct(nProducts);
  }

  const onChangeSubNumber = (value: number) => {
    const nInfo: {[subQR: string]: {weight: number; price: number; products: NomalProductType[]}} = {};
    for(let i = 1; i <= value; i++) {
      const subQR = `${data.billCode}.${i}`;
      if(Object.prototype.hasOwnProperty.call(subInfo, subQR)) {
        nInfo[subQR] = subInfo[subQR];
      } else {
        nInfo[subQR] = {weight: 0, price: randomvnmoney(400000, 1000000), products: []};
      }
    }
    setSubInfo(nInfo);
    mergeInvoice(nInfo)
    setSubNumber(value);
  }

  const onPrintSubQR = () => {
    if(subNumber > 0) {
      printSubQR(data.shipOrderId, subNumber).then((res) => {
        printedModal.handlePresent(
          {
            values: res,
          },
          'IN SUBQR',
        );
      })
    } else {
      addPopup({
        error: { message: "Vui lòng nhập số lượng gói", title: 'Đã có lỗi xảy ra!' },
      });
    }
  }


  //Main
  return (
    <>
      <ToolBar
        toolbarName='KHAI BÁO HÀNG HÓA VẬN CHUYỂN'
        listRightButton={[]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
        isBack={true}
      />
      <div className='ship-order-declaration-container'>
        <div className='ship-order-declaration-row'>
          <Input
            width='380px'
            title="Mã đơn vận chuyển"
            require={true}
            disabled={false}
            value={code}
            onChange={(value) => setCode(value?.trim())}
          />
          <div className="i-tooltip ship-order-declaration-qr">
            <span className="material-icons info-guide" onClick={() => {
              qrReaderModal.handlePresent({
                readQR: (code: string) => {
                  setCode(code);
                  qrReaderModal.handleDismiss();
                }
              }, "QUÉT MÃ VẬN CHUYỂN")
            }}>qr_code</span>
          </div>
        </div>
        {data? <div className='ship-order-declaration-data'>
          <ImageUpload
            images={data.images}
            showOnlyOne={true}
          />
          <div className="ship-order-declaration-row">
            <Input
              width="360px"
              title="Đại lý gửi hàng"
              require={false}
              disabled={true}
              value={data.receiverOrderName}
            />
          </div>
          <div className="ship-order-declaration-row">
            <Input
              width="360px"
              title="Người gửi"
              require={false}
              disabled={true}
              value={data.senderName}
            />
          </div>
          <div className="ship-order-declaration-row">
            <Input
              width="360px"
              title="Tên người nhận"
              require={false}
              disabled={true}
              value={data.receiverName}
            />
          </div>
          <div className="ship-order-declaration-row inline">
            <Input
              width="120px"
              title="Dài (cm)"
              require={false}
              disabled={true}
              value={data.length / 10}
              type='number'
            />
            <Input
              width="120px"
              title="Rộng (cm)"
              require={false}
              disabled={true}
              value={data.width / 10}
              type='number'
            />
            <Input
              width="120px"
              title="Cao (cm)"
              require={false}
              disabled={true}
              value={data.height / 10}
              type='number'
            />
          </div>
          <div className="ship-order-declaration-row inline">
            <Input
              width="180px"
              title="Khối lượng (kg)"
              require={false}
              disabled={true}
              value={data.weight / 1000}
              type='number'
            />
            <Input
              width="180px"
              title="Đơn giá (VNĐ/kg)"
              require={false}
              disabled={true}
              value={data.price}
              type='number'
              step={1000}
            />
          </div>
          <div className="ship-order-declaration-row inline">
            <Input
              width="180px"
              title={`Giảm giá`}
              require={false}
              disabled={true}
              value={data.discount}
              type='number'
              currency="VND"
            />
            <Input
              width="180px"
              title={`Phí v.chuyển`}
              require={false}
              disabled={true}
              value={transportFee}
              inputPadding="3px 10px 3px 3px"
              type='number'
              currency="VND"
            />
          </div>
          <div className="ship-order-declaration-row">
            <SpecialProduct
              width="360px"
              title="DS hàng hóa đặc biệt"
              specialProduct={specialProduct}
              onChange={changeSpecialProduct}
              disabled={false}
            />
          </div>
          <div className="ship-order-declaration-row">
            <Input
              width="360px"
              title="Mô tả"
              require={false}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="ship-order-declaration-row">
            <NomalProduct
              width="360px"
              title="DS hàng hóa khai Invoice"
              nomalProduct={nomalProduct}
              onChange={setNomalProduct}
              disabled={isConsignment}
            />
          </div>
          <div className="ship-order-declaration-row">
            <Checkbox
              width='360px'
              value={isConsignment}
              onChange={(value) => {
                if(value) mergeInvoice(subInfo);
                setConsignment(value);
              }}
              title='Khai báo hàng hóa ký gửi'
            />
          </div>
          {isConsignment && <>
            <div className="ship-order-declaration-row">
              <InlineRow width='360px'>
                <Checkbox
                  width='150px'
                  value={isTaxRefunt}
                  onChange={setTaxRefunt}
                  title='Hoàn thuế'
                />
                <Input
                  title={`Số lượng gói`}
                  require={false}
                  value={subNumber}
                  onChange={onChangeSubNumber}
                  inputPadding="3px 10px 3px 3px"
                  type='number'
                />
                <span className="material-icons print-sub-qr"
                  onClick={onPrintSubQR}
                >
                  print
                </span>
              </InlineRow>
            </div>
        
            {Object.entries(subInfo).map(([subQR, value]) => {
              return <fieldset className='ship-order-declaration-row sub-qr-declaration' key={subQR}>
                <legend className='sub-qr-title'>{subQR}</legend>
                <InlineRow width='360px'>
                  <Input
                    title="Khối lượng (kg)"
                    require={false}
                    value={subInfo[subQR].weight / 1000}
                    onChange={(value) => {
                      const nInfo = {...subInfo};
                      nInfo[subQR].weight = value * 1000;
                      setSubInfo(nInfo);
                    }}
                    type='number'
                  />
                  <Input
                    title="Giá tiền VN (VNĐ)"
                    require={false}
                    value={subInfo[subQR].price}
                    onChange={(value) => {
                      const nInfo = {...subInfo};
                      nInfo[subQR].price = value;
                      setSubInfo(nInfo);
                    }}
                    type='number'
                  />
                </InlineRow>
               
                <NomalProduct
                  noneBorder={true}
                  width="360px"
                  nomalProduct={subInfo[subQR].products}
                  onChange={(value) => {
                    const nInfo = {...subInfo};
                    nInfo[subQR].products = value;
                    setSubInfo(nInfo);
                    mergeInvoice(nInfo);
                  }}
                  disabled={false}
                  inputPrice={isTaxRefunt}
                />
              </fieldset>
            })}
          </>}
          <div className="ship-order-declaration-row">
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
          </div>
        </div> : null}
      </div>
    </>
  );
};

export default ShipOrderDeclaration;
