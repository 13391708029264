/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumProductOrderProgressStatus,
  EnumProductOrderProgressStatusTitle,
  EnumTransportType,
  ImageType,
  ProductOrderStatus,
  ProductOrderType,
  ProfileInfo,
  PurchaseAccountType,
  ReceiverOrderType,
  SalesCollatoraborType,
  TransportType,
} from 'src/api/models';
import { useGetProductOrder, useGetProductOrderById, usePostProductOrder, usePutProductOrder } from 'src/api/productOrderApi';
import { useGetReceiverOrderById } from 'src/api/receiverOrderApi';
import { useGetSalesCollatoraborByUser } from 'src/api/salesCollatoraborApi';
import { useGetUserList } from 'src/api/userApi';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Checkbox from 'src/components/Checkbox';
import ContactList from 'src/components/ContactList/ContactList';
import ImageUpload from 'src/components/ImageUpload';
import Input from 'src/components/Input';
import NomalProduct from 'src/components/NomalProduct';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import Note from 'src/components/Note/Note';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import SelectSearchComponent from 'src/components/SelectSearchComponent/SelectSearchComponent';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { BASE_ORDER_URL } from 'src/constants';
import { useAddPopup, useReloadTable, useRemovePopup } from 'src/state/application/hooks';
import styled from 'styled-components';
import './ProductOrderDetail.css';
import { useGetExchangeRate, useParseFromUrl } from 'src/api/commonInfomationApi';
import Avatar from 'src/components/Avatar';
import { number2jpmoney, number2vnmoney } from 'src/utils/numberUtils';
import { useGetAdminPurchaseAccount } from 'src/api/purchaseAccountApi';

interface IProductOrderDetail {
  productOrderId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ProductOrderDetail: React.FC<IProductOrderDetail> = (props) => {
  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();
  //State
  const [productOrderId, setProductOrderId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const params = useParams<{type: string; productOrderId: string }>();
  const [transportType, setTransportType] = useState<EnumTransportType>(EnumTransportType.HNAir);
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [product, setProduct] = useState<NomalProductType[]>([]);
  const [productUrl, setProductUrl] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [note, setNote] = useState<string>();
  const [japanTrackingCode, setJapanTrackingCode] = useState<string>();
  const [productPrice, setProductPrice] = useState<number>();
  const [exchangeRate, setExchangeRate] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<EnumProductOrderProgressStatus>(EnumProductOrderProgressStatus.Waiting);
  const [isPaid, setPaid] = useState<boolean>();
  const [paymentCode, setPaymentCode] = useState<string>();
  const [discount, setDiscount] = useState<number>();
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [receiverAddress, setReceiverAddress] = useState<string>();
  const [orderFee, setOrderFee] = useState<number>();
  const [orderPercent, setOrderPercent] = useState<number>();
  const [transportFee, setTransportFee] = useState<number>();
  const [shipOrderId, setShipOrderId] = useState<number>();
  const [avatar, setAvatar] = useState<string>();
  const [keyword, setKeyword] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [receiverOrder, setReceiverOrder] = useState<ReceiverOrderType>();
  const [suggessAddress, setSuggessAddress] = useState<any>();
  const [salesCollatorabor, setSalesCollatorabor] = useState<SalesCollatoraborType>();
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);
  const [purchaseAccounts, setPurchaseAccounts] = useState<PurchaseAccountType[]>([]);
  const [accountId, setAccountId] = useState<number>();
  const [paymentFee, setPaymentFee] = useState<number>();
  //End of state

  //Function
  const getProductOrder = useGetProductOrderById();
  const postProductOrder = usePostProductOrder();
  const putProductOrder = usePutProductOrder();
  const addPopup = useAddPopup();
  const getSalesCollatorabor = useGetSalesCollatoraborByUser();
  const getReceiverOrderById = useGetReceiverOrderById();
  const parseFromUrl = useParseFromUrl();
  const getExchangeRate = useGetExchangeRate();
  const getPurchaseAccount = useGetAdminPurchaseAccount();
  
  useEffect(() => {
    setProductOrderId(props.productOrderId || Number(params.productOrderId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
    setSuggessAddress(undefined);
   
  }, [params, params.productOrderId, params.type, props, props.isDisable, props.productOrderId]);


  const onSave = () => new Promise((resolve) => {
    if (productUrl?.trim().length > 0) {
      const productOrder: ProductOrderType = {
        productUrl: productUrl,
        productOrderId: productOrderId,
        userId: userId,
        japanTrackingCode: japanTrackingCode,
        description: description,
        isPaid: isPaid,
        product: JSON.stringify(product),
        receiverAddress: receiverAddress,
        receiverName: receiverName,
        receiverPhoneNumber: receiverPhoneNumber,
        progressStatus: progressStatus,
        productPrice: productPrice,
        transportType: transportType,
        paymentCode: paymentCode,
        discount: discount,
        shipOrderId: shipOrderId,
        transportFee: transportFee,
        orderFee: orderFee,
        orderPercent: orderPercent,
        exchangeRate: exchangeRate,
        avatar: avatar,
        note: note,
        accountId: accountId,
        paymentFee: paymentFee
      };
      const isAdd = !productOrderId;
      const api = isAdd? postProductOrder(productOrder) : putProductOrder(productOrder);
      api.then(
        (res) => {
          setProductOrderId(res.productOrderId);
          setIsDisable(true);
          onSuccess(isAdd, res);
          resolve(true);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập đường dẫn sản phẩm',
        },
      });
      resolve(false);
    }
  });

  const onSuccess = (isAdd: boolean, res: ProductOrderType) => {
    addPopup({
      txn: {
        success: true,
        summary: !isAdd? 'Thay đổi đơn mua hộ thành công' : 'Tạo đơn mua hộ thành công',
      }
    });
    if(props.postProcess) props.postProcess(res);
  }

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_ORDER_URL}/product-order/${productOrderId}/${EnumViewType.Edit}`);
  };

  useEffect(() => {
    getPurchaseAccount(null, -1, -1).then((res) => {
      setPurchaseAccounts(res.items);
    });
    if(productOrderId) {
      getProductOrder(productOrderId).then((res) => {
        setProductUrl(res.productUrl);
        setProgressStatus(res.progressStatus);
        setJapanTrackingCode(res.japanTrackingCode);
        setDescription(res.description);
        setAvatar(res.avatar);
        setUserId(res.userId);
        setUserName(res.userName);
        setPaid(res.isPaid);
        setReceiverAddress(res.receiverAddress);
        setProduct(res.product?JSON.parse(res.product):[]);
        setReceiverPhoneNumber(res.receiverPhoneNumber);
        setReceiverName(res.receiverName);
        setProductPrice(res.productPrice);
        setTransportType(res.transportType);
        setPaymentCode(res.paymentCode);
        setDiscount(res.discount);
        setExchangeRate(res.exchangeRate);
        setOrderFee(res.orderFee);
        setOrderPercent(res.orderPercent);
        setShipOrderId(res.shipOrderId);
        setTransportFee(res.transportFee);
        setNote(res.note);
        setAccountId(res.accountId);
        setPaymentFee(res.paymentFee);
      })
    } else {
      getExchangeRate().then((r) => {
        setExchangeRate(Math.ceil(r));
      })
    }
  }, [productOrderId]);

  const changeOrderPercent = (value: number) => {
    setOrderPercent(value);
    if(productPrice) setOrderFee(productPrice * value / 100);
  }

  const changeProductPrice = (value: number) => {
    setProductPrice(value);
    if(orderPercent) setOrderFee(orderPercent * value / 100);
  }

  const changeOrderFee = (value: number) => {
    setOrderFee(value);
    if(productPrice) setOrderPercent(value * 100 / productPrice);
  }
  

  const changeUser = (user: ProfileInfo) => {
    setUserId(user?.userId??undefined);
    setUserName(user?.fullName??undefined);
    setIsShowUserList(false);
    if(user?.userId) {
      getSalesCollatorabor(user.userId).then((r) => {
        setSalesCollatorabor(r);
        if(r.receiverOrderId > 0 && r.receiverOrderId != receiverOrder.receiverOrderId) {
          getReceiverOrderById(r.receiverOrderId).then((ro) => {
            setReceiverOrder(ro);
            // TODO
          })
        } else {
          // TODO
        }
      }).catch(() => {
        setSalesCollatorabor(undefined);
        // TODO
      })
    } else {
      setSalesCollatorabor(undefined);
      // TODO
    }
  }

  const loadData = () => {
    parseFromUrl(productUrl).then((res) => {
      setProduct([{product: res.name, quantity: Number(res.quantity)}]);
      changeProductPrice(Number(res.price));
      setDescription(res.description);
      setAvatar(res.image);
    })
    .catch((error) => {
      addPopup({
        error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
      });
    });
  } 


  //Main
  return (
    <>
    {props.isSlide || props.isPopup ? null : (
      <ToolBar
        toolbarName={`Đơn mua hộ`}
        listRightButton={isDisable? [{
          name: 'Sửa',
          icon: 'edit',
          actionType: EnumAction.Edit,
          buttonClass: 'info100 tool-btn',
          action: onChangeEditMode,
          align: 'center',
        }] : []}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
        isBack={true}
      />
    )}
    <div className={`productorder-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
      <div className='productorder-info'>
        <Avatar thumbAvatar={avatar} avatar={avatar} disabled={true}/>
        <div className="productorder-row">
          <Input
            width="360px"
            title="Đường dẫn mua hàng"
            disabled={isDisable}
            value={productUrl}
            onChange={setProductUrl}
            isCopy={true}
          />
          <div className="i-tooltip order-guide">
            <span
              className="material-icons info-guide"
              onClick={loadData}
            >
              sync
            </span>
            <span className="tooltiptext">Đồng bộ dữ liệu từ đường dẫn</span>
          </div>
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title="Mã vận chuyển JP"
            disabled={isDisable}
            value={japanTrackingCode}
            onChange={setJapanTrackingCode}
            isCopy={true}
          />
        </div>
        <div className="productorder-row">
          <SelectBoxComponent
            width="360px"
            require={false}
            onChange={setTransportType}
            isDisable={isDisable}
            title={'Hình thức'}
            value={transportType}
            data={TransportType}
            valueType={'value'}
            titleType={'title'}
          />
        </div>
        <div className="productorder-row">
          <Input
            width='360px'
            title="TK gửi"
            require={false}
            disabled={true}
            value={userName}
            isCopy={true}
          />
          {!isDisable && (
            <div className="i-tooltip order-guide">
              {userId? <span className="material-icons send-account-clear" onClick={() => {
                changeUser(undefined);
              }}>clear</span> : null}
              <span
                className="material-icons info-guide"
                onClick={() => setIsShowUserList(true)}
                ref={(el) => {
                  if (el) {
                    const boundingClient = el.getBoundingClientRect();
                    setIconX(boundingClient.left - (props.isPopup? window.innerWidth / 2 : 0));
                    setIconY(boundingClient.y + boundingClient.height - (props.isPopup? window.innerHeight / 10 - 20 : 0));
                  }
                }}
              >
                account_circle
              </span>
              <span className="tooltiptext">Chọn người gửi</span>
            </div>
          )}
          {isShowUserList ? (
            <ContactList
              iconX={iconX}
              iconY={iconY}
              showAll={true}
              onHidden={() => setIsShowUserList(false)}
              onSelect={(user: ProfileInfo) => changeUser(user)}
            />
          ) : null}
        </div>
        <div className="productorder-row">
            <SelectBoxComponent
              width="360px"
              require={false}
              onChange={setAccountId}
              isDisable={isDisable}
              title={'Tài khoản mua hộ'}
              value={accountId}
              data={purchaseAccounts}
              valueType={'purchaseAccountId'}
              titleType={'accountName'}
            />
          </div>
        <div className="productorder-row">
          <NomalProduct
            width="360px"
            title="DS hàng hóa"
            nomalProduct={product}
            onChange={setProduct}
            disabled={isDisable}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title="Mô tả"
            require={false}
            disabled={isDisable}
            value={description}
            onChange={setDescription}
            isCopy={true}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title={`Giá tiền (¥)`}
            require={false}
            disabled={isDisable}
            value={productPrice}
            onChange={changeProductPrice}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row inline">
          <div>
            <Input
              width="140px"
              title={`Phí mua hộ (%)`}
              require={false}
              disabled={isDisable}
              value={orderPercent}
              onChange={changeOrderPercent}
              inputPadding="3px 10px 3px 3px"
              type='number'
              step={1}
            />
          </div>
          <Input
            width="220px"
            title={`Phí mua hộ (¥)`}
            require={false}
            disabled={isDisable}
            value={orderFee}
            onChange={changeOrderFee}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title={`Phí thanh toán (¥)`}
            require={false}
            disabled={isDisable}
            value={paymentFee}
            onChange={setPaymentFee}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title={`Phí v.chuyển (¥)`}
            require={false}
            disabled={isDisable}
            value={transportFee}
            onChange={setTransportFee}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title={`Giảm giá (¥)`}
            require={false}
            disabled={isDisable}
            value={discount}
            onChange={setDiscount}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title={`Tỷ giá (VNĐ / ¥)`}
            require={false}
            disabled={isDisable}
            value={exchangeRate}
            onChange={setExchangeRate}
            inputPadding="3px 10px 3px 3px"
            type='number'
            step={1}
          />
        </div>
        <div className="productorder-row">
          <Input
            title='Thành tiền'
            width="360px"
            require={false}
            disabled={true}
            value={`${number2jpmoney((productPrice??0) + (transportFee??0) + (paymentFee??0) + (orderFee??0) - (discount??0))} = ${number2vnmoney(((productPrice??0) + (transportFee??0) + (paymentFee??0) + (orderFee??0) - (discount??0)) * (exchangeRate??0))} `}
            inputPadding="3px 10px 3px 3px"
            align='right'
          />
           
        </div>

        <div className="productorder-row">
          <SelectBoxComponent
            width="360px"
            require={true}
            onChange={setProgressStatus}
            isDisable={isDisable}
            title={'Trạng thái'}
            value={progressStatus}
            data={ProductOrderStatus}
            valueType={'value'}
            titleType={'title'}
          />
        </div>
        <div className="productorder-row">
          <Checkbox
            width='360px'
            value={isPaid}
            disabled={true}
            onChange={setPaid}
            title='Đã thanh toán'
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title="Ghi chú"
            require={false}
            disabled={isDisable}
            value={note}
            onChange={setNote}
            isCopy={true}
          />
        </div>
     
        {receiverOrder?.address?.length > 0 || salesCollatorabor?.address?.length > 0? <div className="productorder-row">
          <SelectSearchComponent
            width="360px"
            onChange={(value) => {
              setSuggessAddress(value);
              setReceiverName(value.name);
              setReceiverPhoneNumber(value.phone);
              setReceiverAddress(value.address);
            }}
            isDisable={isDisable}
            title={'Địa chỉ nhận gợi ý'}
            value={suggessAddress}
            list={salesCollatorabor?.address?.length > 0? JSON.parse(salesCollatorabor.address) : JSON.parse(receiverOrder.address)}
            titleField='name'
            convert={(item) => {
              return `${item.name} | ${item.phone} | ${item.address}`;
            }}
          />
        </div> : null}
        <div className="productorder-row">
          <Input
            width="360px"
            title="Tên người nhận"
            require={false}
            disabled={isDisable}
            value={receiverName}
            onChange={setReceiverName}
            isCopy={true}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title="SĐT người nhận"
            require={false}
            disabled={isDisable}
            value={receiverPhoneNumber}
            onChange={setReceiverPhoneNumber}
            isCopy={true}
          />
        </div>
        <div className="productorder-row">
          <Input
            width="360px"
            title="Địa chỉ người nhận"
            require={false}
            disabled={isDisable}
            value={receiverAddress}
            onChange={setReceiverAddress}
            isCopy={true}
          />
        </div>


        {!isDisable && <div className="productorder-row">
          <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
        </div>}
      </div>
     
      {productOrderId > 0 ? (
        <div className='productorder-info'>
          <Note
            objectId={productOrderId}
            functionId={EnumDisplayConfig.ProductOrder}
            recordUserId={userId}
          />
        </div>
      ) : null}
    </div>
  </>
  );
};

export default ProductOrderDetail;

const StyledInput = styled.input`
  display: none;
`;
