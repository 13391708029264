/* eslint-disable react-hooks/exhaustive-deps */
import './SimOrder.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EnumSimOrderProgressStatus, EnumSimOrderProgressStatusTitle, EventButton, SimOrderType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import SimOrderDetail from './SimOrderDetail/SimOrderDetail';
import {
  useGetAdminSimOrderList,
  useDeleteSimOrder,
  useExportCsvFile,
} from 'src/api/simOrderApi';
import { useNavigate } from 'react-router-dom';
import { BASE_SIM_URL } from 'src/constants';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { useDebounce } from 'use-debounce';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const SimOrder: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [keyword, setKeyword] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [productId, setProductId] = useState<number>();
  const [simStoreId, setSimStoreId] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<number>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);

  const [rawData, setRawData] = useState<SimOrderType[]>([]);
  const [simOrders, setSimOrders] = useState<SimOrderType[]>([]);
  const [checkList, setCheckList] = useState<SimOrderType[]>([]);

  const USER_FIELD_FILTER = 'userId';
  const PRODUCT_FIELD_FILTER = 'productId';
  const SIM_STORE_FIELD_FILTER = 'simStoreId';
  const STATUS_FIELD_FILTER = 'progressStatus';

  const USER_FILTER = 'USER_FILTER';
  const PRODUCT_FILTER = 'PRODUCT_FILTER';
  const SIM_STORE_FILTER = 'SIM_STORE_FILTER';
  const STATUS_FILTER = 'STATUS_FILTER';

  const lstStatus = [
    {
      value: EnumSimOrderProgressStatus.Waiting,
      title: EnumSimOrderProgressStatusTitle.Waiting,
      css: { color: '#3a3131' },
    },
    {
      value: EnumSimOrderProgressStatus.Sent,
      title: EnumSimOrderProgressStatusTitle.Sent,
      css: { color: 'blue' },
    },
    {
      value: EnumSimOrderProgressStatus.Error,
      title: EnumSimOrderProgressStatusTitle.Error,
      css: { color: 'red' },
    },
  ];

  const filters = [
    {
      data: [],
      valueField: USER_FIELD_FILTER,
      titleField: 'userName',
      title: 'CTV',
      filterKey: USER_FILTER,
    },
    {
      data: [],
      valueField: PRODUCT_FIELD_FILTER,
      titleField: 'productName',
      title: 'Loại SIM',
      filterKey: PRODUCT_FILTER,
    },
    {
      data: [],
      valueField: SIM_STORE_FIELD_FILTER,
      titleField: 'simStoreName',
      title: 'Kho SIM',
      filterKey: SIM_STORE_FILTER,
    },
    {
      data: lstStatus,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
  ];


  //Function
  const addPopup = useAddPopup();
  const getSimOrderList = useGetAdminSimOrderList();
  const deleteSimOrder = useDeleteSimOrder();
  const exportCsvFile = useExportCsvFile();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(SimOrderDetail);
  const slideBar = useSlideBar(SimOrderDetail);

  const display = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'CTV',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
      title: 'Trạng thái',
        options: lstStatus,
      },
      {
        code: 'simStore?.simStoreTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Nguồn gửi',
      },
      {
        code: 'trackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn',
      },
      {
        code: 'phoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Số điện thoại',
      },
      {
        code: 'fullname',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên khách hàng',
      },
      {
        code: 'zipCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã bưu điện',
      },
      {
        code: 'receiverAddress1',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 1',
      },
      {
        code: 'receiverAddress2',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 2',
      },
      {
        code: 'receiverAddress3',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 3',
      },
      {
        code: 'receiverAddress4',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ dòng 4',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
    ] as TableColumnType[]
  };

  const onView = (simOrderId: number) => {
    const url = `${BASE_SIM_URL}/sim-order/${simOrderId}/view`;
    navigate(url);
  };

  const onEdit = (simOrderId: number) => {
    const url = `${BASE_SIM_URL}/sim-order/${simOrderId}/edit`;
    navigate(url);
  };

  const onDelete = (simOrderId: number) => {
    const onConfirm = () => {
      deleteSimOrder(simOrderId)
        .then(() => {
          const idx = checkList.findIndex(i => i.simOrderId == simOrderId);
          if(idx >= 0) {
            checkList.splice(idx, 1);
            setCheckList([...checkList]);
          }
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn hàng này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN HÀNG',
    );
  };

  const menuContext = (item: SimOrderType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.simOrderId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.simOrderId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.simOrderId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onDoubleClick = (simOrder: SimOrderType) => {
    detailModal.handlePresent(
      {
        simOrderId: simOrder.simOrderId,
        isDisable: false,
      },
      'THAY ĐỔI ĐƠN SIM',
    );
  };

  const onClick = (simOrder: SimOrderType) => {
    slideBar.handlePresent({
      simOrderId: simOrder.simOrderId,
      isDisable: true,
    });
  };

  const onClickCheckbox = (simOrder: SimOrderType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != simOrder);
      setCheckList(nCheckList);
      setSimOrders(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.simOrderId == i.simOrderId),
        ),
      );
    } else {
      setCheckList([...checkList, simOrder]);
      setSimOrders(simOrders.filter((o) => o != simOrder));
    }
  };

  //Function in the listButton
  const onAddSimOrderNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
      },
      'THÊM MỚI ĐƠN SIM',
    );
  };

  const onExportCSV = () => {
    if (checkList.length > 0) {
      const arr: number[] = [];
      checkList.forEach((value) => {
        arr.push(value.simOrderId);
      });
      exportCsvFile(arr)
        .then((data) => {
          const csvURL = window.URL.createObjectURL(data);
          const tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', 'sim-order.csv');
          tempLink.click();
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Danh sách trống',
        },
      });
    }
  };


  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddSimOrderNew,
      align: 'center',
    },
    {
      name: 'Export CSV',
      icon: 'exit_to_app',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onExportCSV,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getSimOrderList(page, size, (keywordDebound ?? '') as string, userId, productId, simStoreId, progressStatus)
      .then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setSimOrders(
          data.items.filter(
            (i) => !checkList.some((c) => c.simOrderId == i.simOrderId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getSimOrderList,
    keywordDebound,
    page,
    productId,
    reloadFlag,
    simStoreId,
    size,
    userId,
    progressStatus,
  ]);

  useEffect(() => {
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  const onFilter = (res: { [filterKey: string]: any }) => {
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
    if (res[USER_FILTER] != undefined) {
      const userId = res[USER_FILTER][USER_FIELD_FILTER];
      setUserId(userId);
    } else {
      setUserId(undefined);
    }
    if (res[PRODUCT_FILTER] != undefined) {
      const productId = res[PRODUCT_FILTER][PRODUCT_FIELD_FILTER];
      setProductId(productId);
    } else {
      setProductId(undefined);
    }
    if (res[SIM_STORE_FILTER] != undefined) {
      const simStoreId = res[SIM_STORE_FILTER][SIM_STORE_FIELD_FILTER];
      setSimStoreId(simStoreId);
    } else {
      setSimStoreId(undefined);
    }
  };

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH ĐƠN HÀNG SIM ĐẠI LÝ'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onFilter={onFilter}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
      />

      <Table
        display={display}
        isInput={false}
        checkedData={checkList}
        data={simOrders}
        menuContext={menuContext}
        allowCheckbox={true}
        onDoubleClick={onDoubleClick}
        onClickCheckbox={onClickCheckbox}
        onClick={onClick}
        isNo={true}
      />
    </>
  );
};
export default SimOrder;
