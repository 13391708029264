/* eslint-disable react-hooks/exhaustive-deps */
import './Disbursement.css';
import React from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumAction, EnumDataType, EventButton, DisbursementType } from 'src/api/models';
import { useDeleteDisbursement, useGetDisbursements } from 'src/api/disbursementApi';
import DisbursementDetail from './DisbursementDetail/DisbursementDetail';


const Disbursement: React.FC = () => {

  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [Disbursements, setDisbursements] = useState<DisbursementType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const getDisbursements = useGetDisbursements();
  const deleteDisbursement = useDeleteDisbursement();
  const detailModal = useModal(DisbursementDetail);
  const confirmModal = useModal(ConfirmModal);

  const display = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo',
      },
      {
        code: 'money',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Số tiền',
      },
      {
        code: 'disbursementContent',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Lý do',
      },
    ] as TableColumnType[]
  };

  const onView = (disbursementId: number) => {
    detailModal.handlePresent(
      {
        disbursementId: disbursementId,
        isDisable: true,
      },
      'CHI TIẾT CHI PHÍ',
    );
  };

  const onDelete = (disbursementId: number) => {
    const onConfirm = () => {
      deleteDisbursement(disbursementId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa phiếu chi thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN VẬN CHUYỂN',
    );
  };


  const menuContext = (item: DisbursementType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.disbursementId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.disbursementId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onAddNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: DisbursementType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI PHIẾU CHI',
    );
  };

  useEffect(() => {
    getDisbursements(keyword, page, size)
      .then((data) => {
        setDisbursements(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getDisbursements,
    reloadFlag,
    keyword,
    page,
    size
  ]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH PHIẾU CHI'}
        listRightButton={[{
          name: 'Thêm',
          icon: 'add',
          actionType: EnumAction.View,
          buttonClass: 'info100',
          action: onAddNew,
          align: 'center',
        }]}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
      />

      <Table
        display={display}
        isInput={false}
        data={Disbursements}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.disbursementId)}
        isNo={true}
      />
    </>
  );
};
export default Disbursement;
