import React, { useState, useEffect } from 'react';
import { EnumAction, EventButton, PaymentType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import './PaymentApprove.css';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import useModal from 'src/hooks/useModal';
import Input from 'src/components/Input';
import { useApproveBill, useGetPaymentByCode } from 'src/api/paymentApi';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { number2vnmoney } from 'src/utils/numberUtils';

interface IPaymentApprove {
  paymentCode?: string;
  postProcess?: (...args: any[]) => void;
}

const PaymentApprove: React.FC<IPaymentApprove> = (props) => {
  const {postProcess} = props
  //State
  const [paymentCode, setPaymentCode] = useState<string>(props.paymentCode);
  const [payment, setPayment] = useState<PaymentType>();
  const [money, setMoney] = useState<number>();
  const confirmModal = useModal(ConfirmModal);
  //Function
  const approveBill = useApproveBill();
  const addPopup = useAddPopup();
  const getPaymentByCode = useGetPaymentByCode();

  useEffect(() => {
    if(paymentCode?.length >=6) {
      getPaymentByCode(paymentCode).then((res) => {
        setPayment(res);
      }).catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      })
    }
  }, [addPopup, getPaymentByCode, paymentCode])


  const onSave = () => new Promise((resolve) => {

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: () => {
          approveBill(paymentCode, money).then((res) => {
            addPopup({
              txn: {
                success: true,
                summary: 'Xác nhận hóa đơn thanh toán thành công',
              },
            });
            if(postProcess) postProcess();
          }).catch((error) => {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra',
                message: error.errorMessage,
              },
            });
            resolve(false);
          });
        },
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: `Bạn chắc chắn xác nhận thanh toán hóa đơn ${paymentCode}?`,
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÁC NHẬN THANH TOÁN',
    );



  });

  //Main
  return (
    <div className="payment-approve-container">
      <div className="payment-approve-row">
        <Input
          width="360px"
          title="Mã hóa đơn"
          require={false}
          disabled={false}
          value={paymentCode}
          onChange={setPaymentCode}
        />
        {payment? <div className='payment-approve-info inline'>
          <span>{payment.customerName}</span>
          <span>{number2vnmoney(payment.money??0)} + {number2vnmoney(payment.debt??0)} = {number2vnmoney((payment.money??0) + (payment.debt??0))}</span>
        </div> : null}
        <Input
          width="360px"
          title="Nợ"
          require={false}
          disabled={false}
          value={money}
          onChange={setMoney}
          type='number'
        />
      </div>

      <ButtonComponent icon="verified" title={'XÁC NHẬN THANH TOÁN'} onClick={onSave} loader={true} />
    </div>
  );
};

export default PaymentApprove;
