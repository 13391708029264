import React, { useState, useEffect } from 'react';
import { EnumAction, ProfileInfo, PurchaseAccountType } from 'src/api/models';
import {
  useGetPurchaseAccountById,
  usePostPurchaseAccount,
  usePutPurchaseAccount,
} from 'src/api/purchaseAccountApi';
import { useGetUserList } from 'src/api/userApi';
import { useAddPopup, useReloadTable, useRemovePopup } from 'src/state/application/hooks';
import './PurchaseAccountDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { BASE_ORDER_URL } from 'src/constants';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';


interface ISimOrderDetail {
  purchaseAccountId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const PurchaseAccountDetail: React.FC<ISimOrderDetail> = (props) => {
  const params = useParams<{type: string; purchaseAccountId: string }>();
  //State
  const [purchaseAccountId, setPurchaseAccountId] = useState<number>();
  const [isDisable, setIsDisable] = useState(false);
  const [purchasePage, setPurchasePage] = useState<string>();
  const [accountName, setAccountName] = useState<string>();
  const [accountPassword, setAccountPassword] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [note, setNote] = useState<string>();

  
  const [keyword, setKeyword] = useState(undefined);

  //End of state

  const addPopup = useAddPopup();
  const getPurchaseAccountById = useGetPurchaseAccountById();
  const postPurchaseAccount = usePostPurchaseAccount();
  const putPurchaseAccount = usePutPurchaseAccount();
 
  useEffect(() => {
    setPurchaseAccountId(props.purchaseAccountId || Number(params.purchaseAccountId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [params.purchaseAccountId, params.type, props.isDisable, props.purchaseAccountId]);

  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();

 //TODO
  const onSave = () => new Promise((resolve) => {
    const purchaseAccount: PurchaseAccountType = {
      purchasePage: purchasePage,
      accountName: accountName,
      accountPassword: accountPassword,
      description: description,
      note: note,
      purchaseAccountId: purchaseAccountId,
    };
    const isAdd = !purchaseAccountId;
    const api = isAdd? postPurchaseAccount(purchaseAccount) : putPurchaseAccount(purchaseAccount);
    api.then(
      (res) => {
        setPurchaseAccountId(res.purchaseAccountId);
        setIsDisable(true);
        addPopup({
          txn: {
            success: true,
            summary: !isAdd? 'Thay đổi tài khoản thành công' : 'Tạo tài khoản thành công',
          }
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        resolve(false);
      });
  });



  useEffect(() => {
    if (purchaseAccountId) {
      getPurchaseAccountById(purchaseAccountId).then((data) => {
        setPurchasePage(data.purchasePage);
        setAccountName(data.accountName);
        setAccountPassword(data.accountPassword);
        setDescription(data.description);
        setNote(data.note);
      })
    }
  }, [getPurchaseAccountById, purchaseAccountId]);

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_ORDER_URL}/purchase-account/${purchaseAccountId}/${EnumViewType.Edit}`);
  };
  
  //Main
  return (
    <>
      {props.isSlide || props.isPopup ? null : (
        <ToolBar
          toolbarName={`Tài khoản mua hàng ${accountName}`}
          listRightButton={isDisable? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`purchase-account-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
        <div className="purchase-account-row">
          <Input
            width="360px"
            title="Trang thanh toán"
            require={true}
            disabled={isDisable}
            value={purchasePage}
            onChange={setPurchasePage}
          />
        </div>
        <div className="purchase-account-row">
          <Input
            width="360px"
            title="Tên tài khoản"
            require={true}
            disabled={isDisable}
            value={accountName}
            onChange={setAccountName}
          />
        </div>
        <div className="purchase-account-row">
          <Input
            width="360px"
            title="Mật khẩu"
            require={true}
            disabled={isDisable}
            value={accountPassword}
            onChange={setAccountPassword}
          />
        </div>
        <div className="purchase-account-row">
          <Input
            width="360px"
            title="Hướng dẫn"
            require={false}
            disabled={isDisable}
            value={description}
            onChange={setDescription}
          />
        </div>
        <div className="purchase-account-row">
          <Input
            width="360px"
            title="Ghi chú"
            require={false}
            disabled={isDisable}
            value={note}
            onChange={setNote}
          />
        </div>
        {!isDisable && <div className="purchase-account-row">
          <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
        </div>}

      </div>
    </>



        //   {descriptionInput()}
        //   {noteInput()}
       
        // {purchasePageInput()}
        // {accountNameInput()}
        // {accountPasswordInput()}
        // {btnComponent()}
  );
};

export default PurchaseAccountDetail;
