import './SimStore.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, SimStoreType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useDeleteSimStore, useGetSimStore } from 'src/api/simStoreApi';
import SimStoreDetail, { EnumConfigSimStoreDetail } from './SimStoreDetail/SimStoreDetail';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

const SimStore: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteSimStore = useDeleteSimStore();
  const getSimStore = useGetSimStore();

  //Local state
  const [keyword, setKeyword] = useState<string>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(50);
  const [count, setCount] = useState<number>(null);
  const [simStores, setSimStores] = useState<SimStoreType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(SimStoreDetail);

  const display = {
    header: [
      {
        code: 'simStoreTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'simStoreAddress',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ liên hệ',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };

  //End of define

  //Menucontext
  const onView = (simStoreId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimStoreDetail.view,
        simStoreId: simStoreId,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (simStoreId: number) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimStoreDetail.edit,
        simStoreId: simStoreId,
      },
      'THAY ĐỔI',
    );
  };

  const onAddNewSimStore = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimStoreDetail.add,
      },
      'THÊM MỚI',
    );
  };

  const onDelete = (simStoreId: number) => {
    const onConfirm = () => {
      deleteSimStore(simStoreId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa nguồn SIM này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA NGUỒN SIM',
    );

  };

  const menuContext = (item: SimStoreType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.simStoreId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.simStoreId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.simStoreId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext


  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewSimStore,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getSimStore()
      .then((data) => {
        setSimStores(data);
        setCount(data.length);
        setPage(1);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getSimStore, reloadFlag]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH NGUỒN SIM'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      <Table
        display={display}
        isInput={false}
        data={simStores}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.simStoreId)}
        isNo={true}
      />
    </>
  );
};
export default SimStore;
