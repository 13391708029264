/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import './CameraInput.css';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

interface ICameraInput {
  capture: (...arg: any[]) => void
}

const CameraInput: React.FC<ICameraInput> = (props) => {
  const { capture } = props;
  const [isStart, setStart] = useState<boolean>(false);
  const [facingMode, setFacingMode] = useState<"environment" | "user" | undefined>(FACING_MODES.ENVIRONMENT);

  return (

    <div className="camera-content">

      <Camera
        onTakePhoto = { (dataUri) => {
          const byteString = atob(dataUri.split(',')[1]);

          // separate out the mime component
          const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const file = new File(
            [new Blob([ab], { type: mimeString })],
            "fileName.jpg",
            {
              type: "image/jpeg",
            }
          );
          capture(file);
        }}
        onCameraStart={() => setStart(true)}
        idealFacingMode = {facingMode}
      />
      {isStart && <div className="camera-switch-icon" onClick={() => {
          setFacingMode(facingMode == FACING_MODES.ENVIRONMENT? FACING_MODES.USER : FACING_MODES.ENVIRONMENT);
        }}>
          <span className="material-icons">sync</span>
      </div>}
    </div>
  );
};

export default CameraInput;
