import './Service.css';
import { MouseEvent } from 'react';
import { EnumAction, EnumDataType, EventButton, ServiceType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import ServiceDetails from './ServiceDetails/ServiceDetails';
import { useGetAllService, useDeleteService } from 'src/api/serviceApi';
import useModal from 'src/hooks/useModal';
import { useAddPopup } from 'src/state/application/hooks';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const Service: React.FC = () => {

  //Function

  const addPopup = useAddPopup();
  const getAllService = useGetAllService();
  const deleteService = useDeleteService();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [items, setItems] = useState<ServiceType[]>([]);

  const detailModal = useModal(ServiceDetails);
  const confirmModal = useModal(ConfirmModal);

  const display = {
    header: [
      {
        code: 'serviceCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã dịch vụ',
      },
      {
        code: 'serviceName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên dịch vụ',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
      {
        code: 'staticPageKey',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã trang tĩnh',
      },
    ] as TableColumnType[]
  };



  //Get all service
  useEffect(() => {
    getAllService()
      .then((data) => {
        setItems(data);
      });
  }, [getAllService, reloadFlag]);

  //Menucontext
  const onView = (serviceId: number) => {
    detailModal.handlePresent(
      {
        serviceId: serviceId,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (serviceId: number) => {
    detailModal.handlePresent(
      {
        serviceId: serviceId,
        isDisable: false,
        postProcess: (data: ServiceType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.serviceId);
        },
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (serviceId: number) => {
    const onConfirm = () => {
      deleteService(serviceId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa dịch vụ thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa dịch vụ này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA DỊCH VỤ',
    );
  };



  const menuContext = (item: ServiceType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.serviceId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.serviceId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.serviceId),
      buttonClass: 'info',
      align: '',
    }
  ];

  //Function in the listButton
  const onAddServiceNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ServiceType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.serviceId);
        },
      },
      'THÊM MỚI',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info tool-btn',
      action: onAddServiceNew,
      align: 'center',
    },
  ];

  return (
    <>
      <ToolBar
        toolbarName={'Sản phẩm'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.serviceId)}
      />
    </>
  );
};

export default Service;
