
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, SalesCollatoraborType } from './models';

export const useGetSalesCollatorabor = () => {
  const fetch = useFetch();
  return useCallback(
    (page?: number, size?: number) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor?page=${page??0}&size=${size??0}`,
        method: 'get',
      }) as Promise<PageData<SalesCollatoraborType>>;
    },
    [fetch],
  );
};

export const useGetSalesCollatoraborById = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor/${id}`,
        method: 'get',
      }) as Promise<SalesCollatoraborType>;
    },
    [fetch],
  );
};

export const useGetSalesCollatoraborByUser = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor/user/${id}`,
        method: 'get',
      }) as Promise<SalesCollatoraborType>;
    },
    [fetch],
  );
};

export const usePostSalesCollatorabor = () => {
  const fetch = useFetch();
  return useCallback(
    (data: SalesCollatoraborType) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor`,
        method: 'post',
        data: data,
      }) as Promise<SalesCollatoraborType>;
    },
    [fetch],
  );
};

export const usePutSalesCollatorabor = () => {
  const fetch = useFetch();
  return useCallback(
    (data: SalesCollatoraborType) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor`,
        method: 'put',
        data: data,
      }) as Promise<SalesCollatoraborType>;
    },
    [fetch],
  );
};

export const useDeleteSalesCollatorabor = () => {
  const fetch = useFetch();
  return useCallback(
    (id: number) => {
      return fetch({
        url: `sccore/rest-api/sales-collatorabor/${id}`,
        method: 'put',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

