import './DataTypeRender.css';
import React, { useState } from 'react';
import { EnumDataType } from 'src/api/models';
import { TableColumnType } from '../Table/TableHeader/TableHeader';
import { number2jpmoney, number2vnmoney, numberFormat } from 'src/utils/numberUtils';

interface IDataTypeRender {
  value: any;
  column: TableColumnType;
}


const DataTypeRender: React.FC<IDataTypeRender> = (props) => {
  const {value, column} = props;

  const render = () => {
    if(column.isOptions) {
      const option = column.options.find(o => o.value == value);
      return <div className={'label-table'} style={option?.css??undefined}>{option?.title}</div>
    } else {
      switch(column.dataType) {
        case EnumDataType.Boolean:
          return <div className={`label-table ${column.cellCss? '' : 'text-center'} ${value? 'color-blue' : 'color-red'}`} style={column.cellCss??undefined}>
            <i style={{fontSize: 'medium'}} className='material-icons'>{value? 'check': 'close'}</i>
          </div>;
        case EnumDataType.BigInt:
        case EnumDataType.Int:
        case EnumDataType.Month:
        case EnumDataType.QuarterOfYear:
          return <div className={`label-table ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{numberFormat(value)}</div>
        case EnumDataType.JPY:
          return <div className={` ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{number2jpmoney(value)}</div>
        case EnumDataType.Date:
          return <div className={`label-table ${column.cellCss? '' : 'text-center'}`} style={column.cellCss??undefined}>{value}</div>
        case EnumDataType.Image:
          return <img className="cell-image" src={value} alt="image" />
        case EnumDataType.Ratio:
          return <div className={`label-table ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{value / column.ratio}</div>
        case EnumDataType.HTML:
          return column.convert(value);
        case EnumDataType.VND:
          return <div className={` ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{number2vnmoney(value)}</div>
        case EnumDataType.Decimal:
          return <div className={` ${column.cellCss? '' : 'text-right'}`} style={column.cellCss??undefined}>{numberFormat(value)}</div>

        default:
          return <div className={'label-table'} style={column.cellCss??undefined} title={value}>{value}</div>
      }
    };
  }

  return (<>
    {render()}
  </>)
    
};

export default DataTypeRender;
