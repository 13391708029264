import React, { useState, useEffect } from 'react';
import { SpecialSurchargeType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import './SpecialSurchargeDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { useGetSpecialSurchargeById, usePostSpecialSurcharge, usePutSpecialSurcharge } from 'src/api/specialSurchargeApi';


interface ISpecialSurchargeDetail {
  specialShipId: number;
  specialSurchargeId?: number;
  isDisable: boolean;
  postProcess?: (...args: any[]) => void;
}

const SpecialSurchargeDetail: React.FC<ISpecialSurchargeDetail> = (props) => {
  const [specialSurchargeId, setSpecialSurchargeId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  useEffect(() => {
    console.log("props");
    setSpecialSurchargeId(props.specialSurchargeId);
    setIsDisable(props.isDisable);
  }, [props.isDisable, props.specialSurchargeId]);


  const [specialSurcharge, setSpecialSurcharge] = useState<number>();
  const [specialSurchargeError, setSpecialSurchargeError] = useState<string>();
  const [minQuantity, setMinQuantity] = useState<number>();
  const [minQuantityError, setMinQuantityError] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  //End of state

  //Function
  const postSpecialSurcharge = usePostSpecialSurcharge();
  const putSpecialSurcharge = usePutSpecialSurcharge();
  const getSpecialSurchargeById = useGetSpecialSurchargeById();

  const addPopup = useAddPopup();

  //Validate
  const validateSurcharge = () => {
    if (!specialSurcharge || specialSurcharge < 0) {
      setSpecialSurchargeError('Chưa nhập phí đặc biệt hoặc không đúng');
      return false;
    } else {
      setSpecialSurchargeError(undefined);
      return true;
    }
  };

  const validateQuantity = () => {
    if (!minQuantity || minQuantity < 0) {
      setMinQuantityError('Chưa nhập số lượng tối thiểu hoặc không đúng');
      return false;
    } else {
      setMinQuantityError(undefined);
      return true;
    }
  };


  const onSave = () => new Promise((resolve, reject) => {
    if (validateSurcharge() && validateQuantity()) {
      const body: SpecialSurchargeType = {
        specialSurchargeId: specialSurchargeId,
        specialShipId: props.specialShipId,
        specialSurcharge: specialSurcharge,
        minQuantity: minQuantity,
        displayOrder: displayOrder
      };
      const api = !specialSurchargeId? postSpecialSurcharge : putSpecialSurcharge;
      api(body).then(
        (res) => {
          setSpecialSurchargeId(res.specialSurchargeId);
          addPopup({
            txn: {
              success: true,
              summary: specialSurchargeId? 'Sửa mặt hàng thành công' : 'Tạo mặt hàng thành công',
            },
          });
          if(props.postProcess) props.postProcess(res);
          resolve(true);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          reject(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
      reject(false);
    }
  });

  useEffect(() => {
    if (specialSurchargeId) {
      getSpecialSurchargeById(specialSurchargeId)
        .then((res) => {
          setSpecialSurcharge(res.specialSurcharge);
          setMinQuantity(res.minQuantity);
          setDisplayOrder(res.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getSpecialSurchargeById, specialSurchargeId]);

  //Main
  return (
    <div className="special-surcharge-container">
      <div className='special-surcharge-row'>
        <Input
          title='Số lượng tối thiểu'
          require={true}
          disabled={isDisable}
          value={minQuantity}
          onChange={setMinQuantity}
          error={minQuantityError}
          onBlur={validateQuantity}
          type='number'
        />
      </div>
      <div className='special-surcharge-row'>
        <Input
          title='Phí đặc biệt'
          require={true}
          disabled={isDisable}
          value={specialSurcharge}
          onChange={setSpecialSurcharge}
          type='number'
          error={specialSurchargeError}
          onBlur={validateSurcharge}
        />
      </div>
      <div className='special-surcharge-row'>
        <Input
          title='Thứ tự'
          require={true}
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      <div className='special-surcharge-row'>
        {isDisable ? null : <ButtonComponent icon='save' title={!specialSurchargeId ? 'THÊM' : 'LƯU'} onClick={onSave} loader={true}/>}
      </div>
    </div>
  );
};

export default SpecialSurchargeDetail;
