import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ParameterType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useDeleteParameter, useGetParameterByDate } from 'src/api/parameterApi';
import ParameterDetail, { EnumConfigSimPriceDetail } from './ParameterDetail/ParameterDetail';
import ParameterSlideBar from './ParameterSlideBar/ParameterSlideBar';
import ChooseDateDetail from '../UserCustomerTypeScreen/ChooseDateDetail/ChooseDateDetail';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const Parameter: React.FC = () => {

  const addPopup = useAddPopup();
  const deleteParameter = useDeleteParameter();
  const getParameter = useGetParameterByDate();

  const [parameterList, setParameterList] = useState<ParameterType[]>([]);
  const [date, setDate] = useState<string>();
  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ParameterDetail);
  const slideModal = useSlideBar(ParameterSlideBar);
  const chooseDateModal = useModal(ChooseDateDetail);


  const display = {
    header: [
      {
        code: 'parameterKey',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã tham số',
      },
      {
        code: 'parameterTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'parameterValue',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Giá trị',
      },
      {
        code: 'effectiveDate',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày hiệu lực',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };

  const onChangeDate = (d: string) => {
    setDate(d);
  };

  //Menucontext
  const onView = (parameterId: number) => {
    detailModal.handlePresent(
      {
        parameterId: parameterId,
        config: EnumConfigSimPriceDetail.view,
        postProcess: detailModal.handleDismiss,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (parameterId: number) => {
    detailModal.handlePresent(
      {
        parameterId: parameterId,
        config: EnumConfigSimPriceDetail.edit,
        postProcess: detailModal.handleDismiss,
      },
      'THAY ĐỔI',
    );
  };

  const onUpdate = (parameterId: number) => {
    detailModal.handlePresent(
      {
        parameterId: parameterId,
        config: EnumConfigSimPriceDetail.update,
        postProcess: detailModal.handleDismiss,
      },
      'CẬP NHẬT',
    );
  };

  const onDelete = (parameterId: number) => {
    const onConfirm = () => {
      deleteParameter(parameterId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa tham số thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa tham số này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA THAM SỐ',
    );
  };


  //When click right mouse
  const menuContext = (item: ParameterType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.parameterId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.parameterId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Cập nhật',
      icon: 'update',
      actionType: EnumAction.Edit,
      action: () => onUpdate(item.parameterId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.parameterId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext


  const onClick = (item: ParameterType) => {
    slideModal.handlePresent({
      parameterId: item.parameterId
    })
  };

  //Function in the listButton
  const onAddParameterNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumConfigSimPriceDetail.add,
        postProcess: detailModal.handleDismiss,
      },
      'THÊM MỚI',
    );
  };

  const onChooseDate = (e: MouseEvent) => {
    slideModal.handleDismiss();
    chooseDateModal.handlePresent({
      onChange: onChangeDate,
      postProcess: chooseDateModal.handleDismiss,
    }, "CHỌN NGÀY");
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddParameterNew,
      align: 'center',
    },
    {
      name: `${date ? date : 'Tất cả'}`,
      icon: 'calendar_month',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onChooseDate,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    if (date) {
      getParameter(date)
        .then((data) => {
          setParameterList(data);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, date, getParameter, reloadFlag]);

  useEffect(() => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();
    const temp = `${yy}/${mm}/${dd}`;
    setDate(temp);
  }, []);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH THAM SỐ HỆ THỐNG'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <Table
        display={display}
        isInput={false}
        data={parameterList}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.parameterId)}
        onClick={onClick}
      />
    </>
  );
};
export default Parameter;
