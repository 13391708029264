import { useCallback } from 'react';
import { useFetch } from './backend-api';

import { PageData, ReportInputDataType, ReportType } from './models';

export const useGetUnpaidReport = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/report/unpaid`,
      method: 'get',
    }) as Promise<[][]>;
  }, [fetch]);
};


export const useGetInvoiceReport = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/report/invoice`,
      method: 'get',
    }) as Promise<{[key: string]: any}[]>;
  }, [fetch]);
};




export const useGetReport = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/report`,
      method: 'get',
    }) as Promise<ReportType[]>;
  }, [fetch]);
};

export const useGetReportById = () => {
  const fetch = useFetch();
  return useCallback(
    (reportId: number) => {
      return fetch({
        url: `sccore/rest-api/report/${reportId}`,
        method: 'get',
      }) as Promise<ReportType>;
    },
    [fetch],
  );
};

export const useGetInputPagingData = () => {
  const fetch = useFetch();
  return useCallback(
    (baseParam: {[key: string]: any}, keyword?: string, page?: number, size?: number) => {
      return fetch({
        url: `sccore/rest-api/report/${baseParam.reportId}/input/${baseParam.number}?keyword=${keyword??''}&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ReportInputDataType>>;
    },
    [fetch],
  );
};

export const useGetInputData = () => {
  const fetch = useFetch();
  return useCallback(
    (baseParam: {[key: string]: any}, keyword?: string) => {
      return fetch({
        url: `sccore/rest-api/report/${baseParam.reportId}/input/${baseParam.number}?keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<ReportInputDataType[]>;
    },
    [fetch],
  );
};

export const useGetReportData = () => {
  const fetch = useFetch();
  return useCallback(
    (reportId: number, parameters?: {[key: string]: any}) => {
      return fetch({
        url: `sccore/rest-api/report/data/${reportId}`,
        method: 'post',
        data: parameters
      }) as Promise<{[key: string]: any}[]>;
    },
    [fetch],
  );
};

export const useGetReportPagingData = () => {
  const fetch = useFetch();
  return useCallback(
    (reportId: number, parameters?: {[key: string]: any}, page?: number, size?: number) => {
      return fetch({
        url: `sccore/rest-api/report/data-paging/${reportId}?page=${page}&size=${size}`,
        method: 'post',
        data: parameters
      }) as Promise<PageData<{[key: string]: any}>>;
    },
    [fetch],
  );
};

export const useGetReportByKey = () => {
  const fetch = useFetch();
  return useCallback(
    (reportKey: string) => {
      return fetch({
        url: `sccore/rest-api/function/report/key/${reportKey}`,
        method: 'get',
      }) as Promise<ReportType>;
    },
    [fetch],
  );
};

export const usePostReport = () => {
  const fetch = useFetch();
  return useCallback(
    (printedTemplate: ReportType) => {
      return fetch({
        url: `sccore/rest-api/report`,
        method: 'post',
        data: printedTemplate,
      }) as Promise<ReportType>;
    },
    [fetch],
  );
};

export const usePutReport = () => {
  const fetch = useFetch();
  return useCallback(
    (printedTemplate: ReportType) => {
      return fetch({
        url: `sccore/rest-api/report`,
        method: 'put',
        data: printedTemplate,
      }) as Promise<ReportType>;
    },
    [fetch],
  );
};

export const useDeleteReport = () => {
  const fetch = useFetch();
  return useCallback(
    (reportId: number) => {
      return fetch({
        url: `sccore/rest-api/report/${reportId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
