/* eslint-disable react-hooks/exhaustive-deps */
import './StockInspection.css';
import React, { useCallback } from 'react';
import { useAddPopup } from 'src/state/application/hooks';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import { EnumAction, EnumDataType, EnumDisplayConfig, EventButton, StockInspectionStatus, StockInspectionType } from 'src/api/models';
import { useDeleteStockInspection, useGetStockInspections } from 'src/api/stockInspectionApi';
import { EnumStockInspectionStatus } from 'src/common/enum/EnumStockInspectionStatus';
import StockInspectionDetail from './StockInspectionDetail/StockInspectionDetail';


const StockInspection: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [stockInspections, setStockInspections] = useState<StockInspectionType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const getStockInspections = useGetStockInspections();
  const deleteStockInspection = useDeleteStockInspection();
  const detailModal = useModal(StockInspectionDetail);
  const confirmModal = useModal(ConfirmModal);


  const display = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo',
      },
      {
        code: 'status',
        dataType: EnumDataType.Int,
        isOptions: true,
        title: 'Trạng thái',
        options: StockInspectionStatus
      },
    ] as TableColumnType[]
  };

  const onView = (stockInspectionId: number) => {
    detailModal.handlePresent(
      {
        stockInspectionId: stockInspectionId,
        isDisable: true,
      },
      'CHI TIẾT PHIẾU KIỂM KÊ',
    );
  };

  const onEdit = useCallback((stockInspectionId: number) => {
    detailModal.handlePresent(
      {
        stockInspectionId: stockInspectionId,
        isDisable: false,
        postProcess: () => setReloadFlag(!reloadFlag)
      },
      'THAY ĐỔI PHIẾU KIỂM KÊ',
    );
  }, [reloadFlag]);

  const onCreate = useCallback(() => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: () => setReloadFlag(!reloadFlag)
      },
      'TẠO PHIẾU KIỂM KÊ',
    );
  }, [reloadFlag]);
  

  const onDelete = (stockInspectionId: number) => {
    const onConfirm = () => {
      deleteStockInspection(stockInspectionId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa phiếu kiểm kê thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa phiếu kiểm kê này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA PHIẾU KIỂM KÊ KHO',
    );
  };


  const menuContext = (item: StockInspectionType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.View,
      action: () => onView(item.stockInspectionId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.stockInspectionId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Delete,
      action: () => onDelete(item.stockInspectionId),
      buttonClass: 'info',
      align: '',
    },
  ];


  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onCreate,
      align: 'center',
    },
  ];

  useEffect(() => {
    getStockInspections(keyword, page, size)
      .then((data) => {
        setStockInspections(data.items);
        setCount(data.count);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [
    addPopup,
    getStockInspections,
    reloadFlag,
    keyword,
    page,
    size
  ]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH KIỂM KÊ KHO'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
      />

      <Table
        display={display}
        isInput={false}
        data={stockInspections}
        menuContext={menuContext}
        allowCheckbox={false}
        onDoubleClick={(item) => onView(item.stockInspectionId)}
        isNo={true}
      />
    </>
  );
};
export default StockInspection;
