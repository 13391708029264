import React, { useEffect, useState } from 'react';
import FCMNotificationList from '../FCMNotificationList/FCMNotificationList';
import { useGetBadge, useSeenNotification } from 'src/api/notificationApi';
import { useGetReloadNotificationFlg } from 'src/state/application/hooks';

const FCMNotificationIcon: React.FC = () => {
  const seenNotification = useSeenNotification();
  const getBadge = useGetBadge();
  const [badge, setBadge] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const reloadNotificationFlg = useGetReloadNotificationFlg();
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);

  const onHiddenPopup = () => {
    setIsShow(false);
  };


  useEffect(() => {
    getBadge()
      .then((badge) => {
        console.log(badge);
        setBadge(badge);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadNotificationFlg, getBadge, setBadge]);

  const onClickIcon = () => {
    seenNotification()
      .then(() => {
        setBadge(0);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsShow(!isShow);
  };

  //Main
  return (
    <div className="base-head-icon">
      <i
        className="fas fa-bell"
        style={{ fontSize: 20 }}
        onClick={onClickIcon}
        title="Thông báo"
        id="btn-show-noti"
        ref={(el) => {
          if (el) {
            const boundingClient = el.getBoundingClientRect();
            setIconX(boundingClient.left);
            setIconY(boundingClient.y + boundingClient.height);
          }
        }}
      ></i>
      {badge > 0 ? <div className="badge">{badge}</div> : null}
      {isShow ? <FCMNotificationList iconX={iconX} iconY={iconY} onHiddenPopup={onHiddenPopup} /> : null}
    </div>
  );
};
export default FCMNotificationIcon;
