import './DisbursementDetail.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGetDisbursementById, usePostDisbursement } from 'src/api/disbursementApi';
import { useAddPopup } from 'src/state/application/hooks';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';

interface IDisbursementDetail {
  disbursementId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const DisbursementDetail: React.FC<IDisbursementDetail> = (props) => {

  const getDisbursementById = useGetDisbursementById();
  const [isDisable, setDisable] = useState<boolean>();

  const [disbursementId, setDisbursementId] = useState<number>();
  const [money, setMoney] = useState<number>();
  const [disbursementContent, setDisbursementContent] = useState<string>();

  const postDisbursement = usePostDisbursement();

  const addPopup = useAddPopup();

  useEffect(() => {
    setDisbursementId(props.disbursementId);
    setDisable(props.isDisable);
  }, [props])

  const onSave = () => new Promise((resolve, reject) => {
    const disbursement = {
      disbursementId: disbursementId,
      money: money,
      disbursementContent: disbursementContent
    }
    postDisbursement(disbursement).then(
      (res) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Tạo phiếu chi thành công',
          },
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        reject(false);
      });

  });


  useEffect(() => {
    if(disbursementId) {
      getDisbursementById(disbursementId).then((res) => {
        setMoney(res.money);
        setDisbursementContent(res.disbursementContent);
      }).catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      })
    }
  }, [addPopup, getDisbursementById, disbursementId])


  return (
    <div className={`disbursement-container`}>
      <div className="disbursement-row">
        <Input
          width="300px"
          title="Số tiền"
          disabled={isDisable}
          value={money}
          onChange={setMoney}
          type='number'
        />
      </div>
      <div className="disbursement-row">
        <Input
          width="300px"
          title="Lý do"
          disabled={isDisable}
          value={disbursementContent}
          onChange={setDisbursementContent}
        />
      </div>
      {!isDisable && <div className="disbursement-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
      </div>}
    </div>
  );
};

export default DisbursementDetail;
