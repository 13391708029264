/************************************/
/********                    ********/
/******      Product Order   ********/
/******   Writen by HuyLV      ****/
/********                   ********/
/***********************************/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  EnumProductOrderProgressStatus,
  ImageType,
  PageData,
  PaymentType,
  ProductOrderType,
} from './models';

export const useGetProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number, userId: number, progressStatus: number) => {
      const url = `sccore/rest-api/product-order?keyword=${keyword}&page=${page}&size=${size}${
        userId ? `&userId=${userId}` : ''
      }${progressStatus ? `&progressStatus=${progressStatus}` : ''}`;
      console.log(url);
      return fetch({
        url: url,
        method: 'get',
      }) as Promise<PageData<ProductOrderType>>;
    },
    [fetch],
  );
};

export const useGetProductOrderById = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/${productOrderId}`,
        method: 'get',
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const useDeleteProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderId: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/${productOrderId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order/admin`,
        method: 'post',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const usePutProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order`,
        method: 'put',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const useGetMyProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/product-order/me?keyword=${keyword}&page=${page}&size=${size}`,
        method: 'get',
      }) as Promise<PageData<ProductOrderType>>;
    },
    [fetch],
  );
};

export const usePostMyProductOrder = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrder: ProductOrderType) => {
      return fetch({
        url: `sccore/rest-api/product-order/me`,
        method: 'post',
        data: productOrder,
      }) as Promise<ProductOrderType>;
    },
    [fetch],
  );
};

export const usePutUpdateProductOrderProgressStatus = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderIdList: any, progressStatus: EnumProductOrderProgressStatus) => {
      return fetch({
        url: `sccore/rest-api/product-order/update-status/${progressStatus}`,
        method: 'put',
        data: productOrderIdList,
      }) as Promise<boolean>;
    },
    [fetch],
  );
};


export const useGetProductOrderByIds = () => {
  const fetch = useFetch();
  return useCallback(
    (productOrderIds: number[]) => {
      return fetch({
        url: `sccore/rest-api/product-order/ids`,
        method: 'post',
        data: productOrderIds
      }) as Promise<ProductOrderType[]>;
    },
    [fetch],
  );
};

export const useExportProductOrderBill = () => {
  const fetch = useFetch();
  return useCallback(
    (payment: PaymentType) => {
      return fetch({
        url: `sccore/rest-api/product-order/export-bill`,
        method: 'post',
        data: payment,
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};
//End of Product Order
