/************************************/
/********                    ********/
/******   ExportBill          ********/
/******   Writen by LuanPT   ** ****/
/********                   ********/

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { ExportBillType, PageData } from './models';

/***********************************/

export const useGetExportBills = () => {
  const fetch = useFetch();
  return useCallback(
    (page: number, size: number, status: number) => {
      return fetch({
        url: `sccore/rest-api/export-bill?page=${page??0}&size=${size??0}${status?`&status=${status}`:''}`,
        method: 'get',
      }) as Promise<PageData<ExportBillType>>;
    },
    [fetch],
  );
};

export const useGetExportBill = () => {
  const fetch = useFetch();
  return useCallback(
    (exportBillId: number) => {
      return fetch({
        url: `sccore/rest-api/export-bill/${exportBillId}`,
        method: 'get',
      }) as Promise<ExportBillType>;
    },
    [fetch],
  );
};

export const usePostExportBill = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ExportBillType) => {
      return fetch({
        url: `sccore/rest-api/export-bill`,
        method: 'post',
        data: data,
      }) as Promise<ExportBillType>;
    },
    [fetch],
  );
};


export const usePutExportBill = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ExportBillType) => {
      return fetch({
        url: `sccore/rest-api/export-bill`,
        method: 'put',
        data: data,
      }) as Promise<ExportBillType>;
    },
    [fetch],
  );
};

export const useDeleteExportBill = () => {
  const fetch = useFetch();
  return useCallback(
    (exportBillId: number) => {
      return fetch({
        url: `sccore/rest-api/export-bill/${exportBillId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

