import React, { useState, useEffect } from 'react';
import { ProfileInfo, SendSimPriceToUserType } from 'src/api/models';
import { useSendSimPriceEmail } from 'src/api/simPriceApi';
import { useGetUserList } from 'src/api/userApi';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import ChooseDateDetail from '../ChooseDateDetail/ChooseDateDetail';
import './SendSimPriceDetail.css';

type SendType = {
  userId: number;
  name: string;
};

const SendSimPriceDetail: React.FC = () => {
  //State
  const [date, setDate] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [userList, setUserList] = useState<ProfileInfo[]>(null);

  const [user, setUser] = useState<SendType[]>(null);

  //Function
  const getUserList = useGetUserList();
  const sendEmail = useSendSimPriceEmail();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  const onChangeDate = (d: string) => {
    setDate(d);
  };

  const onAddUserId = (userId: number, name: string) => {
    if (user) {
      const temp = user.filter(() => true);
      let isAdd = true;

      temp.map((value) => {
        if (value.userId == userId) {
          isAdd = false;
        }
      });
      if (isAdd) {
        const v: SendType = {
          userId: userId,
          name: name,
        };
        temp.push(v);
        setUser(temp);
      }
    } else {
      const temp: SendType = {
        userId: userId,
        name: name,
      };
      setUser([temp]);
    }
  };

  const onDeleteUser = (userId: number) => {
    const temp = user.filter(() => true);
    let i;

    temp.map((value, index) => {
      if (value.userId == userId) {
        i = index;
      }
    });
    if (temp.length > 1) {
      temp.splice(i, 1);
      setUser(temp);
    } else {
      setUser(null);
    }
  };

  const onSendEmail = () => {
    const temp: number[] = [];
    user.map((value) => {
      temp.push(value.userId);
    });
    const data: SendSimPriceToUserType = {
      fromDate: date,
      userIds: temp,
    };
    sendEmail(data)
      .then((r) => {
        if (r) {
          addPopup({
            txn: {
              success: true,
              summary: 'Đã gửi mail thông báo bảng giá',
            },
          });
        }
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra!',
            message: error.errorMessage,
          },
        });
      });
  };

  useEffect(() => {
    const date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yy = date.getFullYear();

    const temp = `${yy}/${mm}/${dd}`;
    setDate(temp);
  }, []);

  useEffect(() => {
    getUserList(keyword)
      .then((data) => {
        setUserList(data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getUserList, keyword]);

  //Main
  return (
    <div className="send-sp-dt-container">
      <div className="send-sp-dt-full no-wrap">
        <div className="send-sp-dt-cpn">
          <div className="send-sp-dt-title">Chọn ngày (định dạng dd//mm/yy)</div>
          <input
            className="send-sp-dt-input"
            type="text"
            value={date}
            onChange={(event) => {
              setDate(event.target.value);
            }}
            placeholder={'Định dạng dd/mm/yy'}
          />
          <ChooseDateDetail onChange={onChangeDate} />
        </div>
        <div className="send-sp-dt-cpn">
          <div className="send-sp-dt-title">Chọn CTV</div>
          <input
            className="send-sp-dt-input"
            type="text"
            value={keyword}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
            placeholder={'Nhập tên CTV'}
          />
          <div className="send-sp-dt-user-list">
            {userList
              ? userList.map((value, index) => {
                  return (
                    <div
                      className={`send-sp-dt-user-cpn ${index % 2 == 1 ? 'le' : 'chan'}`}
                      onClick={() => {
                        onAddUserId(value.userId, value.fullName);
                      }}
                    >
                      <div className="send-sp-dt-text name">{value.fullName}</div>
                      <div className="send-sp-dt-text email">
                        <i>{value.email}</i>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="send-sp-dt-full">
        {user
          ? user.map((value) => {
              return (
                <div
                  className="send-sp-dt-user-check"
                  onClick={() => {
                    onDeleteUser(value.userId);
                  }}
                >
                  <div>{value.name}</div>
                  <i className="fas fa-times"></i>
                </div>
              );
            })
          : null}
      </div>
      <div className="send-sp-dt-full-btn">
        <div className="choose-detail-btn mt-3 mb-3" onClick={onSendEmail}>
          Gửi
        </div>
      </div>
    </div>
  );
};

export default SendSimPriceDetail;
