import React, { useState, useEffect } from 'react';
import { SimPriceType } from 'src/api/models';
import { useGetSimPriceById, useGetSimPriceEditHistory } from 'src/api/simPriceApi';
import { useAddPopup } from 'src/state/application/hooks';
import './SimPriceSlideBar.css';

interface ISlideBarDetail {
  simPriceId: number;
}

const SimPriceSlideBar: React.FC<ISlideBarDetail> = (props) => {
  //Value
  const simPriceId = props.simPriceId;

  const [simPriceEditHistory, setSimPriceEditHistory] = useState<SimPriceType[]>(null);

  //Function
  const addPopup = useAddPopup();
  const getSimPriceById = useGetSimPriceById();
  const getSimPriceEditHistory = useGetSimPriceEditHistory();

  //useEffect
  useEffect(() => {
    getSimPriceById(simPriceId)
      .then((r) => {
        getSimPriceEditHistory(r.customerTypeId, r.productId)
          .then((data) => {
            setSimPriceEditHistory(data);
          })
          .catch((error) => {
            addPopup({
              error: {
                title: 'Đã có lỗi xảy ra!',
                message: error.errorMessage,
              },
            });
          });
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra!',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getSimPriceById, getSimPriceEditHistory, simPriceId]);

  //Main
  return (
    <div className="slide-bar-detail-container user">
      <div className="slide-bar-detail-form m-2">
        <div className="slide-bar-detail-title">LỊCH SỬ CẬP NHẬT</div>
      </div>
      <div className="slide-bar-price-container">
        <div className="slide-bar-sim-price-component">
          <div className="slide-bar-sim-price-date">Ngày hiệu lực</div>
          <div className="slide-bar-sim-price-price">Giá</div>
          <div className="slide-bar-sim-price-price">C.tháng</div>
        </div>
        {simPriceEditHistory
          ? simPriceEditHistory.map((value, index: number) => {
              return (
                <div
                  className={`slide-bar-sim-price-component ${index % 2 == 0 ? 'chan' : 'le'}`}
                >
                  <div className="slide-bar-sim-price-date">{value.effectiveDate}</div>
                  <div className="slide-bar-sim-price-price">{value.price}</div>
                  <div className="slide-bar-sim-price-price">
                    {value.monthlySubscriptionFee}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default SimPriceSlideBar;
