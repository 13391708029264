import './PaymentDetail.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tabs from 'src/components/Tabs';

import { PaymentType } from 'src/api/models';
import { useGetPaymentById, usePostPayment } from 'src/api/paymentApi';
import { useAddPopup } from 'src/state/application/hooks';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { EnumPaymentStatus } from 'src/common/enum/EnumPaymentStatus';

interface IPaymentDetail {
  paymentId?: number;
  data?: PaymentType;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

const PaymentDetail: React.FC<IPaymentDetail> = (props) => {
  const {paymentId, data, isPopup, isSlide, postProcess} = props;
  const getPaymentById = useGetPaymentById();
  const postPayment = usePostPayment();
  const [payment, setPayment] = useState<PaymentType>();
  const addPopup = useAddPopup();

  const onSave = () => new Promise((resolve, reject) => {
    postPayment(payment).then(
      (res) => {
        setPayment(res);
        addPopup({
          txn: {
            success: true,
            summary: 'Tạo hóa đơn thanh toán thành công',
          },
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        reject(false);
      });

  });


  useEffect(() => {
    if(paymentId) {
      getPaymentById(paymentId).then((res) => {
        setPayment(res);
      }).catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      })
    } else {
      setPayment(data);
    }
  }, [addPopup, data, getPaymentById, paymentId])


  return (<>
      {!payment?.paymentId? <ButtonComponent className="payment-button" icon="save" title={'LƯU'} onClick={onSave} loader={true}/>
      : <ButtonComponent className="payment-button" icon="content_copy" title={'COPY LINK'} onClick={() => {
        navigator.clipboard.writeText(`https://order.jvscorp.jp/payment/${payment?.paymentCode}`);
      }} loader={true}/>}
      <div className='payment-detail-content'
        dangerouslySetInnerHTML={{
          __html: payment?.paymentContent,
        }}
      />
      {payment?.status == EnumPaymentStatus.Paid ? <div className='paid-payment'>ĐÃ THANH TOÁN</div> : null}
    </>
  );
};

export default PaymentDetail;
