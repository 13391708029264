/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { EnumAction, EnumDataType, EnumDisplayConfig, EnumPaymentType, EnumShipOrderProgressStatus, EnumShipOrderProgressStatusTitle, EnumTransportType, EnumVNShipper, ImageType, PaymentTypeList, ProductOrderType, ProfileInfo, ReceiverOrderType, SalesCollatoraborType, ShipOrderProgressStatusList, ShipOrderType, TransportType, VNShippers } from 'src/api/models';
import { useDeleteShipOrderImage, useGetShipOrderById, usePostShipOrder, usePostShipOrderImage, usePrintSubQR, usePrintVNShip, usePutShipOrder, useReceiverShipOrder } from 'src/api/shipOrderApi';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import { useAddPopup } from 'src/state/application/hooks';
import "./ShipOrderDetail.css";
import { useGetEffectiveParameterByKey } from 'src/api/parameterApi';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import Note from 'src/components/Note/Note';
import Table from 'src/components/Table/Table';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useGetProductOrderByIds } from 'src/api/productOrderApi';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { ADMIN } from 'src/common/constant/Constant';
import ContactList from 'src/components/ContactList/ContactList';
import SpecialProduct, { SpecialProductType } from 'src/components/SpecialProduct/SpecialProduct';
import NomalProduct from 'src/components/NomalProduct';
import SelectSearchComponent from 'src/components/SelectSearchComponent/SelectSearchComponent';
import { useGetReceiverOrder, useGetReceiverOrderByCode, useGetReceiverOrderById } from 'src/api/receiverOrderApi';
import ImageUpload from 'src/components/ImageUpload';
import { useDebounce } from 'use-debounce';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { BASE_ORDER_URL } from 'src/constants';
import { NomalProductType } from 'src/components/NomalProduct/NomalProduct';
import { useGetSalesCollatoraborByUser } from 'src/api/salesCollatoraborApi';
import DataScanList from 'src/components/DataScanList';
import Printed from 'src/components/Printed/Printed';
import { randomvnmoney } from 'src/utils/numberUtils';
import { InlineRow } from 'src/components/Layout';
import { yymmddhhmmss } from 'src/utils/formatTime';

interface IShipOrderDetail {
  shipOrderId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  isReceiver?: boolean;
  role?: string;
  billCode?: string;
  japanTrackingCode?: string;
  newProgressStatus?: number;
  postProcess?: (...args: any[]) => void;
}

const ShipOrderDetail: React.FC<IShipOrderDetail> = (props) => {

  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();
  const [shipOrderId, setShipOrderId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const params = useParams<{role: string; type: string; shipOrderId: string }>();
  const [isReceiver, setReceiver] = useState(false);
  const [role, setRole] = useState<string>();
  const [billCode, setBillCode] = useState<string>();
  const [scanCode, setScanCode] = useState<string>();
  const [conversionCode, setConversionCode] = useState<string>();
  const [scanCodeDebound] = useDebounce(scanCode, 1000);
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [receiverOrder, setReceiverOrder] = useState<ReceiverOrderType>();
  const [salesCollatorabor, setSalesCollatorabor] = useState<SalesCollatoraborType>();
  const [transportType, setTransportType] = useState<EnumTransportType>();
  const [paymentType, setPaymentType] = useState<EnumPaymentType>();
  const [isPaid, setPaid] = useState<boolean>();
  const [senderName, setSenderName] = useState<string>();
  const [senderAddress, setSenderAddress] = useState<string>();
  const [receiverAddress, setReceiverAddress] = useState<string>();
  const [nomalProduct, setNomalProduct] = useState<NomalProductType[]>([]);
  const [specialProduct, setSpecialProduct] = useState<SpecialProductType[]>([]);
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>();
  const [receiverName, setReceiverName] = useState<string>();
  const [airPrice, setAirPrice] = useState<number>();
  const [seaPrice, setSeaPrice] = useState<number>();
  const [discount, setDiscount] = useState<number>();
  const [cod, setCod] = useState<boolean>();

  const [volumeSeaPrice, setVolumeSeaPrice] = useState<number>();
  const [volumeAirPrice, setVolumeAirPrice] = useState<number>();
  const [overAirPrice, setOverAirPrice] = useState<number>();
  const [overSeaPrice, setOverSeaPrice] = useState<number>();
  const [shipPackageId, setShipPackageId] = useState<number>();
  const [weight, setWeight] = useState<number>();
  const [length, setLength] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [transportFee, setTransportFee] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [overPrice, setOverPrice] = useState<number>();
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>(EnumShipOrderProgressStatus.Waiting);
  const [japanTrackingCode, setJapanTrackingCode] = useState<string>();
  const [vietnamTrackingCode, setVietnamTrackingCode] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [customerNote, setCustomerNote] = useState<string>();
  const [paymentCode, setPaymentCode] = useState<string>();
  const [productOrderIds, setProductOrderIds] = useState<number[]>([]);
  const [productOrders, setProductOrders] = useState<ProductOrderType[]>([]);
  const [isConsignment, setConsignment] = useState<boolean>(false);
  const [isTaxRefunt, setTaxRefunt] = useState<boolean>(false);
  const [subNumber, setSubNumber] = useState<number>();
  const [subInfo, setSubInfo] = useState<{[subQR: string]: {weight: number; price: number; products: NomalProductType[]}}>({});
  const getProductOrderByIds = useGetProductOrderByIds();
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);

  const [images, setImages] = useState<ImageType[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [suggessAddress, setSuggessAddress] = useState<any>();

  const [vietnamShipper, setVietnamShipper] = useState<number>();
  const [vietnamShipfee, setVietnamShipfee] = useState<number>();
  //End of state

  //Function
  const addPopup = useAddPopup();
  const getShipOrder = useGetShipOrderById();
  const postShipOrder = usePostShipOrder();
  const putShipOrder =  usePutShipOrder();
  const postShipOderImage = usePostShipOrderImage();
  const deleteShipOrderImage = useDeleteShipOrderImage();
  const getParameterByKey = useGetEffectiveParameterByKey();
  const receiverShipOrder = useReceiverShipOrder();
  const getReceiverOrder = useGetReceiverOrder();
  const getReceiverOrderById = useGetReceiverOrderById();
  const getReceiverOrderByCode = useGetReceiverOrderByCode();
  const getSalesCollatorabor = useGetSalesCollatoraborByUser();

  const qrReaderModal = useModal(QRCodeReader);
  const printedModal = useModal(Printed);
  const printSubQR = usePrintSubQR();
  const printVNShip = usePrintVNShip();

  const display: ItemDisplayType<ProductOrderType> = {
    header: [
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          flex: 'auto'
        }
      }
    ],
    detail: {
      avatar: 'thumbProductImage',
      info: [
        [
          {
            code: 'description',
            dataType: EnumDataType.Text,
            isOptions: false,
          }
        ],
        [
          {
            code: 'quantity',
            dataType: EnumDataType.BigInt,
            isOptions: false,
            icon: 'shopping_cart',
            cellCss: {
              margin: '0 5px'
            }
          },
          {
            code: 'productPrice',
            dataType: EnumDataType.VND,
            isOptions: false,
            icon: 'payments',
            cellCss: {
              margin: '0 5px'
            }
          }
        ]
      ]
    },
  };

  const tableDisplay = {
    header: [
      {
        code: 'thumbProductImage',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Ảnh',
      },
      {
        code: 'productName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tên SP',
      },
      {
        code: 'productPrice',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Giá SP',
      },
      {
        code: 'quantity',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Số lượng',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả hàng hóa',
      }
    ]
  };


  const onAddImage = (file: File) => {
    if (file) {
      if (!shipOrderId) {
        setImageFiles([...imageFiles, file]);
      } else {
        uploadImage(shipOrderId, file)
          .then((res) => {
            setImages([...images, res]);
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  const onDeleteShipOrderImage = (file: ImageType | File) => {
    if (file instanceof File) {
      setImageFiles(imageFiles.filter((f) => f != file));
    } else {
      deleteShipOrderImage(shipOrderId, file.fileId)
        .then(() => {
          setImages(images.filter((i) => i.fileId != file.fileId));
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    }
  };

  const onSuccess = (isAdd: boolean, res: ShipOrderType) => {
    addPopup({
      txn: {
        success: true,
        summary: !isAdd? 'Thay đổi đơn vận chuyển thành công' : 'Tạo đơn vận chuyển thành công',
      }
    });
    if(props.newProgressStatus == EnumShipOrderProgressStatus.Contact && vietnamTrackingCode && vietnamShipfee > 0) {
      console.log("IN VN")
      printVNShip(shipOrderId).then((res) => {
        printedModal.handlePresent(
          {
            values: [res],
          },
          'IN SUBQR',
        );
      })
      
    } else {
      if(props.postProcess) props.postProcess(res);
    }
  }

  const uploadImage = useCallback(
    (shipOrderId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return postShipOderImage(formData, shipOrderId);
    },
    [postShipOderImage],
  );

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_ORDER_URL}/ship-order/${role}/${shipOrderId}/${EnumViewType.Edit}`);
  };

  //TODO
  const onSave = () => new Promise((resolve) => {
    if (description?.trim().length > 0 || billCode?.trim().length > 0) {
      const shipOrder: ShipOrderType = {
        shipOrderId: shipOrderId,
        billCode: billCode,
        userId: userId,
        receiverOrderId: receiverOrder?.receiverOrderId,
        weight: weight,
        japanTrackingCode: japanTrackingCode,
        vietnamTrackingCode: vietnamTrackingCode,
        description: description,
        customerNote: customerNote,
        transportFee: transportFee,
        isPaid: isPaid,
        nomalProduct: JSON.stringify(nomalProduct),
        specialProduct: JSON.stringify(specialProduct),
        subNumber: isConsignment? subNumber : undefined,
        subInfo: isConsignment? JSON.stringify(subInfo): undefined,
        senderAddress: senderAddress,
        receiverAddress: receiverAddress,
        receiverName: receiverName,
        receiverPhoneNumber: receiverPhoneNumber,
        progressStatus: props.newProgressStatus? props.newProgressStatus : progressStatus,
        price: price,
        transportType: transportType,
        length: length,
        width: width,
        height: height,
        senderName: senderName,
        conversionCode: conversionCode,
        paymentType: paymentType,
        paymentCode: paymentCode,
        discount: discount,
        cod: cod,
        shipPackageId: shipPackageId,
        vietnamShipper: vietnamShipper,
        vietnamShipfee: vietnamShipfee
      };
      const isAdd = !shipOrderId;
      const api = isAdd? postShipOrder(shipOrder) : isReceiver? receiverShipOrder(shipOrder) : putShipOrder(shipOrder);
      api.then(
        (res) => {
          setShipOrderId(res.shipOrderId);
          setIsDisable(true);
          if (isAdd) {
            const uploadApis = imageFiles.map((image) => uploadImage(res.shipOrderId, image));
            Promise.all(uploadApis)
              .then((imageRes) => {
                setImages([...images, ...imageRes]);
                onSuccess(isAdd, res);
                resolve(true);
              })
              .catch((error) => {
                addPopup({
                  error: {
                    message: error.errorMessage,
                    title: 'Tải ảnh thất bại, vui lòng thử lại!',
                  },
                });
                resolve(false);
              });
            setImageFiles([]);
          } else {
            onSuccess(isAdd, res);
            resolve(true);
          }
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          resolve(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Yêu cầu nhập mô tả hoặc có mã phiếu',
        },
      });
      resolve(false);
    }

  });


  const changeWeight = (value: number) => {
    const nweight = Number(value) * 1000;
    setWeight(nweight);
    calcPayment(specialProduct, paymentType, transportType, receiverOrder, salesCollatorabor, nweight, width, height, length, discount);
  }

  const changeTransportType = (value: number) => {
    setTransportType(value);
    calcPayment(specialProduct, paymentType, value, receiverOrder, salesCollatorabor, weight, width, height, length, discount);
  }

  const changePaymentType = (value: number) => {
    setPaymentType(value);
    calcPayment(specialProduct, value, transportType, receiverOrder, salesCollatorabor, weight, width, height, length, discount);
  }

  const changeWidth = (value: number) => {
    setWidth(value);
    calcPayment(specialProduct, paymentType, transportType, receiverOrder, salesCollatorabor, weight, value, height, length, discount);
  }

  const changeHeight = (value: number) => {
    setHeight(value);
    calcPayment(specialProduct, paymentType, transportType, receiverOrder, salesCollatorabor, weight, width, value, length, discount);
  }

  const changeLength = (value: number) => {
    setLength(value);
    calcPayment(specialProduct, paymentType, transportType, receiverOrder, salesCollatorabor, weight, width, height, value, discount);
  }

  const calcPayment = (specialProduct: SpecialProductType[],
    paymentType: number,
    transportType: number,
    receiverOrder: ReceiverOrderType,
    salesCollatorabor: SalesCollatoraborType,
    weight: number,
    width: number,
    height: number,
    length: number,
    discount: number) => {
    const specialFee = calcSpecialFee(specialProduct);
    let price;
    let normalFee;
    let overPrice;
    console.log("calc")
    switch(paymentType) {
      case EnumPaymentType.NormalVolume:
        price = transportType > EnumTransportType.HNAir?
          salesCollatorabor?.volumeSeaPrice > 0? salesCollatorabor.volumeSeaPrice : receiverOrder?.volumeSeaPrice > 0? receiverOrder.volumeSeaPrice : volumeSeaPrice :
          salesCollatorabor?.volumeAirPrice > 0? salesCollatorabor.volumeAirPrice : receiverOrder?.volumeAirPrice > 0? receiverOrder?.volumeAirPrice : volumeAirPrice;
        normalFee = (width??0) * (height??0) * (length??0) * (price??0) / 1000000000;
        break;
      case EnumPaymentType.ConvertWeight:
        price = transportType > EnumTransportType.HNAir?
          salesCollatorabor?.seaPrice > 0? salesCollatorabor.seaPrice : receiverOrder?.seaPrice > 0? receiverOrder.seaPrice : seaPrice :
          salesCollatorabor?.airPrice > 0? salesCollatorabor.airPrice : receiverOrder?.airPrice > 0? receiverOrder.airPrice : airPrice;
        normalFee = (width??0) * (height??0) * (length??0) * (price??0) / 6000000;
        break;
      case EnumPaymentType.OverWeight:
        price = transportType > EnumTransportType.HNAir?
          salesCollatorabor?.seaPrice > 0? salesCollatorabor.seaPrice : receiverOrder?.seaPrice > 0? receiverOrder.seaPrice : seaPrice :
          salesCollatorabor?.airPrice > 0? salesCollatorabor.airPrice : receiverOrder?.airPrice > 0? receiverOrder.airPrice : airPrice;
        overPrice = transportType > EnumTransportType.HNAir?
          salesCollatorabor?.overSeaPrice > 0? salesCollatorabor.overSeaPrice : receiverOrder?.overSeaPrice > 0? receiverOrder.overSeaPrice : overSeaPrice :
          salesCollatorabor?.overAirPrice > 0? salesCollatorabor.overAirPrice : receiverOrder?.overAirPrice > 0? receiverOrder.overAirPrice : overAirPrice;
        normalFee =  (weight??0) * (price??0) / 1000 + ((width??0) * (height??0) * (length??0) - (weight??0) * 6000) * (overPrice??0) / 6000000;
        break;
      case EnumPaymentType.NormalWeight:
      default:
        price = transportType > EnumTransportType.HNAir?
          salesCollatorabor?.seaPrice > 0? salesCollatorabor.seaPrice : receiverOrder?.seaPrice > 0? receiverOrder.seaPrice : seaPrice :
          salesCollatorabor?.airPrice > 0? salesCollatorabor.airPrice : receiverOrder?.airPrice > 0? receiverOrder.airPrice : airPrice;
        normalFee = (weight??0) * (price??0) / 1000;
        break;

    }
    setOverPrice(overPrice);
    setPrice(price);
    setTransportFee(normalFee + specialFee - (discount??0));
  }

  const changeReceiverOrder = (value: ReceiverOrderType) => {
    if(!userId && value.userId) {
      setUserId(value.userId);
      setUserName(value.userName);
    }
    setReceiverOrder(value);
    calcPayment(specialProduct, paymentType, transportType, value, salesCollatorabor, weight, width, height, length, discount);
  }

  const changeUser = (user: ProfileInfo) => {
    setUserId(user?.userId??undefined);
    setUserName(user?.fullName??undefined);
    setIsShowUserList(false);
    if(user?.userId) {
      getSalesCollatorabor(user.userId).then((r) => {
        setSalesCollatorabor(r);
        if(r.receiverOrderId > 0 && r.receiverOrderId != receiverOrder.receiverOrderId) {
          getReceiverOrderById(r.receiverOrderId).then((ro) => {
            setReceiverOrder(ro);
            calcPayment(specialProduct, paymentType, transportType, ro, r, weight, width, height, length, discount);
          })
        } else {
          calcPayment(specialProduct, paymentType, transportType, receiverOrder, r, weight, width, height, length, discount);
        }
      }).catch(() => {
        setSalesCollatorabor(undefined);
        calcPayment(specialProduct, paymentType, transportType, receiverOrder, undefined,  weight, width, height, length, discount);
      })
    } else {
      setSalesCollatorabor(undefined);
      calcPayment(specialProduct, paymentType, transportType, receiverOrder, undefined,  weight, width, height, length, discount);
    }
  }

  const changePrice = (value: number) => {
    setPrice(value);
    let normalFee;
    switch(paymentType) {
      case EnumPaymentType.NormalVolume:
        normalFee = (width??0) * (height??0) * (length??0) * (value??0) / 1000000000;
        break;
      case EnumPaymentType.ConvertWeight:
        normalFee = (width??0) * (height??0) * (length??0) * (value??0) / 6000000;
        break;
      case EnumPaymentType.OverWeight:
        normalFee =  (weight??0) * (value??0) / 1000 + ((width??0) * (height??0) * (length??0) - (weight??0) * 6000) * (overPrice??0) / 6000000;
        break;
      case EnumPaymentType.NormalWeight:
      default:
        normalFee = (weight??0) * (value??0) / 1000;
        break;
    }
    const specialFee = calcSpecialFee(specialProduct);
    setTransportFee(normalFee + specialFee - (discount??0));
  }

  const calcSpecialFee = (specialProduct: SpecialProductType[]) => {
    return specialProduct? specialProduct.filter(p => p.price > 0 && p.quantity > 0).reduce((total, p) => total += (p.price * p.quantity), 0) : 0;
  }

  const changeSpecialProduct = (value: SpecialProductType[]) => {
    setSpecialProduct(value);
    calcPayment(value, paymentType, transportType, receiverOrder, salesCollatorabor, weight, width, height, length, discount);
  }

  const changeDiscount = (value: number) => {
    setDiscount(value);
    calcPayment(specialProduct, paymentType, transportType, receiverOrder, salesCollatorabor, weight, width, height, length, value);
  }

  const changeTransportFee = (value: number) => {
    const specialFee = calcSpecialFee(specialProduct);
    setTransportFee(value > 0 ? value : 0);
    switch(paymentType) {
      case EnumPaymentType.NormalVolume:
        if((width??0) * (height??0) * (length??0) > 0 && (value??0 - specialFee) > 0) {
          setPrice((value - specialFee + (discount??0)) * 1000000000 / ((width??0) * (height??0) * (length??0)));
        } else {
          setPrice(undefined);
        }
        return;
      case EnumPaymentType.ConvertWeight:
        if((width??0) * (height??0) * (length??0) > 0 && (value??0 - specialFee) > 0) {
          setPrice((value - specialFee + (discount??0)) * 6000000 / ((width??0) * (height??0) * (length??0)));
        } else {
          setPrice(undefined);
        }
        return;
      case EnumPaymentType.OverWeight:
        if((width??0) * (height??0) * (length??0) > 0 && (value??0 - specialFee) > 0 && weight > 0) {
          setPrice((value - specialFee + (discount??0) - ((width??0) * (height??0) * (length??0) - (weight??0) * 6000) * (overPrice??0) / 6000000) * 1000  / weight);
        } else {
          setPrice(undefined);
        }
        return;
      case EnumPaymentType.NormalWeight:
      default:
        if(weight > 0) {
          const price = (value??0 - specialFee + (discount??0)) > 0? (value??0 - specialFee) * 1000 / weight: 0;
          setPrice(price);
        } else if(price > 0) {
          const weight = (value??0 - specialFee + (discount??0)) > 0? (value??0 - specialFee) * 1000 / price: 0;
          setWeight(weight);
        }
        return;
    }
  }

  const parseBillcode = (code: string) => {
    const codes = code?.split("-");
    if(codes?.length > 0 && !receiverOrder) {
      getReceiverOrderByCode(codes[0]).then(res => {
        if(res) {
          changeReceiverOrder(res);
        } else {
          changeReceiverOrder(undefined);
        }
      }).catch(() => {
        changeReceiverOrder(undefined);
      })
    }
  }

  useEffect(() => {
    setShipOrderId(props.shipOrderId || Number(params.shipOrderId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
    setReceiver(props.isReceiver);
    setRole(props.role || params.role);
    setSuggessAddress(undefined);
    if(props.billCode?.length > 0) {
      setBillCode(props.billCode);
      parseBillcode(props.billCode);
    }
    if(props.japanTrackingCode?.length > 0) {
      setJapanTrackingCode(props.japanTrackingCode);
    }
  }, [params, params.shipOrderId, params.type, props, props.billCode, props.japanTrackingCode, props.isDisable, props.shipOrderId, props.isReceiver, props.role, params.role]);

  useEffect(() => {
    parseBillcode(scanCodeDebound as string);
  }, [scanCodeDebound])

  useEffect(() => {
    Promise.all([
      getParameterByKey('AirPrice'),
      getParameterByKey('SeaPrice'),
      getParameterByKey('VolumeAirPrice'),
      getParameterByKey('VolumeSeaPrice'),
      getParameterByKey('OverAirPrice'),
      getParameterByKey('OverSeaPrice'),
    ]).then(([airFeeRes, seaFeeRes, volumeAirPriceRes, volumeSeaPriceRes, overAirPriceRes, overSeaPriceRes]) => {
      setAirPrice(Number(airFeeRes.parameterValue));
      setSeaPrice(Number(seaFeeRes.parameterValue));
      setVolumeAirPrice(Number(volumeAirPriceRes.parameterValue));
      setVolumeSeaPrice(Number(volumeSeaPriceRes.parameterValue));
      setOverAirPrice(Number(overAirPriceRes.parameterValue));
      setOverSeaPrice(Number(overSeaPriceRes.parameterValue));
    })
    .catch((e) => {
      addPopup({ error: { title: 'Đã có lỗi xảy ra', message: e.errorMessage } });
    });
  }, [])

  useEffect(() => {
    if(shipOrderId) {
      getShipOrder(shipOrderId).then((res) => {
        setBillCode(res.billCode);
        setWeight(res.weight);
        setTransportFee(res.transportFee);
        setProgressStatus(res.progressStatus);
        setJapanTrackingCode(res.japanTrackingCode);
        setVietnamTrackingCode(res.vietnamTrackingCode);
        setDescription(res.description);
        setCustomerNote(res.customerNote);
        setImages(res.images);
        setProductOrderIds(res.productOrderIds);
        setUserId(res.userId);
        setUserName(res.userName);
        setPaid(res.isPaid);
        setSenderAddress(res.senderAddress);
        setReceiverAddress(res.receiverAddress);
        setNomalProduct(res.nomalProduct?JSON.parse(res.nomalProduct):[]);
        setSpecialProduct(res.specialProduct?JSON.parse(res.specialProduct):[]);
        setReceiverPhoneNumber(res.receiverPhoneNumber);
        setReceiverName(res.receiverName);
        setPrice(res.price);
        setTransportType(res.transportType);
        setPaymentType(res.paymentType);
        setSenderName(res.senderName);
        setLength(res.length);
        setWidth(res.width);
        setHeight(res.height);
        setConversionCode(res.conversionCode);
        setPaymentCode(res.paymentCode);
        setDiscount(res.discount);
        setCod(res.cod);
        setShipPackageId(res.shipPackageId);
        setSubNumber(res.subNumber);
        setConsignment(res.subNumber > 0);
        setSubInfo(res.subInfo?JSON.parse(res.subInfo):{});
        if(res.receiverOrderId) {
          getReceiverOrderById(res.receiverOrderId).then((r) => {
            setReceiverOrder(r);
          }).catch(() => {
            setReceiverOrder(undefined);
          })
        }
        if(res.userId) {
          getSalesCollatorabor(res.userId).then((r) => {
            setSalesCollatorabor(r);
          }).catch(() => {
            setSalesCollatorabor(undefined);
          })
        }
        setVietnamShipper(res.vietnamShipper);
        setVietnamShipfee(res.vietnamShipfee);
      })
    } else {
      setTransportType(EnumTransportType.HNAir);
      setPaymentType(EnumPaymentType.NormalWeight);
    }
  }, [shipOrderId]);

  useEffect(() => {
    if(productOrderIds.length > 0) {
      getProductOrderByIds(productOrderIds).then((res) => {
        setProductOrders(res);
      })
    }
  }, [getProductOrderByIds, productOrderIds])

  const mergeInvoice = (value: {[subQR: string]: {weight: number; price: number; products: NomalProductType[]}}) => {
    const nProducts: NomalProductType[] = [];
    Object.entries(value).map(([subQR, info]) => {
      info.products.forEach((item) => {
        const product = nProducts.find(p => p.product == item.product);
        if(product) {
          product.quantity += item.quantity;
        } else {
          nProducts.push({product: item.product, quantity: item.quantity});
        }
      })
    });
    setNomalProduct(nProducts);
  }

  const onChangeSubNumber = (value: number) => {
    const nInfo: {[subQR: string]: {weight: number; price: number; products: NomalProductType[]}} = {};
    for(let i = 1; i <= value; i++) {
      const subQR = `${billCode}.${i}`;
      if(Object.prototype.hasOwnProperty.call(subInfo, subQR)) {
        nInfo[subQR] = subInfo[subQR];
      } else {
        nInfo[subQR] = {weight: 0, price: randomvnmoney(400000, 1000000), products: []};
      }
    }
    setSubInfo(nInfo);
    mergeInvoice(nInfo)
    setSubNumber(value);
  }

  const onPrintSubQR = () => {
    if(subNumber > 0) {
      printSubQR(shipOrderId, subNumber).then((res) => {
        printedModal.handlePresent(
          {
            values: res,
          },
          'IN SUBQR',
        );
      })
    } else {
      addPopup({
        error: { message: "Vui lòng nhập số lượng gói", title: 'Đã có lỗi xảy ra!' },
      });
    }
  }

  const resetVNShip = () => {
    setVietnamShipfee(undefined);
    setVietnamTrackingCode(undefined);
    setVietnamShipper(undefined);
  }

  const onChangeVietnamShipper = (value: number) => {
    const errorMessages = [];
    if(!weight) errorMessages.push("khối lượng");
    if(!height || !length || !width) errorMessages.push("kích thước");
    if(!receiverName) errorMessages.push("tên người nhận");
    if(!receiverAddress) errorMessages.push("địa chỉ người nhận");
    if(!receiverPhoneNumber) errorMessages.push("SĐT người nhận");

    if(errorMessages.length > 0) {
      addPopup({
        error: { message: `Chưa nhập thông tin ${errorMessages.join(", ")}`, title: 'Đã có lỗi xảy ra!' },
      });
      resetVNShip();
    } else {
     
      if(value == EnumVNShipper.NinjaVan) {
        if(weight > 50000 || length > 800 || width > 800 || height > 800) {
          addPopup({
            error: { message: `Đơn hàng quá khổ`, title: 'Đã có lỗi xảy ra!' },
          });
          resetVNShip();
        } else {
          const southern = ["ANGIANG", "BARIA","VUNGTAU", "BACLIEU", "BENTRE", "BINHDUONG", "HCM", "HOCHIMINH",
          "BINHPHUOC", "CAMAU", "CANTHO", "DONGNAI", "DONGTHAP", "HAUGIANG", "KIENGIANG", "LONGAN",
          "SOCTRANG", "TAYNINH", "TIENGIANG", "TRAVINH", "VINHLONG"];
          const central = ["BINHDINH", "BINHTHUAN", "DANANG", "DAKLAK", "DAKNONG", "GIALAI", "HATINH", 
          "KHANHHOA", "KONTUM", "LAMDONG", "NGHEAN", "NINHTHUAN", "PHUYEN", "QUANGBINH", "QUANGNAM",
          "QUANGNGAI", "QUANGTRI", "HUE"];
          const north = ["BACGIANG", "BACKAN", "BACNINH", "CAOBANG", "DIENBIEN", "HAGIANG", "HANAM", "HAIDUONG",
          "HAIPHONG", "HOABINH", "HUNGYEN", "LAICHAU", "LANGSON", "LAOCAI", "NAMDINH", "NINHBINH", "PHUTHO",
          "QUANGNINH", "SONLA", "THAIBINH", "THAINGUYEN", "THANHHOA", "TUYENQUANG", "VINHPHUC", "YENBAI"];
          const hcm = [ "BINHCHANH", "CANGIO", "CUCHI", "HOCMON", "HOOCMON", "NHABE", "QUAN1",
          "QUAN10", "QUAN11", "QUAN12", "QUAN2", "QUAN3", "QUAN4", "QUAN5", "QUAN6", "QUAN7",
          "QUAN8", "QUAN9", "BINHTAN", "BINHTHANH", "GOVAP", "PHUNHUAN", "TANBINH", "TANPHU", "THUDUC"];
          
          const province = receiverAddress.substring(receiverAddress.lastIndexOf(",") + 1, receiverAddress.length)
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').toUpperCase();
          const addr = receiverAddress.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').toUpperCase();
          let max = (width??0) * (height??0) * (length??0) / 6000000;
          max = max > weight / 1000? max : weight / 1000;
          setVietnamTrackingCode(`VNDGP${yymmddhhmmss()}`);
          setVietnamShipper(value);
          if(province.includes("HN") || province.includes("HANOI")) {
            const vnfee = Math.round((26400 + (max > 5? (max - 5) * 2130 : 0)) * 1.08) + 10000;
            setVietnamShipfee(vnfee);
          } else if((province.includes("HCM") || province.includes("HOCHIMINH")) && hcm.reduce((res, q) => res || addr.includes(q), false)) {
            const vnfee = Math.round((46800 + (max > 5? (max - 5) * 5310 : 0)) * 1.08) + 10000;
            setVietnamShipfee(vnfee);
          } else if(north.reduce((res, q) => res || province.includes(q), false)) {
            const vnfee = Math.round((34900 + (max > 5? (max - 5) * 2902 : 0)) * 1.08) + 10000;
            setVietnamShipfee(vnfee);
          } else if(central.reduce((res, q) => res || province.includes(q), false)) {
            const vnfee = Math.round((37200 + (max > 5? (max - 5) * 3870 : 0)) * 1.08) + 10000;
            setVietnamShipfee(vnfee);
          } else if(southern.reduce((res, q) => res || province.includes(q), false)) {
            const vnfee = Math.round((51600 + (max > 5? (max - 5) * 5450 : 0)) * 1.08) + 10000;
            setVietnamShipfee(vnfee);
          } else {
            addPopup({
              error: { message: `Địa chỉ không đúng. Vui lòng kiểm tra lại!`, title: 'Đã có lỗi xảy ra!' },
            });
            resetVNShip();
          }
        }
      } else {
        const nameCode = receiverName.split(" ").map(n =>  n.trim().charAt(0)).join("").toUpperCase();
        setVietnamTrackingCode(`JVSCORP${nameCode}${yymmddhhmmss()}`);
        let max = (width??0) * (height??0) * (length??0) / 6000000;
        max = max > weight / 1000? max : weight / 1000;
        const vnfee = 34000 + (max > 5? (Math.round((max - 5) * 2) + 1) * 3000 : 0);
        setVietnamShipfee(vnfee);
        setVietnamShipper(value);
      }
    }
  }

  //Main
  return (
    <>
      {props.isSlide || props.isPopup ? null : (
        <ToolBar
          toolbarName={`Đơn vận chuyển ${billCode}`}
          listRightButton={isDisable? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`shiporder-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
        <div className='shiporder-info'>
          <ImageUpload
            images={shipOrderId ? images : imageFiles}
            onDelete={onDeleteShipOrderImage}
            addImage={onAddImage}
            showOnlyOne={true}
          />
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã phiếu"
              disabled={isDisable}
              value={billCode}
              onChange={(value) => {
                setBillCode(value);
                setScanCode(value);
              }}
              rightAction={() => {
                if(!isDisable) qrReaderModal.handlePresent({
                  readQR: (code: string) => {
                    setBillCode(code);
                    setScanCode(code);
                    qrReaderModal.handleDismiss();
                  }
                }, "QUÉT MÃ VẬN CHUYỂN")
              }}
              rightIcon='qr_code_scanner'
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã vận chuyển JP"
              disabled={isDisable}
              value={japanTrackingCode}
              onChange={setJapanTrackingCode}
            />
            {/* <DataScanList
              width="360px"
              title="Mã vận chuyển JP"
              value={japanTrackingCode?.length > 0? JSON.parse(japanTrackingCode) : []}
              onChange={(value) =>{
                console.log(value);
                console.log(JSON.stringify(value))
                setJapanTrackingCode(JSON.stringify(value))
              }}
              disabled={isDisable}
              hasScan={false}
            /> */}
          </div>
          <div className="shiporder-row">
            <SelectBoxComponent
              width="360px"
              require={false}
              onChange={(value) => changeTransportType(value)}
              isDisable={isDisable}
              title={'Hình thức'}
              value={transportType}
              data={TransportType}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          <div className="shiporder-row">
            <SelectBoxComponent
              width="360px"
              require={false}
              onChange={(value) => changePaymentType(value)}
              isDisable={isDisable}
              title={'Cách tính cước'}
              value={paymentType}
              data={PaymentTypeList}
              valueType={'value'}
              titleType={'title'}
            />
          </div>
          <div className="shiporder-row">
            <SelectSearchComponent
              width="360px"
              require={false}
              value={receiverOrder}
              onChange={(value: ReceiverOrderType) => changeReceiverOrder(value)}
              onLoad={getReceiverOrder}
              isDisable={isDisable}
              title={'Đại lý gửi hàng'}
              titleField={'title'}
              warning={billCode?.split("-").length > 0 && receiverOrder?.receiverOrderCode != billCode?.split("-")[0]}
            />
          </div>
          {role == ADMIN && <div className="shiporder-row">
            <Input
              width='360px'
              title="TK gửi"
              require={false}
              disabled={true}
              value={userName}
            />
            {!isDisable && (
              <div className="i-tooltip order-guide">
                {userId? <span className="material-icons send-account-clear" onClick={() => {
                  changeUser(undefined);
                }}>clear</span> : null}
                <span
                  className="material-icons info-guide"
                  onClick={() => setIsShowUserList(true)}
                  ref={(el) => {
                    if (el) {
                      const boundingClient = el.getBoundingClientRect();
                      setIconX(boundingClient.left - (props.isPopup? window.innerWidth / 2 : 0));
                      setIconY(boundingClient.y + boundingClient.height - (props.isPopup? window.innerHeight / 10 - 20 : 0));
                    }
                  }}
                >
                  account_circle
                </span>
                <span className="tooltiptext">Chọn người gửi</span>
              </div>
            )}
            {isShowUserList ? (
              <ContactList
                iconX={iconX}
                iconY={iconY}
                showAll={true}
                onHidden={() => setIsShowUserList(false)}
                onSelect={(user: ProfileInfo) => changeUser(user)}
              />
            ) : null}
          </div>}
          <div className="shiporder-row">
            <div className='inline'>
              <Input
                width="80px"
                title="D(cm)"
                require={false}
                disabled={isDisable}
                value={length / 10}
                onChange={(value) => changeLength(value * 10)}
                type='number'
              />
              <Input
                width="80px"
                title="R(cm)"
                require={false}
                disabled={isDisable}
                value={width / 10}
                onChange={(value) => changeWidth(value * 10)}
                type='number'
              />
              <Input
                width="80px"
                title="C(cm)"
                require={false}
                disabled={isDisable}
                value={height / 10}
                onChange={(value) => changeHeight(value * 10)}
                type='number'
              />
              <div>
                <Input
                  width="120px"
                  title="V (khối)"
                  require={false}
                  disabled={true}
                  value={(width??0) * (height??0) * (length??0) / 1000000000}
                  type='number'
                />
              </div>
            </div>
          </div>
          <div className="shiporder-row">
            <div className='inline'>
              <Input
                width="180px"
                title="K.lượng (kg)"
                require={false}
                disabled={isDisable}
                value={weight / 1000}
                onChange={changeWeight}
                type='number'
              />
              <Input
                width="180px"
                title="K.lg quy đổi (kg)"
                require={false}
                disabled={isDisable}
                value={(width??0) * (height??0) * (length??0) / 6000000}
                type='number'
              />
            </div>
          </div>
          <div className="shiporder-row">
            <div className='inline'>
              <Input
                width="180px"
                title={`Đơn giá ${paymentType == EnumPaymentType.NormalVolume? '(VNĐ/khối)' : '(VNĐ/kg)' }`}
                require={false}
                disabled={isDisable}
                value={price}
                onChange={changePrice}
                type='number'
                step={1000}
              />
              <Input
                width="180px"
                title={`Đơn giá quá khổ`}
                require={false}
                disabled={true}
                value={overPrice}
                type='number'
                step={1000}
              />
            </div>
          </div>
          <div className="shiporder-row">
            <SpecialProduct
              width="360px"
              title="DS hàng hóa đặc biệt"
              specialProduct={specialProduct}
              onChange={changeSpecialProduct}
              disabled={isDisable}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title={`Giảm giá`}
              require={false}
              disabled={isDisable}
              value={discount}
              onChange={changeDiscount}
              inputPadding="3px 10px 3px 3px"
              type='number'
              step={1000}
              currency="VND"
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title={`Phí v.chuyển`}
              require={false}
              disabled={isDisable}
              value={transportFee}
              onChange={changeTransportFee}
              inputPadding="3px 10px 3px 3px"
              type='number'
              step={1000}
              currency="VND"
            />
          </div>
          <div className="shiporder-row">
            <NomalProduct
              width="360px"
              title="DS hàng hóa khai Invoice"
              nomalProduct={nomalProduct}
              onChange={setNomalProduct}
              disabled={isConsignment || isDisable}
            />
          </div>
          <div className="shiporder-row">
            <Checkbox
              width='360px'
              value={isConsignment}
              onChange={(value) => {
                if(value) mergeInvoice(subInfo);
                setConsignment(value);
              }}
              disabled={isDisable}
              title='Khai báo hàng hóa ký gửi'
            />
            <span></span>
          </div>

          {isConsignment && <>
            <div className="shiporder-row inline">
              <InlineRow width='360px'>
                <Checkbox
                  width='150px'
                  value={isTaxRefunt}
                  onChange={setTaxRefunt}
                  title='Hoàn thuế'
                />
                <Input
                  title={`Số lượng gói`}
                  require={false}
                  value={subNumber}
                  onChange={onChangeSubNumber}
                  inputPadding="3px 10px 3px 3px"
                  type='number'
                />
                <span className="material-icons print-sub-qr"
                  onClick={onPrintSubQR}
                >
                  print
                </span>
              </InlineRow>
            </div>
            {Object.entries(subInfo).map(([subQR, value]) => {
              return <fieldset className='ship-order-declaration-row sub-qr-declaration' key={subQR}>
                <legend className='sub-qr-title'>{subQR}</legend>
                <InlineRow width='360px'>
                  <Input
                    title="Khối lượng (kg)"
                    require={false}
                    value={subInfo[subQR].weight / 1000}
                    onChange={(value) => {
                      const nInfo = {...subInfo};
                      nInfo[subQR].weight = value * 1000;
                      setSubInfo(nInfo);
                    }}
                    type='number'
                  />
                  <Input
                    title="Giá tiền VN (VNĐ)"
                    require={false}
                    value={subInfo[subQR].price}
                    onChange={(value) => {
                      const nInfo = {...subInfo};
                      nInfo[subQR].price = value;
                      setSubInfo(nInfo);
                    }}
                    type='number'
                  />
                </InlineRow>
                <NomalProduct
                  noneBorder={true}
                  width="360px"
                  nomalProduct={subInfo[subQR].products}
                  onChange={(value) => {
                    const nInfo = {...subInfo};
                    nInfo[subQR].products = value;
                    setSubInfo(nInfo);
                    mergeInvoice(nInfo);
                  }}
                  disabled={false}
                  inputPrice={isTaxRefunt}
                />
              </fieldset>
            })}
          </>}
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mã chuyển đổi"
              disabled={isDisable}
              value={conversionCode}
              onChange={setConversionCode}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Mô tả"
              require={false}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="shiporder-row">
            {props.newProgressStatus? <div className='inline'>
              <SelectBoxComponent
                require={true}
                onChange={setProgressStatus}
                isDisable={true}
                title={'Trạng thái'}
                value={progressStatus}
                data={ShipOrderProgressStatusList}
                valueType={'value'}
                titleType={'title'}
              />
              <div className='inline' style={{marginTop: '8px'}}>
                <span className="material-icons" style={{margin: 'auto 5px'}}>arrow_forward</span>
                <div className='new-progress-status'>{ShipOrderProgressStatusList.find(i => i.value == props.newProgressStatus).title}</div>
              </div>
            </div>
            :
            <SelectBoxComponent
              width="360px"
              require={true}
              onChange={setProgressStatus}
              isDisable={isDisable}
              title={'Trạng thái'}
              value={progressStatus}
              data={ShipOrderProgressStatusList}
              valueType={'value'}
              titleType={'title'}
            />}
          </div>
          <div className="shiporder-row">
            <Checkbox
              width='360px'
              value={cod}
              disabled={isDisable}
              onChange={setCod}
              title='Yêu cầu ship COD'
            />
          </div>
          <div className="shiporder-row">
            <Checkbox
              width='360px'
              value={isPaid}
              disabled={true}
              onChange={setPaid}
              title='Đã thanh toán'
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Ghi chú khách hàng"
              require={false}
              disabled={isDisable}
              value={customerNote}
              onChange={setCustomerNote}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Người gửi"
              require={false}
              disabled={isDisable}
              value={senderName}
              onChange={setSenderName}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Địa chỉ người gửi"
              require={false}
              disabled={isDisable}
              value={senderAddress}
              onChange={setSenderAddress}
            />
          </div>
          {receiverOrder?.address?.length > 0 || salesCollatorabor?.address?.length > 0? <div className="shiporder-row">
            <SelectSearchComponent
              width="360px"
              onChange={(value) => {
                setSuggessAddress(value);
                setReceiverName(value.name);
                setReceiverPhoneNumber(value.phone);
                setReceiverAddress(value.address);
              }}
              isDisable={isDisable}
              title={'Địa chỉ nhận gợi ý'}
              value={suggessAddress}
              list={salesCollatorabor?.address?.length > 0? JSON.parse(salesCollatorabor.address) : JSON.parse(receiverOrder.address)}
              titleField='name'
              convert={(item) => {
                return `${item.name} | ${item.phone} | ${item.address}`;
              }}
            />
          </div> : null}
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Tên người nhận"
              require={false}
              disabled={isDisable}
              value={receiverName}
              onChange={setReceiverName}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="SĐT người nhận"
              require={false}
              disabled={isDisable}
              value={receiverPhoneNumber}
              onChange={setReceiverPhoneNumber}
            />
          </div>
          <div className="shiporder-row">
            <Input
              width="360px"
              title="Địa chỉ người nhận"
              require={false}
              disabled={isDisable}
              value={receiverAddress}
              onChange={setReceiverAddress}
            />
          </div>
          {props.newProgressStatus >= EnumShipOrderProgressStatus.Contact || 
            progressStatus >= EnumShipOrderProgressStatus.Flying? <>
            <div className='shiporder-row'>
              <SelectBoxComponent
                width="360px"
                require={true}
                onChange={onChangeVietnamShipper}
                isDisable={isDisable}
                title={'Vận chuyển VN'}
                value={vietnamShipper}
                data={VNShippers}
                valueType={'value'}
                titleType={'title'}
              />
            </div>
            <div className='shiporder-row'>
              <Input
                width="360px"
                title={`Phí v.ch VN`}
                require={false}
                disabled={isDisable}
                value={vietnamShipfee}
                onChange={setVietnamShipfee}
                inputPadding="3px 10px 3px 3px"
                type='number'
                step={1000}
                currency="VND"
              />
            </div>
            <div className="shiporder-row">
              <Input
                width="360px"
                title="Mã vận chuyển VN"
                disabled={isDisable}
                value={vietnamTrackingCode}
                onChange={setVietnamTrackingCode}
              />
            </div>
          </> : null}


          {!isDisable && <div className="shiporder-row">
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
          </div>}
        </div>
        {shipOrderId > 0 && productOrders?.length > 0? <div className='shiporder-info refproduct-table'>
          <span>Danh sách đơn mua hộ</span>
          {window.innerWidth > 600? <Table
            display={tableDisplay}
            isInput={false}
            data={productOrders}
            isNo={true}
          /> : <ListView
            display={display}
            isInput={false}
            data={productOrders}
            isNo={true}
          />}
        </div> : null}
        {shipOrderId > 0 ? (
          <div className='shiporder-info'>
            <Note
              objectId={shipOrderId}
              functionId={EnumDisplayConfig.ShipOrder}
              recordUserId={userId}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ShipOrderDetail;
