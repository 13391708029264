/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EnumShipOrderProgressStatus, EventButton, ShipInvoiceType, ShipOrderProgressStatusList, ShipOrderType, ShipPackageType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import './ShipPackage.css';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { useDebounce } from 'use-debounce';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useDeleteShipPackage, useExportShipPackage, useGetShipPackage, usePrintDelivery, usePrintShipPackageV40, usePrintShipPackageV5 } from 'src/api/shipPackageApi';
import ShipPackageDetail from './ShipPackageDetail/ShipPackageDetail';
import Printed from 'src/components/Printed/Printed';
import InputModal from 'src/components/InputModal/InputModal';
import ShipInvoiceDetail from '../ShipInvoice/ShipInvoiceDetail/ShipInvoiceDetail';
import { useGetShipInvoice } from 'src/api/shipInvoiceApi';
import UpdateShipPackageProgressStatus from './UpdateShipPackageProgressStatus/UpdateShipPackageProgressStatus';
import { round } from 'lodash';

//TODO
const ShipPackage: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const header = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'shipPackageCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã kiện',
      },
      // {
      //   code: 'receiverName',
      //   dataType: EnumDataType.Text,
      //   isOptions: false,
      //   title: 'Người nhận',
      // },
      // {
      //   code: 'receiverPhoneNumber',
      //   dataType: EnumDataType.Text,
      //   isOptions: false,
      //   title: 'SĐT người nhận',
      // },
      // {
      //   code: 'receiverAddress',
      //   dataType: EnumDataType.Text,
      //   isOptions: false,
      //   title: 'Địa chỉ người nhận',
      // },
      {
        code: 'shipInvoiceCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Chuyến hàng',
      },
      {
        code: 'weight',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Cảnh báo',
        convert: (weight: number) => {
          return <div className='text-right'>{weight / 1000 > 35? <span style={{color: 'orange'}} className='material-icons'>warning</span> : null} </div>;
        }
      },
      {
        code: 'weight',
        dataType: EnumDataType.Ratio,
        ratio: 1000,
        isOptions: false,
        title: 'Khối lượng (kg)',
      },
      {
        code: 'length',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Dài (cm)',
      },
      {
        code: 'width',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Rộng (cm)',
      },
      {
        code: 'height',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Cao (cm)',
      },
      {
        code: '',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Cân thể tích',
        convert: (data: ShipOrderType) => {
          return <div className='text-right'>{round(data.height * data.width * data.length / 6000000, 1)}</div>;
        }
      },
      {
        code: '',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'K.lg lệch',
        convert: (data: ShipOrderType) => {
          return <div className='text-right'>{(data.height * data.width * data.length) / (6000 * data.weight) > 1.5? <span style={{color: 'red'}} className='material-icons'>warning</span> : null} {data.height * data.width * data.length / 6000000 > 0? round((data.height * data.width * data.length / 6000000)  - (data.weight / 1000), 1) : null }</div>;
        }
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái vận chuyển',
        options: ShipOrderProgressStatusList,
      },

    ] as TableColumnType[]
  };

  const display: ItemDisplayType<ShipPackageType> = {
    header: [
      {
        code: 'shipPackageCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      info: [
        [
          {
            code: 'weight',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'monitor_weight',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'progressStatus',
            dataType: EnumDataType.Text,
            isOptions: true,
            options: ShipOrderProgressStatusList,
          },
        ],
        [
          {
            code: 'length',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'straighten',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'width',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'open_in_full',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'height',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'height',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
        ]
      ]
    },
    bottom: [
      [
        {
          code: 'receiverName',
          title: 'Người nhận: ',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            flex: 'auto'
          }
        },
        {
          code: 'receiverPhoneNumber',
          icon: "phone",
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ],
      [
        {
          code: 'receiverAddress',
          icon: 'location_on',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ]
    ],

    actions: (item: ShipPackageType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.shipPackageId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.shipPackageId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  //State
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  const [count, setCount] = useState<number>(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [rawData, setRawData] = useState<ShipPackageType[]>([]);
  const [shipPackages, setShipPackages] = useState<ShipPackageType[]>([]);
  const [checkList, setCheckList] = useState<ShipPackageType[]>([]);
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>();
  const [shipInvoiceId, setShipInvoiceId] = useState<number>();

  //Function
  const addPopup = useAddPopup();
  const getShipPackage = useGetShipPackage();
  const deleteShipPackage = useDeleteShipPackage();
  const printShipPackageV40 = usePrintShipPackageV40();
  const printShipPackageV5 = usePrintShipPackageV5();
  const exportShipPackage = useExportShipPackage();
  const getShipInvoice = useGetShipInvoice();
  const printDelivery = usePrintDelivery();
  
  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ShipPackageDetail);
  const slideBar = useSlideBar(ShipPackageDetail);
  const numberModal = useModal(InputModal);
  const printedModal = useModal(Printed);
  const shipInvoiceModal = useModal(ShipInvoiceDetail);
  const updateModal = useModal(UpdateShipPackageProgressStatus);

  const STATUS_FIELD_FILTER = 'value';
  const STATUS_FILTER = 'STATUS_FILTER';
  const INVOICE_FILTER = 'INVOICE_FILTER';
  const INVOICE_FIELD_FILTER = 'shipInvoiceId';
  const filters = [
    {
      data: ShipOrderProgressStatusList,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
    {
      api: getShipInvoice,
      isSearch: true,
      isPaging: true,
      valueField: INVOICE_FIELD_FILTER,
      titleField: 'shipInvoiceCode',
      title: 'Chuyến hàng',
      filterKey: INVOICE_FILTER,
    },
 ]

  const onClickCheckbox = (shipPackage: ShipPackageType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != shipPackage);
      setCheckList(nCheckList);
      setShipPackages(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.shipPackageId == i.shipPackageId),
        ),
      );
    } else {
      setCheckList([...checkList, shipPackage]);
      setShipPackages(shipPackages.filter((o) => o != shipPackage));
    }
  };

  //Menucontext
  const onView = (shipPackageId: number) => {
    const url = `${BASE_ORDER_URL}/ship-package/${shipPackageId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (shipPackageId: number) => {
    const url = `${BASE_ORDER_URL}/ship-package/${shipPackageId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (shipPackageId: number) => {
    const onConfirm = () => {
      deleteShipPackage(shipPackageId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn vận chuyển thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN VẬN CHUYỂN',
    );
  };


  const menuContext = (item: ShipPackageType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.shipPackageId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.shipPackageId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.shipPackageId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClick = (shipPackage: ShipPackageType) => {
    slideBar.handlePresent(
      {
        shipPackageId: shipPackage.shipPackageId,
        postProcess: () => setReloadFlag(!reloadFlag)
      }
    );
  };

  //Function in the listButton
  const onAddNewShipPackage = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ShipPackageType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI ĐƠN VẬN CHUYỂN',
    );
  };

  const printAction = (type: string) => {
    numberModal.handlePresent({
      fields: [{
        name: 'prefix',
        title: 'Tiền tố (Mặc định JPK nếu để trống)',
        type: 'text'
      },
      {
        name: 'start',
        title: 'Bắt đầu từ',
        type: 'number'
      },
      {
        name: 'pageQuantity',
        title: 'Số lượng trang in / đại lý',
        type: 'number'
      }],
      listActionButton: [
        {
          name: 'Xác nhận',
          icon: 'check',
          actionType: EnumAction.Confirm,
          buttonClass: 'info300',
          align: 'center',
          action: (value: {[name: string]: any}) => {
            const api = type == "v40"? printShipPackageV40 : printShipPackageV5;
            api(value['prefix'] as string, value['start'] as number, value['pageQuantity'] as number).then((res) => {
              printedModal.handlePresent(
                {
                  values: res,
                },
                'IN PHIẾU KIỆN',
              );
            }).catch((err) => {
              addPopup({
                error: {
                  title: 'Đã có lỗi xảy ra',
                  message: err.message,
                },
              });
            })
          }
        },
        {
          name: 'Hủy',
          icon: 'clear',
          actionType: EnumAction.Cancel,
          buttonClass: 'info100',
          align: 'center',
        },

      ],
      postProcess: numberModal.handleDismiss
    }, "SỐ LƯỢNG BẢN IN");

  }

  const onExportShipPackageExcel = () => {
    const ids = checkList.map(i => i.shipPackageId);
    if(ids.length > 0) {
      exportShipPackage(ids)
      .then((data) => {
        const productRealmTemplate = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = productRealmTemplate;
        tempLink.setAttribute('download', 'vanchuyen.xlsx');
        tempLink.click();
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
    } else {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: "Chưa chọn đơn export",
        },
      });
    }

  };


  const onCreateInvoice = () => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn kiện hàng',
        },
      });
    } else if (checkList.some(i => i.shipInvoiceId > 0)) {
      addPopup({
        txn: {
          success: false,
          summary: 'Kiện hàng được chọn đã có thông tin kiện',
        },
      });
    } else {
      shipInvoiceModal.handlePresent(
        {
          shipPackages: checkList,
          isDisable: false,
          postProcess: (data: ShipInvoiceType) => {
            const listButton: EventButton[] = [
              {
                name: 'Tiếp tục',
                icon: 'check',
                actionType: EnumAction.Confirm,
                action: () => window.open(`${BASE_ORDER_URL}/ship-invoice/${data.shipInvoiceId}/1`),
                buttonClass: 'info',
                align: 'center',
              },
              {
                name: 'Hủy',
                icon: 'clear',
                actionType: EnumAction.Cancel,
                buttonClass: 'info',
                align: 'center',
              },
            ];
            confirmModal.handlePresent(
              {
                question: "Đi tới thông tin chuyến hàng vừa tạo",
                listActionButton: listButton,
                postProcess: confirmModal.handleDismiss,
              },
              'XÁC NHẬN',
            );
            shipInvoiceModal.handleDismiss();
            setReloadFlag(!reloadFlag);
          },
        },
        'THÊM MỚI CHUYẾN HÀNG',
      );
    }
  }

  const onUpdateShipPackageProgressStatus = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn kiện hàng',
        },
      });
    } else {
      slideBar.handleDismiss();
      updateModal.handlePresent(
        {
          checkListIds: checkList.map(i => i.shipPackageId),
          postProcess: () => {
            setReloadFlag(!reloadFlag);
            updateModal.handleDismiss();
          }
        },
        'CẬP NHẬT TRẠNG THÁI KIỆN HÀNG',
      );
    }
  }, [checkList, reloadFlag]);


  const onPrintDelivery = () => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn danh sách kiện hàng',
        },
      });
    } else {
      printDelivery(checkList.map(p => p.shipPackageId)).then((res) => {
        printedModal.handlePresent(
          {
            values: res,
          },
          'IN PHIẾU GIAO NHẬN',
        );
      }).catch((err) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: err.message,
          },
        });
      })
    }
  }

  const listButton: EventButton[] = [
    {
      name: 'Cập nhật trạng thái',
      icon: 'sync_alt',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onUpdateShipPackageProgressStatus,
      align: 'center',
    },
    {
      name: 'Export',
      icon: 'file_download',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onExportShipPackageExcel,
      align: 'center',
    },
    {
      name: 'In V40',
      icon: 'print',
      actionType: EnumAction.View,
      action: () => printAction("v40"),
      buttonClass: 'info tool-btn',
      align: 'left',
    },
    {
      name: 'In V5',
      icon: 'print',
      actionType: EnumAction.View,
      action: () => printAction("v5"),
      buttonClass: 'info300 tool-btn',
      align: 'left',
    },
    {
      name: 'In phiếu giao nhận',
      icon: 'print',
      actionType: EnumAction.View,
      action: onPrintDelivery,
      buttonClass: 'info200 tool-btn',
      align: 'left',
    },
    {
      name: 'Tạo chuyến hàng',
      icon: 'inventory_2',
      actionType: EnumAction.Add,
      buttonClass: 'info200 tool-btn',
      action: onCreateInvoice,
      align: 'center',
    },
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewShipPackage,
      align: 'center',
    },
  ];



  //useEffect
  useEffect(() => {
    getShipPackage(keywordDebound as string, page, size, progressStatus, shipInvoiceId).then((data) => {
        setRawData(data.items);
        setCount(data.count);
        const nData: ShipPackageType[] = [];
        data.items.forEach((item) => {
          const checkIndx = checkList.findIndex((c) => c.shipPackageId == item.shipPackageId);
          if(checkIndx >= 0) {
            checkList[checkIndx] = item;
          } else {
            nData.push(item);
          }
        })

        setShipPackages(nData);
        setCheckList([...checkList]);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getShipPackage, keywordDebound, page, progressStatus, reloadFlag, size, shipInvoiceId]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  const onClickCheckAll = () => {
    if(shipPackages.length > 0) {
      setCheckList([...checkList, ...shipPackages]);
      setShipPackages([]);
    } else {
      setCheckList([]);
      setShipPackages([...rawData]);
    }

  };

  const onFilter = (res: { [filterKey: string]: any }) => {
    setPage(1);
   
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
    if (res[INVOICE_FILTER] != undefined) {
      const shipInvoiceId = res[INVOICE_FILTER][INVOICE_FIELD_FILTER];
      setShipInvoiceId(shipInvoiceId);
    } else {
      setShipInvoiceId(undefined);
    }
  }

  return (
    <>
      <ToolBar
        toolBarKey='JVSADMIN_SHIPPACKAGE'
        toolbarName={'DANH SÁCH KIỆN'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
        onFilter={onFilter}
      />

      {window.innerWidth > 600? <Table
          display={header}
          isInput={false}
          checkedData={checkList}
          data={shipPackages}
          menuContext={menuContext}
          allowCheckbox={true}
          onClickCheckAll={onClickCheckAll}
          onDoubleClick={(item) => onView(item.shipPackageId)}
          onClickCheckbox={onClickCheckbox}
          onClick={onClick}
          isNo={true}
        /> : <ListView
          display={display}
          isInput={false}
          data={shipPackages}
          onDoubleClick={(item) => onView(item.shipPackageId)}
          isNo={true}
        />}
      </>
  );
};
export default ShipPackage;
