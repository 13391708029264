import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { CustomerType, EnumAction, EnumDataType, EventButton } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { EnumCustomerTypeDetail } from './CustomerTypeDetail/CustomerTypeDetail';
import { useDeleteCustomerType, useGetCustomerType } from 'src/api/customerTypeApi';
import './CustomerTypeScreen.css';
import CustomerTypeDetail from './CustomerTypeDetail/CustomerTypeDetail';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useModal from 'src/hooks/useModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const CustomerTypeScreen: React.FC = () => {
  //Function
  const addPopup = useAddPopup();
  const deleteCustomerType = useDeleteCustomerType();
  const getCustomerType = useGetCustomerType();

  //Local state
  const [keyword, setKeyword] = useState<string>();
  const [count, setCount] = useState<number>();
  const [customerTypeList, setCustomerTypeList] = useState<CustomerType[]>([]);
  const [reloadFlag, setReloadFlag] = useState(false);

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(CustomerTypeDetail);


  const display = {
    header: [
      {
        code: 'customerTypeCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã kiểu kh.hàng',
      },
      {
        code: 'customerTypeTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'feePercent',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Phí đặt hàng (%)',
      },
      {
        code: 'salesTarget',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Doanh số mục tiêu',
      },
      {
        code: 'discountPercent',
        dataType: EnumDataType.JPY,
        isOptions: false,
        title: 'Tỷ lệ hoa hồng (%)',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };

  //TODO
  const onView = (customerTypeId: number) => {
    detailModal.handlePresent(
      {
        config: EnumCustomerTypeDetail.view,
        isDisable: true,
        customerTypeId: customerTypeId,
      },
      'CHI TIẾT',
    );
  };

  //TODO
  const onEdit = (customerTypeId: number) => {
    detailModal.handlePresent(
      {
        config: EnumCustomerTypeDetail.edit,
        isDisable: false,
        customerTypeId: customerTypeId,
      },
      'THAY ĐỔI',
    );
  };

  //TODO
  const onDelete = (customerTypeId: number) => {
    const onConfirm = () => {
      deleteCustomerType(customerTypeId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa kiểu khách hàng thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa kiểu khách hàng này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA KIỂU KHÁCH HÀNG',
    );
  };


  const menuContext = (item: CustomerType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.customerTypeId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.customerTypeId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.customerTypeId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onAddNewCustomerType = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        config: EnumCustomerTypeDetail.add,
        isDisable: false,
      },
      'THÊM MỚI',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onAddNewCustomerType,
      align: 'center',
    },
  ];


  //useEffect
  useEffect(() => {
    getCustomerType()
      .then((data) => {
        setCustomerTypeList(data);
        setCount(data.length);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getCustomerType, reloadFlag]);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH KIỂU KHÁCH HÀNG'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        keyword={keyword}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={customerTypeList}
        menuContext={menuContext}
        allowCheckbox={true}
        onDoubleClick={(item) => onView(item.customerTypeId)}
        isNo={true}
      />
    </>
  );
};
export default CustomerTypeScreen;
