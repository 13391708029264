import './StockInspectionDetail.css';
import React, {useEffect, useState } from 'react';
import { EnumAction, EventButton, StockInspectionStatus, StockInspectionType } from 'src/api/models';
import { useGetStockInspectionById, useGetStockRemainProducts, usePostStockInspection, usePutStockInspection } from 'src/api/stockInspectionApi';
import { useAddPopup } from 'src/state/application/hooks';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { EnumStockInspectionStatus } from 'src/common/enum/EnumStockInspectionStatus';
import Input from 'src/components/Input';
import { date2string, getCurrentDate } from 'src/utils/formatTime';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import ExtraInfoInspection from 'src/components/ExtraInfoInspection/ExtraInfoInspection';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

interface IStockInspectionDetail {
  stockInspectionId?: number;
  data?: StockInspectionType;
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  postProcess?: (...args: any[]) => void;
}

export type InspectionRowType = {
  productCode: string;
  quantity: number;
}


const StockInspectionDetail: React.FC<IStockInspectionDetail> = (props) => {

  const [stockInspectionId, setStockInspectionId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    setStockInspectionId(props.stockInspectionId);
    setIsDisable(props.isDisable);
  }, [props.isDisable, props.stockInspectionId]);


  const [status, setStatus] = useState<EnumStockInspectionStatus>(EnumStockInspectionStatus.Inspecting);
  const [createdAt, setCreatedAt] = useState<string>(getCurrentDate());
  const [stockInspectionContent, setStockInspectionContent] = useState<InspectionRowType[]>([]);
  const [productCode, setProductCode] = useState<string>();
  const [stockRemain, setStockRemain] = useState<{[productCode: string]: number[]}>({});
  const [scanCode, setScanCode] = useState<string>();

  const addPopup = useAddPopup();
  const getStockInspection = useGetStockInspectionById();
  const postStockInspection = usePostStockInspection();
  const putStockInspection =  usePutStockInspection();
  const getStockRemainProducts = useGetStockRemainProducts();
  
  const qrReaderModal = useModal(QRCodeReader);
  const confirmModal = useModal(ConfirmModal);

  const onSave = () => new Promise((resolve) => {
    const value = JSON.stringify(stockInspectionContent);
    console.log(value);
    const body: StockInspectionType = {
      stockInspectionId: stockInspectionId,
      stockInspectionContent: value,
      status: status
    };
    const isAdd = !stockInspectionId;
    const api = isAdd? postStockInspection(body) : putStockInspection(body);
    api.then(
      (res) => {
        setStockInspectionId(res.stockInspectionId);
        setIsDisable(true);
        addPopup({
          txn: {
            success: true,
            summary: !isAdd? 'Thay đổi phiếu kiểm kê thành công' : 'Tạo phiếu kiểm kê thành công',
          }
        });
        if(props.postProcess) props.postProcess(res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        resolve(false);
      });
  });

  useEffect(() => {
    getStockRemainProducts().then((res) => {
      setStockRemain(res);
    }).catch((error) => {
      addPopup({
        error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
      });
    })
  }, [])

  const refresh = () => {
    getStockRemainProducts().then((res) => {
      setStockRemain(res);
    }).catch((error) => {
      addPopup({
        error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
      });
    })
  }


  useEffect(() => {
    if(stockInspectionId) {
      getStockInspection(stockInspectionId).then((res) => {
        setStatus(res.status);
        setStockInspectionContent(res.stockInspectionContent? JSON.parse(res.stockInspectionContent) : []);
        setCreatedAt(res.createdAt);
      }).catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      })
    }
  }, [stockInspectionId]);


  const onInsert = (v: string) => {
    const code = v?.trim();
    if(code?.length > 0) {
      const product = stockInspectionContent.find(p => p.productCode == code);
      const message = product? `Trong danh sách kiểm kê đã có ${product.quantity} sản phẩm ${product.productCode}. Bạn muốn tăng số lượng sản phẩm lên không?` : 'Chưa có sản phẩm này trong danh sách kiểm kê. Bạn muốn thêm vào danh sách không?';
      const listButton: EventButton[] = [
        {
          name: 'Xác nhận',
          icon: 'check',
          actionType: EnumAction.Confirm,
          action: (number: number) => {
            if(product) {
              if(number > 0) {
                product.quantity = number;
              } else {
                product.quantity++;
              }
              setStockInspectionContent([...stockInspectionContent]);
            } else {
              console.log(number);
              setStockInspectionContent([...stockInspectionContent, {productCode: code, quantity: number > 0 ? number : 1}]);
            }
          },
          buttonClass: 'info',
          align: 'center',
        },
        {
          name: 'Hủy',
          icon: 'clear',
          actionType: EnumAction.Cancel,
          buttonClass: 'info100',
          align: 'center',
        },
      ];
      confirmModal.handlePresent(
        {
          question: message,
          listActionButton: listButton,
          postProcess: () => {
            confirmModal.handleDismiss();
            setScanCode(undefined);
          },
          hasInput: true,
          type: 'number',
          inputTitle: 'Nhập số lượng sản phẩm'
        },
        'XÁC NHẬN SẢN PHẨM',
      );
    }
  };

  useEffect(() => {
    if(scanCode) onInsert(scanCode);
  }, [scanCode])


  const onKeyDown = (event: KeyboardEvent) => {
    if(event.key == 'Enter') {
      onInsert(productCode);
      setProductCode(undefined);
    }
  }

  return (
  <div className={'stock-inspection-container'}>
    {!isDisable && <div className="stock-inspection-row">
      <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
    </div>}
    <div className="stock-inspection-row">
      <Input
        width="100%"
        title="Ngày tạo"
        require={false}
        disabled={true}
        value={createdAt}
      />
    </div>
    <div className="stock-inspection-row">
      <SelectBoxComponent
        width="100%"
        require={true}
        onChange={setStatus}
        isDisable={isDisable}
        title={'Trạng thái'}
        value={status}
        data={StockInspectionStatus}
        valueType={'value'}
        titleType={'title'}
      />
    </div>
    <div className='stock-inspection-tool'>
      <span className="stock-inspection-tool-left material-icons" onClick={() => {
        onInsert(productCode);
        setProductCode(undefined);
      }}>
        add_circle
      </span>
      <Input
        value={productCode}
        disabled={isDisable}
        onChange={setProductCode}
        type='text'
        onKeyDown={onKeyDown}
      />
      <span className="stock-inspection-tool-right material-icons" onClick={() => {
        qrReaderModal.handlePresent({
          readQR: setScanCode
        }, "QUÉT MÃ QR");
      }}>
        qr_code_scanner
      </span>
      <span className="stock-inspection-tool-right material-icons" onClick={refresh} title='Làm mới thông tin tồn kho'>
        refresh
      </span>
    </div>
    <fieldset className='stock-inspection-content'>
      <legend className='stock-inspection-content-title'>Danh sách kiểm kê</legend>
      {stockInspectionContent.map((item, index) => (
        <div className='stock-inspection-row inline' key={`stock-inspection-row${index}`}>
          <Input
            value={item.productCode}
            disabled={true}
            isCopy={true}
          />
          <span className="stock-inspection-multiplication material-icons" onClick={() => {
            if(item.quantity > 1) {
              item.quantity--;
              setStockInspectionContent([...stockInspectionContent]);
            } else {
              setStockInspectionContent(stockInspectionContent.filter((o) => o != item));
            }
          }}>arrow_drop_down</span>
          <input
            className='stock-inspection-quantity'
            type='number'
            value={item.quantity}
            onChange={(event) => {
              const value = (event.target as HTMLInputElement).value;
              item.quantity = value? isNaN(Number(value))? 0 : Number(value) : 0;

              if(item.quantity > 100000000) {
                addPopup({
                  error: {
                    message: 'Số lượng không đúng. Vui lòng kiểm tra lại!',
                    title: 'Đã có lỗi xảy ra!',
                  },
                });
              } else {
                setStockInspectionContent([...stockInspectionContent]);
              }
            }}
            onBlur={() => {
              if(item.quantity < 1) {
                setStockInspectionContent(stockInspectionContent.filter((o) => o != item));
              }
            }}
          />
          <span className="stock-inspection-multiplication material-icons" onClick={() => {
            item.quantity++;
            setStockInspectionContent([...stockInspectionContent]);
          }}>arrow_drop_up</span>
          <span
            className="stock-inspection-clear material-icons"
            onClick={() => {
              setStockInspectionContent(stockInspectionContent.filter((o) => o != item));
            }}
          >
            clear
          </span>
          <ExtraInfoInspection 
            data={Object.prototype.hasOwnProperty.call(stockRemain, item.productCode)? stockRemain[item.productCode] : undefined}
            type={Object.prototype.hasOwnProperty.call(stockRemain, item.productCode)? stockRemain[item.productCode][1] == item.quantity? 'info' : 'warning' : 'error'} 
            postProcess={refresh}
          />
        </div>
      ))}

    </fieldset>
    <fieldset className='stock-inspection-content'>
      <legend className='stock-inspection-content-title'>Danh sách còn thiếu</legend>
      {Object.entries(stockRemain).filter(([key, item]) => !stockInspectionContent?.some(v => v.productCode == key)).map(([key, item], index) => (
        <div className='stock-inspection-row inline' key={`stock-inspection-missing${index}`}>
          <Input
            value={key}
            disabled={true}
            isCopy={true}
          />
          <span className="stock-inspection-multiplication material-icons" >close</span>
          <input
            className='stock-inspection-quantity'
            style={{marginRight: '5px'}}
            type='number'
            value={item[1]}
            disabled={true}
          />
          <ExtraInfoInspection 
            data={item}
            type='fmd_bad' 
            postProcess={refresh}
          />
        </div>
      ))} 
    </fieldset>



   
  </div>
  )
};

export default StockInspectionDetail;
