import React, { useRef } from 'react';
import './HtmlToImageItem.css';
import html2canvas from 'html2canvas';

interface IHtmlToImageItem {
  value: string;
  name?: string;
}

export const HtmlToImageItem: React.FC<IHtmlToImageItem> = (props) => {
  const printedRef = useRef();
  return (
    <div className="htmltoimage-item">
      <button className="htmltoimage-button" onClick={async () => {
        const element = document.getElementById('htmltoimage-content');
        const canvas = await html2canvas(element, {
          allowTaint : true,
          useCORS : true,
        });
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        link.href = data;
        link.download = `${props.name??"qr-code"}.png`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }}>
        <span className="material-icons icon-print">print</span>
        <span>Export Image</span>
      </button>
      <div style={{ border: "solid 1px gray", width: "fit-content"}}>
        <div style={{background: "white", width: "fit-content"}} id='htmltoimage-content' ref={printedRef}
          dangerouslySetInnerHTML={{
            __html: props.value,
          }}
        />
      </div>
    </div>
  );
};
