/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EnumShipOrderProgressStatus, EnumShipOrderProgressStatusTitle, EventButton, ShipOrderProgressStatusList, ShipInvoiceType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import './ShipInvoice.css';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { useDebounce } from 'use-debounce';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useDeleteShipInvoice, useGetShipInvoice } from 'src/api/shipInvoiceApi';
import ShipInvoiceDetail from './ShipInvoiceDetail/ShipInvoiceDetail';
import Printed from 'src/components/Printed/Printed';
import InputModal from 'src/components/InputModal/InputModal';

//TODO
const ShipInvoice: React.FC = () => {
  //Value
  const navigate = useNavigate();

  const header = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'expectedDate',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày dự kiến',
      },
      {
        code: 'shipInvoiceCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã chuyến',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Trạng thái vận chuyển',
        options: ShipOrderProgressStatusList,
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú',
      },
    ] as TableColumnType[]
  };

  const display: ItemDisplayType<ShipInvoiceType> = {
    header: [
      {
        code: 'shipInvoiceCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      info: [
        [
          {
            code: 'weight',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'monitor_weight',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'progressStatus',
            dataType: EnumDataType.Text,
            isOptions: true,
            options: ShipOrderProgressStatusList,
          },
        ],
        [
          {
            code: 'length',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'straighten',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'width',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'open_in_full',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'height',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'height',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
        ]
      ]
    },
    bottom: [
      [
        {
          code: 'receiverName',
          title: 'Người nhận: ',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            flex: 'auto'
          }
        },
        {
          code: 'receiverPhoneNumber',
          icon: "phone",
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ],
      [
        {
          code: 'receiverAddress',
          icon: 'location_on',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ]
    ],

    actions: (item: ShipInvoiceType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.shipInvoiceId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.shipInvoiceId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  //State
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);

  const [count, setCount] = useState<number>(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [rawData, setRawData] = useState<ShipInvoiceType[]>([]);
  const [shipInvoices, setShipInvoices] = useState<ShipInvoiceType[]>([]);
  const [checkList, setCheckList] = useState<ShipInvoiceType[]>([]);
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>();
  //Function
  const addPopup = useAddPopup();
  const getShipInvoice = useGetShipInvoice();
  const deleteShipInvoice = useDeleteShipInvoice();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ShipInvoiceDetail);
  const slideBar = useSlideBar(ShipInvoiceDetail);
  const numberModal = useModal(InputModal);
  const printedModal = useModal(Printed);

  const onClickCheckbox = (shipInvoice: ShipInvoiceType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != shipInvoice);
      setCheckList(nCheckList);
      setShipInvoices(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.shipInvoiceId == i.shipInvoiceId),
        ),
      );
    } else {
      setCheckList([...checkList, shipInvoice]);
      setShipInvoices(shipInvoices.filter((o) => o != shipInvoice));
    }
  };

  //Menucontext
  const onView = (shipInvoiceId: number) => {
    const url = `${BASE_ORDER_URL}/ship-invoice/${shipInvoiceId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (shipInvoiceId: number) => {
    const url = `${BASE_ORDER_URL}/ship-invoice/${shipInvoiceId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (shipInvoiceId: number) => {
    const onConfirm = () => {
      deleteShipInvoice(shipInvoiceId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn vận chuyển thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN VẬN CHUYỂN',
    );
  };


  const menuContext = (item: ShipInvoiceType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.shipInvoiceId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.shipInvoiceId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.shipInvoiceId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onClick = (shipInvoice: ShipInvoiceType) => {
    slideBar.handlePresent(
      {
        shipInvoiceId: shipInvoice.shipInvoiceId,
        postProcess: () => setReloadFlag(!reloadFlag)
      }
    );
  };

  //Function in the listButton
  const onAddNewShipInvoice = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ShipInvoiceType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI CHUYẾN',
    );
  };


  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100 sim-Invoice-tool-btn',
      action: onAddNewShipInvoice,
      align: 'center',
    }

  ];

  //useEffect
  useEffect(() => {
    getShipInvoice(keywordDebound as string, page, size, progressStatus).then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setShipInvoices(
          data.items.filter(
            (i) => !checkList.some((c) => c.shipInvoiceId == i.shipInvoiceId),
          ),
        );
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getShipInvoice, keywordDebound, page, progressStatus, reloadFlag, size]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH CHUYẾN HÀNG'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
      />

      {window.innerWidth > 600? <Table
          display={header}
          isInput={false}
          checkedData={checkList}
          data={shipInvoices}
          menuContext={menuContext}
          allowCheckbox={true}
          onDoubleClick={(item) => onView(item.shipInvoiceId)}
          onClickCheckbox={onClickCheckbox}
          onClick={onClick}
          isNo={true}
        /> : <ListView
          display={display}
          isInput={false}
          data={shipInvoices}
          onDoubleClick={(item) => onView(item.shipInvoiceId)}
          isNo={true}
        />}
      </>
  );
};
export default ShipInvoice;
