import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ReportType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { BASE_REPORT_URL } from 'src/constants';
import { useDeleteReport, useGetReport } from 'src/api/reportApi';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';

//Realm
const Report: React.FC = () => {

  const display = {
    header: [
      {
        code: 'reportKey',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã mẫu in',
      },
      {
        code: 'reportTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[]
  };

  //Function
  const addPopup = useAddPopup();
  const getReport = useGetReport();
  const deleteReport = useDeleteReport();
  const navigate = useNavigate();

  //State
  const [reloadFlag, setReloadFlag] = useState(false);
  const [data, setData] = useState<ReportType[]>([]);

  const confirmModal = useModal(ConfirmModal);


  useEffect(() => {
    getReport()
      .then((data) => {
        setData(data);
      });
  }, [getReport, reloadFlag]);

  //Menucontext
  const onView = (reportId: number) => {
    openDetail(EnumViewType.View, reportId);
  };

  const onEdit = (reportId: number) => {
    openDetail(EnumViewType.Edit, reportId);
  };

  const onDelete = (reportId: number) => {
    const onConfirm = () => {
      deleteReport(reportId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa mẫu in thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa báo cáo này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA BÁO CÁO',
    );
  };


  //When click right mouse
  const menuContext = (item: ReportType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item.reportId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.reportId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.reportId),
      buttonClass: 'info',
      align: '',
    },
  ];
  //End of menucontext


  const openDetail = (type: EnumViewType, reportId: number) => {
    const url = `${BASE_REPORT_URL}/${type}/id/${reportId}`;
    navigate(url);
  };

  //Toolbar
  const onAddNew = () => {
    openDetail(EnumViewType.Edit, 0);
  };

  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100',
      action: onAddNew,
      align: 'center',
    },
  ];
  //End of toolbar

  return (
    <>
      <ToolBar
        toolbarName={'DANH SÁCH BÁO CÁO'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <Table
        display={display}
        isInput={false}
        data={data}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.reportId)}
      />
    </>
  );
};

export default Report;
