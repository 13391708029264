/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { EnumAction, EnumShipOrderProgressStatus, ImageType, ShipOrderProgressStatusList, ShipPackageType, ShipInvoiceType } from 'src/api/models';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import { useAddPopup } from 'src/state/application/hooks';
import "./ShipInvoiceDetail.css";
import { useNavigate, useParams } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import ToolBar from 'src/components/ToolBar/ToolBar';
import useModal from 'src/hooks/useModal';
import QRCodeReader from 'src/components/QRCodeReader/QRCodeReader';
import { useGetShipInvoiceById, usePostShipInvoice, usePutShipInvoice } from 'src/api/shipInvoiceApi';
import DataScanList from 'src/components/DataScanList';
import { useGetShipPackageByCode } from 'src/api/shipPackageApi';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';

interface IShipInvoiceDetail {
  shipInvoiceId?: number;
  shipPackages?: ShipPackageType[];
  isDisable?: boolean;
  isPopup?: boolean;
  isSlide?: boolean;
  shipInvoiceCode?: string;
  newProgressStatus?: number;
  postProcess?: (...args: any[]) => void;
}

const ShipInvoiceDetail: React.FC<IShipInvoiceDetail> = (props) => {

  const [shipInvoiceId, setShipInvoiceId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);
  const [shipPackages, setShipPackages] = useState<ShipPackageType[]>([]);

  const params = useParams<{type: string; shipInvoiceId: string }>();

  useEffect(() => {
    setShipInvoiceId(props.shipInvoiceId || Number(params.shipInvoiceId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
    if(props.shipPackages) setShipPackages(props.shipPackages);
  }, [params.shipInvoiceId, params.type, props.isDisable, props.shipInvoiceId]);

  const navigate = props.isSlide || props.isPopup ? undefined : useNavigate();

  const [note, setNote] = useState<string>();
  const [expectedDate, setExpectedDate] = useState<string>();
  const [shipInvoiceCode, setShipInvoiceCode] = useState<string>(props.shipInvoiceCode);
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>(EnumShipOrderProgressStatus.Waiting);


  //End of state

  //Function
  const addPopup = useAddPopup();
  const getShipInvoice = useGetShipInvoiceById();
  const postShipInvoice = usePostShipInvoice();
  const putShipInvoice =  usePutShipInvoice();
  const getShipPackageByCode = useGetShipPackageByCode();
  const qrReaderModal = useModal(QRCodeReader);

  const onSuccess = (isAdd: boolean, res: ShipInvoiceType) => {
    addPopup({
      txn: {
        success: true,
        summary: !isAdd? 'Thay đổi kiện thành công' : 'Tạo kiện thành công',
      }
    });
    if(props.postProcess) props.postProcess(res);
  }

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`/ship-invoice/${shipInvoiceId}/${EnumViewType.Edit}`);
  };

  //TODO
  const onSave = () => new Promise((resolve) => {
    const shipInvoice: ShipInvoiceType = {
      shipInvoiceId: shipInvoiceId,
      shipInvoiceCode: shipInvoiceCode,
      progressStatus: props.newProgressStatus? props.newProgressStatus : progressStatus,
      shipPackages: shipPackages,
    };
    const isAdd = !shipInvoiceId;
    const api = isAdd? postShipInvoice(shipInvoice) : putShipInvoice(shipInvoice);
    api.then(
      (res) => {
        setShipInvoiceId(res.shipInvoiceId);
        setIsDisable(true);
        onSuccess(isAdd, res);
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
        resolve(false);
      });
  });

  useEffect(() => {
    if(shipInvoiceId) {
      getShipInvoice(shipInvoiceId).then((res) => {
        setShipInvoiceCode(res.shipInvoiceCode);
        setProgressStatus(res.progressStatus);
        setShipPackages(res.shipPackages);
      })
    }
  }, [shipInvoiceId]);

  //Main
  return (
    <>
      {props.isSlide || props.isPopup ? null : (
        <ToolBar
          toolbarName={`Kiện hàng ${shipInvoiceCode}`}
          listRightButton={isDisable? [{
            name: 'Sửa',
            icon: 'edit',
            actionType: EnumAction.Edit,
            buttonClass: 'info100 tool-btn',
            action: onChangeEditMode,
            align: 'center',
          }] : []}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
          isBack={true}
        />
      )}
      <div className={`shipinvoice-container ${props.isPopup? 'popup' : ''} ${props.isSlide? 'slide' : ''}`}>
        <div className='shipinvoice-info'>
          <div className="shipinvoice-row">
            <Input
              width="360px"
              title="Mã phiếu"
              disabled={isDisable}
              value={shipInvoiceCode}
              onChange={setShipInvoiceCode}
              rightAction={() => {
                qrReaderModal.handlePresent({
                  readQR: (code: string) => {
                    setShipInvoiceCode(code);
                    qrReaderModal.handleDismiss();
                  }
                }, "QUÉT MÃ VẬN CHUYỂN")
              }}
              rightIcon='qr_code_scanner'
            />
          </div>
          <div className="shipinvoice-row">
            {props.newProgressStatus? <div className='inline'>
              <SelectBoxComponent
                require={true}
                onChange={setProgressStatus}
                isDisable={isDisable}
                title={'Trạng thái'}
                value={progressStatus}
                data={ShipOrderProgressStatusList}
                valueType={'value'}
                titleType={'title'}
              />
              <div className='inline' style={{marginTop: '8px'}}>
                <span className="material-icons" style={{margin: 'auto 5px'}}>arrow_forward</span>
                <div className='new-progress-status'>{ShipOrderProgressStatusList.find(i => i.value == props.newProgressStatus).title}</div>
              </div>
            </div>
            :
            <SelectBoxComponent
              width="360px"
              require={true}
              onChange={setProgressStatus}
              isDisable={isDisable}
              title={'Trạng thái'}
              value={progressStatus}
              data={ShipOrderProgressStatusList}
              valueType={'value'}
              titleType={'title'}
            />}
          </div>
          <div className="shipinvoice-row">
            <Input
              width="360px"
              title="Ghi chú"
              require={false}
              disabled={isDisable}
              value={note}
              onChange={setNote}
            />
          </div>
          <div className="shipinvoice-row">
            <CalendarInput width="360px" title='Ngày dự kiến' value={expectedDate} onChange={setExpectedDate} />
          </div>
          <div className="shipinvoice-row">
            <DataScanList
              width="360px"
              title="DS kiện"
              value={shipPackages}
              onChange={setShipPackages}
              disabled={isDisable}
              api={getShipPackageByCode}
              name='shipPackageCode'
              hasScan={true}
            />
          </div>

          {!isDisable && <div className="shipinvoice-row">
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
          </div>}
        </div>

      </div>
    </>
  );
};

export default ShipInvoiceDetail;
