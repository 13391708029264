import React, { useState } from 'react';
import './ExtraInfoInspection.css';
import { BASE_WEB_URL } from 'src/constants';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { EnumAction, EventButton, ProductType } from 'src/api/models';
import useModal from 'src/hooks/useModal';
import ProductDetails from 'src/views/Product/ProductDetails/ProductDetails';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useDeleteProduct } from 'src/api/productApi';
import { useAddPopup } from 'src/state/application/hooks';

interface IExtraInfoInspection {
  type: string;
  data?: number[];
  isDisable?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ExtraInfoInspection: React.FC<IExtraInfoInspection> = (props) => {
  const {type, data, isDisable, postProcess} = props;
  const [isShow, setShow] = useState(false);
  
  const detailModal = useModal(ProductDetails);
  const confirmModal = useModal(ConfirmModal);

  const addPopup = useAddPopup();
  const deleteProduct = useDeleteProduct();

  const onView = (productId: number) => {
    detailModal.handlePresent(
      {
        productId: productId,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  };

  const onAdd = () => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ProductType) => {
          detailModal.handleDismiss();
          onView(data.productId);
          if(postProcess) postProcess();
        },
      },
      'THÊM MỚI',
    );
  };

  const onEdit = (productId: number) => {
    detailModal.handlePresent(
      {
        productId: productId,
        isDisable: false,
        postProcess: (data: ProductType) => {
          detailModal.handleDismiss();
          onView(data.productId);
          if(postProcess) postProcess();
        },
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (productId: number) => {
    const onConfirm = () => {
      deleteProduct(productId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa sản phẩm thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => {if(postProcess) postProcess()});
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa sản phẩm này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA SẢN PHẨM',
    );
  };


  return (
    <div className={`stock-inspection-compare ${isShow? type : ''}`}>
      {isShow? <>
        <div className={`hidden-stock-inspection-compare ${type}`} onClick={() => setShow(false)}></div>
        <div className='stock-inspection-reality-quantity'>{type == 'error'? 'N/A' : `S.lg: ${data[1]}`} | </div>
        {type != 'error'? <>
        <span className='stock-inspection-action material-icons' onClick={() => {
          onView(data[0]);
        }}>visibility</span>
        {!isDisable && type != 'info'? <span className='stock-inspection-action material-icons' onClick={() => {
          if(type == 'warning') {
            onEdit(data[0]);
          } else {
            onDelete(data[0]);
          }
        }}>
        {!isDisable && type == 'warning'? 'edit' : 'delete'}</span> : null}
        </> : !isDisable? <span className='stock-inspection-action material-icons' onClick={() => {
          onAdd();
        }}>add</span> : null}
      </>
      : <span className={`material-icons ${type}`} onClick={() => setShow(true)}>
        {type}
      </span>}
    </div>
  );
};

export default ExtraInfoInspection;
