
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import {
  PageData,
  PaymentType,
} from './models';

/***********************************/
export const useGetPayments = () => {
  const fetch = useFetch();
  return useCallback(
    (keyword: string, page: number, size: number) => {
      return fetch({
        url: `sccore/rest-api/payment?page=${page??0}&size=${size??0}&keyword=${keyword??''}`,
        method: 'get',
      }) as Promise<PageData<PaymentType>>;
    },
    [fetch],
  );
};

export const useApproveBill = () => {
  const fetch = useFetch();
  return useCallback(
    (paymentCode: string, money: number) => {
      return fetch({
        url: `sccore/rest-api/payment/approve-bill/${paymentCode}`,
        method: 'put',
        data: money
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const usePostPayment = () => {
  const fetch = useFetch();
  return useCallback(
    (payment: PaymentType) => {
      return fetch({
        url: 'sccore/rest-api/payment',
        method: 'post',
        data: payment,
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};

export const useGetPaymentById = () => {
  const fetch = useFetch();
  return useCallback(
    (paymentId: number) => {
      return fetch({
        url: `sccore/rest-api/payment/${paymentId}`,
        method: 'get',
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};

export const useGetPaymentByCode = () => {
  const fetch = useFetch();
  return useCallback(
    (code: string) => {
      return fetch({
        url: `sccore/rest-api/payment/code/${code}`,
        method: 'get',
      }) as Promise<PaymentType>;
    },
    [fetch],
  );
};

export const useDeletePayment = () => {
  const fetch = useFetch();
  return useCallback(
    (paymentId: number, money: number) => {
      return fetch({
        url: `sccore/rest-api/payment/${paymentId}`,
        method: 'delete',
        data: money
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
