/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ProfileInfo, ReceiverOrderType } from 'src/api/models';
import {
  useGetReceiverOrderById,
  usePostReceiverOrder,
  usePutReceiverOrder,
} from 'src/api/receiverOrderApi';
import { useAddPopup } from 'src/state/application/hooks';
import './ReceiverOrderDetail.css';
import Input from 'src/components/Input/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import InputDataList from 'src/components/InputDataList';
import ContactList from 'src/components/ContactList/ContactList';

interface IReceiverOrderDetail {
  receiverOrderId?: number;
  isDisable?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const ReceiverOrderDetail: React.FC<IReceiverOrderDetail> = (props) => {


  //Value
  const [receiverOrderId, setReceiverOrderId] = useState<number>();
  const [receiverOrderCode, setReceiverOrderCode] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [address, setAddress] = useState<any[]>([]);
  const [isDisable, setIsDisable] = useState(false);
  const [seaPrice, setSeaPrice] = useState<number>();
  const [airPrice, setAirPrice] = useState<number>();
  const [volumeSeaPrice, setVolumeSeaPrice] = useState<number>();
  const [volumeAirPrice, setVolumeAirPrice] = useState<number>();
  const [overSeaPrice, setOverSeaPrice] = useState<number>();
  const [overAirPrice, setOverAirPrice] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [pic, setPic] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [picName, setPicName] = useState<string>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);

  const [isShowPicList, setIsShowPicList] = useState(false);
  const [iconPicX, setIconPicX] = useState(0);
  const [iconPicY, setIconPicY] = useState(0);

  //End of state

  useEffect(() => {
    setReceiverOrderId(props.receiverOrderId);
    setIsDisable(props.isDisable);
  }, [props.isDisable, props.receiverOrderId]);

  //Function
  const getReceiverOrderById = useGetReceiverOrderById();
  const addPopup = useAddPopup();
  const putReceiverOrder = usePutReceiverOrder();
  const postReceiverOrder = usePostReceiverOrder();

  const onSave = () => {

    if (receiverOrderCode?.trim().length > 0) {
      const receiverOrder: ReceiverOrderType = {
        receiverOrderId: receiverOrderId,
        receiverOrderCode: receiverOrderCode,
        title: title,
        seaPrice: seaPrice,
        airPrice: airPrice,
        address: JSON.stringify(address),
        overAirPrice: overAirPrice,
        overSeaPrice: overSeaPrice,
        volumeAirPrice: volumeAirPrice,
        volumeSeaPrice: volumeSeaPrice,
        userId: userId,
        pic: pic
      };
      const api = receiverOrderId? putReceiverOrder : postReceiverOrder;
      api(receiverOrder).then(
        (res) => {
          setReceiverOrderId(res.receiverOrderId);
          setIsDisable(true);
          addPopup({
            txn: {
              success: true,
              summary: receiverOrderId? 'Thay đổi đại lý nhận hàng thành công' : 'Tạo đại lý nhận hàng thành công',
            }
          });
          if(props.postProcess) props.postProcess(res);
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa mã đại lý nhận hàng',
        },
      });
    }
  };



  useEffect(() => {
    if (receiverOrderId) {
      getReceiverOrderById(receiverOrderId)
        .then((res) => {
          setReceiverOrderId(res.receiverOrderId);
          setReceiverOrderCode(res.receiverOrderCode);
          setTitle(res.title);
          setSeaPrice(res.seaPrice);
          setAirPrice(res.airPrice)
          setVolumeSeaPrice(res.volumeSeaPrice);
          setVolumeAirPrice(res.volumeAirPrice);
          setOverAirPrice(res.overAirPrice);
          setOverSeaPrice(res.overSeaPrice);
          setAddress(res.address?JSON.parse(res.address):[]);
          setUserId(res.userId);
          setPic(res.pic);
          setUserName(res.userName);
          setPicName(res.picName);
        })
        .catch((e) => {
          addPopup({ error: { title: 'Đã có lỗi xảy ra', message: e.errorMessage } });
        });
    }
  }, [receiverOrderId]);
  //Main
  return (
    <div className={`receiverorder-container`}>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Mã đại lý"
          require={false}
          disabled={isDisable}
          value={receiverOrderCode}
          onChange={setReceiverOrderCode}
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Tiêu đề"
          require={false}
          disabled={isDisable}
          value={title}
          onChange={setTitle}
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width='360px'
          title="TK gửi"
          require={false}
          disabled={true}
          value={userName}
        />
        {!isDisable && (
          <div className="i-tooltip order-guide">
            {userId? <span className="material-icons send-account-clear" onClick={() => {
              setUserId(undefined);
              setUserName(undefined);
              setIsShowUserList(false);
            }}>clear</span> : null}
            <span
              className="material-icons info-guide"
              onClick={() => setIsShowUserList(true)}
              ref={(el) => {
                if (el) {
                  const boundingClient = el.getBoundingClientRect();
                  setIconX(boundingClient.left - (props.isPopup? window.innerWidth / 2 : 0));
                  setIconY(boundingClient.y + boundingClient.height - (props.isPopup? window.innerHeight / 10 - 20 : 0));
                }
              }}
            >
              account_circle
            </span>
            <span className="tooltiptext">Chọn người gửi</span>
          </div>
        )}
        {isShowUserList ? (
          <ContactList
            iconX={iconX}
            iconY={iconY}
            showAll={true}
            onHidden={() => setIsShowUserList(false)}
            onSelect={(user: ProfileInfo) => {
              setUserId(user?.userId);
              setUserName(user?.fullName);
              setIsShowUserList(false);
            }}
          />
        ) : null}
      </div>
      <div className="receiverorder-row">
        <Input
          width='360px'
          title="PIC"
          require={false}
          disabled={true}
          value={picName}
        />
        {!isDisable && (
          <div className="i-tooltip order-guide">
            {pic? <span className="material-icons send-account-clear" onClick={() => {
              setPic(undefined);
              setPicName(undefined);
              setIsShowPicList(false);
            }}>clear</span> : null}
            <span
              className="material-icons info-guide"
              onClick={() => setIsShowPicList(true)}
              ref={(el) => {
                if (el) {
                  const boundingClient = el.getBoundingClientRect();
                  setIconPicX(boundingClient.left - (props.isPopup? window.innerWidth / 2 : 0));
                  setIconPicY(boundingClient.y + boundingClient.height - (props.isPopup? window.innerHeight / 10 - 20 : 0));
                }
              }}
            >
              account_circle
            </span>
            <span className="tooltiptext">Chọn người gửi</span>
          </div>
        )}
        {isShowPicList ? (
          <ContactList
            iconX={iconPicX}
            iconY={iconPicY}
            showAll={true}
            onHidden={() => setIsShowPicList(false)}
            onSelect={(pic: ProfileInfo) => {
              setPic(pic?.userId);
              setPicName(pic?.fullName);
              setIsShowPicList(false);
            }}
          />
        ) : null}
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước Air / kg"
          require={false}
          disabled={isDisable}
          value={airPrice}
          onChange={setAirPrice}
          type='number'
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước Sea / kg"
          require={false}
          disabled={isDisable}
          value={seaPrice}
          onChange={setSeaPrice}
          type='number'
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước Air / khối"
          require={false}
          disabled={isDisable}
          value={volumeAirPrice}
          onChange={setVolumeAirPrice}
          type='number'
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước Sea / khối"
          require={false}
          disabled={isDisable}
          value={volumeSeaPrice}
          onChange={setVolumeSeaPrice}
          type='number'
        />
      </div>
       <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước quá khổ Air / kg"
          require={false}
          disabled={isDisable}
          value={overAirPrice}
          onChange={setOverAirPrice}
          type='number'
        />
      </div>
      <div className="receiverorder-row">
        <Input
          width="360px"
          title="Cước quá khổ Sea / kg"
          require={false}
          disabled={isDisable}
          value={overSeaPrice}
          onChange={setOverSeaPrice}
          type='number'
        />
      </div>
      <div className="receiverorder-row">
        <InputDataList
          width="360px"
          title="Thông tin nhận hàng"
          data={address}
          onChange={setAddress}
          disabled={isDisable}
          fields={[{
            name: "name",
            title: "Tên"
          }, {
            name: "phone",
            title: "SĐT"
          }, {
            name: "address",
            title: "Địa chỉ"
          }]}

        />
      </div>
      {!isDisable && <div className="receiverorder-row">
        <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} />
      </div>}
    </div>
  );
};

export default ReceiverOrderDetail;
