import './Cart.css';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CartItem from '../CartItem/CartItem';
import { OrderRequirementType, ProductType } from 'src/api/models';
import { number2jpmoney } from 'src/utils/numberUtils';
import { de } from 'date-fns/locale';
import CartOrderItem from '../CartOrderItem/CartOrderItem';

interface ICart {
  orders: OrderRequirementType[];
}

const Cart: React.FC<ICart> = (props) => {
  const {orders} = props;

  console.log(orders);
  

  return (
    <div className="cart-container">
      {orders?.length > 0 ?
        <>
          {orders.map((order: OrderRequirementType, i: number) => {

            return <CartOrderItem order={order}/>
            
            
          })}
        </> :
        <div className="empty-cart-cls text-center">
          <div><span className="material-icons">remove_shopping_cart</span></div>
          <label>Không có hàng xuất</label>
          <div><i>"Vui lòng kiểm tra lại!"</i></div>
        </div>}
    </div>
  );

};

export default Cart;
