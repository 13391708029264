import React, { useState, useEffect } from 'react';
import { useGetShipInfoByZipcode } from 'src/api/commonInfomationApi';
import {
  EnumAction,
  EnumDisplayConfig,
  EnumSimOrderProgressStatus,
  EnumSimOrderProgressStatusTitle,
  EventButton,
  PageData,
  ProductType,
  ProfileInfo,
  SimOrderDetailsType,
  SimOrderType,
  SimPriceType,
  SimStoreType,
} from 'src/api/models';
import { useGetProduct, useGetProductById } from 'src/api/productApi';
import { useGetProductRealm } from 'src/api/productRealmApi';
import {
  useGetSimOrderById,
  usePutSimOrder,
  usePostAdminSimOrder,
  usePostSimOrderBillImage,
  useExportCsvFile,
  useSyncOrderToSim,
} from 'src/api/simOrderApi';
import { useGetSimPriceListByDateAndUserId } from 'src/api/simPriceApi';
import { useGetSimStore } from 'src/api/simStoreApi';
import { useGetUserList } from 'src/api/userApi';
import SimOrderDetailItem from 'src/components/SimOrderDetailItem/SimOrderDetailItem';
import { useAddPopup, useReloadTable } from 'src/state/application/hooks';
import { normalizationZipcode } from 'src/utils/stringUtils';
import './SimOrderDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { BASE_SIM_URL } from 'src/constants';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Avatar from 'src/components/Avatar';
import Input from 'src/components/Input';
import SelectBoxComponent from 'src/components/SelectBoxComponent/SelectBoxComponent';
import ContactList from 'src/components/ContactList/ContactList';
import Note from 'src/components/Note/Note';


interface ISimOrderDetail {
  simOrderId?: number;
  isDisable?: boolean;
  isSlide?: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const SimOrderDetail: React.FC<ISimOrderDetail> = (props) => {


  const params = useParams<{ type: string; simOrderId: string }>()
  const [simOrderId, setSimOrderId] = useState<number>();
  const [createdAt, setCreateAt] = useState<string>();
  const [isDisable, setIsDisable] = useState(true);
  const [userId, setUserId] = useState<number>();
  const [isShowUserList, setIsShowUserList] = useState(false);
  const [userName, setUserName] = useState<string>();
  const [userIdError, setUserIdError] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [fullNameError, setFullNameError] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [zipCodeError, setZipCodeError] = useState<string>();
  const [address1, setAddress1] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [address3, setAddress3] = useState<string>();
  const [address4, setAddress4] = useState<string>();
  const [addressError, setAddressError] = useState<string>();
  const [trackingCode, setTrackingCode] = useState<string>();
  const [note, setNote] = useState<string>();
  const [simList, setSimList] = useState<ProductType[]>([]);
  const [orderList, setOrderList] = useState<SimOrderDetailsType[]>([]);
  const [progressStatus, setProgressStatus] = useState<number>();
  const [simStoreId, setSimStoreId] = useState<number>();
  const [simStoreIdError, setSimStoreIdError] = useState<string>();
  const [simStoreList, setSimStoreList] = useState<SimStoreType[]>([]);

  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);
  const [isLoadImage, setIsLoadImage] = useState(false);
  const [image, setImage] = useState(null);
  const [thumbImage, setThumbImage] = useState(null);
  const [imageFile, setImageFile] = useState<File>();

  const [keyword, setKeyword] = useState(undefined);
  const [userList, setUserList] = useState<ProfileInfo[]>();

  const [simPriceList, setSimPriceList] = useState<SimPriceType[]>(null);
  const [reloadFlag, setReloadFlag] = useState(false);
  //End of state


  const lstStatus = [
    {
      value: EnumSimOrderProgressStatus.Waiting,
      title: EnumSimOrderProgressStatusTitle.Waiting,
      css: { color: '#3a3131' },
    },
    {
      value: EnumSimOrderProgressStatus.Sent,
      title: EnumSimOrderProgressStatusTitle.Sent,
      css: { color: 'blue' },
    },
    {
      value: EnumSimOrderProgressStatus.Error,
      title: EnumSimOrderProgressStatusTitle.Error,
      css: { color: 'red' },
    },
  ];


  //Function
  const navigate = props.isSlide || props.isPopup? undefined : useNavigate();
  const reloadTable = useReloadTable();
  const getRealm = useGetProductRealm();
  const getProduct = useGetProduct();
  const getSimOrderById = useGetSimOrderById();
  const putSimOrder = usePutSimOrder();
  const getProductById = useGetProductById();
  const postSimOrder = usePostAdminSimOrder();
  const addPopup = useAddPopup();
  const addBillImage = usePostSimOrderBillImage();
  const getUserList = useGetUserList();
  const getSimStore = useGetSimStore();
  const getShipInfoByZipcode = useGetShipInfoByZipcode();
  const getSimPriceListByDateAndUserId = useGetSimPriceListByDateAndUserId();
  const syncOrderToSim = useSyncOrderToSim();
  const exportCsvFile = useExportCsvFile();


  useEffect(() => {
    setSimOrderId(props.simOrderId || Number(params.simOrderId));
    setIsDisable(Number(params.type) == EnumViewType.View || props.isDisable);
  }, [props.simOrderId, params.simOrderId, props.isDisable, params.type]);

  const onChangeEditMode = (e: MouseEvent) => {
    navigate(`${BASE_SIM_URL}/sim-order/${simOrderId}/${EnumViewType.Edit}`);
  };


  //Upload image
  const onParseZipcode = (code: string) => {
    setZipCode(code);
    const nZipcode = normalizationZipcode(code);
    if (!nZipcode || nZipcode.length != 7) return;
    getShipInfoByZipcode(nZipcode)
      .then((data) => {
        setAddress1(data?.pref);
        setAddress2(data?.city);
        setAddress3(data?.town);
      })
      .catch(() => {
        setAddress1(undefined);
        setAddress2(undefined);
        setAddress3(undefined);
      });
  };

  const onAddImage = (file: File) => {
    if (file) {
      if (!simOrderId) {
        const url = URL.createObjectURL(file);
        setImage(url);
        setThumbImage(url);
        setImageFile(file);
      } else {
        const formData = new FormData();
        formData.append('file', file);
        addBillImage(simOrderId, formData)
          .then((res) => {
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  const onChangePhoneNumber = (index: number, simPhoneNumber: string) => {
    const _odList = orderList.filter(() => true);
    _odList[index].simPhoneNumber = simPhoneNumber;

    setOrderList(_odList);
  };

  const onChangeSimImei = (index: number, simImei: string) => {
    const _odList = orderList.filter(() => true);
    _odList[index].simImei = simImei;

    setOrderList(_odList);
  };

  const onChangePrice = (index: number, price: number) => {
    const _odList = orderList.filter(() => true);
    _odList[index].price = price;
    setOrderList(_odList);
  };

  const onChangeDeposit = (index: number, deposit: number) => {
    const _odList = orderList.filter(() => true);
    _odList[index].deposit = deposit;
    setOrderList(_odList);
  };

  const onChangeMonthlySubscriptionFee = (index: number, monthlySubscriptionFee: number) => {
    const _odList = orderList.filter(() => true);
    _odList[index].monthlySubscriptionFee = monthlySubscriptionFee;
    setOrderList(_odList);
  };

  const onDeleteOrderItem = (index: number) => {
    const _orderList = orderList.filter(() => true);
    _orderList.splice(index, 1);
    setOrderList(_orderList);
  };

  //Validate
  const validateSimStoreId = () => {
    let isContinue = true;

    if (!simStoreId || simStoreId == 0) {
      isContinue = false;
      setSimStoreIdError('Chưa chọn nguồn sim');
    } else setSimStoreIdError(null);

    return isContinue;
  };

  const validateFullName = () => {
    let isContinue = true;

    if (!fullName || fullName == '') {
      isContinue = false;
      setFullNameError('Chưa nhập tên');
    } else setFullNameError(null);

    return isContinue;
  };

  const validateUserId = () => {
    let isContinue = true;

    if (!userId) {
      isContinue = false;
      setUserIdError('Chưa nhập CTV');
    } else setUserIdError(null);

    return isContinue;
  };

  const validateZipCode = () => {
    let isContinue = true;

    if (!zipCode || zipCode == '') {
      isContinue = false;
      setZipCodeError('Chưa nhập mã bưu điện');
    } else setZipCodeError(null);

    return isContinue;
  };

  const validateAddress = () => {
    let isContinue = true;

    if (
      (!address1 || address1 == '') &&
      (!address2 || address2 == '') &&
      (!address3 || address3 == '') &&
      (!address4 || address4 == '')
    ) {
      isContinue = false;
      setAddressError('Chưa nhập địa chỉ');
    } else setAddressError(null);

    return isContinue;
  };

  const onAddOrderDetail = (_itemFocus: string) => {
    if (userId) {
      if (simPriceList) {
        getProductById(Number(_itemFocus))
          .then((data: ProductType) => {
            const _odList = orderList.filter(() => true);

            const _simPrice = simPriceList.filter(
              (value) => value.productId == Number(_itemFocus),
            )[0];

            const _item: SimOrderDetailsType = {
              productId: Number(_itemFocus),
              product: data,
              quantity: 1,
              deposit: _simPrice.deposit,
              monthlySubscriptionFee: _simPrice.monthlySubscriptionFee,
              price: _simPrice.price,
            };
            _odList.push(_item);

            setOrderList(_odList);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        addPopup({
          error: {
            title: 'Chờ chút',
            message: 'Đang lấy bảng giá dành cho CTV này',
          },
        });
      }
    } else {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: 'Bạn chưa chọn CTV',
        },
      });
    }
  };

  const onSave = () => new Promise((resolve, reject) => {
    const isFullName = validateFullName();
    const isZipCode = validateZipCode();
    const isAddress = validateAddress();
    const isUserId = validateUserId();
    const isSimStoreId = validateSimStoreId();
    if (isFullName && isZipCode && isAddress && isUserId && isSimStoreId) {
      if (orderList.length > 0) {
        const simOrder: SimOrderType = {
          simOrderId: simOrderId,
          userId: userId,
          fullname: fullName,
          phoneNumber: phoneNumber,
          zipCode: zipCode,
          address1: address1,
          address2: address2,
          address3: address3,
          address4: address4,
          note: note,
          simOrderDetails: orderList,
          simStoreId: simStoreId,
        };

        const api =  props.simOrderId? putSimOrder : postSimOrder;
        api(simOrder).then((res) => {
            setSimOrderId(res.simOrderId);
            addPopup({
              txn: {
                success: true,
                summary: props.simOrderId? 'Sửa thành công' : 'Lên đơn thành công',
              },
            });
            reloadTable();
            resolve(true);
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
            reject(false);
          });
      } else {
        addPopup({
          txn: {
            success: false,
            summary: 'Danh sách sim không dược để trống',
          },
        });
        reject(false);
      }
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
      reject(false);
    }
  });


  //End of component

  //useEffect
  useEffect(() => {
    getSimStore()
      .then((data) => {
        setSimStoreList(data);
      })
      .catch((error) => {
        console.log(error);
        console.log('Get sim store error');
      });
  }, [getSimStore]);

  useEffect(() => {
    if (!props.isDisable) {
      getRealm()
        .then((data) => {
          const _arr: number[] = [];
          data.map((value) => {
            if (value.isHidden) {
              _arr.push(Number(value.productRealmId));
            }
          });

          getProduct(null, 1, 9999, _arr, [], false)
            .then((data) => {
              setSimList(data.items);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getProduct, getRealm]);


  useEffect(() => {
    if (simOrderId) {
      getSimOrderById(simOrderId)
        .then((data) => {
          setOrderList(data.simOrderDetails);
          setUserName(data.userName);
          setFullName(data.fullname);
          setCreateAt(data.createdAt);
          setNote(data.note);
          setPhoneNumber(data.phoneNumber);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setAddress3(data.address3);
          setAddress4(data.address4);
          setZipCode(data.zipCode);
          setProgressStatus(data.progressStatus);
          setImage(data.image);
          setThumbImage(data.thumbImage);
          setTrackingCode(data.trackingCode);
          setUserId(data.userId);
          setKeyword(data.userName);
          setSimStoreId(data.simStoreId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getSimOrderById, simOrderId]);

  useEffect(() => {
    if (keyword && keyword !== '') {
      getUserList(keyword)
        .then((data) => {
          setUserList(data.items);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUserList(null);
    }
  }, [getUserList, keyword, userId]);

  useEffect(() => {
    if (userId) {
      const date = new Date();
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yy = date.getFullYear();
      const temp = `${yy}/${mm}/${dd}`;

      getSimPriceListByDateAndUserId(temp, userId)
        .then((data) => {
          setSimPriceList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getSimPriceListByDateAndUserId, userId]);


  const onSync = () => new Promise((resolve, reject) => {
    if (simOrderId) {
      syncOrderToSim(simOrderId)
        .then(() => {
          reloadTable();
          addPopup({
            txn: {
              success: true,
              summary: 'Đồng bộ thành công',
            },
          });
          setReloadFlag(!reloadFlag);
          resolve(true);
        })
        .catch((error) => {
          reloadTable();
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra!',
              message: error.errorMessage,
            },
          });
          reject(false);
        });
    }
  });

  const onExportCSV = () => new Promise((resolve, reject) => {
    const arr: number[] = [];
    arr.push(simOrderId);
    exportCsvFile(arr)
      .then((data) => {
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'sim-order.csv');
        tempLink.click();
        resolve(true);
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        reject(false);
      });
  });

  const listButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: () => navigate(-1),
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onChangeEditMode,
      align: 'center',
    },
  ];

  const listButtonDis: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: () => navigate(-1),
      align: 'center',
    },
  ];

  //Main
  return (
    <>
      {props.isSlide || props.isPopup? null : (
        <ToolBar
          toolbarName={`Đơn hàng SIM ${userName} - ${createdAt}`}
          listRightButton={isDisable ? listButton : listButtonDis}
          width={'100%'}
          backgroundColor={'#ebe9e9'}
          isPaging={false}
        />
      )}
      <div className='sim-order-detail-container'>
        <div className='sim-order-detail-info'>
          <div className='sim-order-detail-row'>
            <ButtonComponent icon="sync" title={'Đồng bộ SIM hoạt động'} onClick={onSync} loader={true}/>
          </div>
          <div className='sim-order-detail-row'>
            <ButtonComponent icon="print" title={'In đơn nhanh'} onClick={onExportCSV} loader={true}/>
          </div>
          <div className='sim-order-detail-row'>
            <Avatar change={onAddImage} thumbAvatar={thumbImage} avatar={image} />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Mã vận đơn"
              require={false}
              disabled={isDisable}
              value={trackingCode}
              type="text"
              onChange={setTrackingCode}
            />
          </div>
          <div className='sim-order-detail-row'>
            <SelectBoxComponent
              width="300px"
              require={true}
              onChange={setProgressStatus}
              isDisable={isDisable}
              value={progressStatus}
              data={lstStatus}
              valueType={'value'}
              titleType={'title'}
              title="Tr.thái vận chuyển"
            />
          </div>
        </div>
        <div className='sim-order-detail-info'>

          <div className='sim-order-detail-row'>
            <SelectBoxComponent
              width="300px"
              require={true}
              onChange={setSimStoreId}
              isDisable={isDisable}
              value={simStoreId}
              data={simStoreList}
              valueType={'simStoreId'}
              titleType={'simStoreTitle'}
              title="Chọn nguồn SIM"
              onBlur={validateSimStoreId}
              error={simStoreIdError}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Cộng tác viên"
              require={true}
              disabled={isDisable}
              value={userName}
            />
            {!isDisable && (
              <div className="i-tooltip guide">
                <span
                  className="material-icons info-guide"
                  onClick={() => setIsShowUserList(true)}
                  id="show-contact-icon"
                  ref={(el) => {
                    if (el) {
                      const boundingClient = el.getBoundingClientRect();
                      setIconX(boundingClient.left);
                      setIconY(boundingClient.y + boundingClient.height);
                    }
                  }}
                >
                  account_circle
                </span>
                <span className="tooltiptext">Chọn CTV đặt SIM</span>
              </div>
            )}
            {isShowUserList ? (
              <ContactList
                iconX={iconX}
                iconY={iconY}
                onHidden={() => setIsShowUserList(false)}
                onSelect={(user: ProfileInfo) => {
                  setUserId(user.userId);
                  setUserName(user.fullName);
                  setIsShowUserList(false);
                  setUserIdError(undefined);
                }}
              />
            ) : null}
            <div style={{ color: 'red', paddingLeft: 2 }}>{userIdError}</div>
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Tên khách hàng"
              require={false}
              disabled={isDisable}
              value={fullName}
              type="text"
              onChange={setFullName}
              onBlur={validateFullName}
              error={fullNameError}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Số điện thoại"
              require={false}
              disabled={isDisable}
              value={phoneNumber}
              type="text"
              onChange={setPhoneNumber}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Mã bưu điện"
              require={true}
              disabled={isDisable}
              value={zipCode}
              type="text"
              onChange={onParseZipcode}
              onBlur={validateZipCode}
              error={fullNameError}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Địa chỉ 1"
              require={true}
              disabled={isDisable}
              value={address1}
              type="text"
              onChange={setAddress1}
              onBlur={validateAddress}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Địa chỉ 2"
              require={true}
              disabled={isDisable}
              value={address2}
              type="text"
              onChange={setAddress2}
              onBlur={validateAddress}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Địa chỉ 3"
              require={true}
              disabled={isDisable}
              value={address3}
              type="text"
              onChange={setAddress3}
              onBlur={validateAddress}
            />
          </div>
          <div className='sim-order-detail-row'>
            <Input
              width="300px"
              title="Địa chỉ 4"
              require={true}
              disabled={isDisable}
              value={address4}
              type="text"
              onChange={setAddress4}
              onBlur={validateAddress}
            />
            <div style={{ color: 'red', paddingLeft: 2 }}>{addressError}</div>
          </div>

        </div>
        <div className='sim-order-detail-items'>
          {orderList.map((value, index: number) => {
            return (
              <SimOrderDetailItem
                key={`order-item${index}`}
                productId={value.productId}
                simPhoneNumber={value.simPhoneNumber}
                onChangePhoneNumber={onChangePhoneNumber}
                simImei={value.simImei}
                onChangeSimImei={onChangeSimImei}
                price={value.price}
                onChangePrice={onChangePrice}
                isDisable={isDisable}
                index={index}
                onDeleteOrderItem={onDeleteOrderItem}
                isCreatedSim={value.isCreatedSim}
                deposit={value.deposit}
                monthlySubscriptionFee={value.monthlySubscriptionFee}
                onChangeDeposit={onChangeDeposit}
                onChangeMonthlySubscriptionFee={onChangeMonthlySubscriptionFee}
              />
            );
          })}
          {!props.isDisable ?
            <div className='sim-order-detail-row'>
              <SelectBoxComponent
                width="300px"
                require={true}
                onChange={onAddOrderDetail}
                isDisable={isDisable}
                data={simList}
                valueType={'productId'}
                titleType={'productName'}
                title="Thêm sim"
                value={''}
              />
            </div>
            : null}
          {isDisable ? null : (
            <ButtonComponent icon="save" title={'LƯU'} onClick={onSave} loader={true} />
          )}
        </div>

        {simOrderId > 0 ? (
          <Note
            objectId={simOrderId}
            functionId={EnumDisplayConfig.Sim_order}
            recordUserId={userId}
          />
        ) : null}
      </div>
    </>

  );
};

export default SimOrderDetail;
