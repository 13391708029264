/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EnumShipOrderProgressStatus, ShipOrderProgressStatusList, EventButton, ShipOrderType, TransportType, PaymentTypeList, ShipPackageType, PackageInfoType, VNShippers, EnumVNShipper } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_ORDER_URL } from 'src/constants';
import { useDeleteShipOrder, useExportManifest, useExportShipOrder, useExportShipOrderBill, useGetReceiveShipOrder, useGetShipOrder, usePutUpdateShipOrderPaid } from 'src/api/shipOrderApi';
import './ShipOrder.css';
import ShipOrderDetail from './ShipOrderDetail/ShipOrderDetail';
import UpdateShipOrderProgressStatus from './UpdateShipOrderProgressStatus/UpdateShipOrderProgressStatus';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import useSlideBar from 'src/hooks/useSlideBar';
import { useDebounce } from 'use-debounce';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { ADMIN, RECEIVE } from 'src/common/constant/Constant';
import ListView from 'src/components/ListView/ListView';
import { ItemDisplayType } from 'src/components/ListView/ItemView/ItemView';
import { useGetReceiverOrder } from 'src/api/receiverOrderApi';
import UpdateUser from './UpdateUser/UpdateUser';
import { useGetUserList } from 'src/api/userApi';
import PaymentExport from '../../components/PaymentExport/PaymentExport';
import ShipPackageDetail from '../ShipPackage/ShipPackageDetail/ShipPackageDetail';
import { useGetShipPackageInfo } from 'src/api/shipPackageApi';
import { number2vnmoney, numberFormat } from 'src/utils/numberUtils';
import { useGetShipInvoice } from 'src/api/shipInvoiceApi';
import { SpecialProductType } from 'src/components/SpecialProduct/SpecialProduct';
import ManifestEditor from './ManifestEditor/ManifestEditor';

//TODO
const ShipOrder: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const { role } = useParams<{ role: string }>();

  const header = {
      header: [
      {
        code: 'createdAt',
        dataType: EnumDataType.Date,
        isOptions: false,
        title: 'Ngày tạo đơn',
      },
      {
        code: 'billCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã đơn',
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã t.toán',
      },
      {
        code: 'japanTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã v.đơn Nhật',
      },
      {
        code: 'conversionCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã ch.đổi',
      },
      {
        code: 'shipPackageId',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Kiện',
        convert: (value: number) => {
          return (value?
            <a href={`${BASE_ORDER_URL}/ship-package/${value}/1`} target="_blank" style={{fontSize: 'small'}}>{packageInfo[value]?.shipPackageCode}</a>
            : null
          );
        },
      },
      {
        code: 'shipPackageId',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Chuyến bay',
        convert: (value: number) => {
          return value && packageInfo[value]?.shipInvoiceCode? <div className={'label-table'}>{packageInfo[value]?.shipInvoiceCode}</div> : null;
        },
      },
      {
        code: 'transportType',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Hình thức',
        options: TransportType,
      },
      {
        code: 'paymentType',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Cách tính cước',
        options: PaymentTypeList,
      },
      {
        code: 'receiverOrderName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Đại lý',
      },
      {
        code: 'userName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'TK gửi',
      },
      {
        code: 'progressStatus',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'Tr.thái v.chuyển',
        options: ShipOrderProgressStatusList,
      },
      {
        code: 'isPaid',
        dataType: EnumDataType.Boolean,
        isOptions: false,
        title: 'Th.toán',
      },
      {
        code: 'weight',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 1000,
        title: 'Kh.lượng (kg)',
      },
      {
        code: 'length',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Dài (cm)',
      },
      {
        code: 'width',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Rộng (cm)',
      },
      {
        code: 'height',
        dataType: EnumDataType.Ratio,
        isOptions: false,
        ratio: 10,
        title: 'Cao (cm)',
      },
      {
        code: 'price',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Đơn giá (VNĐ/kg)',
      },
      {
        code: 'specialProduct',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Hàng hóa đặc biệt',
        convert: (value: string) => {
          return <div className='text-right'>{number2vnmoney(value? (JSON.parse(value) as SpecialProductType[]).reduce((total, item) => total+=(Number(item.price) * Number(item.quantity)), 0): null)}</div>;
        }
      },
      {
        code: 'transportFee',
        dataType: EnumDataType.VND,
        isOptions: false,
        title: 'Giá tiền (VNĐ)',
      },
      {
        code: 'senderName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người gửi',
      },
      {
        code: 'senderAddress',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ gửi',
      },
      {
        code: 'receiverName',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Người nhận',
      },
      {
        code: 'receiverPhoneNumber',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'SĐT người nhận',
      },
      {
        code: 'receiverAddress',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Địa chỉ người nhận',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'customerNote',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Ghi chú của khách hàng',
      },
      {
        code: 'note',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'G.chú Admin',
      },
      {
        code: 'vietnamTrackingCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mã vận đơn Việt Nam',
      },
      {
        code: 'vietnamShipper',
        dataType: EnumDataType.Text,
        isOptions: true,
        title: 'VNShip',
        options: VNShippers,
      },
    ] as TableColumnType[]
  };

  const display: ItemDisplayType<ShipOrderType> = {
    header: [
      {
        code: 'billCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'orange',
          borderRadius: '5px',
        }
      },
      {
        code: 'paymentCode',
        dataType: EnumDataType.Text,
        isOptions: false,
        cellCss: {
          display: 'flex',
          background: 'lightgreen',
          borderRadius: '5px',
        }
      }
    ],
    detail: {
      info: [
        [
          {
            code: 'receiverOrderName',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },
          {
            code: 'userName',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },
          {
            code: 'progressStatus',
            dataType: EnumDataType.Text,
            isOptions: true,
            options: ShipOrderProgressStatusList,
          },
        ],
        [
          {
            code: 'weight',
            dataType: EnumDataType.Ratio,
            ratio: 1000,
            isOptions: false,
            icon: 'monitor_weight',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },

          {
            code: 'transportFee',
            dataType: EnumDataType.VND,
            isOptions: false,
            icon: 'monetization_on',
            cellCss: {
              margin: '0 5px',
              flex: 'auto',
            }
          },
          {
            code: 'isPad',
            dataType: EnumDataType.Boolean,
            isOptions: false,
            title: 'Thanh toán',
          },
        ],
        [
          {
            code: 'length',
            dataType: EnumDataType.Ratio,
            ratio: 10,
            isOptions: false,
            icon: 'straighten',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'width',
            dataType: EnumDataType.Ratio,
            ratio: 10,
            isOptions: false,
            icon: 'open_in_full',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
          {
            code: 'height',
            dataType: EnumDataType.Ratio,
            ratio: 10,
            isOptions: false,
            icon: 'height',
            cellCss: {
              margin: '0 5px',
              flex: 'auto'
            }
          },
        ],
        [
          {
            code: 'description',
            dataType: EnumDataType.Text,
            isOptions: false,
            cellCss: {
              display: 'flex',
              flex: 'auto'
            }
          },
        ],

      ]
    },
    bottom: [
      [
        {
          code: 'receiverName',
          title: 'Người nhận: ',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            flex: 'auto'
          }
        },
        {
          code: 'receiverPhoneNumber',
          icon: "phone",
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ],
      [
        {
          code: 'receiverAddress',
          icon: 'location_on',
          dataType: EnumDataType.Text,
          isOptions: false,
          cellCss: {
            margin: '0 5px',
          }
        },
      ]
    ],

    actions: (item: ShipOrderType) => [
      {
        icon: 'mode_edit',
        actionType: EnumAction.Edit,
        action: () => onEdit(item.shipOrderId),
        buttonClass: 'info600',
        align: '',
      },
      {
        icon: 'delete',
        actionType: EnumAction.Delete,
        action: () => onDelete(item.shipOrderId),
        buttonClass: 'info100',
        align: '',
      }
    ]
  };

  //State
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const [transportType, setTransportType] = useState<number>();
  const [isPaid, setPaid] = useState<boolean>();
  const [count, setCount] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [rawData, setRawData] = useState<ShipOrderType[]>([]);
  const [shipOrders, setShipOrders] = useState<ShipOrderType[]>([]);
  const [checkList, setCheckList] = useState<ShipOrderType[]>([]);
  const [progressStatus, setProgressStatus] = useState<EnumShipOrderProgressStatus>();
  const [vietnamShipper, setVietnamShipper] = useState<EnumVNShipper>();
  const [receiverOrderId, setReceiverOrderId] = useState<number>();
  const [packageInfo, setPackageInfo] = useState<{[shipPackageId: number]: PackageInfoType}>({});
  const [extraInfo, setExtraInfo] = useState<{[key: string]: number}>();
  const [shipInvoiceId, setShipInvoiceId] = useState<number>();
  
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  //Function
  const addPopup = useAddPopup();
  const getShipOrder = useGetShipOrder();
  const getReceiveShipOrder = useGetReceiveShipOrder();
  const deleteShipOrder = useDeleteShipOrder();
  const exportShipOrder = useExportShipOrder();
  const exportManifest = useExportManifest();
  // const updateShipOrderPaid = usePutUpdateShipOrderPaid();
  const getReceiverOrder = useGetReceiverOrder();
  const getUsers = useGetUserList();
  const getShipPackageInfo = useGetShipPackageInfo();
  const getShipInvoice = useGetShipInvoice();
  const exportShipOrderBill = useExportShipOrderBill();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(ShipOrderDetail);
  const slideBar = useSlideBar(ShipOrderDetail);
  const updateModal = useModal(UpdateShipOrderProgressStatus);
  const updateUserModal = useModal(UpdateUser);
  const exportBillModal = useModal(PaymentExport);
  const shipPackageModal = useModal(ShipPackageDetail);
  const manifestEditor = useModal(ManifestEditor);

  const CREATED_AT_FILTER = "CREATED_AT_FILTER";
  const STATUS_FIELD_FILTER = 'value';
  const STATUS_FILTER = 'STATUS_FILTER';
  const AGENT_FIELD_FILTER = 'receiverOrderId';
  const AGENT_FILTER = 'AGENT_FILTER';
  const TRANSPORT_TYPE_FIELD_FILTER = 'value';
  const TRANSPORT_TYPE_FILTER = 'TRANSPORT_TYPE_FILTER';
  const VNSHIPPER_FILTER = 'VNSHIPPER_FILTER';
  const VNSHIPPER_FIELD_FILTER = 'value';

  const PAID_FIELD_FILTER = 'value';
  const PAID_FILTER = 'PAID_FILTER';
  const USER_FILTER = 'USER_FILTER';
  const USER_FIELD_FILTER = 'userId';
  const INVOICE_FILTER = 'INVOICE_FILTER';
  const INVOICE_FIELD_FILTER = 'shipInvoiceId';

  const filters = [
    {
      title: 'Ngày tạo',
      filterKey: CREATED_AT_FILTER,
      timestamp: true
    },
    {
      data: ShipOrderProgressStatusList,
      valueField: STATUS_FIELD_FILTER,
      titleField: 'title',
      title: 'Trạng thái',
      filterKey: STATUS_FILTER,
    },
    {
      data: VNShippers,
      valueField: VNSHIPPER_FIELD_FILTER,
      titleField: 'title',
      title: 'VNShip',
      filterKey: VNSHIPPER_FILTER,
    },
    {
      api: getReceiverOrder,
      isSearch: true,
      isPaging: true,
      valueField: AGENT_FIELD_FILTER,
      titleField: 'title',
      title: 'Đại lý',
      filterKey: AGENT_FILTER,
    },
    {
      data: TransportType,
      valueField: TRANSPORT_TYPE_FIELD_FILTER,
      titleField: 'title',
      title: 'Hình thức',
      filterKey: TRANSPORT_TYPE_FILTER,
    },
    {
      data: [
        {
          value: true,
          title: "Đã thanh toán",
          css: { color: 'blue' },
        },
        {
          value: false,
          title: "Chưa thanh toán",
          css: { color: 'red' },
        },
      ],
      valueField: PAID_FIELD_FILTER,
      titleField: 'title',
      title: 'Thanh toán',
      filterKey: PAID_FILTER,
    },
    {
      api: getUsers,
      isSearch: true,
      isPaging: true,
      valueField: USER_FIELD_FILTER,
      titleField: 'email',
      title: 'CTV',
      filterKey: USER_FILTER,
    },
    {
      api: getShipInvoice,
      isSearch: true,
      isPaging: true,
      valueField: INVOICE_FIELD_FILTER,
      titleField: 'shipInvoiceCode',
      title: 'Chuyến hàng',
      filterKey: INVOICE_FILTER,
    },
 ];

  const onClickCheckbox = (shipOrder: ShipOrderType, isChecked: boolean) => {
    if (isChecked) {
      const nCheckList = checkList.filter((o) => o != shipOrder);
      setCheckList(nCheckList);
      setShipOrders(
        rawData.filter(
          (i) => !nCheckList.some((c) => c.shipOrderId == i.shipOrderId),
        ),
      );
    } else {
      setCheckList([...checkList, shipOrder]);
      setShipOrders(shipOrders.filter((o) => o != shipOrder));
    }
  };

  const onClickCheckAll = () => {
    if(shipOrders.length > 0) {
      setCheckList([...checkList, ...shipOrders]);
      setShipOrders([]);
    } else {
      setCheckList([]);
      setShipOrders([...rawData]);
    }

  };

  //Menucontext
  const onView = (shipOrderId: number) => {
    const url = `${BASE_ORDER_URL}/ship-order/${role}/${shipOrderId}/${EnumViewType.View}`;
    navigate(url);
  };

  const onEdit = (shipOrderId: number) => {
    const url = `${BASE_ORDER_URL}/ship-order/${role}/${shipOrderId}/${EnumViewType.Edit}`;
    navigate(url);
  };

  const onDelete = (shipOrderId: number) => {
    const onConfirm = () => {
      deleteShipOrder(shipOrderId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa đơn vận chuyển thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đơn vận chuyển này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƠN VẬN CHUYỂN',
    );
  };


  const menuContext = (item: ShipOrderType) => role == ADMIN? [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.shipOrderId);
      },
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item.shipOrderId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.shipOrderId),
      buttonClass: 'info',
      align: '',
    },
  ] : [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => {
        onView(item.shipOrderId);
      },
      buttonClass: 'info',
      align: 'left',
    },
  ];

  const onClick = (shipOrder: ShipOrderType) => {
    slideBar.handlePresent(
      {
        role: role,
        shipOrderId: shipOrder.shipOrderId,
        postProcess: () => setReloadFlag(!reloadFlag)
      }
    );
  };

  //Function in the listButton
  const onAddNewShipOrder = useCallback((e: MouseEvent) => {
    detailModal.handlePresent(
      {
        role: role,
        isDisable: false,
        postProcess: (data: ShipOrderType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        },
      },
      'THÊM MỚI ĐƠN VẬN CHUYỂN',
    );
  }, [reloadFlag]);

  const onUpdateShipOrderProgressStatus = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn vận chuyển',
        },
      });
    } else {
      slideBar.handleDismiss();
      updateModal.handlePresent(
        {
          checkListIds: checkList.map(i => i.shipOrderId),
          postProcess: () => {
            setReloadFlag(!reloadFlag);
            updateModal.handleDismiss();
          }
        },
        'CẬP NHẬT TRẠNG THÁI ĐƠN VẬN CHUYỂN',
      );
    }
  }, [checkList, reloadFlag]);

  const onUpdateUser = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn vận chuyển',
        },
      });
    } else {
      updateUserModal.handlePresent(
        {
          checkListIds: checkList.map(i => i.shipOrderId),
          postProcess: () => {
            updateUserModal.handleDismiss();
            setReloadFlag(!reloadFlag);
          }
        },
        'CẬP NHẬT TK GỬI',
      );
    }
  }, [checkList, reloadFlag]);

  const openExport = useCallback((checkListIds: number[]) => {
    exportBillModal.handlePresent(
      {
        checkListIds: checkListIds,
        exportApi: exportShipOrderBill,
        postProcess: () => {
          exportBillModal.handleDismiss();
          setReloadFlag(!reloadFlag);
        }
      },
      'XUẤT HÓA ĐƠN',
    );
  }, [reloadFlag])

  const onExportBill = useCallback((e: MouseEvent) => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn vận chuyển',
        },
      });
    } else if (checkList.some(i => !i.billCode)) {
      addPopup({
        txn: {
          success: false,
          summary: 'Đơn vận chuyển được chọn chưa có mã đơn',
        },
      });
    } else {
      const checkListIds = checkList.map(i => i.shipOrderId);
      let message = "";
      if(checkList.some(i => i.paymentCode?.length > 0)) message += 'Tồn tại đơn vận chuyển đã có mã thanh toán. ';
      if(checkList.some(i => !i.transportFee)) message += 'Tồn tại đơn vận chuyển chưa có phí vận chuyển. ';
      if(message.length > 0) {
        message += 'Bạn vẫn muốn thực hiện tạo hóa đơn thanh toán?';
        const listButton: EventButton[] = [
          {
            name: 'Xác nhận',
            icon: 'check',
            actionType: EnumAction.Confirm,
            action: () => openExport(checkListIds),
            buttonClass: 'info',
            align: 'center',
          },
          {
            name: 'Hủy',
            icon: 'clear',
            actionType: EnumAction.Cancel,
            buttonClass: 'info',
            align: 'center',
          },
        ];
        confirmModal.handlePresent(
          {
            question: message,
            listActionButton: listButton,
            postProcess: confirmModal.handleDismiss,
          },
          'TẠO HÓA ĐƠN VẬN CHUYỂN',
        );
      } else {
        openExport(checkListIds);
      }
    }
  }, [checkList, openExport, reloadFlag]);

  const onCreatePackage = useCallback(() => {
    if (checkList.length == 0) {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa chọn đơn vận chuyển',
        },
      });
    } else if (checkList.some(i => Object.prototype.hasOwnProperty.call(packageInfo, i.shipPackageId))) {
      addPopup({
        txn: {
          success: false,
          summary: 'Đơn vận chuyển được chọn đã có thông tin kiện',
        },
      });
    } else {
      shipPackageModal.handlePresent(
        {
          shipOrders: checkList,
          isDisable: false,
          postProcess: (data: ShipPackageType) => {
            const listButton: EventButton[] = [
              {
                name: 'Tiếp tục',
                icon: 'check',
                actionType: EnumAction.Confirm,
                action: () => window.open(`${BASE_ORDER_URL}/ship-package/${data.shipPackageId}/1`),
                buttonClass: 'info',
                align: 'center',
              },
              {
                name: 'Hủy',
                icon: 'clear',
                actionType: EnumAction.Cancel,
                buttonClass: 'info',
                align: 'center',
              },
            ];
            confirmModal.handlePresent(
              {
                question: "Đi tới thông tin kiện vừa tạo",
                listActionButton: listButton,
                postProcess: confirmModal.handleDismiss,
              },
              'XÁC NHẬN',
            );
            shipPackageModal.handleDismiss();
            setReloadFlag(!reloadFlag);
          },
        },
        'THÊM MỚI KIỆN',
      );
    }
  }, [checkList, reloadFlag]);

  const onExportShipOrderExcel = useCallback(() => {
    const ids = checkList.map(i => i.shipOrderId);
    if(ids.length > 0) {
      exportShipOrder(ids)
      .then((data) => {
        const productRealmTemplate = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = productRealmTemplate;
        tempLink.setAttribute('download', 'vanchuyen.xlsx');
        tempLink.click();
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
    } else {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: "Chưa chọn đơn export",
        },
      });
    }

  }, [checkList, reloadFlag]);

  const onExportManifest = useCallback(() => {
    const ids = checkList.map(i => i.shipOrderId);
    if(ids.length > 0) {
      exportManifest(ids)
      .then((data) => {
        manifestEditor.handlePresent({
          manifest: data,
          postProcess: manifestEditor.handleDismiss
        }, "DUYỆT MANIFEST")
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
    } else {
      addPopup({
        error: {
          title: 'Đã có lỗi xảy ra',
          message: "Chưa chọn đơn export",
        },
      });
    }

  }, [checkList, reloadFlag]);
  const listButton: EventButton[] = [
    {
      name: 'Cập nhật trạng thái',
      icon: 'sync_alt',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onUpdateShipOrderProgressStatus,
      align: 'center',
    },
    {
      name: 'Export',
      icon: 'file_download',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onExportShipOrderExcel,
      align: 'center',
    },
    {
      name: 'Export Manifest',
      icon: 'file_download',
      actionType: EnumAction.View,
      buttonClass: 'info200 tool-btn',
      action: onExportManifest,
      align: 'center',
    },
    // {
    //   name: 'Đã thanh toán',
    //   icon: 'paid',
    //   actionType: EnumAction.View,
    //   buttonClass: 'info300 tool-btn',
    //   action: () => {
    //     const ids = checkList.map(i => i.shipOrderId);
    //     if(ids.length > 0) {
    //       updateShipOrderPaid(ids)
    //       .then((data) => {
    //         slideBar.handleDismiss();
    //         setReloadFlag(!reloadFlag);
    //         addPopup({
    //           txn: {
    //             success: true,
    //             summary: 'Đã cập nhật trạng thái thanh toán thành công',
    //           },
    //         });
    //       })
    //       .catch((error) => {
    //         addPopup({
    //           error: {
    //             title: 'Đã có lỗi xảy ra',
    //             message: error.errorMessage,
    //           },
    //         });
    //       });
    //     } else {
    //       addPopup({
    //         error: {
    //           title: 'Đã có lỗi xảy ra',
    //           message: "Chưa chọn đơn cập nhật trạng thái thanh toán",
    //         },
    //       });
    //     }
    //   },
    //   align: 'center',
    // },
    {
      name: 'Cập nhật TK gửi',
      icon: 'sync',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onUpdateUser,
      align: 'center',
    },
    {
      name: 'Xuất hóa đơn',
      icon: 'payments',
      actionType: EnumAction.View,
      buttonClass: 'info100 tool-btn',
      action: onExportBill,
      align: 'center',
    },
    {
      name: 'Tạo kiện',
      icon: 'inventory_2',
      actionType: EnumAction.Add,
      buttonClass: 'info200 tool-btn',
      action: onCreatePackage,
      align: 'center',
    },
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onAddNewShipOrder,
      align: 'center',
    }
  ];

  const onFilter = (res: { [filterKey: string]: any }) => {
    setPage(1);
    if (res[CREATED_AT_FILTER] != undefined) {
      const value = res[CREATED_AT_FILTER] as string[];
      setMinDate(value.length > 0?value[0] : undefined);
      setMaxDate(value.length > 1?value[1] : undefined);
    } else {
      setMinDate(undefined);
      setMaxDate(undefined);
    }
    if (res[STATUS_FILTER] != undefined) {
      const status = res[STATUS_FILTER][STATUS_FIELD_FILTER];
      setProgressStatus(status);
    } else {
      setProgressStatus(undefined);
    }
    if (res[VNSHIPPER_FILTER] != undefined) {
      const vnShipper = res[VNSHIPPER_FILTER][VNSHIPPER_FIELD_FILTER];
      setVietnamShipper(vnShipper);
    } else {
      setVietnamShipper(undefined);
    }
    if (res[AGENT_FILTER] != undefined) {
      const receiverOrderId = res[AGENT_FILTER][AGENT_FIELD_FILTER];
      setReceiverOrderId(receiverOrderId);
    } else {
      setReceiverOrderId(undefined);
    }
    if (res[TRANSPORT_TYPE_FILTER] != undefined) {
      const transportType = res[TRANSPORT_TYPE_FILTER][TRANSPORT_TYPE_FIELD_FILTER];
      setTransportType(transportType);
    } else {
      setTransportType(undefined);
    }
    if (res[PAID_FILTER] != undefined) {
      const isPaid = res[PAID_FILTER][PAID_FIELD_FILTER];
      setPaid(isPaid);
    } else {
      setPaid(undefined);
    }
    if (res[USER_FILTER] != undefined) {
      const userId = res[USER_FILTER][USER_FIELD_FILTER];
      setUserId(userId);
    } else {
      setUserId(undefined);
    }
    if (res[INVOICE_FILTER] != undefined) {
      const shipInvoiceId = res[INVOICE_FILTER][INVOICE_FIELD_FILTER];
      setShipInvoiceId(shipInvoiceId);
    } else {
      setShipInvoiceId(undefined);
    }
  }

  //useEffect
  useEffect(() => {
    const api = role == RECEIVE? getReceiveShipOrder : getShipOrder;
    api(keywordDebound as string, page, size, progressStatus, minDate, maxDate, receiverOrderId, transportType, isPaid, userId, shipInvoiceId, vietnamShipper).then((data) => {
        setRawData(data.items);
        setCount(data.count);
        setExtraInfo(data.extraInfo);
        const nData: ShipOrderType[] = [];
        data.items.forEach((item) => {
          const checkIndx = checkList.findIndex((c) => c.shipOrderId == item.shipOrderId);
          if(checkIndx >= 0) {
            checkList[checkIndx] = item;
          } else {
            nData.push(item);
          }
        })

        setShipOrders(nData);
        setCheckList([...checkList]);
        const shipPackageIds = data.items.filter(i => i.shipPackageId > 0).map(i => i.shipPackageId);
        if(shipPackageIds.length > 0)
          getShipPackageInfo(shipPackageIds).then((res) => setPackageInfo(res));

      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
      });
  }, [addPopup, getShipOrder, keywordDebound, page, progressStatus, vietnamShipper, reloadFlag, size, minDate, maxDate, receiverOrderId, transportType, isPaid, userId, shipInvoiceId]);

  useEffect(() => {
    setCheckList([]);
    return () => {
      slideBar.handleDismiss();
    };
  }, []);

  return (
    <>
      <ToolBar
        toolbarName={role == ADMIN? 'DANH SÁCH ĐƠN VẬN CHUYỂN' : 'DANH SÁCH ĐƠN VẬN CHUYỂN TIẾP NHẬN'}
        toolBarKey='JVSADMIN_SHIPORDER'
        listRightButton={role == ADMIN? listButton : []}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        onSearch={(value) => setKeyword(value)}
        onChangePage={setPage}
        onChangeSize={setSize}
        keyword={keyword}
        page={page}
        size={size}
        isPaging={true}
        filters={filters}
        onFilter={onFilter}
      />

      {window.innerWidth > 600? <Table
          display={header}
          isInput={false}
          checkedData={checkList}
          data={shipOrders}
          menuContext={menuContext}
          allowCheckbox={true}
          onDoubleClick={(item) => onView(item.shipOrderId)}
          onClickCheckbox={onClickCheckbox}
          onClickCheckAll={onClickCheckAll}
          onClick={onClick}
          isNo={true}
        /> : <ListView
          display={display}
          isInput={false}
          data={shipOrders}
          onDoubleClick={(item) => onView(item.shipOrderId)}
          isNo={true}
        />}
        <div className='ship-order-bottom'>Cân nặng: {numberFormat(extraInfo?.weight / 1000)}  -   Tổng tiền: {number2vnmoney(extraInfo?.transportFee)}</div>
      </>

  );
};
export default ShipOrder;
