import React, { useState, useEffect } from 'react';
import { PaymentType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import './PaymentExport.css';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import useModal from 'src/hooks/useModal';
import PaymentDetail from 'src/views/Payment/PaymentDetail/PaymentDetail';
import Input from 'src/components/Input';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';

interface IPaymentExport {
  checkListIds: number[];
  exportApi?: (payment: PaymentType) => Promise<PaymentType>;
  postProcess?: (...args: any[]) => void;
}

const PaymentExport: React.FC<IPaymentExport> = (props) => {
  const {checkListIds, exportApi, postProcess} = props
  //State
  const [customerName, setCustomerName] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [expirationDate, setExpirationDate] = useState<string>();

  //Function
  const addPopup = useAddPopup();
  const detailModal = useModal(PaymentDetail);

  const onSave = () => new Promise((resolve) => {
    const body: PaymentType = {
      object: JSON.stringify(checkListIds),
      customerEmail: customerEmail,
      customerName: customerName,
      expirationDate: expirationDate,
    }
    exportApi(body)
      .then((res) => {
        detailModal.handlePresent({
          data: res,
          postProcess: () => {
            detailModal.handleDismiss();
            if(postProcess) postProcess();
          }
        }, "HÓA ĐƠN THANH TOÁN");

        resolve(true);
      }).catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra',
            message: error.errorMessage,
          },
        });
        resolve(false);
      });
  });

  //Main
  return (
    <div className="paymentexport-container">
      <div className="paymentexport-row">
        <Input
          width="360px"
          title="Tên khách hàng"
          require={false}
          disabled={false}
          value={customerName}
          onChange={setCustomerName}
        />
      </div>
      <div className="paymentexport-row">
        <Input
          width="360px"
          title="Email"
          require={false}
          disabled={false}
          value={customerEmail}
          onChange={setCustomerEmail}
        />
      </div>
      <div className="paymentexport-row">
        <CalendarInput title="Hạn thanh toán" width="360px" value={expirationDate} onChange={setExpirationDate} />
      </div>
      <ButtonComponent icon="grid_view" title={'XEM HÓA ĐƠN'} onClick={onSave} loader={true} />
    </div>
  );
};

export default PaymentExport;
