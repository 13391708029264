/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useRef } from 'react';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PageData } from 'src/api/models';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

interface ISelectSearch<T> {
  width?: string;
  height?: string;
  onChange: (value: T) => void;
  isDisable: boolean;
  require?: boolean;
  value: T;
  onLoad?: (...args: any[]) => Promise<PageData<T>>;
  list?: T[];
  placeholder?: string;
  valueField?: string;
  titleField?: string;
  blockUndefined?: boolean;
  error?: string;
  title?: string;
  undefinedTitle?: string;
  onBlur?: (...args: any[]) => void;
  convert?: (value: T) => string;
  warning?: boolean;
}

const SelectSearchComponent = <T,>(props: ISelectSearch<T>) => {
  const {
    width,
    height,
    require,
    onChange,
    value,
    isDisable,
    onLoad,
    placeholder,
    title,
    titleField,
    onBlur,
    list,
    convert,
    warning
  } = props;

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [data, setData] = useState<T[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [keywordDebound] = useDebounce(keyword, 1000);
  const [isShowOption, setShowOption] = useState(false);
  const optionRef = useRef<HTMLFieldSetElement>();
  const [hasMore, setHasMore] = useState(true);

  const handleClick = (event: MouseEvent) => {
    if (!optionRef?.current?.contains(event.target as Node)) {
      setShowOption(false);
    }
  };

  const change = (option: T) => {
    if(onChange) onChange(option);
  }

  useEffect(() => {
    if(value) {
      const v = titleField? eval(`value?.${titleField}`) : value;
      setKeyword(v);
    }
  }, [props])

  const fetchData = (reset: boolean) => {
    const cpage = reset ? 1 : page;
    onLoad(keywordDebound??"", cpage, size)
      .then((res) => {
        const nList = reset ? res.items : [...data, ...res.items];
        setData(nList);
        if (res.items.length < size) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage(cpage + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if(onLoad) {
      fetchData(true);
    } else {
      const nData = list.filter(item => {
        return Object.entries(item).some(([key, value]) => {
          const bOk = value?.toString().includes(keywordDebound??"");
          return bOk;
        });
      })
      setData(nData);
    }
  }, [keywordDebound, list])

  useEffect(() => {
    if (isShowOption) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }
    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, [isShowOption])

  return (
    <SelectSearchContainer width={width} ref={optionRef}>
      {title ? (
        <SelectSearchTitle>
          {title}
          {require ? <RequireSpan>*</RequireSpan> : null}
          {warning? <WarningSpan className='material-icons'>warning</WarningSpan> : null}
        </SelectSearchTitle>
      ) : null}
      <SearchContainer>
        <SelectBoxBase
          onFocus={() => setShowOption(true)}
          disabled={isDisable}
          placeholder={placeholder}
          width={width}
          spellCheck={false}
          onChange={(event) => {
            setKeyword((event.target as HTMLInputElement).value);
          }}
          value={keyword}
          onBlur={() => {;
            if(onBlur) onBlur();
          }}
          wrong={(titleField? eval(`value?.${titleField}`) : value) != keyword}
        />
        <OptionTitle className="material-icons" isDisable={isDisable} onClick={() => {if(!isDisable) setShowOption(!isShowOption)}}>arrow_drop_down</OptionTitle>
      </SearchContainer>
      {isShowOption && data.length > 0? <SearchOptionContainer id="contact-content">
        <InfiniteScroll
          dataLength={data.length}
          next={() => fetchData(false)}
          hasMore={hasMore}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
          scrollableTarget="contact-content"
          loader={<h4></h4>}
        >
          {data.map((option, index) => {
          return (
            <SearchOptionItem key={`selectboxoption${index}`}
              onClick={() => {
                change(option);
                setShowOption(false);
              }}
            >
              {convert? convert(option) : titleField ? eval(`option?.${titleField}`) : option}
            </SearchOptionItem>
          );
        })}
        </InfiniteScroll>
      </SearchOptionContainer> : null}
    </SelectSearchContainer>
  );
};

export default SelectSearchComponent;

const OptionTitle = styled.span<{isDisable?: boolean}>`
  vertical-align: middle;
  width: fit-content;
  ${({isDisable}) => isDisable? 'color: gray;' : ''}
`;

const SelectSearchContainer = styled.fieldset<{ width?: string }>`
  border: 1px solid #dddcdc;
  flex: 1;
  width: ${({ width }) => (width ? width : 'fit-content')};
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0px 10px;
  border-radius: 5px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectSearchTitle = styled.legend`
  font-size: medium;
  margin-bottom: -5px;
  padding: 0 5px 3px 5px;
  width: fit-content;
`;

const RequireSpan = styled.span`
  color: red;
  margin-left: 2px;
`;

const WarningSpan = styled.span`
  color: orange;
  margin: 0 5px;
  transform: translateY(5px);
`;

const SelectBoxBase = styled.input<{wrong: boolean}>`
  outline: none;
  border: none;
  width: 100%;
  padding: 3px;
  max-width: 80vw;
  background-color: transparent;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: textfield;
  font-weight: 400;
  ${({ wrong }) => (wrong ? 'color: red;' : '')}
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #dddcdc;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #dddcdc;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #dddcdc;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #dddcdc;
  }
`;

const SearchOptionContainer = styled.div`
  width: 100%;
  background: lightgray;
  cursor: pointer;
  margin-bottom: 3px;
  max-height: 100px;
  overflow: scroll;
`;

const SearchOptionItem = styled.div`
  background: #c1bfbf;
  width: 100%;
  margin-top: 1px;
  padding: 0 5px 5px 5px;
`;
