
import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { SpecialSurchargeType } from './models';

export const useGetSpecialSurcharge = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/special-surcharge`,
      method: 'get',
    }) as Promise<SpecialSurchargeType[]>;
  }, [fetch]);
};

export const useGetSpecialSurchargeByShip = () => {
  const fetch = useFetch();
  return useCallback((specialShipId: number) => {
    return fetch({
      url: `sccore/rest-api/special-surcharge/special-ship/${specialShipId}`,
      method: 'get',
    }) as Promise<SpecialSurchargeType[]>;
  }, [fetch]);
};

export const useGetSpecialSurchargeById = () => {
  const fetch = useFetch();
  return useCallback(
    (specialSurchargeId: number) => {
      return fetch({
        url: `sccore/rest-api/special-surcharge/${specialSurchargeId}`,
        method: 'get',
      }) as Promise<SpecialSurchargeType>;
    },
    [fetch],
  );
};

export const usePostSpecialSurcharge = () => {
  const fetch = useFetch();
  return useCallback(
    (specialSurcharge: SpecialSurchargeType) => {
      return fetch({
        url: `sccore/rest-api/special-surcharge`,
        method: 'post',
        data: specialSurcharge,
      }) as Promise<SpecialSurchargeType>;
    },
    [fetch],
  );
};

export const usePutSpecialSurcharge = () => {
  const fetch = useFetch();
  return useCallback(
    (specialSurcharge: SpecialSurchargeType) => {
      return fetch({
        url: `sccore/rest-api/special-surcharge`,
        method: 'put',
        data: specialSurcharge,
      }) as Promise<SpecialSurchargeType>;
    },
    [fetch],
  );
};

export const useDeleteSpecialSurcharge = () => {
  const fetch = useFetch();
  return useCallback(
    (specialSurchargeId: number) => {
      return fetch({
        url: `sccore/rest-api/special-surcharge/${specialSurchargeId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

//End of  SpecialSurcharge
