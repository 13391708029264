import React, { useState, useEffect } from 'react';
import { SpecialShipType, StoreType } from 'src/api/models';
import { useAddPopup, useReloadTable, useRemovePopup } from 'src/state/application/hooks';
import './SpecialShipDetail.css';
import { useGetSpecialShipById, usePostSpecialShip, usePutSpecialShip } from 'src/api/specialShipApi';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';


interface ISpecialShipDetail {
  specialShipId?: number;
  isDisable: boolean;
  postProcess?: (...args: any[]) => void;
}

const SpecialShipDetail: React.FC<ISpecialShipDetail> = (props) => {
  const [specialShipId, setSpecialShipId] = useState<number>();
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    setSpecialShipId(props.specialShipId);
    setIsDisable(props.isDisable);
  }, [props.isDisable, props.specialShipId]);


  const [specialShipName, setSpecialShipName] = useState<string>();
  const [specialShipNameError, setSpecialShipNameError] = useState<string>();
  const [unit, setUnit] = useState<string>();
  const [unitError, setUnitError] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();

  //End of state

  //Function
  const postSpecialShip = usePostSpecialShip();
  const putSpecialShip = usePutSpecialShip();
  const getSpecialShipById = useGetSpecialShipById();

  const addPopup = useAddPopup();

  //Validate
  const validateName = () => {
    if (!specialShipName || specialShipName == '') {
      setSpecialShipNameError('Chưa nhập tên mặt hàng đặc biệt');
      return false;
    } else {
      setSpecialShipNameError(undefined);
      return true;
    }
  };

  const validateUnit = () => {
    if (!unit || unit == '') {
      setUnitError('Chưa nhập đơn vị');
      return false;
    } else {
      setUnitError(undefined);
      return true;
    }
  };


  const onSave = () => new Promise((resolve, reject) => {
    if (validateName() && validateUnit()) {
      const specialShip: SpecialShipType = {
        specialShipId: specialShipId,
        specialShipName: specialShipName,
        unit: unit,
        displayOrder: displayOrder
      };
      const api = !specialShipId? postSpecialShip : putSpecialShip;
      api(specialShip).then(
        (res) => {
          setSpecialShipId(res.specialShipId);
          addPopup({
            txn: {
              success: true,
              summary: specialShipId? 'Sửa mặt hàng thành công' : 'Tạo mặt hàng thành công',
            },
          });
          if(props.postProcess) props.postProcess(res);
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
          reject(false);
        });
    } else {
      addPopup({
        txn: {
          success: false,
          summary: 'Chưa nhập đủ thông tin',
        },
      });
      reject(false);
    }
  });

  useEffect(() => {
    if (specialShipId) {
      getSpecialShipById(specialShipId)
        .then((res) => {
          setSpecialShipName(res.specialShipName);
          setUnit(res.unit);
          setDisplayOrder(res.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: {
              title: 'Đã có lỗi xảy ra',
              message: error.errorMessage,
            },
          });
        });
    }
  }, [addPopup, getSpecialShipById, specialShipId]);

  //Main
  return (
    <div className="special-ship-container">
      <div className='special-ship-row'>
        <Input
          title='Tên mặt hàng'
          require={true}
          disabled={isDisable}
          value={specialShipName}
          onChange={setSpecialShipName}
          error={specialShipNameError}
          onBlur={validateName}
        />
      </div>
      <div className='special-ship-row'>
        <Input
          title='Đơn vị'
          require={true}
          disabled={isDisable}
          value={unit}
          onChange={setUnit}
          error={unitError}
          onBlur={validateUnit}
        />
      </div>
      <div className='special-ship-row'>
        <Input
          title='Thứ tự'
          require={true}
          disabled={isDisable}
          value={displayOrder}
          onChange={setDisplayOrder}
        />
      </div>
      <div className='special-ship-row'>
        {isDisable ? null : <ButtonComponent icon='save' title={!specialShipId ? 'THÊM' : 'LƯU'} onClick={onSave} loader={true}/>}
      </div>
    </div>
  );
};

export default SpecialShipDetail;
