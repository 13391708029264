import React, { useEffect } from 'react';
import './Menu.css';
import { NavLink } from 'react-router-dom';
import ButtonNavLink from 'src/components/ButtonNavLink/ButtonNavLink';
import useProfile from 'src/hooks/useProfile';
import { useState } from 'react';
import { useAddPopup, useSetRole } from 'src/state/application/hooks';
import { EnumDisplayConfig, EnumShipOrderProgressStatus, FuncionActionType, FunctionType, RolePermisionType } from 'src/api/models';
import { useGetFunctionByUserId } from 'src/api/functionApi';
import { BASE_ORDER_URL, BASE_REPORT_URL, BASE_SETTING_URL, BASE_SIM_URL, BASE_WEB_URL } from 'src/constants';
import { ADMIN, RECEIVE, SALE } from 'src/common/constant/Constant';
import { useGetReport } from 'src/api/reportApi';


interface IButtonNavLink {
  link?: string;
  activeClass?: string;
  iconName?: string;
  name?: string;
}

interface IMenuButtonLevel1 {
  mainButton: IButtonNavLink;
  buttonList: IButtonNavLink[];
}

enum EnumLevel {
  level_1 = 1,
  level_2 = 2,
}

const Menu: React.FC = () => {

  //Value
  const profile = useProfile();
  //const notiCount = useGetNotiCount();

  //Local state
  const [isShowNav, setIsShowNav] = useState(false);
  const [isShowNavLv2, setIsShowNavLv2] = useState(false);
  const [level, setLevel] = useState<EnumLevel>(EnumLevel.level_1);

  const [buttonList, setButtonList] = useState<IButtonNavLink[]>(null);
  const [buttonLv1List, setButtonLv1List] = useState<IMenuButtonLevel1[]>([]);
  const [focusInput, setFocusInput] = useState(null);

  //Function
  const getFunctionByUserId = useGetFunctionByUserId();
  const getReport = useGetReport();
  const addPopup = useAddPopup();
  const setRole = useSetRole();

  const wrapperRef = React.createRef<HTMLDivElement>();

  //Component
  const menuLevel1 = () => {
    return (
      <div
        className={`menu-nav responsive mini-size${
          isShowNav && level == EnumLevel.level_1 ? ' on-mini-size' : ''
        }`}
        id="menu-nav"
      >
        <div
          className="btn-close-nav"
          onClick={() => {
            setIsShowNav(false);
            setIsShowNavLv2(false);
          }}
        >
          <i className="fa fa-chevron-circle-right"></i>
        </div>
        <div className="menu-nav-container">
          <div className="menu-nav-logo">
            <div className="nav-logo">
              <NavLink to="/">
                <div>
                  <img src={profile.info.avataUrl} />
                </div>
              </NavLink>
            </div>
            <div>{profile.info.fullName}</div>
          </div>
          <div className="menu-nav-body" id="menu-nav-body">
            {buttonLv1List
              ? buttonLv1List.map((value: IMenuButtonLevel1, index: number) => {
                  return value.buttonList.length > 0? (
                    <div
                      key={`menulv1button${index}`}
                      onClick={() => {
                        setButtonList(value.buttonList);
                        setFocusInput(index);
                        setLevel(EnumLevel.level_2);
                        setIsShowNavLv2(true);
                      }}
                    >
                      <ButtonNavLink
                        iconName={value.mainButton.iconName}
                        name={value.mainButton.name}
                        isFocus={focusInput == index}
                        level={1}
                      />
                    </div>
                  ) : <div
                    key={`menulv1button${index}`}
                    onClick={() => {
                      setFocusInput(index);
                    }}
                  >
                    <ButtonNavLink
                      link={value.mainButton.link}
                      iconName={value.mainButton.iconName}
                      name={value.mainButton.name}
                      isFocus={focusInput == index}
                      level={1}
                    />
                  </div>;
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  const menuLevel2 = () => {
    return (
      <div
        className={`menu-nav responsive mini-size${
          level == EnumLevel.level_2 && isShowNavLv2 ? ' on-mini-size' : ''
        }`}
        id="menu-nav-level-2"
      >
        <div
          className="btn-close-nav"
          onClick={() => {
            setIsShowNavLv2(false);
            setIsShowNav(false);

          }}
        >
          <i className="fa fa-chevron-circle-right"></i>
        </div>
        <div className="menu-nav-container">
          <div className="menu-nav-logo">
            <div
              className="btn-back-lv-1 text-medium"
              onClick={() => {
                setLevel(EnumLevel.level_1);
                setButtonList(null);
                setIsShowNav(true);
                setIsShowNavLv2(false);
              }}
            >
              <i className="fas fa-arrow-left"></i>
              <span style={{ marginLeft: 5 }}>Trở lại</span>
            </div>
          </div>
          <div className="menu-nav-body">
            {buttonList
              ? buttonList.map((value: IButtonNavLink, index: number) => {
                  return (
                    <div
                      key={`menulv2button${index}`}
                      onClick={() => {
                        setIsShowNavLv2(false);
                        setIsShowNav(false);

                      }}
                    >
                      <ButtonNavLink
                        link={value.link}
                        activeClass={value.activeClass}
                        iconName={value.iconName}
                        name={value.name}
                        buttonClass={'info100'}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  //End of component

  useEffect(() => {
    if (profile) {
      Promise.all([
        getFunctionByUserId(profile.info.userId),
        getReport()
      ]).then(([functionRes, reportRes]) => {
        const functionIds: number[] = [];
        const role: {[functionId: number]: FuncionActionType} = {};
        functionRes.forEach((item) => {
          if(!item.actions.Add && !item.actions.Confirm && !item.actions.Delete && !item.actions.Edit && !item.actions.View) return;
          if(Object.prototype.hasOwnProperty.call(role, item.functionId)) {
            role[item.functionId].View ||= item.actions.View;
            role[item.functionId].Add ||= item.actions.Add;
            role[item.functionId].Edit ||= item.actions.Edit;
            role[item.functionId].Delete ||= item.actions.Delete;
            role[item.functionId].Confirm ||= item.actions.Confirm;
          } else {
            role[item.functionId] = item.actions;
            functionIds.push(item.functionId);
          }
        })
        functionIds.sort();
        setRole(role);

        const webButtonList: IButtonNavLink[] = [];
        const shopButtonList: IButtonNavLink[] = [];
        const simButtonList: IButtonNavLink[] = [];
        const orderButtonList: IButtonNavLink[] = [];
        const accountButtonList: IButtonNavLink[] = [];
        const reportButtonList: IButtonNavLink[] = [];

        //menu lv1
        const mainWebButton: IButtonNavLink = {
          link: BASE_WEB_URL,
          activeClass: 'focus',
          iconName: 'public',
          name: 'Website JVS',
        };
        const mainShopButton: IButtonNavLink = {
          link: BASE_WEB_URL,
          activeClass: 'focus',
          iconName: 'store',
          name: 'Đồ cũ',
        };
        const mainSimButton: IButtonNavLink = {
          link: BASE_SIM_URL,
          activeClass: 'focus',
          iconName: 'sim_card',
          name: 'Viễn thông',
        };
        const mainOrderButton: IButtonNavLink = {
          link: BASE_ORDER_URL,
          activeClass: 'focus',
          iconName: 'local_shipping',
          name: 'Vận chuyển & mua hộ',
        };
        const mainAccountButton: IButtonNavLink = {
          link: BASE_SETTING_URL,
          activeClass: 'focus',
          iconName: 'settings',
          name: 'Hệ thống',
        };
        const mainReportButton: IButtonNavLink = {
          link: BASE_REPORT_URL,
          activeClass: 'focus',
          iconName: 'summarize',
          name: 'Báo cáo',
        };
        reportButtonList.push({
          link: `${BASE_REPORT_URL}/invoice`,
          activeClass: 'focus',
          iconName: 'inventory',
          name: 'Báo cáo chuyến hàng',
        });
        reportButtonList.push({
          link: `${BASE_REPORT_URL}`,
          activeClass: 'focus',
          iconName: 'settings',
          name: 'Báo cáo',
        });

        reportRes.forEach((report) => {
          reportButtonList.push({
            link: `${BASE_REPORT_URL}/data/${report.reportId}`,
            activeClass: 'focus',
            iconName: 'inventory',
            name: report.reportTitle,
          });
        })

        // menu lv2
        webButtonList.push({
          link: BASE_WEB_URL + '/menu',
          activeClass: 'focus',
          iconName: 'menu',
          name: 'Menu hiển thị',
        });
        functionIds.forEach((fId) => {
          if (fId == EnumDisplayConfig.Loai_san_pham) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/realm`,
              activeClass: 'focus',
              iconName: 'inventory_2',
              name: 'Loại sản phẩm',
            });
          } else if (fId == EnumDisplayConfig.Danh_muc_san_pham) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/category`,
              activeClass: 'focus',
              iconName: 'category',
              name: 'Danh mục sản phẩm',
            });
          } else if (fId == EnumDisplayConfig.San_pham) {
            shopButtonList.push({
              link: `${BASE_WEB_URL}/product`,
              activeClass: 'focus',
              iconName: 'laptop',
              name: 'Sản phẩm',
            });
            shopButtonList.push({
              link: `${BASE_WEB_URL}/inventory`,
              activeClass: 'focus',
              iconName: 'inventory_2',
              name: 'Xuất nhập kho',
            });
          } else if (fId == EnumDisplayConfig.Dich_vu) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/service`,
              activeClass: 'focus',
              iconName: 'wifi_tethering',
              name: 'Dịch vụ',
            });
          } else if (fId == EnumDisplayConfig.Banner) {
            webButtonList.push({
              link: BASE_WEB_URL + '/banner',
              activeClass: 'focus',
              iconName: 'collections',
              name: 'Banner',
            });
          } else if (fId == EnumDisplayConfig.AdvertisingBanner) {
            webButtonList.push({
              link: BASE_WEB_URL + '/advertising-banner',
              activeClass: 'focus',
              iconName: 'collections',
              name: 'Ảnh quảng cáo',
            });
          } else if (fId == EnumDisplayConfig.Logo) {
            webButtonList.push({
              link: BASE_WEB_URL + '/logo',
              activeClass: 'focus',
              iconName: 'collections',
              name: 'Logo',
            });
          } else if (fId == EnumDisplayConfig.Thong_tin) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/info`,
              activeClass: 'focus',
              iconName: 'feed',
              name: 'Thông tin',
            });
          } else if (fId == EnumDisplayConfig.Hinh_anh_cong_ty) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/company-image`,
              activeClass: 'focus',
              iconName: 'image',
              name: 'Hình ảnh công ty',
            });
          } else if (fId == EnumDisplayConfig.Trang_thong_tin_tinh) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/static-page`,
              activeClass: 'focus',
              iconName: 'feed',
              name: 'Trang thông tin',
            });
          } else if (fId == EnumDisplayConfig.Danh_sach_dat_hang) {
            shopButtonList.push({
              link: `${BASE_WEB_URL}/order-requirement/admin`,
              activeClass: 'focus',
              iconName: 'local_mall',
              name: 'Danh sách đặt hàng',
            });
            shopButtonList.push({
              link: `${BASE_WEB_URL}/stock-inspection`,
              activeClass: 'focus',
              iconName: 'warehouse',
              name: 'Kiểm kho',
            });
            shopButtonList.push({
              link: `${BASE_WEB_URL}/export-bill`,
              activeClass: 'focus',
              iconName: 'qr_code_scanner',
              name: 'Quét xuất hàng',
            });
          } else if (fId == EnumDisplayConfig.MyOrder) {
            // shopButtonList.push({
            //   link: `${BASE_WEB_URL}/order-requirement/${SALE}`,
            //   activeClass: 'focus',
            //   iconName: 'shopping_cart',
            //   name: 'Đặt hàng của tôi',
            // });
          } else if (fId == EnumDisplayConfig.Tin_tuc) {
            webButtonList.push({
              link: `${BASE_WEB_URL}/news`,
              activeClass: 'focus',
              iconName: 'feed',
              name: 'Tin tức',
            });
          } else if (fId == EnumDisplayConfig.Sim_order) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/sim-order`,
              activeClass: 'focus',
              iconName: 'sim_card',
              name: 'Danh sách đơn sim',
            });
          } else if (fId == EnumDisplayConfig.Sim_da_ban) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/sim`,
              activeClass: 'focus',
              iconName: 'sim_card_download',
              name: 'Danh sách sim đã bán',
            });
          } else if (fId == EnumDisplayConfig.Lich_su_bang_gia) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/sim-price`,
              activeClass: 'focus',
              iconName: 'request_quote',
              name: 'Bảng giá',
            });
            simButtonList.push({
              link: `${BASE_SIM_URL}/sim-store`,
              activeClass: 'focus',
              iconName: 'storefront',
              name: 'Danh sách nguồn sim',
            });
            simButtonList.push({
              link: `${BASE_SIM_URL}/monthly-subscription-fee`,
              activeClass: 'focus',
              iconName: 'currency_bitcoin',
              name: 'Cước hàng tháng',
            });
          } else if (fId == EnumDisplayConfig.Quan_ly_tai_khoan) {
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/user`,
              activeClass: 'focus',
              iconName: 'face',
              name: 'Danh sách tài khoản',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/account-balance-list`,
              activeClass: 'focus',
              iconName: 'paid',
              name: 'Danh sách tài khoản tiền',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/customer-type`,
              activeClass: 'focus',
              iconName: 'airline_seat_recline_extra',
              name: 'Danh sách loại khách hàng',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/user-customer-type`,
              activeClass: 'focus',
              iconName: 'support_agent',
              name: 'Quản lý loại khách hàng',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/receiver-info`,
              activeClass: 'focus',
              iconName: 'contact_mail',
              name: 'Địa chỉ nhận hàng',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/store`,
              activeClass: 'focus',
              iconName: 'storefront',
              name: 'Địa chỉ kho',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/special-ship`,
              activeClass: 'focus',
              iconName: 'local_shipping',
              name: 'Vận chuyển đặc biệt',
            });
          } else if (fId == EnumDisplayConfig.Cau_hinh_phan_quyen) {
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/api`,
              activeClass: 'focus',
              iconName: 'webhook',
              name: 'API',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/module`,
              activeClass: 'focus',
              iconName: 'view_module',
              name: 'Danh sách module',
            });
          } else if (fId == EnumDisplayConfig.Phan_quyen) {
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/role`,
              activeClass: 'focus',
              iconName: 'rule',
              name: 'Danh sách nhóm quyền',
            });
          } else if (fId == EnumDisplayConfig.Mau_email) {
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/email-template`,
              activeClass: 'focus',
              iconName: 'email',
              name: 'Mẫu email',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/notification-template`,
              activeClass: 'focus',
              iconName: 'notifications',
              name: 'Mẫu thông báo',
            });
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/parameter`,
              activeClass: 'focus',
              iconName: 'settings',
              name: 'Thiết lập hệ thống',
            });
          } else if (fId == EnumDisplayConfig.PrintedTemplate) {
            accountButtonList.push({
              link: `${BASE_SETTING_URL}/printed-template`,
              activeClass: 'focus',
              iconName: 'print',
              name: 'Mẫu in',
            });
          } else if (fId == EnumDisplayConfig.ShipInvoice) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-invoice`,
              activeClass: 'focus',
              iconName: 'flight',
              name: 'Chuyến hàng',
            });
          } else if (fId == EnumDisplayConfig.ShipPackage) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-package`,
              activeClass: 'focus',
              iconName: 'inventory_2',
              name: 'Kiện hàng',
            });
          } else if (fId == EnumDisplayConfig.ShipOrder) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order/${ADMIN}`,
              activeClass: 'focus',
              iconName: 'local_shipping',
              name: 'Đơn vận chuyển',
            });
          } else if (fId == EnumDisplayConfig.ShipOrderDeclaration) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order-declaration`,
              activeClass: 'focus',
              iconName: 'app_registration',
              name: 'Khai báo hàng hóa',
            });
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/manifest`,
              activeClass: 'focus',
              iconName: 'drag_indicator',
              name: 'Manifest',
            });
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/manifest-printer`,
              activeClass: 'focus',
              iconName: 'print',
              name: 'In nhãn',
            });
          } else if (fId == EnumDisplayConfig.ImportJapanStock) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order-scanner/${EnumShipOrderProgressStatus.JapanStore}`,
              activeClass: 'focus',
              iconName: 'qr_code_scanner',
              name: 'Nhập kho Nhật Bản',
            });
          } else if (fId == EnumDisplayConfig.ImportVNStock) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order-scanner/${EnumShipOrderProgressStatus.VietNamStore}`,
              activeClass: 'focus',
              iconName: 'qr_code_scanner',
              name: 'Nhập kho Việt Nam',
            });
          } else if (fId == EnumDisplayConfig.SentToCustomer) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order-scanner/${EnumShipOrderProgressStatus.Contact}`,
              activeClass: 'focus',
              iconName: 'qr_code_scanner',
              name: 'Gửi hàng cho khách',
            });
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/ship-order-scanner/${EnumShipOrderProgressStatus.Finish}`,
              activeClass: 'focus',
              iconName: 'qr_code_scanner',
              name: 'Hoàn thành',
            });
          } else if (fId == EnumDisplayConfig.ShipAgent) {
            orderButtonList.push({
              link: `${BASE_SETTING_URL}/transfer-agent`,
              activeClass: 'focus',
              iconName: 'home_work',
              name: 'Đại lý vận chuyển',
            });
          } else if (fId == EnumDisplayConfig.ShipCollatorabor) {
            orderButtonList.push({
              link: `${BASE_SETTING_URL}/sales-collatorabor`,
              activeClass: 'focus',
              iconName: 'workspaces',
              name: 'CTV vận chuyển',
            });
          } else if (fId == EnumDisplayConfig.Payment) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/payment`,
              activeClass: 'focus',
              iconName: 'payments',
              name: 'Hóa đơn thanh toán',
            });
          } else if (fId == EnumDisplayConfig.Disbursement) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/disbursement`,
              activeClass: 'focus',
              iconName: 'paid',
              name: 'Phiếu chi',
            });
          } else if (fId == EnumDisplayConfig.CustomerDebt) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/customer-debt`,
              activeClass: 'focus',
              iconName: 'receipt_long',
              name: 'Ghi nợ khách hàng',
            });
          // } else if (fId == EnumDisplayConfig.ReceiverShipOrder) {
            // const temp = {
            //   link: `${BASE_ORDER_URL}/receiver-ship-order`,
            //   activeClass: 'focus',
            //   iconName: 'check_circle',
            //   name: 'Tiếp nhận vận chuyển',
            // };
            // const temp1 = {
            //   link: `${BASE_ORDER_URL}/ship-order/${RECEIVE}`,
            //   activeClass: 'focus',
            //   iconName: 'list',
            //   name: 'Đơn v.chuyển tiếp nhận',
            // };
          } else if (fId == EnumDisplayConfig.ProductOrder) {
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/product-order`,
              activeClass: 'focus',
              iconName: 'phone_iphone',
              name: 'Đơn mua hộ',
            });
            orderButtonList.push({
              link: `${BASE_ORDER_URL}/purchase-account`,
              activeClass: 'focus',
              iconName: 'account_balance_wallet',
              name: 'DS tài khoản mua hộ',
            });
          } else if (fId == EnumDisplayConfig.SendSimEmail) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/list-email`,
              activeClass: 'focus',
              iconName: 'send',
              name: 'Danh sách email đã gửi',
            });
          } else if (fId == EnumDisplayConfig.SimGuide) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/guide-page`,
              activeClass: 'focus',
              iconName: 'lightbulb',
              name: 'Hướng dẫn',
            });
          } else if (fId == EnumDisplayConfig.SimNew) {
            simButtonList.push({
              link: `${BASE_SIM_URL}/sim-news`,
              activeClass: 'focus',
              iconName: 'feed',
              name: 'Tin tức sim',
            });
          }

          setButtonLv1List([
            {
              mainButton: mainWebButton,
              buttonList: webButtonList,
            },
            {
              mainButton: mainShopButton,
              buttonList: shopButtonList,
            },
            {
              mainButton: mainSimButton,
              buttonList: simButtonList,
            },
            {
              mainButton: mainOrderButton,
              buttonList: orderButtonList,
            },
            {
              mainButton: mainAccountButton,
              buttonList: accountButtonList,
            },
            {
              mainButton: mainReportButton,
              buttonList: reportButtonList,
            },
            {
              mainButton: {
                link: `shortlink`,
                activeClass: 'focus',
                iconName: 'app_registration',
                name: 'Link rút gọn',
              },
              buttonList: [],
            },
          ]);
        })
      })
      .catch((error) => {
        addPopup({
          error: {
            title: 'Đã có lỗi xảy ra!',
            message: error.errorMessage,
          },
        });
      });
    }
  }, [addPopup, getFunctionByUserId, profile]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        wrapperRef !== null &&
        !wrapperRef?.current?.contains(event.target as Node)
      ) {
        setIsShowNav(false);
        setIsShowNavLv2(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [wrapperRef]);


 useEffect(() => {
    const onKeyboard = (event: KeyboardEvent) => {
      if (event.code === 'KeyZ' && event.ctrlKey) {
        setIsShowNav(!isShowNav);
        setIsShowNavLv2(!isShowNavLv2);
      }
    };
    document.addEventListener('keydown', onKeyboard);
    return () => {
      document.removeEventListener('keydown', onKeyboard);
    };
  }, [isShowNav, isShowNavLv2]);

  //Main
  return (
    profile && <div className="container-fluid" ref={wrapperRef}>
      <div className="row justify-content-center">
        {menuLevel1()}
        {menuLevel2()}
        <div
          className={`btn-mini-size responsive mini-size${isShowNav ? ' on-mini-size' : ''}`}
          id="btn-show-nav"
          onClick={() => {
            setIsShowNav(true);
            setIsShowNavLv2(true);

          }}
        >
          <i className="fa fa-chevron-circle-right"/>
        </div>
      </div>
    </div>
  );
};

export default Menu;
