import React, { useEffect, useState } from 'react';
import { useGetRoomBadge } from 'src/api/roomApi';
import { useGetReloadChatlg } from 'src/state/application/hooks';
import ChatList from '../ChatList/ChatList';

const ChatIcon: React.FC = () => {
  const [iconX, setIconX] = useState(0);
  const [iconY, setIconY] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [badge, setBadge] = useState(0);
  const getRoomBadge = useGetRoomBadge();
  const reloadChatlg = useGetReloadChatlg();
  const onHiddenPopup = () => {
    setIsShow(false);
  };

  useEffect(() => {
    getRoomBadge()
      .then((badge) => {
        setBadge(badge);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reloadChatlg, getRoomBadge, setBadge]);

  //Main
  return (
    <div className="base-head-icon">
      <i
        className="fas fa-comment"
        style={{ fontSize: 20 }}
        onClick={() => setIsShow(!isShow)}
        title="Trò chuyện"
        id="btn-show-chat"
        ref={(el) => {
          if (el) {
            const boundingClient = el.getBoundingClientRect();
            setIconX(boundingClient.left);
            setIconY(boundingClient.y + boundingClient.height);
          }
        }}
      ></i>
      {badge > 0 ? <div className="badge">{badge}</div> : null}
      {isShow ? <ChatList iconX={iconX} iconY={iconY} onHiddenPopup={onHiddenPopup} /> : null}
    </div>
  );
};
export default ChatIcon;
