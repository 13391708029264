/* eslint-disable react-hooks/exhaustive-deps */
import './ShortLink.css';
import { MouseEvent } from 'react';
import { EnumAction, EnumDataType, EventButton, ShortLinkType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import {
  useGetShortLinks,
  useDeleteShortLink,
  useGenerateFile,
} from 'src/api/shortlinkApi';
import useModal from 'src/hooks/useModal';
import { useAddPopup } from 'src/state/application/hooks';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import ShortLinkDetail from './ShortlinkDetail/ShortLinkDetail';

const ShortLink: React.FC = () => {
  //Function

  const addPopup = useAddPopup();
  const getAllShortLinks = useGetShortLinks();
  const deleteShortLink = useDeleteShortLink();
  const generateFile = useGenerateFile();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [items, setItems] = useState<ShortLinkType[]>([]);


  const detailModal = useModal(ShortLinkDetail);
  const confirmModal = useModal(ConfirmModal);

  const display = {
    header: [
      {
        code: 'shortlink',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Mã',
      },
      {
        code: 'href',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Link',
      },
      {
        code: 'shortlink',
        dataType: EnumDataType.HTML,
        isOptions: false,
        title: 'Link rút gọn',
        convert: (value: string) => {
          return (<a href={`http://doc.jvscorp.jp/${value}`}>{`http://doc.jvscorp.jp/${value}`}</a>);
        },
      },
    ] as TableColumnType[],
  };

  useEffect(() => {
    getAllShortLinks()
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [getAllShortLinks, reloadFlag, addPopup]);

  //ShortLinkcontext
  const onView = (shortlink: ShortLinkType) => {
    detailModal.handlePresent(
      {
        shortlink: shortlink.shortlink,
        href: shortlink.href,
        isDisable: true,
      },
      'CHI TIẾT',
    );
  };

  const onEdit = (shortlink: ShortLinkType) => {
    detailModal.handlePresent(
      {
        shortlink: shortlink.shortlink,
        href: shortlink.href,
        isDisable: false,
        postProcess: (data: ShortLinkType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.shortlink);
        },
      },
      'THAY ĐỔI',
    );
  };

  const onDelete = (shortlink: string) => {
    const onConfirm = () => {
      deleteShortLink(shortlink)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa bản dịch thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa đường dẫn rút gọn này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA ĐƯỜNG DẪN RÚT GỌN',
    );
  };

  const menuContext = (item: ShortLinkType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => onView(item),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => onEdit(item),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.shortlink),
      buttonClass: 'info',
      align: '',
    },
  ];

  //Function in the listButton
  const onAddShortLinkNew = (e: MouseEvent) => {
    detailModal.handlePresent(
      {
        isDisable: false,
        postProcess: (data: ShortLinkType) => {
          detailModal.handleDismiss();
          setReloadFlag(!reloadFlag);
          onView(data.shortlink);
        },
      },
      'THÊM MỚI',
    );
  };

  //Toolbar
  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.Add,
      buttonClass: 'info sim-order-tool-btn',
      action: onAddShortLinkNew,
      align: 'center',
    },

    {
      name: 'Tạo file',
      icon: 'cloud_upload',
      actionType: EnumAction.Add,
      buttonClass: 'info100 sim-order-tool-btn',
      action: () => {
        generateFile()
          .then((res) => {
            addPopup({
              txn: {
                success: true,
                summary: 'Tạo file dịch thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra!',
              },
            });
          });
      },
      align: 'center',
    },
  ];

  return (
    <>
      <ToolBar
        toolbarName={'LINK RÚT GỌN'}
        listRightButton={listButton}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={items.length}
        isPaging={false}
      />

      <Table
        display={display}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item)}
      />
    </>
  );
};

export default ShortLink;
