import './Inventory.css';
import React from 'react';
import { MouseEvent } from 'react';
import {
  useAddPopup,
} from 'src/state/application/hooks';
import {
  EnumAction,
  EnumDataType,
  EnumInventoryType,
  EventButton,
  InventoryType,
} from 'src/api/models';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useParams } from 'react-router-dom';
import { useGetProductById, useGetProductByIds } from 'src/api/productApi';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { useDeleteInventory, useGetInventories } from 'src/api/inventoryApi';
import InventoryDetail from './InventoryDetail/InventoryDetail';
import { BASE_WEB_URL } from 'src/constants';
import { ADMIN } from 'src/common/constant/Constant';
import { EnumViewType } from 'src/common/enum/EnumViewType';


const Inventory: React.FC = () => {
  //Value
  const params = useParams<{ productId: string }>();
  const [productId, setProductId] = useState<number>();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState<number>(0);
  const [status, setStatus] = useState<number>();
  const [reloadFlag, setReloadFlag] = useState(false);

  
  const InventoryStatus = [
    {
      value: 0,
      title: 'Đang xử lý',
      css: { color: 'yellow' },
    },
    {
      value: 1,
      title: 'Hoàn thành',
      css: { color: 'green' },
    },
    
  ];

  useEffect(() => {
    setProductId(Number(params.productId));
  }, [params, params.productId])


  const [header, setHeader] = useState<TableColumnType[]>([
    {
      code: 'effectiveDate',
      dataType: EnumDataType.Date,
      isOptions: false,
      title: 'Ngày xuất/nhập',
    },
    {
      code: 'inventoryType',
      dataType: EnumDataType.Int,
      isOptions: true,
      title: 'Loại phiếu',
      options: [
        {
          title: 'Nhập kho',
          value: EnumInventoryType.Input,
          css: {
            color: 'blue',
          },
        },
        {
          title: 'Xuất kho',
          value: EnumInventoryType.Output,
          css: {
            color: 'red',
          },
        },
      ],
    },
    {
      code: 'productCode',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Mã SP',
    },
    {
      code: 'productName',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Tên SP',
    },
    {
      code: 'quantity',
      dataType: EnumDataType.BigInt,
      isOptions: false,
      title: 'Số lượng',
    },
    {
      code: 'status',
      dataType: EnumDataType.Int,
      isOptions: true,
      title: 'Trạng thái',
      options: InventoryStatus
    },
    {
      code: 'orderId',
      dataType: EnumDataType.HTML,
      isOptions: false,
      title: 'Đơn hàng',
      convert: (value: number) => {
        return value? <a className={'label-table'} href={`${BASE_WEB_URL}/order-requirement/${ADMIN}/${value}/${EnumViewType.View}`} >{value}</a> : null;
      },
    },
    {
      code: 'note',
      dataType: EnumDataType.Text,
      isOptions: false,
      title: 'Ghi chú',
    },
  ]);

  

  //Function
  const addPopup = useAddPopup();
  const getProduct = useGetProductById();
  const getInventories = useGetInventories();
  const deleteInventory = useDeleteInventory();
  const getProductByIds = useGetProductByIds();

  const confirmModal = useModal(ConfirmModal);
  const detailModal = useModal(InventoryDetail);

  useEffect(() => {
    getInventories(page, size, productId, status).then((data) => {
      setCount(data.count);
      const ids = data.items.map(i => i.productId);
      getProductByIds(ids).then((res) => {
        data.items.forEach((i) => {
          const product = res.find(p => p.productId == i.productId);
          i.productCode = product?.productCode;
          i.productName = product?.productName;
        });
        setItems(data.items);
      })
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [reloadFlag, productId, page, size, getInventories, addPopup, getProduct]);

  const menuContext = (item: InventoryType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.View,
      action: () => onView(item.inventoryId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Delete,
      action: () => onDelete(item.inventoryId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const onView = (inventoryId: number) => {
    detailModal.handlePresent({
      inventoryId: inventoryId,
      isDisable: true
    }, "PHIẾU XUẤT / NHẬP KHO")
  };

  const onDelete = (inventoryId: number) => {
    const onConfirm = () => {
      deleteInventory(inventoryId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa phiếu thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn phiếu này?',
        listActionButton: listToDo,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA PHIẾU XUẤT / NHẬP KHO',
    );
  };


  //Function in the listButton
  const onAddInventory = (e: MouseEvent) => {
    detailModal.handlePresent({
      isDisable: false,
      postProcess: (data: InventoryType) => {
        detailModal.handleDismiss();
        setReloadFlag(!reloadFlag);
        onView(data.inventoryId);
      },
    }, "THÊM PHIẾU XUẤT / NHẬP KHO")
  };


  // List of buttons in toolbar
  const listButtonRight: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info tool-btn',
      action: onAddInventory,
      align: 'center',
    }
  ];


  //Main
  return (
    <>
      <ToolBar
        toolbarName={`Xuất/nhập kho`}
        listRightButton={listButtonRight}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        count={count}
        isBack={true}
        page={page}
        size={size}
        isPaging={true}
        onChangePage={setPage}
        onChangeSize={setSize}
      />
      <Table
        display={{
          header: header
        }}
        isInput={false}
        data={items}
        menuContext={menuContext}
        onDoubleClick={(item) => onView(item.inventoryId)}
        isNo={true}
      />
    </>
  );
};

export default Inventory;
