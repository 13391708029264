import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, ShortLinkType } from './models';

/***********************************/
export const useGetShortLinks = () => {
  const fetch = useFetch();
  return useCallback(
    () => {
      return fetch({
        url: `sccore/rest-api/shortlink`,
        method: 'get',
      }) as Promise<ShortLinkType[]>;
    },
    [fetch],
  );
};

export const usePostShortLink = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ShortLinkType) => {
      return fetch({
        url: 'sccore/rest-api/shortlink',
        method: 'post',
        data: data,
      }) as Promise<ShortLinkType>;
    },
    [fetch],
  );
};

export const useGetShortLinkById = () => {
  const fetch = useFetch();
  return useCallback(
    (shortLink: string) => {
      return fetch({
        url: `sccore/rest-api/shortlink/${shortLink}`,
        method: 'get',
      }) as Promise<ShortLinkType>;
    },
    [fetch],
  );
};

export const usePutShortLink = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ShortLinkType) => {
      return fetch({
        url: `sccore/rest-api/shortlink`,
        method: 'put',
        data: data,
      }) as Promise<ShortLinkType>;
    },
    [fetch],
  );
};

export const useDeleteShortLink = () => {
  const fetch = useFetch();
  return useCallback(
    (shortlink: string) => {
      return fetch({
        url: `sccore/rest-api/shortlink/${shortlink}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};

export const useGenerateFile = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `sccore/rest-api/shortlink/generate`,
      method: 'get',
    }) as Promise<boolean>;
  }, [fetch]);
};
