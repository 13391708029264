import './StaticPageDetails.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup } from 'src/state/application/hooks';
import { useNavigate , useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { EnumAction, EventButton } from 'src/api/models';
import MonacoEditor, { Monaco, OnMount } from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import Tabs from 'src/components/Tabs';
import {
  useGetStaticPageById,
  usePostStaticPage,
  usePutStaticPage,
} from 'src/api/staticPageApi';
import { BASE_WEB_URL } from 'src/constants';
import useModal from 'src/hooks/useModal';
import OtherImageUpload from 'src/components/OtherImageUpload';

enum TabKey {
  Content,
  SourceCode,
}

const StaticPageDetails: React.FC = () => {
  //Function
  const getStaticPageById = useGetStaticPageById();
  const postStaticPage = usePostStaticPage();
  const putStaticPage = usePutStaticPage();
  const addPopup = useAddPopup();

  const imageModal = useModal(OtherImageUpload);

  //Value
  const navigate = useNavigate();
  const tinyEditorRef = useRef(null);
  const params = useParams<{ type: string; staticPageId: string }>();

  //State
  const [key, setKey] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [value, setValue] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>();
  //State
  const [tab, setTab] = useState(TabKey.Content);

  const [staticPageId, setStaticPageId] = useState(
    params?.staticPageId ? Number(params.staticPageId) : undefined,
  );

  const editorRef = useRef(null);

  //Value
  const isAdd = Number(params.type) === EnumViewType.Edit && !(staticPageId > 0);
  const isDisable = Number(params.type) == EnumViewType.View;

  useEffect(() => {
    if (!isAdd) {
      getStaticPageById(staticPageId).then((data) => {
        setKey(data.staticPageKey);
        setTitle(data.staticPageTitle);
        setValue(data.staticPageValue);
        setDescription(data.description);
        setDisplayOrder(data.displayOrder);
      }).catch((error) => {
          addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
    }
  }, [addPopup, getStaticPageById, isAdd, staticPageId]);

  const handleEditorDidMount: OnMount = (editor: any, monaco: Monaco) => {
    editorRef.current = editor;
  }

  const onSave = useCallback(() => {
    const pageValue = tab == TabKey.SourceCode ? editorRef.current.getValue() : tinyEditorRef.current.getContent();
    const api = isAdd? postStaticPage(key, title, pageValue, description, displayOrder) : putStaticPage(staticPageId, key, title, pageValue, description, displayOrder);

    api.then((r) => {
      setStaticPageId(r.staticPageId);
      setValue(r.staticPageValue);
      addPopup({
        txn: {
          success: true,
          summary: isAdd? 'Thêm thành công!' : 'Sửa thành công!',
        },
      });
    })
    .catch((error) => {
      addPopup({
        error: {
          message: error.errorMessage,
          title: 'Đã có lỗi xảy ra!',
        },
      });
    });
  }, [tab, isAdd, postStaticPage, key, title, description, displayOrder, putStaticPage, staticPageId, addPopup]);

  const listEditToolButton: EventButton[] = [
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: onSave,
      align: 'center',
    },
    {
      name: 'D.sách ảnh',
      icon: 'image',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-page-tool-btn cloud_upload-btn',
      action: () => {
        imageModal.handlePresent({}, 'DANH SÁCH ẢNH')
      },
      align: 'center',
    }
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: () => navigate(`${BASE_WEB_URL}/static-page/${EnumViewType.Edit}/id/${staticPageId}`),
      align: 'center',
    }
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        if (tab == TabKey.SourceCode) {
          const content = editorRef.current.getValue();
          setValue(content);
        }
        setTab(TabKey.Content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        if (tab == TabKey.Content) {
          const content = tinyEditorRef.current.getContent();
          setValue(content);
        }
        setTab(TabKey.SourceCode);
      },
    },
  ];

  return (
    <>
      <ToolBar
        toolbarName={'Trang thông tin'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        isBack={true}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />

      <div className='page-content'>
        <div className='page-content-info'>
          <div className='page-content-input'>
            <Input
              title='Từ khóa'
              require={true}
              disabled={isDisable}
              value={key}
              onChange={setKey}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Tiêu đề'
              require={true}
              disabled={isDisable}
              value={title}
              onChange={setTitle}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Thứ tự'
              require={true}
              disabled={isDisable}
              value={displayOrder}
              onChange={setDisplayOrder}
            />
          </div>
          <div className='page-content-input'>
            <Input
              title='Mô tả'
              require={true}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>

        </div>

        <Tabs activeTab={tab} tabs={tabs} />

        {tab == TabKey.SourceCode ? (
          <MonacoEditor
            height="690px"
            defaultLanguage="html"
            value={value}
            onMount={handleEditorDidMount}
            options={{
              readOnly: isDisable,
            }}
          />
        ) : (
          <Editor
            disabled={isDisable}
            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
            initialValue={value}
            init={{
              min_height: 690,
              menubar: true,
              plugins: ['preview', 'lists', 'advlist', 'anchor', 'link', 'autolink', 'autoresize', 'charmap', 'code', 'codesample',
              'directionality', 'emoticons', 'fullscreen', 'image', 'insertdatetime', 'media', 'nonbreaking', 'pagebreak', 'quickbars',
              'searchreplace', 'table', 'visualblocks', 'visualchars', 'wordcount', 'help'],
              toolbar:
                'preview | undo redo | forecolor | formatselect | bold italic backcolor | blocks | blockquote | hr | ' +
                'align | bullist numlist outdent indent | alignjustify | visualchars | wordcount' +
                'link | charmap | code | ltr rtl | emoticons | fullscreen | image | table |' +
                'nonbreaking | pagebreak | print | view |searchreplace | visualblocks | insertdatetime | media | paste | wordcount |' +
                'rotateleft rotateright | ' +
                'removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        )}
      </div>
    </>
  );
};

export default StaticPageDetails;
